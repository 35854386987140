import React, { useMemo, useState } from 'react';
import { FlatList, ImageSourcePropType, ListRenderItemInfo, StyleSheet } from 'react-native';
import { baseTheme, colors, materialTheme, styles as stylesImported } from '../../../assets';
import { onLayoutMeasure } from '../../../utilities/reactUtils';
import { useTranslation } from 'react-i18next';
import { List, Text } from 'react-native-paper';
import { CommonCard } from '../../../components/CommonCard';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import CommonAvatar from '../../../components/CommonAvatar';
import { notImplementedAlert } from '../../../utilities/utils';
import US from '../../../assets/images/flags/us.png';
import EU from '../../../assets/images/flags/eu.png';

enum HubItemType {
  MESSAGES,
  DOCUMENTS,
  FX,
  MAP,
}

type HubItem = {
  title: string;
  type: HubItemType;
  content?: Array<HubItemContentProps>;
};

type HubItemContentProps = {
  label?: string;
  description?: string;
  rightText?: string;
  icon?: IconSource;
  image?: ImageSourcePropType;
};

function HubScreen() {
  const { t } = useTranslation();

  const infoCards: Array<HubItem> = [
    {
      title: t('hub.branchesAndATMs'),
      type: HubItemType.MAP,
      content: [
        {
          label: 'Praha',
          icon: 'city-variant-outline',
          description: 'Pasáž Oasis Florenc',
          rightText: '500 m',
        },
        {
          label: 'Brno',
          icon: 'city-variant-outline',
          description: 'Budova DORN',
          rightText: '180 km',
        },
      ],
    },
    {
      title: t('hub.fxRates'),
      type: HubItemType.FX,
      content: [
        {
          label: '1 EUR 24,44 Kč',
          rightText: 'Směnit',
          image: EU,
        },
        {
          label: '1 USD 20,01 Kč',
          rightText: 'Směnit',
          image: US,
        },
      ],
    },
    {
      title: t('hub.messages'),
      type: HubItemType.MESSAGES,
    },
    {
      title: t('hub.documents'),
      type: HubItemType.DOCUMENTS,
    },
  ];

  const [size, setSize] = useState({ width: 0, height: 0 });

  const columns = 1;
  // const columns = useMemo(() => (size.width > 550 ? 2 : 1), [size]);

  const renderItem = ({ item }: ListRenderItemInfo<HubItem>) => {
    let renderedComponent;
    switch (item.type) {
      case HubItemType.MAP:
        renderedComponent = (
          <CommonCard
            visibleDivider={true}
            style={{ marginVertical: 8 }}
            header={
              <List.Item
                style={{ paddingStart: 6, paddingEnd: 14, paddingVertical: 12 }}
                title={item.title}
                titleStyle={{
                  ...materialTheme.fonts.titleMedium,
                  fontWeight: '500',
                  paddingEnd: 5,
                }}
                rippleColor={baseTheme.colors.ripple}
                onPress={() => {}}
              />
            }
            content={item.content?.map((cItem, cIndex) => (
              <List.Item
                key={cIndex}
                title={cItem.label}
                description={cItem.description}
                left={() => (
                  <CommonAvatar
                    icon={cItem.icon}
                    style={{ backgroundColor: colors.ui_color_secondary }}
                  />
                )}
                right={() => (
                  <Text variant="bodyMedium" style={styles.listItemRight}>
                    {cItem.rightText}
                  </Text>
                )}
                onPress={() => {
                  notImplementedAlert();
                }}
                style={styles.listItem}
              />
            ))}
          />
        );
        break;
      case HubItemType.FX:
        renderedComponent = (
          <CommonCard
            visibleDivider={true}
            style={{ marginVertical: 8 }}
            header={
              <List.Item
                style={{ paddingStart: 6, paddingEnd: 14, paddingVertical: 12 }}
                title={item.title}
                titleStyle={{
                  ...materialTheme.fonts.titleMedium,
                  fontWeight: '500',
                  paddingEnd: 5,
                }}
                rippleColor={baseTheme.colors.ripple}
                onPress={() => {}}
              />
            }
            content={item.content?.map((cItem, cIndex) => (
              <List.Item
                key={cIndex}
                title={cItem.label}
                left={() => (
                  <CommonAvatar
                    image={cItem.image}
                    style={{ backgroundColor: colors.ui_transparent }}
                  />
                )}
                right={() => (
                  <Text variant="bodyMedium" style={styles.listItemRight}>
                    {cItem.rightText}
                  </Text>
                )}
                onPress={() => {
                  notImplementedAlert();
                }}
                style={styles.listItem}
              />
            ))}
          />
        );
        break;
      default:
        renderedComponent = (
          <CommonCard
            style={{ marginVertical: 8 }}
            header={
              <List.Item
                style={{ paddingStart: 6, paddingEnd: 14, paddingVertical: 12 }}
                title={item.title}
                titleStyle={{
                  ...materialTheme.fonts.titleMedium,
                  fontWeight: '500',
                  paddingEnd: 5,
                }}
                rippleColor={baseTheme.colors.ripple}
                onPress={() => {}}
              />
            }
          />
        );
    }
    return renderedComponent;
  };

  return (
    <FlatList
      contentInsetAdjustmentBehavior="automatic"
      numColumns={columns}
      key={columns}
      data={infoCards}
      onLayout={onLayoutMeasure(setSize)}
      renderItem={renderItem}
      contentContainerStyle={{
        ...stylesImported.container,
        paddingTop: 12,
      }}
    />
  );
}

const styles = StyleSheet.create({
  listItem: { paddingStart: 16 },
  listItemRight: { color: colors.ui_black, alignSelf: 'center' },
});

export default HubScreen;
