import React, { useCallback, useEffect, useState } from 'react';
import {
  NestableDraggableFlatList,
  NestableScrollContainer,
  RenderItemParams,
  ScaleDecorator,
} from 'react-native-draggable-flatlist';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { styles } from '../../../assets';
import { SectionItem } from './components/SectionItem';
import { SectionHeader } from './components/SectionHeader';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../types';
import { useNavigation } from '@react-navigation/native';
import { HomeSettingsListItem } from '../../../types/HomeItem';
import useSaveHomeItemsOrder from './hooks/useSaveHomeItemsOrder';
import useSortedHomeSettingsItems from './hooks/useSortedHomeSettingsItems';
import DragHandle from '../../../assets/svg/DragHandle';

/** Screen navigation props. */
export type HomeSettingsScreenProp = StackNavigationProp<RootStackParamList, 'HomeSettings'>;

function HomeSettingsScreen() {
  const { t } = useTranslation('resources');
  const navigation: HomeSettingsScreenProp = useNavigation<HomeSettingsScreenProp>();

  // gets all home item as sorted array
  const [homeItems] = useSortedHomeSettingsItems();
  // defines list items
  const [listItems, setListItems] = useState({
    displayed: new Array<HomeSettingsListItem>(),
    hidden: new Array<HomeSettingsListItem>(),
  });

  // updates list items when new home data arrives
  useEffect(() => {
    setListItems(homeItems);
  }, [homeItems]);

  // save current order when click back on this screen
  useSaveHomeItemsOrder(listItems.displayed.concat(listItems.hidden), navigation);

  const updateItemVisibility = useCallback(
    (saveIdx: string | number, visible: boolean) => {
      let newDisplayed: Array<HomeSettingsListItem>;
      let newHidden: Array<HomeSettingsListItem>;

      if (visible) {
        const idx: number = listItems.displayed.findIndex((value) => value.saveId === saveIdx);
        const item: HomeSettingsListItem = listItems.displayed[idx];

        newDisplayed = listItems.displayed.filter((item) => item.saveId != saveIdx);
        newHidden = listItems.hidden.concat({ ...item, displayed: false });
        console.log(`Item ${item.title} moved into hidden list.`);
      } else {
        const idx: number = listItems.hidden.findIndex((value) => value.saveId === saveIdx);
        const item: HomeSettingsListItem = listItems.hidden[idx];

        newHidden = listItems.hidden.filter((item) => item.saveId != saveIdx);
        newDisplayed = listItems.displayed.concat({ ...item, displayed: true });
        console.log(`Item ${item.title} moved into displayed list.`);
      }

      setListItems({ displayed: newDisplayed, hidden: newHidden });
    },
    [listItems.displayed, listItems.hidden],
  );

  const renderItem = ({ item, drag, isActive }: RenderItemParams<HomeSettingsListItem>) => {
    return (
      <ScaleDecorator activeScale={1.05}>
        <TouchableOpacity onLongPress={drag} disabled={isActive} activeOpacity={1}>
          <SectionItem
            title={item.title}
            hidden={!item.displayed}
            onVisibilityIconClicked={() => {
              updateItemVisibility(item.saveId, item.displayed);
            }}
            draggableArea={({ visible, iconSize }) => (
              <View
                style={{
                  ...styles.homeSettingsListItem,
                  paddingStart: 16,
                }}
              >
                {visible && <DragHandle width={iconSize} height={iconSize} />}
              </View>
            )}
            key={item.saveId}
          />
        </TouchableOpacity>
      </ScaleDecorator>
    );
  };

  return (
    <NestableScrollContainer
      style={{
        ...styles.container,
        paddingTop: 14,
      }}
    >
      {listItems.displayed.length > 0 && (
        <View>
          <SectionHeader
            style={{ paddingBottom: 16, paddingHorizontal: 16 }}
            title={t('homeSettings.displayed')}
            description={t('homeSettings.displayedDescription')}
          />
          <NestableDraggableFlatList
            data={listItems.displayed}
            onDragEnd={({ data }) => setListItems({ ...listItems, displayed: data })}
            keyExtractor={(item) => item.saveId.toString()}
            renderItem={renderItem}
            style={{ paddingHorizontal: 2 }}
            scrollEnabled={false}
          />
        </View>
      )}
      {listItems.hidden.length > 0 && (
        <View>
          <SectionHeader
            style={{ padding: 16 }}
            title={t('homeSettings.hidden')}
            description={t('homeSettings.hiddenDescription')}
          />
          {listItems.hidden.map((item) => (
            <SectionItem
              title={item.title}
              hidden={!item.displayed}
              onVisibilityIconClicked={() => {
                updateItemVisibility(item.saveId, item.displayed);
              }}
              key={item.saveId}
              draggableArea={() => <View />}
            />
          ))}
        </View>
      )}
    </NestableScrollContainer>
  );
}

export default HomeSettingsScreen;
