import * as React from 'react';
import Svg, { SvgProps, Circle } from 'react-native-svg';

const Ellipse = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <Circle cx={12} cy={12} r={12} fill={props.color ?? '#fff'} />
  </Svg>
);

export default Ellipse;
