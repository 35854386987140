import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Account, AccountNumber } from '../../../generated/graphql';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import {
  getDefaultDebitAccount,
  isAccountNumberValid,
  isAccountPrefixValid,
  isBankCodeValid,
} from '../../../utilities/paymentUtils';
import { DomesticAccountValidationResult } from '../components/domestic/DomesticAccountInput';
import { DateTime } from 'luxon';
import { graphQLService } from '../../../services/graphQLService';

type PaymentDomesticContextType = {
  debitAccount: Account | undefined;
  setDebitAccount: (account: Account) => void;
  recipientAccount: AccountNumber;
  setRecipientAccount: (accountNumber: AccountNumber) => void;
  recipientName: string | undefined;
  setRecipientName: (recipientName: string | undefined) => void;
  recipientValidation: DomesticAccountValidationResult;
  paymentData: PaymentData;
  setPaymentData: (paymentData: PaymentData) => void;
  paymentDataValid: boolean;
  resetPayment: () => void;
  resetPaymentData: () => void;
  balanceInfoAmount: number | undefined;
  sendPayment: () => void;
  loading: boolean;
  navigateToVictory: boolean;
  showError: boolean;
  resetError: () => void;
};

export type PaymentData = {
  amount: number;
  dueDate?: DateTime;
  message?: string;
  note?: string;
  symbolVariable?: string;
  symbolConstant?: string;
  symbolSpecific?: string;
};

const defaultState: PaymentDomesticContextType = {
  debitAccount: undefined,
  setDebitAccount: () => {},
  recipientAccount: { prefix: '', number: '', bankCode: '' },
  setRecipientAccount: () => {},
  recipientName: undefined,
  setRecipientName: () => {},
  recipientValidation: {},
  paymentData: { amount: 0, dueDate: DateTime.now() },
  setPaymentData: () => {},
  paymentDataValid: true,
  resetPayment: () => {},
  resetPaymentData: () => {},
  balanceInfoAmount: undefined,
  sendPayment: () => {},
  loading: false,
  navigateToVictory: false,
  showError: false,
  resetError: () => {},
};

const PaymentDomesticContext = createContext<PaymentDomesticContextType>(defaultState);

export const PaymentDomesticProvider: FC<PropsWithChildren> = ({ children }) => {
  const globalContext = useGlobalContext();
  const { userData } = useGlobalContext();

  const [debitAccount, setDebitAccount] = useState<Account>();
  const [recipient, setRecipient] = useState<AccountNumber>(defaultState.recipientAccount);
  const [recipientName, setRecipientName] = useState<string | undefined>(
    defaultState.recipientName,
  );
  const [recipientValidation, setRecipientValidation] = useState(defaultState.recipientValidation);
  const [paymentData, setPaymentData] = useState<PaymentData>(defaultState.paymentData);
  const [paymentDataValid, setPaymentDataValid] = useState(true);
  const [balanceInfoAmount, setBalanceInfoAmount] = useState<number | undefined>(
    defaultState.balanceInfoAmount,
  );
  const [loading, setLoading] = useState(defaultState.loading);
  const [navigateToVictory, setNavigateToVictory] = useState(defaultState.navigateToVictory);
  const [showError, setShowError] = useState(defaultState.showError);

  useEffect(() => {
    const { userAccounts } = userData;
    if (userAccounts?.accounts) {
      if (!debitAccount) {
        setDebitAccount(getDefaultDebitAccount(userAccounts.accounts, true));
      }
    }
  }, [debitAccount, userData]);

  useEffect(() => {
    setRecipientValidation({
      isPrefixValid: isAccountPrefixValid(recipient.prefix ?? ''),
      isAccountNumberValid: isAccountNumberValid(recipient.number),
      isBankCodeValid: isBankCodeValid(recipient.bankCode),
    });
  }, [recipient]);

  useEffect(() => {
    // TODO
    setPaymentDataValid(true);
  }, [paymentData]);

  useEffect(() => {
    if (debitAccount?.availableBalance && paymentData.amount) {
      setBalanceInfoAmount(debitAccount.availableBalance - paymentData.amount);
    } else {
      setBalanceInfoAmount(undefined);
    }
  }, [paymentData, debitAccount]);

  const resetPayment = () => {
    refreshAccounts();
    setNavigateToVictory(false);
    setLoading(false);
    resetError();
    setRecipient(defaultState.recipientAccount);
    setRecipientName(undefined);
    resetPaymentData();
    const { userAccounts } = userData;
    if (userAccounts?.accounts) {
      setDebitAccount(getDefaultDebitAccount(userAccounts.accounts));
    }
  };

  const resetPaymentData = () => {
    setPaymentData(defaultState.paymentData);
  };

  const resetError = () => {
    setShowError(false);
  };

  const sendPayment = () => {
    if (loading || !debitAccount || !recipient) return;
    setLoading(true);
    graphQLService
      .mutationDomesticPaymentOrder({
        amount: paymentData.amount,
        constantSymbol: paymentData.symbolConstant ?? '',
        creditAccountNumber: {
          prefix: recipient.prefix,
          number: recipient.number,
          bankCode: recipient.bankCode,
        },
        debitAccountId: debitAccount.id,
        executionDate: paymentData.dueDate?.toISO().slice(0, 10),
        messageForMe: paymentData.note ?? '',
        messageForRecipient: paymentData.message ?? '',
        specificSymbol: paymentData.symbolSpecific ?? '',
        variableSymbol: paymentData.symbolVariable ?? '',
      })
      .then((response) => {
        console.debug(response.domesticPaymentOrder);
        setNavigateToVictory(true);
      })
      .catch((reason) => {
        console.error(reason);
        setShowError(true);
      })
      .finally(() => {
        setLoading(false);
        refreshAccounts();
      });
  };

  const refreshAccounts = React.useCallback(() => {
    globalContext.queryUserAccounts().catch(console.error);
  }, [globalContext]);

  return (
    <PaymentDomesticContext.Provider
      value={{
        debitAccount: debitAccount,
        setDebitAccount: setDebitAccount,
        recipientAccount: recipient,
        setRecipientAccount: setRecipient,
        recipientName: recipientName,
        setRecipientName: setRecipientName,
        recipientValidation: recipientValidation,
        paymentData: paymentData,
        setPaymentData: setPaymentData,
        paymentDataValid: paymentDataValid,
        resetPayment: resetPayment,
        resetPaymentData: resetPaymentData,
        balanceInfoAmount: balanceInfoAmount,
        sendPayment: sendPayment,
        loading: loading,
        navigateToVictory: navigateToVictory,
        showError: showError,
        resetError: resetError,
      }}
    >
      {children}
    </PaymentDomesticContext.Provider>
  );
};

export const usePaymentDomestic = () => {
  const context = useContext(PaymentDomesticContext);

  if (!context) {
    throw new Error('usePaymentDomestic() must be used within an PaymentDomesticProvider');
  }

  return context;
};
