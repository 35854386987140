import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../types';
import { useTranslation } from 'react-i18next';

// screens
import WebLoginScreen from '../../screens/WebLogin/WebLoginScreen';

const Stack = createNativeStackNavigator<RootStackParamList>();

function WebLoginStack() {
  const { t } = useTranslation('resources');

  return (
    <Stack.Navigator initialRouteName="WebLogin" screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="WebLogin"
        component={WebLoginScreen}
        options={{
          title: t('login.titleWeb'),
        }}
      />
    </Stack.Navigator>
  );
}

export default WebLoginStack;
