import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, TextInput as RNTextInput, View } from 'react-native';
import { HelperText, Text, TextInput } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../utilities/reactUtils';
import { colors as assetColors } from '../../../../../assets';
import { usePaymentDomestic } from '../../../context/PaymentDomesticContext';

export type DomesticAccountValidationResult = {
  isPrefixValid?: boolean;
  isAccountNumberValid?: boolean;
  isBankCodeValid?: boolean;
};

interface DomesticAccountInputProps {
  ref_input_account_prefix: React.RefObject<RNTextInput>;
  ref_input_account_number: React.RefObject<RNTextInput>;
  ref_input_bank_code: React.RefObject<RNTextInput>;
}

const DomesticAccountInput = ({
  ref_input_account_prefix,
  ref_input_account_number,
  ref_input_bank_code,
}: DomesticAccountInputProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { recipientAccount, setRecipientAccount, recipientValidation } = usePaymentDomestic();
  const { isPrefixValid, isBankCodeValid, isAccountNumberValid } = recipientValidation;

  const [accountPrefix, setAccountPrefix] = useState(recipientAccount.prefix ?? '');
  const [accountNumber, setAccountNumber] = useState(recipientAccount.number);
  const [bankCode, setBankCode] = useState(recipientAccount.bankCode);
  const [showPrefixError, setShowPrefixError] = useState(false);
  const [showShowAccNumberError, setShowAccNumberError] = useState(false);

  const setPrefix = (prefix: string) => {
    setShowPrefixError(false);
    const filtered = prefix.replace(/\D/g, '');
    setAccountPrefix(filtered);
  };

  const setNumber = (number: string) => {
    setShowAccNumberError(false);
    const filtered = number.replace(/\D/g, '');
    setAccountNumber(filtered);
  };

  const setBank = (bank: string) => {
    const filtered = bank.replace(/\D/g, '');
    setBankCode(filtered);
  };

  const onBlur = useCallback(() => {
    if (!isPrefixValid) {
      setShowPrefixError(true);
    }
    if (!isAccountNumberValid && accountNumber.length !== 0) {
      setShowAccNumberError(true);
    }
  }, [isPrefixValid, isAccountNumberValid, accountNumber]);

  useEffect(() => {
    setRecipientAccount({
      prefix: accountPrefix,
      number: accountNumber,
      bankCode: bankCode,
    });
  }, [accountPrefix, accountNumber, bankCode, setRecipientAccount]);

  return (
    <>
      <View style={styles.container}>
        <TextInput
          ref={ref_input_account_prefix}
          label={t('payment.domesticAccount.accountInputPrefixLabel')}
          placeholder={t('payment.domesticAccount.accountInputPrefixPlaceholder')}
          activeUnderlineColor={colors.secondary}
          onChangeText={setPrefix}
          onBlur={onBlur}
          value={accountPrefix}
          error={showPrefixError}
          textContentType="none"
          keyboardType="numeric"
          maxLength={6}
          autoFocus={false}
          style={styles.prefixInput}
        />
        <Text style={styles.divider}>-</Text>
        <TextInput
          ref={ref_input_account_number}
          label={t('payment.domesticAccount.accountInputNumberLabel')}
          placeholder={t('payment.domesticAccount.accountInputNumberPlaceholder')}
          activeUnderlineColor={colors.secondary}
          onChangeText={setNumber}
          onBlur={onBlur}
          value={accountNumber}
          error={accountNumber.length !== 0 && showShowAccNumberError}
          textContentType="none"
          keyboardType="numeric"
          maxLength={10}
          autoFocus={true}
          style={styles.accountNumberInput}
        />
        <Text style={styles.divider}>/</Text>
        <TextInput
          ref={ref_input_bank_code}
          label={t('payment.domesticAccount.accountInputBankCodeLabel')}
          placeholder={t('payment.domesticAccount.accountInputBankCodePlaceholder')}
          activeUnderlineColor={colors.secondary}
          onChangeText={setBank}
          value={bankCode}
          error={bankCode.length === 4 && !isBankCodeValid}
          textContentType="none"
          keyboardType="numeric"
          maxLength={4}
          autoFocus={false}
          style={styles.bankCodeInput}
        />
      </View>
      {(showPrefixError || showShowAccNumberError) && (
        <HelperText style={styles.helper} type={'error'}>
          {t('payment.domesticAccount.accountValidationError')}
        </HelperText>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 56,
    flexDirection: 'row',
    backgroundColor: assetColors.ui_base_bg,
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    marginHorizontal: 16,
    marginTop: 13,
  },
  prefixInput: { width: '26%' },
  divider: { marginTop: 24, width: '5%', textAlign: 'center' },
  accountNumberInput: { width: '34%' },
  bankCodeInput: { width: '30%' },
  helper: { marginStart: 8 },
});

export default DomesticAccountInput;
