import { useEffect, useState } from 'react';
import {
  HomeItemType,
  HomeSettingsListItem,
  SavableHomeSettingsItem,
} from '../../../../types/HomeItem';
import { asyncStorageService } from '../../../../services/asyncStorageService';
import useHomeItems from '../../hooks/useHomeItems';
import { sortItems } from '../../../../types/SortableListItem';

/**
 * This hook gathers all categories that should be shown on the home screen.
 * It sorts the final list according to the saved order. If there is no previously
 * saved order it uses default one (based on the item type).
 */
export default function useSortedHomeSettingsItems() {
  const [listItems, setListItems] = useState({
    displayed: new Array<HomeSettingsListItem>(),
    hidden: new Array<HomeSettingsListItem>(),
  });
  // Home items
  const [homeItems] = useHomeItems();
  // Home items order
  const [homeItemsOrder, setHomeItemsOrder] = useState<Array<SavableHomeSettingsItem> | null>(null);

  // load ordered list items only once
  useEffect(() => {
    void asyncStorageService
      .getHomeItemsOrder()
      .then((values) => setHomeItemsOrder(values))
      .catch((err: Error) => console.log(`AsyncStorageServiceErr: ${err.message}`));
  }, []);

  useEffect(() => {
    // merged home items into one list
    const merged = new Array<HomeSettingsListItem>()
      .concat(
        homeItems.accounts.map(({ id, name }) => ({
          saveId: id,
          sortId: -1,
          type: HomeItemType.ACCOUNT,
          displayed: true,
          title: name,
        })),
      )
      .concat(homeItems.cards)
      .concat(homeItems.offers)
      .concat(homeItems.others);

    // sort merged items and save
    const sortedItems = sortItems<SavableHomeSettingsItem, HomeSettingsListItem, HomeItemType>(
      homeItemsOrder,
      merged,
    );
    setListItems({
      displayed: sortedItems.filter((item) => item.displayed),
      hidden: sortedItems.filter((item) => !item.displayed),
    });
  }, [homeItems, homeItemsOrder]);

  return [listItems];
}
