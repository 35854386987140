import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { prepareTestID } from '../../utilities/utils';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface HeaderLeftProps {
  onPress: () => void;
}

function HeaderLeft({ onPress }: HeaderLeftProps) {
  return (
    <TouchableOpacity
      onPress={onPress}
      hitSlop={{ top: 25, bottom: 50, left: 15, right: 15 }}
      testID={prepareTestID('button_navigate_up')}
    >
      <MaterialCommunityIcons
        name="arrow-left"
        size={24}
        style={{ borderRadius: 12, marginLeft: Platform.OS === 'web' ? 16 : 0 }}
      />
    </TouchableOpacity>
  );
}

export default HeaderLeft;
