import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { colors } from '../../../assets';
import { RootStackParamList } from '../../../types';
import { PaymentDomesticProvider } from '../../../features/Payment/context/PaymentDomesticContext';
import { PaymentToaProvider } from '../../../features/Payment/context/PaymentToaContext';

// screens
import PaymentScreen from '../../../screens/Main/Payment/PaymentScreen';
import PaymentDomesticRecipientScreen from '../../../features/Payment/screens/domestic/PaymentDomesticRecipientScreen';
import PaymentDomesticScreen from '../../../features/Payment/screens/domestic/PaymentDomesticScreen';
import PaymentDomesticSummaryScreen from '../../../features/Payment/screens/domestic/PaymentDomesticSummaryScreen';
import PaymentDomesticAuthorizationScreen from '../../../features/Payment/screens/domestic/PaymentDomesticAuthorizationScreen';
import PaymentToaSummaryScreen from '../../../features/Payment/screens/toa/PaymentToaSummaryScreen';
import PaymentToaVictoryScreen from '../../../features/Payment/screens/toa/PaymentToaVictoryScreen';
import PaymentToaScreen from '../../../features/Payment/screens/toa/PaymentToaScreen';
import PaymentToaDateScreen from '../../../features/Payment/screens/toa/PaymentToaDateScreen';
import PaymentDomesticDateScreen from '../../../features/Payment/screens/domestic/PaymentDomesticDateScreen';
import PaymentDomesticQrScreen from '../../../features/Payment/screens/domestic/PaymentDomesticQrScreen';
import { useTranslation } from 'react-i18next';

const Stack = createNativeStackNavigator<RootStackParamList>();

function PaymentStack() {
  const { t } = useTranslation('resources');

  return (
    <PaymentToaProvider>
      <PaymentDomesticProvider>
        <Stack.Navigator
          initialRouteName="Payment"
          screenOptions={{
            headerStyle: { backgroundColor: colors.ui_white },
            headerLargeTitle: true,
            headerTitleStyle: { color: colors.ui_black },
            headerShadowVisible: false,
          }}
        >
          <Stack.Screen
            name="Payment"
            component={PaymentScreen}
            options={{
              title: t('payment.title'),
              headerShown: true,
            }}
          />
          <Stack.Group
            screenOptions={{
              headerShown: false,
              presentation: 'modal',
              animation: 'slide_from_bottom',
            }}
          >
            <Stack.Screen name={'PaymentDomesticModal'} component={PaymentDomesticModalStack} />
            <Stack.Screen name={'PaymentToaModal'} component={PaymentToaModalStack} />
          </Stack.Group>
        </Stack.Navigator>
      </PaymentDomesticProvider>
    </PaymentToaProvider>
  );
}

const PaymentDomesticModalStack = () => {
  return (
    <Stack.Navigator initialRouteName="PaymentDomesticRecipient">
      <Stack.Group screenOptions={{ headerShown: false }}>
        <Stack.Screen name="PaymentDomesticRecipient" component={PaymentDomesticRecipientScreen} />
        <Stack.Screen name="PaymentDomesticQR" component={PaymentDomesticQrScreen} />
        <Stack.Screen
          name="PaymentDomestic"
          component={PaymentDomesticScreen}
          options={({
            route: {
              params: { allowBack },
            },
          }) => ({ animation: 'slide_from_right', gestureEnabled: allowBack })}
          initialParams={{ allowBack: true }}
        />
        <Stack.Screen
          name="PaymentDomesticDate"
          component={PaymentDomesticDateScreen}
          options={{ presentation: 'modal' }}
        />
        <Stack.Screen
          name="PaymentDomesticSummary"
          component={PaymentDomesticSummaryScreen}
          options={{ animation: 'slide_from_right' }}
        />
        <Stack.Screen
          name="PaymentDomesticAuthorization"
          component={PaymentDomesticAuthorizationScreen}
          options={{ animation: 'slide_from_right', gestureEnabled: false }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

const PaymentToaModalStack = () => {
  return (
    <Stack.Navigator initialRouteName="PaymentToa">
      <Stack.Group screenOptions={{ headerShown: false }}>
        <Stack.Screen name="PaymentToa" component={PaymentToaScreen} />
        <Stack.Screen
          name="PaymentToaSummary"
          component={PaymentToaSummaryScreen}
          options={{ animation: 'slide_from_right' }}
        />
        <Stack.Screen
          name="PaymentToaDate"
          component={PaymentToaDateScreen}
          options={{ presentation: 'modal' }}
        />
        <Stack.Screen
          name="PaymentToaVictory"
          component={PaymentToaVictoryScreen}
          options={{ animation: 'slide_from_right', gestureEnabled: false }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default PaymentStack;
