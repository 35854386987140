import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CurveArrowRight = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.13 10.23 13.5 4v4c-7.1.28-10 6.13-10 12 0 0 3.88-5.69 10-6v4l7.63-6.23a1 1 0 0 0 0-1.54Z"
    />
  </Svg>
);

export default CurveArrowRight;
