import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { colors } from '../../../assets';
import { RootStackParamList } from '../../../types';
import { useTranslation } from 'react-i18next';

// screens
import HubScreen from '../../../screens/Main/Hub/HubScreen';

const Stack = createNativeStackNavigator<RootStackParamList>();

function HubStack() {
  const { t } = useTranslation('resources');

  return (
    <Stack.Navigator
      initialRouteName="Hub"
      screenOptions={{ headerStyle: { backgroundColor: colors.ui_white } }}
    >
      <Stack.Screen
        name="Hub"
        component={HubScreen}
        options={{
          title: t('hub.title'),
          headerLargeTitle: true,
          headerTitleStyle: { color: colors.ui_black },
          headerShadowVisible: false,
        }}
      />
    </Stack.Navigator>
  );
}

export default HubStack;
