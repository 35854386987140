import React, { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../types';
import { usePaymentToa } from '../../context/PaymentToaContext';
import Calendar, { CalendarDateChangeCallBack } from '../../../../components/Calendar';
import { useTranslation } from 'react-i18next';
import PaymentModalScreen from '../../components/shared/PaymentModalScreen';
import { DateTime } from 'luxon';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentToaDate'>;

const PaymentToaDateScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavProp>();

  const { paymentData, setPaymentData } = usePaymentToa();
  const selectedStartDate = paymentData.dueDate;

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onDatePressed = useCallback(
    ({ date }: CalendarDateChangeCallBack) => {
      setPaymentData({ ...paymentData, dueDate: date });
      onBack();
    },
    [onBack, paymentData, setPaymentData],
  );

  const currentDate = useMemo(() => DateTime.now(), []);
  const maxDate = useMemo(() => currentDate.plus({ year: 1 }), [currentDate]);

  return (
    <PaymentModalScreen
      headerProps={{ headerText: t('payment.toaDate.header'), onClosePress: onBack }}
      content={
        <Calendar
          onDateChange={onDatePressed}
          minDate={currentDate}
          maxDate={maxDate}
          selectedStartDate={selectedStartDate}
          shouldResize={Platform.OS === 'web' || (Platform.OS === 'ios' && Platform.isPad)}
        />
      }
    />
  );
};

export default PaymentToaDateScreen;
