import i18next from 'i18next';
import { asyncStorageService } from './asyncStorageService';
import * as Localization from 'expo-localization';
import { Language, parseLanguage } from '../types/Language';

async function initLanguage(): Promise<void> {
  const savedLanguage = await asyncStorageService.getLanguage();
  await i18next.changeLanguage(savedLanguage || parseLanguage(Localization.locale));
}

async function changeLanguage(language: Language): Promise<void> {
  const i18nChange = i18next.changeLanguage(language);
  const asyncSave = asyncStorageService.setLanguage(language);

  await i18nChange;
  await asyncSave;
}

function getI18Language(): Language {
  return parseLanguage(i18next.language);
}

export const languageService = {
  initLanguage,
  changeLanguage,
  getI18Language,
};
