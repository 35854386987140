import * as Localization from 'expo-localization';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'intl-pluralrules';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import * as csResources from './cs/resources.json';
import * as enResources from './en/resources.json';
import { biometry as csBiometry } from './cs/biometry';
import { biometry as enBiometry } from './en/biometry';
import { parseLanguage } from '../types/Language';

export const defaultNS = 'resources';
export const resources = {
  en: {
    resources: enResources,
    biometry: enBiometry,
  },
  cs: {
    resources: csResources,
    biometry: csBiometry,
  },
} as const;

void i18n
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources,
    lng: parseLanguage(Localization.locale),
    ns: ['resources', 'biometry'],
    defaultNS,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
