import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const EyeOff = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.697 4.638 8.839 8.78c.3-.29.71-.469 1.161-.469.92 0 1.667.743 1.667 1.66 0 .452-.182.862-.476 1.16l4.112 4.114a.833.833 0 1 1-1.178 1.178l-.75-.75c-1.07.62-2.196.935-3.375.935-2.988 0-5.626-2.016-7.922-5.92l-.176-.303-.235-.414.235-.414c.702-1.232 1.435-2.284 2.201-3.155l-.585-.586a.833.833 0 1 1 1.179-1.178ZM3.788 9.64l-.2.33.173.286c1.968 3.192 4.05 4.692 6.239 4.692.727 0 1.443-.166 2.146-.504l-3.149-3.149a1.672 1.672 0 0 1-.332-.332L5.28 7.579A16.262 16.262 0 0 0 3.788 9.64ZM10 3.333c2.988 0 5.626 2.016 7.922 5.92l.176.304.235.414-.407.71c-.64 1.09-1.308 2.034-2.002 2.827l-1.177-1.177a16.92 16.92 0 0 0 1.665-2.36C14.393 6.582 12.252 4.993 10 4.993a4.89 4.89 0 0 0-2.107.485L6.662 4.246c1.06-.606 2.172-.913 3.338-.913Z"
      fill="#000"
    />
  </Svg>
);

export default EyeOff;
