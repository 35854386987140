import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const TouchId = (props: SvgProps) => (
  <Svg viewBox="0 0 30 30" {...props}>
    <Path
      fill="none"
      stroke={props.color ?? '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M8.692 10.08A7.965 7.965 0 0 0 7 15c0 4.816-.811 8.005-.811 8.005M22 24.818s1-6.656 1-9.818a8 8 0 0 0-10.666-7.545"
    />
    <Path
      fill="none"
      stroke={props.color ?? '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M18.869 18.728C18.948 17.496 19 16.226 19 15a4 4 0 0 0-8 0c0 7.112-1 10.941-1 10.941m8 .708s.221-1.379.455-3.401m-4.433 2.644c-.159.727-.273 1.09-.273 1.09M15 15c0 2.755-.148 5.034-.343 6.838m-11.63-7.649a12.092 12.092 0 0 0 .336 3.739m19.34-12.13A11.951 11.951 0 0 0 15 3c-4.97 0-9.235 3.022-11.057 7.328m22.553 8.109A11.93 11.93 0 0 0 27 15c0-2.089-.534-4.053-1.472-5.764"
    />
  </Svg>
);

export default TouchId;
