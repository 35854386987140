import { MD3Theme, useTheme as _useTheme } from 'react-native-paper';
import { LayoutChangeEvent } from 'react-native';

export function useTheme(): MD3Theme {
  return _useTheme();
}

type Measure = { width: number; height: number };

export function onLayoutMeasure(
  onMeasure: (measure: Measure) => void,
): (event: LayoutChangeEvent) => void {
  return ({ nativeEvent: { layout } }: LayoutChangeEvent) => onMeasure(layout);
}
