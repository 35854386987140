import React, { useEffect, useMemo, useState } from 'react';
import { Button, List, Text } from 'react-native-paper';
import { FlatList } from 'react-native-gesture-handler';
import { ListRenderItemInfo, Pressable, StyleSheet, View } from 'react-native';
import { colors, styles as stylesImported } from '../../../../../assets';
import { Account } from '../../../../../generated/graphql';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';
import { useTranslation } from 'react-i18next';
import {
  getCurrencyLabel,
  getDomesticPaymentAccounts,
  getToaPaymentAccounts,
} from '../../../../../utilities/paymentUtils';
import { formatAmount } from '../../../../../utilities/formatUtils';
import { useTheme } from '../../../../../utilities/reactUtils';
import CommonAvatar from '../../../../../components/CommonAvatar';

export enum AccountPickerVariant {
  DOMESTIC_PAYMENT,
  TOA_PAYMENT,
}
interface AccountPickerOverlayProps {
  visible: boolean;
  variant: AccountPickerVariant;
  onDismiss: () => void;
  onSubmit: (selected: Account) => void;
}

const AccountPickerOverlay = ({
  visible,
  variant,
  onDismiss,
  onSubmit,
}: AccountPickerOverlayProps) => {
  const { t } = useTranslation();
  const { fonts } = useTheme();
  const { userData } = useGlobalContext();
  const [accounts, setAccounts] = useState<Account[]>([]);

  useEffect(() => {
    const userAccounts = userData.userAccounts?.accounts ?? [];
    switch (variant) {
      case AccountPickerVariant.DOMESTIC_PAYMENT:
        setAccounts(getDomesticPaymentAccounts(userAccounts));
        break;
      case AccountPickerVariant.TOA_PAYMENT:
        setAccounts(getToaPaymentAccounts(userAccounts));
        break;
    }
  }, [userData, variant]);

  const itemAvatar = useMemo(() => {
    return <CommonAvatar size={32} avatar="USER" />;
  }, []);

  const itemView = ({ item }: ListRenderItemInfo<Account>) => (
    <List.Item
      title={item.name}
      description={formatAmount(item.availableBalance, getCurrencyLabel(item.currencyCode), true)}
      titleStyle={fonts.bodyMedium}
      descriptionStyle={{ ...fonts.bodySmall, opacity: 0.6 }}
      left={() => itemAvatar}
      right={() => (
        <Text variant="bodyMedium" style={styles.listItemRight}>
          {t('payment.accountPicker.buttonText')}
        </Text>
      )}
      onPress={() => {
        onSubmit(item);
      }}
      style={styles.listItem}
    />
  );
  if (!visible) return null;

  return (
    <View style={styles.container}>
      <Pressable onPress={onDismiss} style={styles.overlay} />
      <View style={{ ...stylesImported.container, ...styles.sheetContainer }}>
        <View style={styles.sheet}>
          <Text variant="headlineSmall" style={styles.sheetTitle}>
            {t('payment.accountPicker.title')}
          </Text>
          <FlatList
            centerContent={true}
            data={accounts}
            keyExtractor={(type, index) => `${index}`}
            renderItem={itemView}
            scrollEnabled={true}
            contentContainerStyle={styles.sheetList}
          />
          <Button
            mode="contained"
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
            }}
            onPress={onDismiss}
            style={stylesImported.formActionButton}
            contentStyle={{ width: '100%', height: '100%' }}
          >
            {t('payment.accountPicker.buttonClose')}
          </Button>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: colors.ui_grey_32,
    opacity: 0.4,
  },
  sheetContainer: { position: 'absolute', right: 0, left: 0, bottom: 0 },
  sheet: {
    backgroundColor: colors.ui_white,
    borderTopStartRadius: 32,
    borderTopEndRadius: 32,
    marginHorizontal: 8,
    paddingTop: 24,
    paddingHorizontal: 16,
    paddingBottom: 24,
  },
  sheetTitle: { marginTop: 8, marginBottom: 16, alignSelf: 'center' },
  sheetList: { paddingBottom: 16 },
  listItem: { paddingStart: 16 },
  listItemRight: { color: colors.ui_black, alignSelf: 'center' },
});

export default AccountPickerOverlay;
