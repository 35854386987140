import React, { useCallback, useEffect } from 'react';
import { Button, Text } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../types';
import { useTheme } from '../../../../utilities/reactUtils';
import { useTranslation } from 'react-i18next';
import PaymentSummaryTop from '../../components/shared/PaymentSummaryTop';
import { formatAmount, formatDate } from '../../../../utilities/formatUtils';
import { usePaymentToa } from '../../context/PaymentToaContext';
import PaymentToaSummarySections from '../../components/toa/PaymentToaSummarySections';
import Toast, { ToastVariant } from '../../../../components/Toast';
import { getCurrencyLabel } from '../../../../utilities/paymentUtils';
import PaymentModalScreen from '../../components/shared/PaymentModalScreen';
import { colors, styles } from '../../../../assets';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentToaSummary'>;

const PaymentToaSummaryScreen = () => {
  const { t } = useTranslation();
  const { fonts } = useTheme();
  const navigation = useNavigation<NavProp>();

  const {
    debitAccount,
    balanceInfoAmount,
    loading,
    sendPayment,
    navigateToVictory,
    showError,
    resetError,
  } = usePaymentToa();

  const { paymentData } = usePaymentToa();
  const { amount, dueDate } = paymentData;

  const onClose = useCallback(() => {
    resetError();
    navigation.navigate('Payment');
  }, [navigation, resetError]);

  const onBack = useCallback(() => {
    if (!loading) {
      resetError();
      navigation.goBack();
    }
  }, [loading, navigation, resetError]);

  const onContinue = useCallback(() => {
    resetError();
    if (!loading) {
      sendPayment();
    }
  }, [loading, resetError, sendPayment]);

  useEffect(() => {
    if (navigateToVictory) {
      navigation.navigate('PaymentToaVictory');
    }
  }, [navigateToVictory, navigation]);

  return (
    <PaymentModalScreen
      headerProps={{ onBackPress: onBack, onClosePress: onClose }}
      content={
        <>
          <PaymentSummaryTop
            avatarData={{ avatar: 'USER' }}
            headlineText={formatAmount(amount, getCurrencyLabel(debitAccount?.currencyCode), true)}
            subTextLeft={debitAccount?.name ?? ''}
            subTextRight={dueDate ? formatDate(dueDate) : t('payment.toaSummary.dueDateNow')}
          />
          <PaymentToaSummarySections />
          {balanceInfoAmount && (
            <Text variant={'bodyMedium'} style={{ marginTop: 8, padding: 16, alignSelf: 'center' }}>
              {t('payment.toaSummary.remainingBalanceText', {
                balance: formatAmount(
                  balanceInfoAmount,
                  getCurrencyLabel(debitAccount?.currencyCode),
                  true,
                ),
              })}
            </Text>
          )}
        </>
      }
      footerContent={
        <Button
          onPress={onContinue}
          disabled={loading}
          loading={loading}
          mode="contained"
          theme={{
            roundness: 8,
            colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
          }}
          style={styles.formActionButton}
          labelStyle={fonts.labelSmall}
          contentStyle={{ width: '100%', height: '100%' }}
        >
          {t('payment.toaSummary.buttonContinue', {
            amount: formatAmount(amount, getCurrencyLabel(debitAccount?.currencyCode), true),
          })}
        </Button>
      }
      otherContent={
        showError && (
          <Toast
            text={t('error.unknown')}
            variant={ToastVariant.Error}
            style={{
              position: 'absolute',
              marginTop: 24,
              margin: 16,
              start: 0,
              end: 0,
              zIndex: 2,
            }}
          />
        )
      }
    />
  );
};

export default PaymentToaSummaryScreen;
