import React, { useEffect, useState, useRef, useMemo } from 'react';
import SafeAreaViewFixed from '../../components/SafeAreaViewFixed';
import { TextInput as RNTextInput, View, Alert, ScrollView } from 'react-native';
import { colors as assetColors, styles } from '../../assets';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../types';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import DeviceInfo from 'react-native-device-info';
import KeyboardAware from '../../components/KeyboardAware';
import { notImplementedAlert, prepareTestID } from '../../utilities/utils';
import { formatPhoneNumber } from '../../utilities/formatUtils';
import { Button, Text, TextInput } from 'react-native-paper';
import { useTheme } from '../../utilities/reactUtils';
import { MOCK_USER_ID_1, MOCK_USER_ID_2 } from '@env';

type ActivationProp = StackNavigationProp<RootStackParamList, 'MobileActivationVerification'>;
type MobileActivationVerificationRouteProp = RouteProp<
  RootStackParamList,
  'MobileActivationVerification'
>;

function MobileActivationVerificationScreen() {
  const { t } = useTranslation('resources');
  const navigation = useNavigation<ActivationProp>();
  const route = useRoute<MobileActivationVerificationRouteProp>();
  const globalContext = useGlobalContext();
  const { colors, fonts } = useTheme();

  const [formActionInProgress, setFormActionInProgress] = React.useState(false);
  const phoneNumber = formatPhoneNumber(route.params.phoneNumber);
  const [password, setPassword] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [canResendSms, setCanResendSms] = useState(false);

  const ref_input_ibPassword = useRef<RNTextInput>(null);
  const ref_input_smsCode = useRef<RNTextInput>(null);

  useEffect(() => {
    DeviceInfo.getDeviceName().then(setDeviceName).catch(console.error);
    setTimeout(() => ref_input_ibPassword.current?.focus(), 700);
  }, []);

  useEffect(() => {
    if (!canResendSms) setTimeout(() => setCanResendSms(true), 60 * 1000);
  }, [canResendSms]);

  const actionButtonTitle = useMemo(() => {
    if (password.length >= 8 && smsCode.length === 6) {
      return t('common.continue');
    } else if (password.length >= 8 && smsCode.length !== 6) {
      return t('mobileActivation.smsCodePrompt');
    } else {
      return t('mobileActivation.ibPasswordPrompt');
    }
  }, [password, smsCode, t]);

  const submitForm = () => {
    setFormActionInProgress(true);

    // RFR HACK
    const device = deviceName === 'sdk_gphone64_arm64' ? 'Pixel 5' : deviceName;
    const smsCodeLastChar = smsCode.slice(-1);
    const userId = smsCodeLastChar == '1' ? MOCK_USER_ID_1 : MOCK_USER_ID_2;

    globalContext
      .removeActivationLocal()
      .then(() => globalContext.createActivation(userId, device))
      .then(() => navigation.navigate('MobileActivationPassword'))
      .catch((reason) => {
        setFormActionInProgress(false);
        //TODO: error handling
        Alert.alert(
          t('mobileActivation.invalidCredentialsTitle'),
          t('mobileActivation.invalidCredentialsMessage'),
          [
            {
              text: t('mobileActivation.invalidCredentialsButtonTitle'),
              onPress: () => {
                setTimeout(() => {
                  ref_input_ibPassword.current?.focus();
                }, 10);
              },
            },
          ],
        );
        console.debug(reason);
      });
  };

  const onActionButtonSubmit = () => {
    if (password.length >= 8 && smsCode.length === 6) {
      submitForm();
    } else if (password.length >= 8 && smsCode.length !== 6) {
      ref_input_smsCode.current?.focus();
    } else {
      ref_input_ibPassword.current?.focus();
    }
  };

  const ActionButton = () => {
    return (
      <Button
        onPress={onActionButtonSubmit}
        disabled={formActionInProgress}
        loading={formActionInProgress}
        mode="contained"
        theme={{
          roundness: 8,
          colors: { primary: assetColors.ui_turq_prim, onPrimary: assetColors.ui_black },
        }}
        style={styles.formActionButton}
        labelStyle={fonts.labelMedium}
        contentStyle={{ width: '100%', height: '100%' }}
        testID={prepareTestID('button_continue')}
      >
        {actionButtonTitle}
      </Button>
    );
  };

  return (
    <SafeAreaViewFixed style={styles.screenBase} edges={['left', 'right', 'bottom']}>
      <KeyboardAware style={{ width: '100%', flex: 1 }}>
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          keyboardShouldPersistTaps="always"
          keyboardDismissMode="interactive"
          contentContainerStyle={styles.formContainer}
        >
          <TextInput
            ref={ref_input_ibPassword}
            label={t('mobileActivation.ibPassword')}
            placeholder={t('mobileActivation.ibPasswordPlaceholder')}
            activeUnderlineColor={colors.secondary}
            onChangeText={setPassword}
            value={password}
            secureTextEntry={true}
            textContentType="password"
            autoComplete="password"
            returnKeyType="done"
            onSubmitEditing={onActionButtonSubmit}
            editable={!formActionInProgress}
            testID={prepareTestID('activation_verification_password_ibs_input')}
          />
          <Text
            variant="bodySmall"
            style={styles.inputMessage}
            testID={prepareTestID('activation_verification_password_info_text')}
          >
            {t('mobileActivation.ibPasswordMessage')}
          </Text>

          <TextInput
            ref={ref_input_smsCode}
            label={t('mobileActivation.smsCode')}
            placeholder={t('mobileActivation.smsCodePlaceholder')}
            activeUnderlineColor={colors.secondary}
            onChangeText={setSmsCode}
            keyboardType="number-pad"
            textContentType="oneTimeCode"
            autoComplete="sms-otp"
            maxLength={6}
            value={smsCode}
            editable={!formActionInProgress}
            testID={prepareTestID('activation_verification_sms_input')}
          />
          <Text
            variant="bodySmall"
            style={styles.inputMessage}
            testID={prepareTestID('text_sms_info')}
          >
            {t('mobileActivation.smsCodeMessage', { phoneNumber: formatPhoneNumber(phoneNumber) })}
          </Text>
        </ScrollView>

        <View style={styles.formActionContainer}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 24,
            }}
          >
            <Text
              variant="bodyMedium"
              testID={prepareTestID('activation_verification_sms_resend_text')}
            >
              {t(
                canResendSms
                  ? 'mobileActivation.resendSmsMessage'
                  : 'mobileActivation.resendSmsTimer',
              )}
            </Text>
            {canResendSms ? (
              <Button
                mode="text"
                compact
                labelStyle={fonts.bodyMedium}
                textColor={colors.secondary}
                onPress={() => {
                  notImplementedAlert();
                  setCanResendSms(false);
                }}
                disabled={formActionInProgress}
                testID={prepareTestID('activation_verification_sms_resend_button')}
              >
                {t('mobileActivation.resendSms')}
              </Button>
            ) : null}
          </View>
          <ActionButton />
        </View>
      </KeyboardAware>
    </SafeAreaViewFixed>
  );
}

export default MobileActivationVerificationScreen;
