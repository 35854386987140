import React, { useEffect, useMemo, useState } from 'react';
import { FlatList, ListRenderItemInfo, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { RootStackParamList } from '../../../types';
import { StackNavigationProp } from '@react-navigation/stack';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { PowerAuthBiometryInfo } from 'react-native-powerauth-mobile-sdk/lib/model/PowerAuthBiometryInfo';
import { powerAuthService } from '../../../services/powerAuthService';
import PickerModal from '../../../components/Picker/PickerModal';
import { Language } from '../../../types/Language';
import { languageService } from '../../../services/languageService';
import { List, Text } from 'react-native-paper';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { styles } from '../../../assets';
import { capitalize } from '../../../utilities/formatUtils';
import { prepareTestID } from '../../../utilities/utils';
import CommonAvatar from '../../../components/CommonAvatar';

type profileProp = StackNavigationProp<RootStackParamList, 'Profile'>;

type Header = {
  title: string;
  condition?: () => boolean;
  testID: string;
};

type Link = {
  avatar?: boolean;
  title: string;
  onClick: () => void;
  condition?: () => boolean;
  testID: string;
};

type MenuItem = Header | Link;

function isLink(item: MenuItem): item is Link {
  return (item as Link).onClick !== undefined;
}

function ProfileScreen() {
  const navigation = useNavigation<profileProp>();
  const { t } = useTranslation('resources');
  const [biometry, setBiometry] = useState<PowerAuthBiometryInfo | undefined>();
  const [showLanguagePicker, setShowLanguagePicker] = useState(false);
  const { powerAuthData } = useGlobalContext();

  useEffect(() => {
    if (powerAuthData.isPlatformSupported) {
      void powerAuthService.getBiometryInfo().then((info) => {
        if (info.isAvailable) {
          setBiometry(info);
        }
      });
    }
  }, [powerAuthData.isPlatformSupported]);

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        title: t('profile.aboutMe'),
        onClick: () => {
          navigation.navigate('UserDetail');
        },
        avatar: true,
        testID: 'profile_screen_about_me',
      },
      { title: t('profile.security'), testID: 'profile_screen_security_section' },
      {
        title: t('profile.deviceManagement'),
        onClick: () => {
          navigation.navigate('DeviceManagement');
        },
        testID: 'profile_screen_device_management',
      },
      {
        title: capitalize(t('profile.biometry', { context: biometry?.biometryType })),
        onClick: () => {
          if (biometry !== undefined) {
            navigation.navigate('MobileBiometrySettings', { biometry: biometry });
          }
        },
        condition: () => !!biometry?.isAvailable,
        testID: 'profile_screen_biometry',
      },
      {
        title: t('profile.ibPasswordChange'),
        onClick: () => {},
        condition: () => Platform.OS === 'web',
        testID: 'profile_screen_ibs_password_change',
      },
      {
        title: t('profile.passwordChange'),
        onClick: () => {
          navigation.navigate('MobilePasswordChange');
        },
        condition: () => Platform.OS !== 'web',
        testID: 'profile_screen_mobile_password_change',
      },
      { title: t('profile.application'), testID: 'profile_screen_application_section' },
      {
        title: t('profile.language'),
        onClick: () => {
          setShowLanguagePicker(true);
        },
        testID: 'profile_screen_language',
      },
      {
        title: t('profile.applicationInfo'),
        onClick: () => {
          navigation.navigate('AppInfo');
        },
        testID: 'profile_screen_application_info',
      },
    ],
    [biometry, navigation, t],
  );

  return (
    <>
      <FlatList
        contentInsetAdjustmentBehavior="automatic"
        data={menuItems}
        renderItem={RenderMenuItem}
        contentContainerStyle={{ ...styles.container, paddingVertical: 24 }}
      />
      <PickerModal
        visible={showLanguagePicker}
        items={[
          {
            item: Language.CS,
            label: t('common.appLanguage.czech'),
            testID: 'language_cs',
          },
          {
            item: Language.EN,
            label: t('common.appLanguage.english'),
            testID: 'language_en',
          },
        ]}
        title={t('profile.language')}
        onDismiss={() => setShowLanguagePicker(false)}
        onSubmit={(selected) => {
          languageService.changeLanguage(selected).catch(console.error);
        }}
        testID={prepareTestID('language_modal')}
      />
    </>
  );
}

function RenderMenuItem({ item }: ListRenderItemInfo<MenuItem>) {
  if (item.condition && !item.condition()) return null;

  if (isLink(item))
    return (
      <List.Item
        title={item.title}
        testID={prepareTestID(item.testID)}
        left={() => item.avatar && <CommonAvatar avatar="USER" style={{ marginLeft: 16 }} />}
        right={() => (
          <MaterialCommunityIcons name="chevron-right" size={24} style={{ alignSelf: 'center' }} />
        )}
        onPress={item.onClick}
      />
    );
  else
    return (
      <Text
        variant="titleLarge"
        style={{ fontWeight: '700', marginTop: 32, marginBottom: 8, paddingHorizontal: 16 }}
        testID={prepareTestID(item.testID)}
      >
        {item.title}
      </Text>
    );
}

export default ProfileScreen;
