import { APOLLO_GRAPHQL_URL, ENV_NAME } from '@env';
import { Alert, Platform } from 'react-native';
import { Buffer } from 'buffer';
import * as Application from 'expo-application';
import manifest from '../../app.json';
import { Direction } from '../generated/graphql';

export const APOLLO_GRAPHQL_URL_DROID_LOCALHOST_FIX = function () {
  if (APOLLO_GRAPHQL_URL === 'http://localhost:4000' && Platform.OS === 'android') {
    return 'http://10.0.2.2:4000';
  }
  return APOLLO_GRAPHQL_URL;
};

export function toBase64(str: string): string {
  return Buffer.from(str).toString('base64');
}

export function notImplementedAlert() {
  Alert.alert('Not Implemented', 'Feature not implemented yet.', [
    {
      text: 'OK',
      onPress: () => {},
    },
  ]);
}

export function optional<T>(value: T | undefined | null): T | undefined {
  return value ?? undefined;
}

export function notNull<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function getLongAppVersion() {
  return (
    Platform.OS +
    '-' +
    Platform.select({
      native: `${Application.nativeApplicationVersion || '-1'}(${
        Application.nativeBuildVersion || '-1'
      })`,
      web: `${manifest.expo.web.version}(${manifest.expo.web.build})`,
      default: 'null',
    }) +
    '@' +
    ENV_NAME
  );
}

export function amountSign(
  amount: number | undefined | null,
  direction: Direction,
): number | undefined {
  if (amount === undefined || amount === null) return undefined;
  return direction === Direction.Credit ? Math.abs(amount) : -Math.abs(amount);
}

export const prepareTestID = (testID: string) => {
  const shouldAddPrefix = Platform.OS === 'android' && !testID.includes(':id/');
  return Application.applicationId && shouldAddPrefix
    ? `${Application.applicationId}:id/${testID}`
    : testID;
};
