import React from 'react';
import { ListRenderItemInfo } from 'react-native';
import { baseTheme } from '../../assets';
import { Button, Text } from 'react-native-paper';
import BottomSheet from '../BottomSheet';
import { FlatList } from 'react-native-gesture-handler';
import { prepareTestID } from '../../utilities/utils';

type ItemWrapper<T> = {
  item: T;
  label: string;
  testID?: string;
};

interface PickerModalProps<T> {
  visible: boolean;
  items: Array<ItemWrapper<T>>;
  title: string;
  scrollEnabled?: boolean;
  onDismiss: () => void;
  onSubmit: (selected: T) => void;
  testID?: string;
}

function PickerModal<T>({
  visible,
  items,
  title,
  scrollEnabled = false,
  onDismiss,
  onSubmit,
  testID = prepareTestID('picker_modal'),
}: PickerModalProps<T>) {
  const itemView = ({ item, index }: ListRenderItemInfo<ItemWrapper<T>>) => (
    <Button
      mode="text"
      textColor={baseTheme.colors.secondary}
      style={{ marginVertical: 4 }}
      onPress={() => {
        onSubmit(item.item);
        onDismiss();
      }}
      testID={`${testID}_item_${item.testID ?? index}`}
    >
      {item.label}
    </Button>
  );

  return (
    <BottomSheet visible={visible} onDismiss={onDismiss}>
      <Text
        variant="headlineSmall"
        style={{ marginTop: 8, marginBottom: 16, alignSelf: 'center' }}
        testID={testID + '_title'}
      >
        {title}
      </Text>
      <FlatList
        centerContent={true}
        data={items}
        keyExtractor={(type, index) => `${index}`}
        renderItem={itemView}
        scrollEnabled={scrollEnabled}
        contentContainerStyle={{ paddingBottom: 16 }}
        testID={testID + '_list'}
      />
    </BottomSheet>
  );
}

export default PickerModal;
