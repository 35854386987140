import CalendarPicker from 'react-native-calendar-picker';
import { Platform, StyleSheet, View, ViewStyle } from 'react-native';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import { onLayoutMeasure } from '../../utilities/reactUtils';

const INITIAL_WIDTH = 400;

export interface CalendarProps {
  initialDate?: DateTime | undefined;
  selectedStartDate?: DateTime | undefined;
  minDate?: DateTime | undefined;
  maxDate?: DateTime | undefined;
  disabledDates?: DateTime[] | undefined;
  onDateChange?: (cb: CalendarDateChangeCallBack) => void;
  shouldResize?: boolean | undefined;
  style?: ViewStyle | undefined;
}

export type CalendarDateChangeCallBack = {
  date: DateTime;
  type: 'START_DATE' | 'END_DATE';
};

const Calendar = ({
  initialDate,
  selectedStartDate,
  minDate,
  maxDate,
  disabledDates,
  onDateChange,
  shouldResize,
  style,
}: CalendarProps) => {
  const { t } = useTranslation();
  const [size, setSize] = useState({ width: INITIAL_WIDTH, height: 0 });

  const months = t('common.months', { returnObjects: true });
  const days = t('common.shortDays', { returnObjects: true });

  const onDateChangeInternal = useCallback(
    (date: Moment, type: 'START_DATE' | 'END_DATE') => {
      if (onDateChange) {
        onDateChange({ date: DateTime.fromJSDate(date.toDate()), type: type });
      }
    },
    [onDateChange],
  );

  return (
    <View style={[styles.container, style]}>
      <View onLayout={onLayoutMeasure(setSize)}>
        <CalendarPicker
          startFromMonday
          months={months}
          weekdays={days}
          selectMonthTitle={t('calendar.selectMonth')}
          selectYearTitle={t('calendar.selectYear')}
          previousTitle={t('common.previous')}
          nextTitle={t('common.next')}
          onDateChange={onDateChangeInternal}
          minDate={minDate?.toJSDate()}
          maxDate={maxDate?.toJSDate()}
          disabledDates={disabledDates?.map((date) => date.toJSDate()) ?? []}
          selectedStartDate={selectedStartDate?.toJSDate()}
          initialDate={selectedStartDate?.toJSDate() ?? initialDate?.toJSDate() ?? new Date()}
          width={shouldResize ? size.width : undefined}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginTop: 16,
    width: Platform.OS === 'web' || (Platform.OS === 'ios' && Platform.isPad) ? '70%' : undefined,
  },
});

export default Calendar;
