import React from 'react';
import { RefreshControl, ScrollView } from 'react-native';
import { styles } from '../../assets';
import { useUserDetail } from './context/UserDetailContext';
import UserDetailTop from './components/UserDetailTop';
import UserDetailSections from './components/UserDetailSections';

const UserDetailScreen = () => {
  const { loading, onRefresh } = useUserDetail();

  return (
    <>
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        contentContainerStyle={{ ...styles.container, paddingVertical: 16 }}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={onRefresh} />}
      >
        <UserDetailTop />
        <UserDetailSections />
      </ScrollView>
    </>
  );
};

export default UserDetailScreen;
