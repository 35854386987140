import React from 'react';
import {
  PowerAuthData,
  SystemStateStatus,
  SystemStateData,
  useGlobalContext,
  UserData,
} from '../contexts/GlobalContext';
import { PowerAuthActivationState } from 'react-native-powerauth-mobile-sdk';

// navigators
import SystemStateNavigator from './SystemStateNavigator';
import MainNavigator from './MainNavigator';
import MobileLoginNavigator from './MobileLoginNavigator';
import MobileActivationNavigator from './MobileActivationNavigator';
import WebLoginNavigator from './WebLoginNavigator';
import BackgroundLogoutProvider from '../contexts/BackgroundLogoutProvider';

enum RootNavigator {
  SystemState = 'SystemState',
  MobileActivation = 'MobileActivation',
  MobileLogin = 'MobileLogin',
  WebLogin = 'WebLogin',
  Main = 'Main',
}

function rootNavigatorLogic(
  systemStateData: SystemStateData,
  powerAuthData: PowerAuthData,
  userData: UserData,
): RootNavigator {
  const hasActiveActivation = (powerAuthData: PowerAuthData) =>
    powerAuthData.activationStatus !== undefined &&
    powerAuthData.activationStatus.hasValidActivation &&
    powerAuthData.activationStatus.status?.state === PowerAuthActivationState.ACTIVE;

  // Main for logged in finished state
  if (
    systemStateData.result !== undefined &&
    systemStateData.result.systemStateStatus === SystemStateStatus.Normal &&
    !userData.loginInProgress &&
    userData.userLoggedIn &&
    userData.userId !== undefined &&
    (!powerAuthData.isPlatformSupported || hasActiveActivation(powerAuthData))
  ) {
    return RootNavigator.Main;
  }

  if (
    systemStateData.result == undefined ||
    systemStateData.result.systemStateStatus !== SystemStateStatus.Normal ||
    (powerAuthData.isPlatformSupported && powerAuthData.activationStatus == undefined)
  ) {
    return RootNavigator.SystemState;
  }

  if (!powerAuthData.isPlatformSupported) {
    return RootNavigator.WebLogin;
  }

  if (hasActiveActivation(powerAuthData)) {
    return RootNavigator.MobileLogin;
  }

  return RootNavigator.MobileActivation;
}

function RootNavigatorElement({ rootNavigator }: { rootNavigator: RootNavigator }) {
  switch (rootNavigator) {
    case RootNavigator.SystemState:
      return <SystemStateNavigator />;
    case RootNavigator.MobileLogin:
      return <MobileLoginNavigator />;
    case RootNavigator.MobileActivation:
      return <MobileActivationNavigator />;
    case RootNavigator.WebLogin:
      return <WebLoginNavigator />;
    case RootNavigator.Main:
      return (
        <BackgroundLogoutProvider>
          <MainNavigator />
        </BackgroundLogoutProvider>
      );
  }
}

function Router() {
  const { systemStateData, powerAuthData, userData } = useGlobalContext();

  const rootNavigator = rootNavigatorLogic(systemStateData, powerAuthData, userData);
  console.debug('Router: rootNavigator=' + rootNavigator);

  return <RootNavigatorElement rootNavigator={rootNavigator} />;
}

export default Router;
