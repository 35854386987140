import { graphQLService } from './graphQLService';

export type LoginResult = {
  success: boolean;
  userId?: string;
  accessToken?: string;
  refreshToken?: string;
};

function powerAuthLogin(powerAuthHeader: string, powerAuthPayload: string): Promise<LoginResult> {
  console.debug('loginService: powerAuthLogin([powerAuthHeader], [powerAuthPayload]) invoked');

  return new Promise((resolve, reject) => {
    graphQLService
      .mutationLogin(powerAuthHeader, powerAuthPayload)
      .then((_login) => {
        //
        if (_login.login.userId && _login.login.accessToken && _login.login.refreshToken) {
          resolve({
            success: true,
            userId: _login.login.userId,
            accessToken: _login.login.accessToken,
            refreshToken: _login.login.refreshToken,
          });
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

function ibTempLogin(userId: string): Promise<LoginResult> {
  console.debug(`loginService: ibTempLogin(${userId}) invoked`);

  return new Promise((resolve) => {
    setTimeout(() => {
      //
      resolve({
        success: true,
        userId: userId, // HACK: fixed MOCK_USER_ID_1 MOCK_USER_ID_2
        accessToken: '<accessToken>',
        refreshToken: '<refreshToken>',
      });
    }, 1000);
  });
}

function logout(): Promise<void> {
  console.debug('loginService: logout() invoked');

  return graphQLService.mutationLogout();
}

export const loginService = {
  powerAuthLogin,
  ibTempLogin,
  logout,
};
