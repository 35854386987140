import React, { useMemo } from 'react';
import { Account } from '../../../../../generated/graphql';
import {
  formatAccountNumber,
  formatAmount,
  formatDate,
} from '../../../../../utilities/formatUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import CommonItemSection, {
  SectionItem,
  SectionItemType,
} from '../../../../../components/CommonItemSection';
import { PaymentToaData, usePaymentToa } from '../../../context/PaymentToaContext';
import { getCurrencyLabel } from '../../../../../utilities/paymentUtils';

const PaymentToaSummarySections = () => {
  const { t } = useTranslation();
  const { paymentData, creditAccount, debitAccount } = usePaymentToa();

  const accountSection = useMemo(
    () => prepareAccountsSection(paymentData, creditAccount, debitAccount, t),
    [paymentData, creditAccount, debitAccount, t],
  );
  const baseDataSection = useMemo(
    () => prepareBaseDataSection(paymentData, t, debitAccount),
    [paymentData, t, debitAccount],
  );

  return (
    <>
      <CommonItemSection items={accountSection} marginTop={24} />
      <CommonItemSection items={baseDataSection} />
    </>
  );
};

const prepareAccountsSection = (
  paymentData: PaymentToaData,
  creditAccount: Account | undefined,
  debitAccount: Account | undefined,
  t: TFunction,
) => {
  const section: Array<SectionItem> = [];
  if (debitAccount) {
    section.push({
      label: t('payment.toaSummary.sectionItemLabelOriginAcc'),
      value: debitAccount.name,
      subValue: formatAccountNumber(debitAccount.number),
    });
  }
  if (creditAccount) {
    section.push({
      label: t('payment.toaSummary.sectionItemLabelRecipientAcc'),
      value: creditAccount.name,
      subValue: formatAccountNumber(creditAccount.number),
    });
  }
  return section;
};

const prepareBaseDataSection = (
  { amount, message, dueDate }: PaymentToaData,
  t: TFunction,
  debitAccount?: Account,
) => {
  const section: Array<SectionItem> = [];
  const amountData = {
    label: t('payment.toaSummary.sectionItemLabelAmount'),
    value: formatAmount(amount, getCurrencyLabel(debitAccount?.currencyCode)),
  };
  section.push(amountData);
  section.push({
    label: t('payment.toaSummary.sectionItemLabelDueDate'),
    value: dueDate ? formatDate(dueDate) : t('payment.toaSummary.dueDateNow'),
  });
  if (message) {
    section.push({
      label: t('payment.toaSummary.sectionItemLabelNote'),
      value: message,
      type: SectionItemType.Multiline,
    });
  }
  return section;
};

export default PaymentToaSummarySections;
