import { View } from 'react-native';
import { List, Text } from 'react-native-paper';
import { baseTheme, styles } from '../../../../assets';
import React, { useMemo } from 'react';
import { useTheme } from '../../../../utilities/reactUtils';
import { splitAmount } from '../../../../utilities/formatUtils';

function AccountCard({
  title,
  balance,
  currencyCode,
  onPress,
  testID,
}: {
  title: string;
  balance: number;
  currencyCode: string;
  onPress: () => void;
  testID: string;
}) {
  return (
    <AccountCardHeader
      title={title}
      balance={balance}
      currencyCode={currencyCode}
      onPress={onPress}
      testID={`${testID}_header`}
    />
  );
}

function AccountCardHeader({
  title,
  balance,
  currencyCode,
  onPress,
  testID,
}: {
  title: string;
  balance: number;
  currencyCode: string;
  onPress: () => void;
  testID: string;
}) {
  const { fonts } = useTheme();

  return (
    <List.Item
      style={{ paddingStart: 6, paddingEnd: 14, paddingVertical: 12 }}
      titleNumberOfLines={3}
      title={title}
      testID={testID}
      titleStyle={{
        ...fonts.titleMedium,
        fontWeight: '500',
        paddingEnd: 5,
      }}
      right={(props) => (
        <AccountCardHeaderBalance
          {...props}
          balance={balance}
          currencyCode={currencyCode}
          testID={testID}
        />
      )}
      rippleColor={baseTheme.colors.ripple}
      onPress={onPress}
    />
  );
}

function AccountCardHeaderBalance({
  balance,
  currencyCode,
  testID,
}: {
  balance: number;
  currencyCode: string;
  testID: string;
}) {
  const { whole, fraction } = useMemo(
    () => splitAmount(balance, currencyCode),
    [balance, currencyCode],
  );
  return (
    <View style={styles.accountCardHeaderAmount}>
      <Text
        variant="titleMedium"
        style={{ fontWeight: '900', letterSpacing: -0.3 }}
        testID={`${testID}_balance`}
      >
        {whole}
      </Text>
      <Text
        variant="bodyMedium"
        style={{ fontWeight: '900', letterSpacing: -0.3 }}
        testID={`${testID}_fraction`}
      >
        {fraction}
      </Text>
    </View>
  );
}

export default AccountCard;
