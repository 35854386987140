import { StyleSheet } from 'react-native';
import { MD3LightTheme } from 'react-native-paper';

export const colors = {
  // base colors
  ui_grey_05: '#F2F2F2',
  ui_grey_10: '#E4E4E3',
  ui_grey_15: '#D8D8D8',
  ui_grey_20: '#CBCACA',
  ui_grey_32: '#525252',
  ui_grey_50: '#888888',
  ui_grey_70: '#4D4C4C',
  ui_grey_80: '#333333',
  ui_grey_90: '#161616',
  ui_white: '#FFFFFF',
  ui_black: '#000000',
  ui_transparent: '#FFFFFFFF',

  ui_color_focus: '#6C63FF',
  ui_color_warning: '#F6921E',

  ui_color_positive: '#169913',

  ui_color_processed: '#60C170',

  // background
  ui_base_bg: '#F5F5F5',
  ui_light_ivory_grey_bg: '#F0F0EB',
  ui_dark_midnight_navy_bg: '#121228',
  ui_whisper: '#ECECEC',
  ui_gainsboro: '#DADADA',
  ui_secondary_container: '#D8E2FF',

  ui_tab_bar_focused: '#1c1b1f1f',

  ui_error_red: '#E37869',

  ui_turq_prim: '#6DD9E7',
  ui_color_secondary: '#3E3E3E',

  // debug colors
  ui_debug_yellow: '#FFF995',
  ui_debug_orange: '#FFD479',
  ui_debug_peach: '#F4A99E',
  ui_debug_ocean: '#84D9F7',
  ui_debug_grass: '#B7F7A9',
};

export const baseTheme = {
  dark: false,
  colors: {
    primary: colors.ui_black,
    secondary: colors.ui_black,
    background: colors.ui_white,
    card: colors.ui_base_bg,
    text: colors.ui_black,
    textSecondary: colors.ui_grey_70,
    border: colors.ui_grey_70,
    notification: colors.ui_black,
    ripple: colors.ui_grey_10,
    error: colors.ui_error_red,
  },
};

export const webTheme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    border: colors.ui_grey_15,
  },
};

export const materialTheme = {
  ...MD3LightTheme,
  roundness: 2,
  colors: {
    ...MD3LightTheme.colors,
    primary: baseTheme.colors.primary,
    secondary: baseTheme.colors.secondary,
    surfaceVariant: baseTheme.colors.card,
    secondaryContainer: colors.ui_grey_10,
  },
  fonts: {
    ...MD3LightTheme.fonts,
    displayLarge: {
      fontSize: 57,
      lineHeight: 64,
      fontWeight: '600',
    },
    displayMedium: {
      fontSize: 45,
      lineHeight: 52,
      fontWeight: '600',
    },
    displaySmall: {
      fontSize: 36,
      lineHeight: 44,
      fontWeight: '600',
    },
    headlineLarge: {
      fontSize: 32,
      lineHeight: 40,
      fontWeight: '600',
    },
    headlineMedium: {
      fontSize: 28,
      lineHeight: 36,
      fontWeight: '600',
    },
    headlineSmall: {
      fontSize: 24,
      lineHeight: 32,
      fontWeight: '600',
    },
    titleLarge: {
      fontSize: 22,
      lineHeight: 28,
      fontWeight: '600',
    },
    titleMedium: {
      fontSize: 16,
      lineHeight: 24,
      fontWeight: '600',
    },
    titleSmall: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: '600',
    },
    labelLarge: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: '600',
    },
    labelMedium: {
      fontSize: 14,
      lineHeight: 22,
      fontWeight: '600',
    },
    labelSmall: {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: '600',
    },
    bodyLarge: {
      fontSize: 16,
      lineHeight: 24,
      fontWeight: '500',
    },
    bodyMedium: {
      fontSize: 14,
      lineHeight: 20,
      fontWeight: '500',
    },
    bodySmall: {
      fontSize: 12,
      lineHeight: 16,
      fontWeight: '500',
    },
  },
};

export const uiConstants = {
  drawerWidth: 200,
  maxWidth: 744,
};

const singleColumnResponsive = {
  width: '100%',
  maxWidth: uiConstants.maxWidth,
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const styles = StyleSheet.create({
  screenBase: {
    width: '100%',
    height: '100%',
    backgroundColor: baseTheme.colors.background,
    //backgroundColor: colors.ui_debug_yellow,
  },
  container: {
    ...singleColumnResponsive,
    //backgroundColor: colors.ui_debug_orange,
  },
  containerFlex: {
    ...singleColumnResponsive,
    flex: 1,
    //backgroundColor: colors.ui_debug_grass,
  },

  centered: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundColor: colors.ui_debug_peach,
  },

  bottomCentered: {
    width: '100%',
    flex: 0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 8,
    //backgroundColor: colors.ui_debug_yellow,
  },

  topRight: {
    paddingTop: 16,
    paddingRight: 16,
  },

  logoHorizontal: {
    width: 220,
    height: 76,
    resizeMode: 'contain',
  },

  mainButtonContainer: {
    width: '70%',
    maxWidth: 350,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  formContainer: {
    ...singleColumnResponsive,
    paddingTop: 32,
    paddingBottom: 16,
    paddingHorizontal: 16,
    //backgroundColor: colors.ui_debug_orange,
  },

  formActionButton: {
    width: '100%',
    height: 48,
    justifyContent: 'center',
  },

  formActionContainer: {
    ...singleColumnResponsive,
    paddingHorizontal: 16,
    paddingVertical: 20,
    //backgroundColor: colors.ui_debug_peach,
  },

  inputMessage: {
    marginTop: 4,
    marginBottom: 16,
    marginStart: 16,
    opacity: 0.6,
  },

  //TODO: update, remove or refactor following
  item: {
    //backgroundColor: colors.ui_peach_salmon,
    paddingTop: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 8,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  itemBoldText: {
    fontWeight: 'bold',
    color: baseTheme.colors.text,
  },
  itemSubheader: {
    paddingTop: 10,
    color: baseTheme.colors.textSecondary,
  },
  buttonFilled: {
    paddingHorizontal: 16,
  },

  errorTextBox: {
    position: 'absolute',
    top: 0,
    marginHorizontal: 16,
    paddingVertical: 20,
    paddingHorizontal: 10,
    backgroundColor: baseTheme.colors.error,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorTextIcon: {
    marginRight: 16,
  },
  errorTextText: {
    flex: 1,
    fontSize: 16,
    fontWeight: '500',
    color: colors.ui_white,
  },

  accountHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 16,
    marginTop: 16,
    marginBottom: 14,
    //backgroundColor: colors.ui_debug_peach,
  },
  accountCardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  accountCardHeaderAmount: {
    flexDirection: 'row',
    alignItems: 'baseline',
    alignSelf: 'center',
  },

  homeSettingsListItem: {
    alignSelf: 'center',
    alignItems: 'baseline',
    paddingVertical: 8,
  },

  feedMovementItem: {
    //backgroundColor: colors.ui_debug_yellow,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 28,
    paddingRight: 16,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  feedMovementItemLeftView: {
    //backgroundColor: colors.ui_debug_orange,
    flexDirection: 'column',
  },
  feedMovementItemRightView: {
    //backgroundColor: colors.ui_debug_yellow,
    justifyContent: 'center',
  },
  feedMovementItemTitle: {
    fontWeight: 'normal',
    color: colors.ui_black,
  },
  feedMovementItemSubtitle: {
    paddingTop: 5,
    fontSize: 12,
    color: colors.ui_grey_50,
  },
  feedMovementItemAmount: {
    fontSize: 15,
  },

  deviceCard: {
    flexDirection: 'row',
  },
  deviceCardImage: {
    height: 56,
    width: 56,
    marginRight: 8,
    alignSelf: 'center',
  },
  deviceCardColumn: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  deviceCardName: {
    marginBottom: 8,
  },
  deviceCardAuthorization: {
    color: baseTheme.colors.secondary,
    borderColor: baseTheme.colors.secondary,
    borderWidth: 1,
    borderRadius: 4,
    padding: 4,
    marginTop: 8,
  },
  deviceCardChevron: {
    fontSize: 22,
    marginTop: -4,
  },
  deviceDetailDescription: {
    marginTop: 8,
    fontSize: 14,
    color: baseTheme.colors.textSecondary,
  },
  deviceDetailButtons: {
    marginTop: 32,
    padding: 16,
    borderColor: baseTheme.colors.text,
    borderRadius: 8,
    backgroundColor: baseTheme.colors.card,
  },
  toastContainer: {
    flexDirection: 'row',
    borderRadius: 8,
  },
  commonCard: {
    backgroundColor: baseTheme.colors.card,
    borderRadius: 8,
    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 0 },
    // shadowOpacity: 0.05,
    // shadowRadius: 3,
    marginHorizontal: 16,
    marginVertical: 5,
  },
  dashboardItemHorizontalListCard: {
    marginStart: 8,
    width: 170,
    height: 122,
    borderRadius: 5,
    padding: 8,
    backgroundColor: colors.ui_turq_prim,
  },
  dashboardItemHorizontalListCardContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  homeCardChip: {
    backgroundColor: colors.ui_black,
    height: 20,
    width: 64,
    alignContent: 'center',
    borderRadius: 5,
  },
  homeCardChipLabel: {
    color: colors.ui_white,
    paddingHorizontal: 6,
    paddingVertical: 2,
    textAlign: 'center',
  },
  homeCardButton: {
    height: 40,
    marginTop: 40,
    justifyContent: 'center',
    alignSelf: 'center',
  },
});
