import React, { useMemo } from 'react';
import { FlatList, Platform, StyleSheet, View } from 'react-native';
import { colors } from '../../../../../assets';
import { Text } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import PaymentAction, { PaymentActionProps } from '../PaymentAction';
import { AccountNumber } from '../../../../../generated/graphql';
import { useNavigation } from '@react-navigation/native';
import { PaymentNavProp } from '../../../../../screens/Main/Payment/PaymentScreen';
import { usePaymentDomestic } from '../../../context/PaymentDomesticContext';
import { prepareTestID } from '../../../../../utilities/utils';

interface PaymentActionFavoriteItemProps extends PaymentActionProps {
  accountNumber: AccountNumber;
  favoriteName: string;
}

const PaymentFavoritesSegment = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<PaymentNavProp>();
  const { resetPayment, setRecipientAccount, setRecipientName } = usePaymentDomestic();

  const paymentActions: PaymentActionFavoriteItemProps[] = useMemo(
    () => [
      {
        accountNumber: { bankCode: '3030', number: '1013022051' },
        favoriteName: 'Tomáš Veselý',
        color: '#393939',
        onPress: () => {},
      },
      {
        accountNumber: { bankCode: '3030', number: '1013022019' },
        favoriteName: 'Radek Frolík',
        color: '#393939',
        onPress: () => {},
      },
      {
        accountNumber: { bankCode: '0800', number: '123' },
        favoriteName: 'Xhevi',
        color: '#393939',
        onPress: () => {},
      },
      {
        accountNumber: { bankCode: '3100', number: '123' },
        favoriteName: 'Tomáš Bdínka',
        color: '#393939',
        onPress: () => {},
      },
      {
        accountNumber: { bankCode: '2010', number: '123' },
        favoriteName: 'Petr Fuka',
        color: '#393939',
        onPress: () => {},
      },
      {
        accountNumber: { bankCode: '0300', number: '123' },
        favoriteName: 'Jakub Zíka',
        color: '#393939',
        onPress: () => {},
      },
      {
        accountNumber: { bankCode: '0710', number: '123' },
        favoriteName: 'Sára Sebránková',
        color: '#393939',
        onPress: () => {},
      },
      {
        accountNumber: { bankCode: '0600', number: '123' },
        favoriteName: 'Jan Horvát',
        color: '#393939',
        onPress: () => {},
      },
    ],
    [],
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text variant={'titleMedium'}>{t('payment.favorites.title')}</Text>
      </View>
      <FlatList
        style={styles.list}
        numColumns={Platform.OS === 'web' ? 8 : 4}
        data={paymentActions}
        renderItem={({ item, index }) =>
          PaymentAction({
            ...item,
            text: item.favoriteName,
            containerStyle: styles.action,
            textColor: colors.ui_black,
            textVariant: 'bodySmall',
            textStyle: styles.actionText,
            onPress: () => {
              resetPayment();
              setRecipientAccount(item.accountNumber);
              setRecipientName(item.favoriteName);
              navigation.navigate('PaymentDomesticModal', { screen: 'PaymentDomestic' });
            },
            testID: prepareTestID(`payment_action_favorite_${index}`),
          })
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    marginHorizontal: 16,
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: colors.ui_base_bg,
    borderRadius: 8,
  },
  header: { alignItems: 'center' },
  action: {
    paddingHorizontal: 0,
    paddingVertical: 8,
    marginEnd: 0,
    flexGrow: Platform.OS === 'web' ? 0.125 : 0.25,
    flex: Platform.OS === 'web' ? 0.125 : 0.25,
  },
  actionText: { fontWeight: '500', letterSpacing: 0.4 },
  list: { width: '100%', marginVertical: 16 },
});
export default PaymentFavoritesSegment;
