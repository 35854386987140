import { graphQLService } from './graphQLService';
import { Direction, MovementFragment } from '../generated/graphql';
import { DateTime } from 'luxon';

async function getMovements(
  accountId: string,
  fullText: string,
  filter: MovementFilter,
): Promise<Array<MovementFragment>> {
  console.debug(`movementService: getMovements(${accountId}) invoked`);
  const query = await graphQLService.queryMovements(accountId, 0, 150);

  return (
    ((query.movementsByAccount ?? []) as MovementFragment[])
      // FIXME: Temporary filtering
      .filter((m) =>
        (m.title + ' ' + m.amount.toString()).toLowerCase().includes(fullText.toLowerCase()),
      )
      .filter(
        (m) =>
          filter.direction === 'ALL' ||
          (filter.direction === 'INCOMING' && m.direction === Direction.Credit) ||
          (filter.direction === 'OUTGOING' && m.direction === Direction.Debit),
      )
      .filter((m) => filter.type === 'ALL' || m.source.__typename === 'Card')
      .filter(
        (m) =>
          filter.date === 'ALL' ||
          (filter.date === 'THIS_MONTH' && isThisMonth(m.date)) ||
          (filter.date === 'LAST_MONTH' && isThisMonth(m.date, true)) ||
          (typeof filter.date !== 'string' &&
            isDateBetween(m.date, filter.date.from, filter.date.to)),
      )
      .filter(
        (m) =>
          filter.amount === 'ALL' ||
          isBetween(Math.abs(m.amount), filter.amount.from, filter.amount.to),
      )
  );
}

// FIXME: Temporary filtering
function isThisMonth(dateString: string, previous = false): boolean {
  const date = DateTime.fromISO(dateString);
  const month = previous ? DateTime.now().startOf('month').minus({ day: 1 }) : DateTime.now();

  return date.hasSame(month, 'month');
}

// FIXME: Temporary filtering
function isDateBetween(dateString: string, from: DateTime, to: DateTime): boolean {
  const date = DateTime.fromISO(dateString);

  return date.diff(from).valueOf() >= 0 && to.diff(date).valueOf() >= 0;
}

// FIXME: Temporary filtering
function isBetween(value: number, from: number, to: number): boolean {
  return value >= from && to >= value;
}

export class MovementFilter {
  readonly direction: 'ALL' | 'INCOMING' | 'OUTGOING';
  readonly type: 'ALL' | 'CARD';
  readonly date: 'ALL' | 'THIS_MONTH' | 'LAST_MONTH' | FilterRange<DateTime>;
  readonly amount: 'ALL' | FilterRange<number>;

  constructor(
    direction: 'ALL' | 'INCOMING' | 'OUTGOING',
    type: 'ALL' | 'CARD',
    date: 'ALL' | 'THIS_MONTH' | 'LAST_MONTH' | FilterRange<DateTime>,
    amount: 'ALL' | FilterRange<number>,
  ) {
    this.direction = direction;
    this.type = type;
    this.date = date;
    this.amount = amount;
  }

  public withDirection(direction: 'ALL' | 'INCOMING' | 'OUTGOING'): MovementFilter {
    return new MovementFilter(direction, this.type, this.date, this.amount);
  }

  public withType(type: 'ALL' | 'CARD'): MovementFilter {
    return new MovementFilter(this.direction, type, this.date, this.amount);
  }

  public withDate(
    date: 'ALL' | 'THIS_MONTH' | 'LAST_MONTH' | FilterRange<DateTime>,
  ): MovementFilter {
    return new MovementFilter(this.direction, this.type, date, this.amount);
  }

  public withAmount(amount: 'ALL' | FilterRange<number>): MovementFilter {
    return new MovementFilter(this.direction, this.type, this.date, amount);
  }

  static EMPTY = new MovementFilter('ALL', 'ALL', 'ALL', 'ALL');
}

export type FilterRange<T> = { from: T; to: T };

export const movementService = {
  getMovements,
};
