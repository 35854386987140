import { GestureResponderEvent, Platform, View } from 'react-native';
import { baseTheme, colors, materialTheme, styles } from '../../../../assets';
import { Text, TouchableRipple } from 'react-native-paper';
import React, { useMemo } from 'react';
import { prepareTestID } from '../../../../utilities/utils';
import CommonAvatar from '../../../../components/CommonAvatar';
import { splitAmount } from '../../../../utilities/formatUtils';

const DashboardHeader = ({
  balanceSum,
  balanceCurrency,
  onProfilePress,
}: {
  balanceSum: number;
  balanceCurrency: string;
  onProfilePress: (event: GestureResponderEvent) => void;
}) => {
  return (
    <View style={styles.accountHeader}>
      <Balance amount={balanceSum} currency={balanceCurrency} />
      <Menu onProfilePress={onProfilePress} />
    </View>
  );
};

const Balance = ({ amount, currency }: { amount: number; currency: string }) => {
  const { whole, fraction } = useMemo(() => splitAmount(amount, currency), [amount, currency]);

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'baseline',
          marginLeft: 16,
          paddingTop: 1,
          //backgroundColor: colors.ui_debug_orange,
        },
      ]}
    >
      {currency && <BalancePrimary value={whole} />}
      {currency && <BalanceSecondary value={fraction} />}
    </View>
  );
};

const BalancePrimary = ({ value }: { value: string }) => (
  <Text
    variant="displaySmall"
    style={{ fontWeight: '800' }}
    testID={prepareTestID('dashboard_header_balance_primary_text')}
  >
    {value}
  </Text>
);

const BalanceSecondary = ({ value }: { value: string }) => (
  <Text
    variant="titleLarge"
    style={{ fontWeight: '800', fontSize: 20, lineHeight: 20 }}
    testID={prepareTestID('dashboard_header_balance_secondary_text')}
  >
    {value}
  </Text>
);

const Menu = ({ onProfilePress }: { onProfilePress: (event: GestureResponderEvent) => void }) => {
  const iconSize = 40;
  const rippleColor = baseTheme.colors.ripple;

  if (Platform.OS === 'web') {
    return <></>;
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginEnd: 14,
        paddingTop: 0,
        paddingBottom: 0,
        //backgroundColor: colors.ui_debug_orange,
      }}
    >
      <TouchableRipple
        onPress={onProfilePress}
        rippleColor={rippleColor}
        testID={prepareTestID('dashboard_header_profile_button')}
        style={{
          marginStart: 10,
          borderWidth: 1,
          borderRadius: 50,
          borderColor: materialTheme.colors.background,
        }}
      >
        <CommonAvatar size={iconSize} avatar="USER" />
      </TouchableRipple>
    </View>
  );
};

export default DashboardHeader;
