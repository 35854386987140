import React from 'react';
import { useTheme } from '../../../../utilities/reactUtils';
import { baseTheme, colors, styles } from '../../../../assets';
import { List, TouchableRipple } from 'react-native-paper';
import { StyleProp, View, ViewStyle } from 'react-native';
import Eye from '../../../../assets/svg/Eye';
import EyeOff from '../../../../assets/svg/EyeOff';
import { CommonCard } from '../../../../components/CommonCard';

export function SectionItem({
  title,
  hidden,
  onVisibilityIconClicked,
  draggableArea,
  style,
}: {
  title: string;
  hidden: boolean;
  onVisibilityIconClicked: () => void;
  draggableArea: (props: { visible: boolean; iconSize: number }) => React.ReactNode;
  style?: StyleProp<ViewStyle> | undefined;
}) {
  const { fonts } = useTheme();
  const backgroundColor = hidden ? colors.ui_base_bg : colors.ui_white;
  const iconSize = 20;

  const LeftItemContent = () => draggableArea({ visible: !hidden, iconSize: iconSize });
  const RightItemContent = () => (
    <TouchableRipple
      onPress={() => onVisibilityIconClicked()}
      rippleColor={baseTheme.colors.ripple}
      style={{ borderRadius: 50 }}
    >
      <View
        style={{
          ...styles.homeSettingsListItem,
          paddingHorizontal: 8,
        }}
      >
        {hidden ? <Eye width={iconSize} /> : <EyeOff width={iconSize} />}
      </View>
    </TouchableRipple>
  );

  return (
    <View style={style}>
      <CommonCard
        style={{ backgroundColor: backgroundColor }}
        content={
          <List.Item
            title={title}
            titleStyle={fonts.titleMedium}
            left={LeftItemContent}
            right={RightItemContent}
          />
        }
      />
    </View>
  );
}
