import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { RootStackParamList } from '../../../types';
import { useTranslation } from 'react-i18next';
import { MovementFilter, movementService } from '../../../services/movementService';
import { Text } from 'react-native-paper';
import { DateTime } from 'luxon';
import { baseTheme, styles } from '../../../assets';
import { StackScreenProps } from '@react-navigation/stack/src/types';
import { AccountInfoModal } from './AccountDetail';
import { MovementListItem } from './MovementListItem';
import { FlashList } from '@shopify/flash-list';
import { prepareTestID } from '../../../utilities/utils';
import { Account, MovementFragment } from '../../../generated/graphql';
import { formatTransactionDate, formatTransactionMonth } from '../../../utilities/formatUtils';
import { TFunction } from 'i18next';
import AccountDetailHeader, { MovementSearchHeader } from './AccountDetailHeader';

function AccountDetailScreen({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'AccountDetail'>) {
  const account = route.params.account;

  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [fullText, setFullText] = useState('');
  const [filter, setFilter] = useState<MovementFilter>(MovementFilter.EMPTY);
  const [movements, setMovements] = useState<Array<MovementFragment> | undefined>();

  const loadMovements = useCallback(
    (onLoaded: () => void) => {
      movementService
        .getMovements(account.id, fullText, filter)
        .then((result) => setMovements(result))
        .catch(console.error)
        .finally(onLoaded);
    },
    [account.id, fullText, filter],
  );

  useEffect(() => {
    navigation.setOptions({
      header: ({ navigation }) => {
        return searchMode ? (
          <MovementSearchHeader
            fullText={fullText}
            onFullText={setFullText}
            filter={filter}
            currency={account.currencyCode}
            onFilter={setFilter}
            onClose={() => {
              setSearchMode(false);
              setFilter(MovementFilter.EMPTY);
              setFullText('');
            }}
          />
        ) : (
          <AccountDetailHeader
            account={account}
            navigation={navigation}
            onReload={() => loadMovements(() => {})}
            onShowAccountInfo={() => setShowAccountInfo(true)}
            onSearch={() => {
              setSearchMode(true);
              setShowAccountInfo(false);
            }}
          />
        );
      },
    });
  }, [navigation, account, loadMovements, searchMode, fullText, filter]);

  useEffect(() => {
    loadMovements(() => {});
  }, [loadMovements]);

  const goToDetail = useCallback(
    (movement: MovementFragment) => {
      navigation.navigate('MovementDetail', { movement: movement, account: account });
    },
    [navigation, account],
  );

  return (
    <>
      {movements && (
        <MovementList
          movements={movements}
          account={account}
          onMovementClick={goToDetail}
          onLoad={loadMovements}
        />
      )}
      <AccountInfoModal
        account={account}
        visible={showAccountInfo}
        onDismiss={() => setShowAccountInfo(false)}
      />
    </>
  );
}

function MovementList({
  movements,
  account,
  onMovementClick,
  onLoad,
}: {
  movements: Array<MovementFragment>;
  account: Account;
  onMovementClick: (movement: MovementFragment) => void;
  onLoad: (onLoaded: () => void) => void;
}) {
  const { t, i18n } = useTranslation('resources');

  const { data, headerIndices } = useMemo(
    () => addDateHeaders(movements, t, i18n.language),
    [movements, t, i18n.language],
  );

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    onLoad(() => setRefreshing(false));
  }, [onLoad]);

  const headerOffset = useMemo(() => {
    if (Platform.OS !== 'android') return 0;

    const item = data[0];
    if (item?.__typename === 'HeaderWrapper') return item.month ? 92 : 52;
    else return 0;
  }, [data]);

  return (
    <View style={{ ...styles.containerFlex, backgroundColor: baseTheme.colors.background }}>
      <FlashList
        contentContainerStyle={{ paddingBottom: 16 }}
        data={data}
        renderItem={({ item, index }) => {
          if (item.__typename === 'HeaderWrapper') {
            return <MovementHeader item={item} />;
          } else {
            return (
              <MovementListItem
                item={item}
                accountCurrency={account.currencyCode}
                isFirst={headerIndices.includes(index - 1)}
                isLast={headerIndices.includes(index + 1) || data.length == index + 1}
                onItemClick={onMovementClick}
                testID={prepareTestID(`movement_list_item_${index}`)}
              />
            );
          }
        }}
        getItemType={(item) => item.__typename}
        keyExtractor={(item) => (item.__typename === 'HeaderWrapper' ? item.date : item.id)}
        stickyHeaderIndices={headerIndices}
        estimatedItemSize={58}
        onRefresh={onRefresh}
        refreshing={refreshing}
        progressViewOffset={headerOffset}
        ListEmptyComponent={() => (
          <View style={{ alignItems: 'center', paddingTop: 200 }}>
            <Text variant="titleLarge" style={{ opacity: 0.6 }}>
              {t('common.noData')}
            </Text>
          </View>
        )}
      />
    </View>
  );
}

function MovementHeader({ item }: { item: HeaderWrapper }) {
  return (
    <View
      style={{
        width: '100%',
        padding: 16,
        backgroundColor: baseTheme.colors.background,
      }}
    >
      {item.month && (
        <Text variant="titleMedium" style={{ fontWeight: '700', marginBottom: 16 }}>
          {item.month}
        </Text>
      )}
      <Text variant="bodyMedium" style={{ opacity: 0.65 }}>
        {item.date}
      </Text>
    </View>
  );
}

type HeaderWrapper = {
  __typename: 'HeaderWrapper';
  date: string;
  month: string | undefined;
};

function addDateHeaders(
  movements: Array<MovementFragment>,
  t: TFunction<'resources'>,
  language: string,
): { data: Array<MovementFragment | HeaderWrapper>; headerIndices: number[] } {
  const result: Array<MovementFragment | HeaderWrapper> = [];
  const headerIndices: number[] = [];
  let lastKey = '';
  let lastMonth = DateTime.now().month; // don't show current month

  for (const movement of movements) {
    const currentDate = DateTime.fromISO(movement.date).startOf('day');
    const currentKey = currentDate.toISODate();
    const currentMonth = currentDate.month;

    if (lastKey !== currentKey) {
      const montHeader =
        lastMonth !== currentMonth ? formatTransactionMonth(currentDate, language) : undefined;

      headerIndices.push(result.length);
      result.push({
        date: formatTransactionDate(currentDate, t, language),
        month: montHeader,
        __typename: 'HeaderWrapper',
      });

      lastKey = currentKey;
      lastMonth = currentMonth;
    }

    result.push(movement);
  }

  return { data: result, headerIndices: headerIndices };
}

export default AccountDetailScreen;
