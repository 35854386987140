import React, { useCallback, useMemo, useState } from 'react';
import { BackHandler, Keyboard } from 'react-native';
import { Button } from 'react-native-paper';
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../types';
import { useTheme } from '../../../../utilities/reactUtils';
import PaymentRecipient from '../../components/shared/PaymentRecipient';
import PaymentDomesticInputs from '../../components/domestic/PaymentDomesticInputs';
import { usePaymentDomestic } from '../../context/PaymentDomesticContext';
import { formatAccountNumber } from '../../../../utilities/formatUtils';
import { useTranslation } from 'react-i18next';
import AccountPickerOverlay, {
  AccountPickerVariant,
} from '../../components/shared/AccountPickerOverlay';
import { Account } from '../../../../generated/graphql';
import PaymentModalScreen from '../../components/shared/PaymentModalScreen';
import { colors, styles } from '../../../../assets';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentDomestic'>;
type PaymentRouteProp = RouteProp<RootStackParamList, 'PaymentDomestic'>;

const PaymentDomesticScreen = () => {
  const { t } = useTranslation();
  const { fonts } = useTheme();
  const navigation = useNavigation<NavProp>();
  const { params } = useRoute<PaymentRouteProp>();

  const {
    recipientAccount,
    paymentData,
    paymentDataValid,
    debitAccount,
    setDebitAccount,
    recipientName,
  } = usePaymentDomestic();

  const [showAccountPicker, setShowAccountPicker] = useState(false);

  const canContinue = useMemo(() => {
    if (paymentData.amount < 0.1) return false;
    return paymentDataValid;
  }, [paymentData, paymentDataValid]);

  const onClose = useCallback(() => {
    navigation.navigate('Payment');
  }, [navigation]);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onContinue = useCallback(() => {
    Keyboard.dismiss();
    setTimeout(() => {
      navigation.navigate('PaymentDomesticSummary');
    }, 50);
  }, [navigation]);

  const onAccountPress = useCallback(() => {
    Keyboard.dismiss();
    setTimeout(() => {
      setShowAccountPicker(true);
    }, 50);
  }, []);

  const onPickAccount = useCallback(
    (account: Account) => {
      setDebitAccount(account);
      setShowAccountPicker(false);
    },
    [setDebitAccount],
  );

  const onAccountPickerDismiss = useCallback(() => {
    setShowAccountPicker(false);
  }, []);

  useFocusEffect(
    useCallback(() => {
      const handler = () => {
        if (params.allowBack) {
          return false;
        }
        onClose();
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', handler);
      return () => BackHandler.removeEventListener('hardwareBackPress', handler);
    }, [onClose, params.allowBack]),
  );

  return (
    <PaymentModalScreen
      headerProps={{
        accountData: debitAccount,
        onBackPress: params.allowBack ? onBack : undefined,
        onClosePress: onClose,
        onAccountPress: onAccountPress,
      }}
      content={
        <>
          <PaymentRecipient
            recipientName={recipientName}
            disabled={true}
            recipientAccount={formatAccountNumber(recipientAccount)}
          />
          <PaymentDomesticInputs />
        </>
      }
      footerContent={
        <Button
          onPress={onContinue}
          disabled={!canContinue}
          loading={false}
          mode="contained"
          theme={{
            roundness: 8,
            colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
          }}
          style={styles.formActionButton}
          labelStyle={fonts.labelSmall}
          contentStyle={{ width: '100%', height: '100%' }}
        >
          {t(
            canContinue
              ? 'payment.domesticForm.buttonContinue'
              : 'payment.domesticForm.buttonAmount',
          )}
        </Button>
      }
      otherContent={
        <AccountPickerOverlay
          visible={showAccountPicker}
          variant={AccountPickerVariant.DOMESTIC_PAYMENT}
          onDismiss={onAccountPickerDismiss}
          onSubmit={onPickAccount}
        />
      }
    />
  );
};

export default PaymentDomesticScreen;
