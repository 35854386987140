import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetModal,
  BottomSheetView,
  useBottomSheetDynamicSnapPoints,
} from '@gorhom/bottom-sheet';
import { SafeAreaView } from 'react-native-safe-area-context';
import { BackHandler, useWindowDimensions } from 'react-native';
import { Dialog, IconButton, Portal } from 'react-native-paper';
import { useTheme } from '../../utilities/reactUtils';
import { useFocusEffect } from '@react-navigation/native';

interface BottomSheetProps {
  visible: boolean;
  onDismiss: () => void;
  children: React.ReactNode;
}

function BottomSheet({ visible, onDismiss, children }: BottomSheetProps) {
  const { width, height } = useWindowDimensions();
  const maxHeight = useMemo(() => height * 0.75, [height]);

  useFocusEffect(
    useCallback(() => {
      const handler = () => {
        if (visible) {
          onDismiss();
          return true;
        } else return false;
      };

      BackHandler.addEventListener('hardwareBackPress', handler);
      return () => BackHandler.removeEventListener('hardwareBackPress', handler);
    }, [visible, onDismiss]),
  );

  const useBottomSheet = width <= 600;

  return (
    <>
      <BottomSheetInternal
        maxHeight={maxHeight}
        visible={visible && useBottomSheet}
        onDismiss={onDismiss}
      >
        {children}
      </BottomSheetInternal>
      <DialogInternal
        maxHeight={maxHeight}
        visible={visible && !useBottomSheet}
        onDismiss={onDismiss}
      >
        {children}
      </DialogInternal>
    </>
  );
}

function BottomSheetInternal({
  visible,
  onDismiss,
  maxHeight,
  children,
}: BottomSheetProps & { maxHeight: number }) {
  const bottomSheetRef = useRef<BottomSheetModal>(null);

  const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], []);

  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
    useBottomSheetDynamicSnapPoints(initialSnapPoints);

  const renderBackdrop = useCallback(
    (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />
    ),
    [],
  );

  useEffect(() => {
    if (visible) bottomSheetRef.current?.present();
    else bottomSheetRef.current?.close();
  }, [visible]);

  return (
    <BottomSheetModal
      ref={bottomSheetRef}
      snapPoints={animatedSnapPoints}
      handleHeight={animatedHandleHeight}
      contentHeight={animatedContentHeight}
      backdropComponent={renderBackdrop}
      enablePanDownToClose={true}
      onDismiss={onDismiss}
    >
      <BottomSheetView onLayout={handleContentLayout} style={{ maxHeight: maxHeight }}>
        <SafeAreaView edges={['bottom']}>{children}</SafeAreaView>
      </BottomSheetView>
    </BottomSheetModal>
  );
}

function DialogInternal({
  visible,
  onDismiss,
  maxHeight,
  children,
}: BottomSheetProps & { maxHeight: number }) {
  const { colors } = useTheme();

  return (
    <Portal>
      <Dialog
        dismissable={true}
        visible={visible}
        onDismiss={onDismiss}
        theme={{ roundness: 4 }}
        style={{
          width: 506,
          marginLeft: 'auto',
          marginRight: 'auto',
          maxHeight: maxHeight,
          backgroundColor: colors.surface,
        }}
      >
        <Dialog.ScrollArea>
          <IconButton
            icon="close"
            onPress={onDismiss}
            style={{ alignSelf: 'flex-end', margin: -8 }}
          />
          {children}
        </Dialog.ScrollArea>
      </Dialog>
    </Portal>
  );
}

export default BottomSheet;
