import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Linking, ListRenderItemInfo, Platform, ScrollView, View } from 'react-native';
import { Button, Chip, IconButton, List, Text } from 'react-native-paper';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../types';
import { Account, AccountType } from '../../../generated/graphql';
import { LogoutReason, UpdateInfoData, useGlobalContext } from '../../../contexts/GlobalContext';
import { baseTheme, colors, materialTheme, styles } from '../../../assets';
import { notImplementedAlert, prepareTestID } from '../../../utilities/utils';
import AccountCard from './AccountCard';
import DashboardHeader from './DashboardHeader';
import SafeAreaViewFixed from '../../../components/SafeAreaViewFixed';
import PageSettings from '../../../assets/svg/PageSettings';
import Toast, { ToastVariant } from '../../../components/Toast';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../utilities/reactUtils';
import useSortedHomeItems from './hooks/useSortedHomeItems';
import { HomeItemType, HomeListItem, HomeSettingsListItem } from '../../../types/HomeItem';
import { useNavigation } from '@react-navigation/native';
import { CommonCard } from '../../../components/CommonCard';

/** Screen navigation props. */
export type HomeScreenProp = StackNavigationProp<RootStackParamList, 'Home'>;

function HomeScreen() {
  const { userData, updateInfoData } = useGlobalContext();
  const globalContext = useGlobalContext();
  const { t } = useTranslation('resources');
  const theme = useTheme();
  const navigation: HomeScreenProp = useNavigation<HomeScreenProp>();

  const [refreshing, setRefreshing] = React.useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const [sortedHomeItems] = useSortedHomeItems(navigation);

  useEffect(() => {
    const show = updateInfoData.showUpdate;
    setShowUpdate(show);
  }, [updateInfoData.showUpdate]);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);

    globalContext
      .queryUserAccounts()
      .then(() => {
        setRefreshing(false);
      })
      .catch(console.error);
  }, [globalContext]);

  const onLogout = useCallback(
    () => void globalContext.logout(LogoutReason.MANUAL),
    [globalContext],
  );

  const onHide = useCallback(() => globalContext.hideUpdateInfo(), [globalContext]);

  useEffect(() => {
    if (Platform.OS === 'web')
      navigation.setOptions({
        headerRight: () => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <IconButton
              icon="refresh"
              containerColor={baseTheme.colors.background}
              size={24}
              onPress={onRefresh}
              testID={prepareTestID('home_screen_refresh_button')}
            />
            <Button
              style={{ paddingRight: 16 }}
              mode="text"
              textColor={theme.colors.secondary}
              onPress={onLogout}
              testID={prepareTestID('home_screen_log_out_button')}
            >
              {t('home.logOut')}
            </Button>
          </View>
        ),
      });
  }, [navigation, onLogout, onRefresh, t, theme]);

  const accounts: Array<Account> = userData?.userAccounts?.accounts?.filter(Boolean) ?? [];
  const accountsAvailable = accounts.length > 0;

  const totalBalance =
    accounts.reduce(
      (total, current) =>
        total +
        (current.currencyCode == 'EUR'
          ? current.availableBalance * 23.5
          : current.availableBalance),
      0,
    ) ?? 0;

  const totalBalanceCurrency =
    accounts.find((ac) => ac.type === AccountType.Current)?.currencyCode ?? 'CZK';

  const renderItem = ({ item, index }: ListRenderItemInfo<HomeListItem>) => {
    let renderedComponent; // Component that will be rendered based on the input item type
    switch (item.type) {
      case HomeItemType.ACCOUNT:
        renderedComponent = (
          <CommonCard
            style={{ marginHorizontal: 16, marginVertical: 8 }}
            header={
              <AccountCard
                title={(item.value as Account).name}
                balance={(item.value as Account).availableBalance}
                currencyCode={(item.value as Account).currencyCode}
                onPress={() =>
                  navigation.navigate('AccountDetail', { account: item.value as Account })
                }
                testID={prepareTestID(`dashboard_account_card_${index}`)}
              />
            }
          />
        );
        break;
      case HomeItemType.OTHER:
        renderedComponent = (
          <CommonCard
            style={{ marginHorizontal: 16, marginVertical: 8 }}
            header={
              <List.Item
                style={{ paddingStart: 6, paddingEnd: 14, paddingVertical: 12 }}
                title={(item.value as HomeSettingsListItem).title}
                titleStyle={{
                  ...materialTheme.fonts.titleMedium,
                  fontWeight: '500',
                  paddingEnd: 5,
                }}
                rippleColor={baseTheme.colors.ripple}
                onPress={() => {}}
              />
            }
          />
        );
        break;
      case HomeItemType.CARD:
        renderedComponent = (
          <CommonCard
            header={
              <List.Item
                style={{ paddingStart: 6, paddingEnd: 14, paddingVertical: 12 }}
                title={(item.value as HomeSettingsListItem).title}
                titleStyle={{
                  ...materialTheme.fonts.titleMedium,
                  fontWeight: '500',
                  paddingEnd: 5,
                }}
                rippleColor={baseTheme.colors.ripple}
                onPress={() => {}}
              />
            }
            content={
              <ScrollView
                style={{
                  width: '100%',
                  paddingBottom: 16,
                }}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
              >
                <View style={styles.dashboardItemHorizontalListCard}>
                  <View style={styles.homeCardChip}>
                    <Text variant={'labelSmall'} style={styles.homeCardChipLabel}>
                      {t('home.cardChipPlastic')}
                    </Text>
                  </View>
                  <Text variant={'bodySmall'} style={{ marginTop: 16 }}>
                    {t('home.mockCardName')}
                  </Text>
                  <Text variant={'bodySmall'}>{'**** 0055'}</Text>
                </View>
                <View style={styles.dashboardItemHorizontalListCard}>
                  <View style={styles.homeCardChip}>
                    <Text variant={'labelSmall'} style={styles.homeCardChipLabel}>
                      {t('home.cardChipVirtual')}
                    </Text>
                  </View>
                  <View style={styles.dashboardItemHorizontalListCardContent}>
                    <Button
                      icon="play-outline"
                      labelStyle={[theme.fonts.labelSmall, { alignSelf: 'center' }]}
                      mode="contained"
                      onPress={() => notImplementedAlert()}
                      theme={{
                        roundness: 8,
                        colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
                      }}
                      style={styles.homeCardButton}
                      contentStyle={{ height: '100%' }}
                    >
                      {t('home.cardButtonGet')}
                    </Button>
                  </View>
                </View>
              </ScrollView>
            }
          />
        );
        break;
      case HomeItemType.OFFER:
        renderedComponent = (
          <CommonCard
            header={
              <List.Item
                style={{ paddingStart: 6, paddingEnd: 14, paddingVertical: 12 }}
                title={(item.value as HomeSettingsListItem).title}
                titleStyle={{
                  ...materialTheme.fonts.titleMedium,
                  fontWeight: '500',
                  paddingEnd: 5,
                }}
                rippleColor={baseTheme.colors.ripple}
                onPress={() => {}}
              />
            }
            content={
              <ScrollView
                style={{
                  width: '100%',
                  paddingBottom: 16,
                }}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                pagingEnabled={true}
              >
                <View style={styles.dashboardItemHorizontalListCard}>
                  <Text variant={'titleSmall'}>{t('home.mockOfferSA')}</Text>
                  <View style={styles.dashboardItemHorizontalListCardContent}>
                    <Button
                      icon="play-outline"
                      labelStyle={[theme.fonts.labelSmall, { alignSelf: 'center' }]}
                      mode="contained"
                      onPress={() => notImplementedAlert()}
                      theme={{
                        roundness: 8,
                        colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
                      }}
                      style={styles.homeCardButton}
                      contentStyle={{ height: '100%' }}
                    >
                      {t('home.offersButtonCreate')}
                    </Button>
                  </View>
                </View>
                <View style={styles.dashboardItemHorizontalListCard}>
                  <Text variant={'titleSmall'}>{t('home.mockOfferBonus')}</Text>
                  <View style={styles.dashboardItemHorizontalListCardContent}>
                    <Button
                      icon="play-outline"
                      labelStyle={[theme.fonts.labelSmall, { alignSelf: 'center' }]}
                      mode="contained"
                      onPress={() => notImplementedAlert()}
                      theme={{
                        roundness: 8,
                        colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
                      }}
                      style={styles.homeCardButton}
                      contentStyle={{ height: '100%' }}
                    >
                      {t('home.offersButtonCreate')}
                    </Button>
                  </View>
                </View>
              </ScrollView>
            }
          />
        );
        break;
      default:
        renderedComponent = (
          <CommonCard
            header={
              <List.Item
                style={{ paddingStart: 6, paddingEnd: 14, paddingVertical: 12 }}
                title={item.saveId.toString()}
                titleStyle={{
                  ...materialTheme.fonts.titleMedium,
                  fontWeight: '500',
                  paddingEnd: 5,
                }}
                rippleColor={baseTheme.colors.ripple}
                onPress={() => {}}
              />
            }
          />
        );
    }
    return renderedComponent;
  };

  return (
    <SafeAreaViewFixed style={styles.screenBase} edges={['top', 'left', 'right']}>
      <OptionalUpdateView showUpdate={showUpdate} updateInfoData={updateInfoData} onHide={onHide} />
      <FlatList
        contentContainerStyle={styles.container}
        data={sortedHomeItems}
        ListHeaderComponent={
          <DashboardHeader
            balanceSum={totalBalance}
            balanceCurrency={totalBalanceCurrency}
            onProfilePress={() => navigation.navigate('Profile')}
          />
        }
        renderItem={renderItem}
        keyExtractor={(item) => item.saveId.toString()}
        contentInsetAdjustmentBehavior="automatic"
        ListFooterComponentStyle={{
          marginTop: 16,
          marginBottom: 16,
          //backgroundColor: colors.ui_debug_yellow,
        }}
        ListFooterComponent={
          accountsAvailable ? (
            <Button
              icon={() => <PageSettings width={20} height={20} />}
              labelStyle={theme.fonts.labelMedium}
              mode="text"
              onPress={() => navigation.navigate('HomeSettings')}
              testID={prepareTestID('dashboard_page_settings_button')}
            >
              {t('home.pageSettings')}
            </Button>
          ) : (
            <View />
          )
        }
        refreshing={refreshing}
        onRefresh={onRefresh}
        ListEmptyComponent={
          accountsAvailable ? (
            <View />
          ) : (
            <View style={{ alignItems: 'center', paddingTop: 200 }}>
              <Text variant="titleLarge" style={{ opacity: 0.6 }}>
                {t('common.noData')}
              </Text>
            </View>
          )
        }
      />
    </SafeAreaViewFixed>
  );
}

function OptionalUpdateView({
  showUpdate,
  updateInfoData,
  onHide,
}: {
  showUpdate: boolean;
  updateInfoData: UpdateInfoData;
  onHide: () => void;
}) {
  const { t } = useTranslation();

  if (!showUpdate || Platform.OS === 'web') return null;

  return (
    <Toast
      variant={ToastVariant.Info}
      style={{
        position: 'absolute',
        marginHorizontal: 16,
        marginTop: 48, //TODO
        zIndex: 2,
      }}
      text={updateInfoData.updateMessage ?? t('common.optionalUpdateInfo')}
      buttonText={t(
        Platform.OS === 'ios'
          ? 'common.optionalUpdateButtonIOS'
          : 'common.optionalUpdateButtonAndroid',
      )}
      onButtonPress={() => {
        onHide();
        if (updateInfoData?.updateAppUrl != null) {
          Linking.openURL(updateInfoData.updateAppUrl).catch(console.warn);
        }
      }}
      onAnimationFinished={onHide}
    />
  );
}

export default HomeScreen;
