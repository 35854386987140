import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../types';
import DoneScreen from '../../../../screens/Common/DoneScreen';
import { useTranslation } from 'react-i18next';
import { usePaymentToa } from '../../context/PaymentToaContext';
import { formatAmount } from '../../../../utilities/formatUtils';
import { getCurrencyLabel } from '../../../../utilities/paymentUtils';
import { colors } from '../../../../assets';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentToaVictory'>;

const PaymentToaVictoryScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavProp>();

  const { balanceInfoAmount, debitAccount } = usePaymentToa();

  const onClose = useCallback(() => {
    navigation.navigate('Payment');
  }, [navigation]);

  return (
    <DoneScreen
      title={t('payment.toaDone.title')}
      message={
        balanceInfoAmount
          ? t('payment.toaDone.message', {
              balance: formatAmount(
                balanceInfoAmount,
                getCurrencyLabel(debitAccount?.currencyCode),
                true,
              ),
            })
          : undefined
      }
      image={() => (
        <MaterialCommunityIcons
          name="checkbox-marked-circle-outline"
          size={140}
          color={colors.ui_turq_prim}
        />
      )}
      primaryButtonLabel={t('payment.toaDone.button')}
      onPrimaryButton={onClose}
    />
  );
};

export default PaymentToaVictoryScreen;
