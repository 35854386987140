import { ENV_NAME } from '@env';
import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import { colors } from '../../assets';
import { Text } from 'react-native-paper';
import { getLongAppVersion, prepareTestID } from '../../utilities/utils';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const AppVersionInfo = () => {
  const [longVersion] = useState(getLongAppVersion);
  const insets = useSafeAreaInsets();

  //TODO: adjust this when real ENV_NAME for production builds is known
  // const isProductionApp = ENV_NAME == 'PROD';

  const isProductionApp = true; // TODO: return old check after demo

  //little hack to hide <AppVersionInfo> when tapped
  const [hidden, setHidden] = useState(false);

  return !hidden && !isProductionApp ? (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        zIndex: 1,
        marginTop: insets.top,
        //backgroundColor: colors.ui_debug_ocean,
      }}
    >
      <Pressable onPress={() => setHidden(true)}>
        <Text
          style={{ paddingHorizontal: 4, backgroundColor: colors.ui_debug_yellow }}
          testID={prepareTestID('app_version_info_text')}
        >
          {longVersion}
        </Text>
      </Pressable>
    </View>
  ) : (
    <></>
  );
};

export default AppVersionInfo;
