import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { webTheme, uiConstants } from '../assets';

// stacks
import HomeStack from '../stacks/Main/HomeStack';
import PaymentStack from '../stacks/Main/PaymentStack';
import HubStack from '../stacks/Main/HubStack';
import ProfileStack from '../stacks/Main/ProfileStack';

function MainNavigator() {
  const { t } = useTranslation('resources');

  const Drawer = createDrawerNavigator();

  return (
    <NavigationContainer theme={webTheme}>
      <Drawer.Navigator
        screenOptions={{
          drawerType: 'permanent',
          drawerStyle: {
            width: uiConstants.drawerWidth,
          },
        }}
      >
        <Drawer.Screen
          name="HomeStack"
          options={{
            title: t('home.title'),
            headerShown: false,
          }}
          component={HomeStack}
        />
        <Drawer.Screen
          name="PaymentStack"
          options={{
            title: t('payment.title'),
            headerShown: false,
          }}
          component={PaymentStack}
        />
        <Drawer.Screen
          name="HubStack"
          options={{
            title: t('hub.title'),
            headerShown: false,
          }}
          component={HubStack}
        />
        <Drawer.Screen
          name="ProfileStack"
          options={{
            title: t('profile.title'),
            headerShown: false,
          }}
          component={ProfileStack}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default MainNavigator;
