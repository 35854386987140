import React, { useCallback, useEffect, useState } from 'react';
import { powerAuthService } from '../../services/powerAuthService';
import { colors as assetColors, styles } from '../../assets';
import { Keyboard, useWindowDimensions, View } from 'react-native';
import PasswordKeyboard, { getBiometryIcon, KeySize } from '../../components/PasswordKeyboard';
import SafeAreaViewFixed from '../../components/SafeAreaViewFixed';
import PasswordType, { DefaultPasswordType, isDigits } from '../../types/PasswordType';
import PasswordInputField from '../../components/PasswordInputField';
import { useTranslation } from 'react-i18next';
import {
  PowerAuthAuthentication,
  PowerAuthBiometryType,
  PowerAuthError,
  PowerAuthErrorCode,
} from 'react-native-powerauth-mobile-sdk';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { asyncStorageService } from '../../services/asyncStorageService';
import KeyboardAware from '../../components/KeyboardAware';
import PasswordTypeModal from '../../components/Picker/PasswordTypeModal';
import ErrorText from '../../components/ErrorText';
import { Button, Text } from 'react-native-paper';
import { useTheme } from '../../utilities/reactUtils';
import { prepareTestID } from '../../utilities/utils';

interface PasswordScreenProps {
  title: string;
  message?: string;
  image?: () => React.ReactElement;
  enabled?: boolean;
  allowBiometry: boolean;
  suppressAutoBiometry?: boolean;
  allowChangeType?: boolean;
  externalError?: string;
  temporaryPasswordType?: PasswordType;
  safeArea?: boolean;
  onAuthorization: (authentication: PowerAuthAuthentication) => Promise<void>;
  /** Called after successful authorization */
  onDone?: () => void;
  /** Called after fail animation */
  onAfterFailed?: (reason: unknown) => void;
  onPasswordTypeChanged?: (passwordType: PasswordType) => void;
}

function PasswordScreen({
  title,
  message,
  image,
  enabled = true,
  allowBiometry,
  suppressAutoBiometry = false,
  allowChangeType = false,
  externalError,
  temporaryPasswordType,
  safeArea = true,
  onAuthorization,
  onDone = () => {},
  onAfterFailed = () => {},
  onPasswordTypeChanged = () => {},
}: PasswordScreenProps) {
  const globalContext = useGlobalContext();
  const { t } = useTranslation('resources');
  const { colors, fonts } = useTheme();
  const { height } = useWindowDimensions();

  useEffect(() => {
    //console.info(`Window:${height}`);
  }, [height]);

  const keySize = height < 600 ? KeySize.Small : KeySize.Default;

  const [passwordType, setPasswordType] = useState<PasswordType>(DefaultPasswordType);
  const [showBiometry, setShowBiometry] = useState(false);
  const [biometryType, setBiometryType] = useState(PowerAuthBiometryType.NONE);
  const [biometryText, setBiometryText] = useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showChangeType, setShowChangeType] = useState(false);
  const [errorAnimation, setErrorAnimation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (temporaryPasswordType === undefined) {
      asyncStorageService.getPasswordType().then(setPasswordType).catch(console.error);
    } else {
      setPasswordType(temporaryPasswordType);
    }
  }, [temporaryPasswordType]);

  const handleError = useCallback(
    (reason: unknown) => {
      if (reason instanceof PowerAuthError) {
        console.debug(reason.code);
        switch (reason.code) {
          case PowerAuthErrorCode.AUTHENTICATION_ERROR:
            setErrorMessage(t('error.password'));
            globalContext.fetchActivationStatus().catch(console.error);
            break;
          case PowerAuthErrorCode.BIOMETRY_CANCEL:
          case PowerAuthErrorCode.BIOMETRY_NOT_RECOGNIZED:
          case PowerAuthErrorCode.BIOMETRY_LOCKOUT:
          case PowerAuthErrorCode.BIOMETRY_FAILED:
          case PowerAuthErrorCode.BIOMETRY_NOT_AVAILABLE:
            setErrorMessage('');
            break;
          default:
            console.debug(reason);
            setErrorMessage(t('error.unknown'));
            break;
        }
      } else if (typeof reason === 'string' && reason.includes('Signature is invalid')) {
        // REFACTOR: Proper unified error handling in GraphQL
        setErrorMessage(t('error.password'));
        globalContext.fetchActivationStatus().catch(console.error);
      } else {
        console.debug(reason);
        // RFR HACK
        setErrorMessage(t('error.password'));
      }

      setErrorAnimation(true);
      setTimeout(() => {
        setErrorAnimation(false);
        setPassword('');
        onAfterFailed(reason);
      }, ERROR_TIMEOUT);
    },
    [globalContext, onAfterFailed, t],
  );

  const authorize = useCallback(
    (authentication: PowerAuthAuthentication) => {
      setErrorMessage(undefined);
      setLoading(true);
      onAuthorization(authentication)
        .then(onDone)
        .catch(handleError)
        .finally(() => setLoading(false));
    },
    [onAuthorization, onDone, handleError],
  );

  const onPassword = useCallback(
    (password: string) => {
      authorize(PowerAuthAuthentication.password(password));
    },
    [authorize],
  );

  const onBiometry = useCallback(
    (message: string) => {
      authorize(
        PowerAuthAuthentication.biometry({
          promptTitle: title,
          promptMessage: message,
        }),
      );
    },
    [authorize, title],
  );

  // get biometry info only if user biometry is allowed here and user has biometry factor set up
  useEffect(() => {
    if (allowBiometry) {
      void powerAuthService.hasBiometryFactor().then((hasBiometryFactor) => {
        if (hasBiometryFactor)
          powerAuthService
            .getBiometryInfo()
            .then((info) => {
              setBiometryType(info.biometryType);
              setShowBiometry(info.isAvailable);
              const biometryText = t('authorization.authorizeWithBiometry', {
                context: info.biometryType,
              });
              setBiometryText(biometryText);

              if (info.isAvailable && !suppressAutoBiometry) {
                onBiometry(biometryText);
              }
            })
            .catch(console.warn);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowBiometry, suppressAutoBiometry, t]);

  const editingEnabled = enabled && !errorAnimation && !loading;
  const submitEnabled = editingEnabled && password.length >= 4;

  const onSubmit = useCallback(() => {
    if (submitEnabled) onPassword(password);
  }, [password, submitEnabled, onPassword]);

  const onKey = useCallback(
    (key: string) => {
      const newPassword = password + key;
      setPassword(newPassword);
      setErrorMessage('');

      if (isDigits(passwordType) && passwordType.length == newPassword.length)
        onPassword(newPassword);
    },
    [password, passwordType, onPassword],
  );

  const onErase = useCallback(() => {
    setPassword(password.slice(0, -1));
    setErrorMessage('');
  }, [password]);

  const onEditPassword = useCallback((text: string) => {
    setPassword(text);
    setErrorMessage('');
  }, []);

  return (
    <SafeAreaViewFixed
      style={{ ...styles.screenBase, backgroundColor: assetColors.ui_base_bg }}
      edges={safeArea ? ['top', 'left', 'right', 'bottom'] : []}
    >
      <KeyboardAware style={{ ...styles.containerFlex, paddingHorizontal: 16 }}>
        <View style={styles.centered}>
          <View style={{ marginBottom: 16 }}>{image ? image() : null}</View>
          <Text
            variant="headlineSmall"
            style={{ textAlign: 'center' }}
            testID={prepareTestID('password_screen_title_text')}
          >
            {title}
          </Text>
          {Boolean(message) && (
            <Text
              variant="bodyLarge"
              style={{
                textAlign: 'center',
                paddingHorizontal: 16,
                marginTop: 16,
              }}
              testID={prepareTestID('password_screen_message_text')}
            >
              {message}
            </Text>
          )}
          <PasswordInputField
            style={{ marginTop: 40 }}
            label={title}
            onChangeText={onEditPassword}
            password={password}
            passwordType={passwordType}
            enabled={editingEnabled}
            error={errorAnimation}
            onSubmitEditing={onSubmit}
          />
          {allowChangeType && (
            <Button
              disabled={!editingEnabled}
              style={{ marginTop: 16 }}
              labelStyle={fonts.labelMedium}
              textColor={colors.secondary}
              mode="text"
              onPress={() => {
                setShowChangeType(true);
                Keyboard.dismiss;
              }}
              testID={prepareTestID('password_screen_change_type_button')}
            >
              {t('password.changeType')}
            </Button>
          )}
        </View>
        {!isDigits(passwordType) ? (
          <View style={styles.formActionContainer}>
            {showBiometry && (
              <Button
                labelStyle={fonts.labelMedium}
                textColor={colors.secondary}
                icon={() => getBiometryIcon(biometryType)({ width: 24, height: 24 })}
                mode="text"
                disabled={!editingEnabled}
                onPress={() => onBiometry(biometryText)}
                testID={prepareTestID('password_screen_biometry_button')}
              >
                {biometryText}
              </Button>
            )}
            <Button
              mode="contained"
              style={{ ...styles.mainButtonContainer, ...styles.formActionButton }}
              theme={{
                roundness: 8,
                colors: { primary: assetColors.ui_turq_prim, onPrimary: assetColors.ui_black },
              }}
              labelStyle={fonts.labelSmall}
              disabled={!submitEnabled}
              onPress={onSubmit}
              testID={prepareTestID('password_screen_confirm_password_button')}
            >
              {t('authorization.confirm')}
            </Button>
          </View>
        ) : (
          <PasswordKeyboard
            enabled={editingEnabled}
            biometryVisible={showBiometry}
            biometryType={biometryType}
            keySize={keySize}
            onKey={onKey}
            onErase={onErase}
            onBiometry={() => onBiometry(biometryText)}
          />
        )}

        <ErrorText style={{ alignSelf: 'center' }} error={externalError || errorMessage} />
      </KeyboardAware>

      <PasswordTypeModal
        visible={showChangeType}
        onDismiss={() => setShowChangeType(false)}
        onSubmit={(type) => {
          onPasswordTypeChanged(type);
          setPasswordType(type);
          setPassword('');
        }}
      />
    </SafeAreaViewFixed>
  );
}

const ERROR_TIMEOUT = 2000; // ms

export default PasswordScreen;
