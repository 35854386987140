import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Keyboard, StyleSheet, TextInput as RNTextInput, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { HelperText, TextInput } from 'react-native-paper';
import { useTheme } from '../../../../../utilities/reactUtils';
import { formatAmount, formatDate } from '../../../../../utilities/formatUtils';
import { usePaymentToa } from '../../../context/PaymentToaContext';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../../types';
import { useNavigation } from '@react-navigation/native';
import { getCurrencyLabel } from '../../../../../utilities/paymentUtils';
import { DateTime } from 'luxon';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentToa'>;

const PaymentToaInputs = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { paymentData, setPaymentData, balanceInfoAmount, debitAccount } = usePaymentToa();
  const navigation = useNavigation<NavProp>();

  const ref_input_amount = useRef<RNTextInput>(null);
  const ref_input_message = useRef<RNTextInput>(null);
  const ref_input_due_date = useRef<RNTextInput>(null);

  const [amount, setAmount] = useState(
    paymentData.amount === 0 ? '' : paymentData.amount.toString(),
  );
  const [message, setMessage] = useState(paymentData.message ?? '');
  const [dueDate, setDueDate] = useState(paymentData.dueDate);
  const [dueDateInput, setDueDateInput] = useState('');

  useEffect(() => {
    setPaymentData({
      amount: amount.length === 0 ? 0 : parseFloat(amount.replace(',', '.')),
      message: message.length === 0 ? undefined : message,
      dueDate: dueDate,
    });
  }, [amount, message, dueDate, setPaymentData]);

  useEffect(() => {
    setDueDate(paymentData.dueDate);
  }, [paymentData]);

  useEffect(() => {
    if (dueDate) {
      setDueDateInput(formatDate(dueDate));
    } else {
      setDueDateInput('');
    }
  }, [dueDate]);

  const onCalendarPressed = () => {
    Keyboard.dismiss();
    navigation.navigate('PaymentToaDate');
  };

  const handleAmount = useCallback((value: string) => {
    if (value.length === 0) {
      setAmount('');
      return;
    }
    if (value.match(/^\d{1,8}([.,]\d{0,2})?$/)) {
      const resValue = value.replace('.', ',');
      setAmount(resValue);
    }
  }, []);

  const handleDueDate = useCallback((value: string) => {
    const filtered = value.replace(/\s/g, '');
    if (filtered.length === 0) {
      setDueDate(undefined);
    }
    if (filtered.match(/^[\d.]{0,10}$/)) {
      setDueDateInput(filtered);
      const date = DateTime.fromFormat(filtered, 'd.M.yyyy');
      if (date.isValid) {
        setDueDate(date);
      }
    }
  }, []);

  return (
    <View style={styles.container}>
      <TextInput
        ref={ref_input_amount}
        label={t('payment.toaForm.amountLabel')}
        placeholder={t('payment.toaForm.amountPlaceholder')}
        activeUnderlineColor={colors.secondary}
        onChangeText={handleAmount}
        value={amount}
        textContentType="none"
        keyboardType="numeric"
        right={<TextInput.Affix text={getCurrencyLabel(debitAccount?.currencyCode)} />}
        maxLength={12}
        autoFocus={true}
      />

      {balanceInfoAmount && (
        <HelperText type={'info'}>
          {t('payment.toaForm.remainingBalanceText', {
            balance: formatAmount(
              balanceInfoAmount,
              getCurrencyLabel(debitAccount?.currencyCode),
              true,
            ),
          })}
        </HelperText>
      )}

      <TextInput
        ref={ref_input_message}
        label={t('payment.toaForm.noteLabel')}
        activeUnderlineColor={colors.secondary}
        onChangeText={setMessage}
        value={message}
        maxLength={140}
        autoFocus={false}
        autoCorrect={false}
        multiline={true}
        style={styles.inputs}
      />

      <TextInput
        ref={ref_input_due_date}
        label={t('payment.toaForm.dueDateLabel')}
        activeUnderlineColor={colors.secondary}
        onChangeText={handleDueDate}
        value={dueDateInput}
        maxLength={12}
        autoFocus={false}
        autoCorrect={false}
        left={
          dueDateInput.length === 0 && <TextInput.Affix text={t('payment.toaForm.dueDateNow')} />
        }
        right={<TextInput.Icon icon={'calendar'} onPress={onCalendarPressed} />}
        style={styles.inputs}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginHorizontal: 16, marginTop: 16 },
  inputs: { marginTop: 16 },
});

export default PaymentToaInputs;
