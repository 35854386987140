import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { baseTheme } from '../assets';

// stacks
import SystemStateStack from '../stacks/SystemStateStack';

function SystemStateNavigator() {
  return (
    <NavigationContainer theme={baseTheme}>
      <SystemStateStack />
    </NavigationContainer>
  );
}

export default SystemStateNavigator;
