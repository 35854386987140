import React, { useState } from 'react';
import SafeAreaViewFixed from '../../../../../components/SafeAreaViewFixed';
import { styles as stylesImported } from '../../../../../assets';
import { Platform, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import PaymentFormHeader, { PaymentFormHeaderProps } from '../PaymentFormHeader';
import KeyboardAware from '../../../../../components/KeyboardAware';

interface PaymentModalScreenProps {
  headerProps: PaymentFormHeaderProps;
  content: React.ReactNode;
  footerContent?: React.ReactNode;
  otherContent?: React.ReactNode;
  contentContainerStyle?: StyleProp<ViewStyle>;
}

const PaymentModalScreen = ({
  headerProps,
  content,
  footerContent,
  otherContent,
  contentContainerStyle,
}: PaymentModalScreenProps) => {
  const [viewHeight, setViewHeight] = useState(0);

  return (
    <SafeAreaViewFixed
      style={stylesImported.screenBase}
      edges={
        Platform.OS === 'android' ? ['top', 'left', 'right', 'bottom'] : ['left', 'right', 'bottom']
      }
      onLayout={(event) => {
        const { height } = event.nativeEvent.layout;
        setViewHeight(height);
      }}
    >
      <View style={{ ...stylesImported.containerFlex }}>
        <PaymentFormHeader {...headerProps} />
        <KeyboardAware viewHeight={viewHeight} adjustForModal={true} style={styles.keyboardAware}>
          <ScrollView
            keyboardShouldPersistTaps={'always'}
            contentContainerStyle={contentContainerStyle}
          >
            {content}
          </ScrollView>
          {footerContent && <View style={stylesImported.formActionContainer}>{footerContent}</View>}
        </KeyboardAware>
      </View>
      {otherContent && <>{otherContent}</>}
    </SafeAreaViewFixed>
  );
};

const styles = StyleSheet.create({
  keyboardAware: { flex: 1, flexGrow: 1 },
});

export default PaymentModalScreen;
