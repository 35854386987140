import React, { useCallback, useState } from 'react';
import { TextInput } from 'react-native-paper';
import { DateTime } from 'luxon';
import { useTheme } from '../../utilities/reactUtils';
import { notImplementedAlert } from '../../utilities/utils';
import { formatDate } from '../../utilities/formatUtils';
import { StyleProp, ViewStyle } from 'react-native';

const DateInput = ({
  date,
  label,
  placeholder,
  autoFocus,
  onDate,
  testID,
  style,
}: {
  date: DateTime | null | undefined;
  label: string;
  placeholder?: string;
  autoFocus?: boolean | undefined;
  onDate: (amount: DateTime | undefined) => void;
  testID?: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const { colors } = useTheme();
  const [value, setValue] = useState(date ? formatDate(date).replace(/\s/g, '') : '');

  const handleDate = useCallback(
    (value: string) => {
      const filtered = value.replace(/\s/g, '').replace(/[^\d.]/g, '');
      setValue(filtered);

      if (filtered.match(/^[\d.]{0,10}$/)) {
        const date = DateTime.fromFormat(filtered, 'd.M.yyyy');
        onDate(date.isValid ? date : undefined);
      } else onDate(undefined);
    },
    [onDate],
  );

  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      activeUnderlineColor={colors.secondary}
      onChangeText={handleDate}
      value={value}
      maxLength={12}
      autoFocus={autoFocus}
      autoCorrect={false}
      right={<TextInput.Icon icon={'calendar'} onPress={notImplementedAlert} />}
      testID={testID}
      style={style}
    />
  );
};

export default DateInput;
