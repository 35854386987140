import AsyncStorage from '@react-native-async-storage/async-storage';
import PasswordType, { parsePasswordType } from '../types/PasswordType';
import { Language, parseLanguage } from '../types/Language';
import { parseHomeItems, SavableHomeSettingsItem } from '../types/HomeItem';

enum AsyncStorageKey {
  PasswordType = '@password_type',
  Language = '@language',
  HomeItemsOrder = '@home_items_order',
  UserId = '@user_id',
}

async function clearCaches(): Promise<void> {
  console.debug('asyncStorageService: clearCaches() invoked');

  await AsyncStorage.multiRemove([AsyncStorageKey.PasswordType, AsyncStorageKey.UserId]).catch(
    console.warn,
  );
}

async function getPasswordType(): Promise<PasswordType> {
  const value = await AsyncStorage.getItem(AsyncStorageKey.PasswordType);
  return parsePasswordType(value);
}

async function setPasswordType(passwordType: PasswordType): Promise<void> {
  await AsyncStorage.setItem(AsyncStorageKey.PasswordType, JSON.stringify(passwordType));
}

async function getLanguage(): Promise<Language | null> {
  const value = await AsyncStorage.getItem(AsyncStorageKey.Language);
  return value != null ? parseLanguage(value) : null;
}

async function setLanguage(language: Language): Promise<void> {
  await AsyncStorage.setItem(AsyncStorageKey.Language, language);
}

/**
 * Gets the list of dashboard items as a JSON and converts it into array of objects.
 */
async function getHomeItemsOrder(): Promise<Array<SavableHomeSettingsItem> | null> {
  return AsyncStorage.getItem(AsyncStorageKey.HomeItemsOrder).then((value) =>
    value != null ? parseHomeItems(value) : null,
  );
}

/**
 * Converts the list of home list items into the list and save it into async storage.
 * The result should be JSON as a string.
 *
 * EXAMPLE:
 * [
 *  {
 *    "index": 1,
 *    "type": "ACCOUNT"
 *  },
 *  {
 *    "index": 2,
 *    "type": "CARD"
 *  }
 * ]
 */
async function setHomeItemsOrder(items: Array<SavableHomeSettingsItem>): Promise<void> {
  const jsonItem = JSON.stringify(items);
  return AsyncStorage.setItem(AsyncStorageKey.HomeItemsOrder, jsonItem);
}

async function getUserId(): Promise<string | null> {
  return await AsyncStorage.getItem(AsyncStorageKey.UserId);
}

async function setUserId(userId: string) {
  await AsyncStorage.setItem(AsyncStorageKey.UserId, userId);
}

export const asyncStorageService = {
  clearCaches,
  getPasswordType,
  setPasswordType,
  getLanguage,
  setLanguage,
  getHomeItemsOrder,
  setHomeItemsOrder,
  getUserId,
  setUserId,
};
