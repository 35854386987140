import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { useTranslation } from 'react-i18next';
import {
  PasswordType as PAPassword,
  PowerAuthAuthentication,
  PowerAuthBiometryType,
} from 'react-native-powerauth-mobile-sdk';
import PasswordScreen from '../Common/PasswordScreen';
import { powerAuthService } from '../../services/powerAuthService';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../types';
import { useNavigation } from '@react-navigation/native';
import PasswordType from '../../types/PasswordType';
import { asyncStorageService } from '../../services/asyncStorageService';
import DoneScreen from '../Common/DoneScreen';
import { colors } from '../../assets';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Secure from '../../assets/svg/Secure';

enum Step {
  Enter,
  Repeat,
  Done,
}

type ActivationProp = StackNavigationProp<RootStackParamList, 'MobileActivationPassword'>;

function MobileActivationPasswordScreen() {
  const { t } = useTranslation('resources');
  const globalContext = useGlobalContext();
  const navigation = useNavigation<ActivationProp>();

  const [step, setStep] = useState(Step.Enter);
  const [savedPassword, setSavedPassword] = useState<PAPassword | undefined>();
  const [error, setError] = useState('');
  const [biometryType, setBiometryType] = useState(PowerAuthBiometryType.NONE);

  useEffect(() => {
    void powerAuthService.getBiometryInfo().then((info) => {
      if (info.isAvailable) {
        setBiometryType(info.biometryType);
      }
    });
  }, []);

  const onSubmit = (authentication: PowerAuthAuthentication) => {
    setError('');
    if (step === Step.Enter) {
      setSavedPassword(authentication.password);
      setStep(Step.Repeat);
      return Promise.resolve();
    } else {
      if (authentication.password === savedPassword) {
        return Promise.resolve();
      } else {
        setError(t('mobileActivation.passwordsNoMatch'));
        return Promise.reject();
      }
    }
  };

  const onDone = () => {
    if (step === Step.Repeat && savedPassword !== undefined) {
      if (biometryType === PowerAuthBiometryType.NONE) {
        globalContext
          .commitActivation(PowerAuthAuthentication.commitWithPassword(savedPassword))
          .then(() => setStep(Step.Done))
          .catch(console.error);
      } else {
        navigation.navigate('MobileActivationBiometry', {
          password: savedPassword,
          biometryType: biometryType,
        });
      }
    }
  };

  const onExit = useCallback(
    () => void globalContext.fetchActivationStatus().catch(console.debug),
    [globalContext],
  );

  const onFailed = () => {
    if (step === Step.Repeat) {
      setError('');
      setStep(Step.Enter);
    }
  };

  const onPasswordTypeChanged = (passwordType: PasswordType) => {
    void asyncStorageService.setPasswordType(passwordType).catch(console.error);
  };

  let title: string;
  let message: string;

  switch (step) {
    case Step.Enter:
      title = t('mobileActivation.choosePassword');
      message = t('mobileActivation.choosePasswordMessage');
      break;
    case Step.Repeat:
      title = t('mobileActivation.repeatPassword');
      message = t('mobileActivation.repeatPasswordMessage');
      break;
    case Step.Done:
      title = t('mobileActivation.success');
      message = '';
      break;
  }

  return step === Step.Done ? (
    <DoneScreen
      title={title}
      image={() => (
        <MaterialCommunityIcons
          name="checkbox-marked-circle-outline"
          size={140}
          color={colors.ui_turq_prim}
        />
      )}
      primaryButtonLabel={t('common.continue')}
      onPrimaryButton={onExit}
    />
  ) : (
    <PasswordScreen
      title={title}
      message={message}
      image={() => <Secure />}
      externalError={error}
      allowBiometry={false}
      onAuthorization={onSubmit}
      allowChangeType={step === Step.Enter}
      onDone={onDone}
      onAfterFailed={onFailed}
      onPasswordTypeChanged={onPasswordTypeChanged}
      key={step}
    />
  );
}

export default MobileActivationPasswordScreen;
