import { HomeSettingsScreenProp } from '../HomeSettingsScreen';
import { useCallback, useEffect } from 'react';
import { HomeSettingsListItem, SavableHomeSettingsItem } from '../../../../types/HomeItem';
import { asyncStorageService } from '../../../../services/asyncStorageService';

/**
 * Saves product data when user leaves the screen.
 *
 * @param products data list
 * @param navigation screen navigation prop
 */
export default function useSaveHomeItemsOrder(
  products: Array<HomeSettingsListItem>,
  navigation: HomeSettingsScreenProp,
) {
  const saveAction = useCallback(() => {
    const converted = convertToSavable(products);
    console.log('saving home items order');
    void asyncStorageService.setHomeItemsOrder(converted);
  }, [products]);

  useEffect(() => {
    navigation.addListener('beforeRemove', saveAction);
    return () => navigation.removeListener('beforeRemove', saveAction);
  }, [navigation, saveAction]);
}

function convertToSavable(items: Array<HomeSettingsListItem>): Array<SavableHomeSettingsItem> {
  return items.map((item, index) => ({
    saveId: item.saveId,
    type: item.type,
    sortId: index,
    displayed: item.displayed,
  }));
}
