import { Account } from '../../../../generated/graphql';
import {
  HomeItemType,
  HomeListItem,
  HomeSettingsListItem,
  SavableHomeSettingsItem,
} from '../../../../types/HomeItem';
import { useEffect, useState } from 'react';
import { asyncStorageService } from '../../../../services/asyncStorageService';
import { sortItems } from '../../../../types/SortableListItem';
import useHomeItems from '../../hooks/useHomeItems';
import { HomeScreenProp } from '../HomeScreen';

export default function useSortedHomeItems(navigation: HomeScreenProp) {
  // Home items
  const [homeItems] = useHomeItems();
  // Home items order
  const [homeItemsOrder, setHomeItemsOrder] = useState<Array<SavableHomeSettingsItem> | null>(null);
  // Sorted home items
  const [sortedHomeItems, setSortedHomeItems] = useState<Array<HomeListItem>>([]);

  const loadHomeItemsOrder = () => {
    void asyncStorageService
      .getHomeItemsOrder()
      .then((values) => setHomeItemsOrder(values))
      .catch((err: Error) => console.log(`AsyncStorageServiceErr: ${err.message}`));
  };

  // load ordered list items only once
  useEffect(() => {
    navigation.addListener('focus', loadHomeItemsOrder);
    //console.log('home item order loaded');
  }, [navigation]);

  useEffect(() => {
    const sortedItems = sortItems<SavableHomeSettingsItem, HomeListItem, HomeItemType>(
      homeItemsOrder,
      convertToHomeListItem(homeItems),
    );
    setSortedHomeItems(sortedItems.filter((item) => item.displayed));
  }, [homeItems, homeItemsOrder]);

  return [sortedHomeItems];
}

/**
 * Converts input data into array with [HomeItemList] objects.
 *
 * @param data needs to be converted
 */
function convertToHomeListItem(data: {
  accounts: Array<Account>;
  cards: Array<HomeSettingsListItem>;
  offers: Array<HomeSettingsListItem>;
  others: Array<HomeSettingsListItem>;
}): Array<HomeListItem> {
  return new Array<HomeListItem>()
    .concat(
      data.accounts.map((account) => ({
        type: HomeItemType.ACCOUNT,
        value: account,
        sortId: -1,
        displayed: true,
        saveId: account.id,
      })),
    )
    .concat(
      data.cards.map((card) => ({
        type: HomeItemType.CARD,
        value: card,
        sortId: -1,
        displayed: true,
        saveId: card.saveId,
      })),
    )
    .concat(
      data.offers.map((offer) => ({
        type: HomeItemType.OFFER,
        value: offer,
        sortId: -1,
        displayed: true,
        saveId: offer.saveId,
      })),
    )
    .concat(
      data.others.map((other) => ({
        type: HomeItemType.OTHER,
        value: other,
        sortId: -1,
        displayed: true,
        saveId: other.saveId,
      })),
    );
}
