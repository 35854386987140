import React, { useCallback } from 'react';
import SafeAreaViewFixed from '../../components/SafeAreaViewFixed';
import { colors, styles } from '../../assets';
import { BackHandler, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Button, Text } from 'react-native-paper';
import { useTheme } from '../../utilities/reactUtils';
import { prepareTestID } from '../../utilities/utils';

type DoneScreenProps = {
  title: string;
  message?: string;
  image?: () => React.ReactElement;
  visible?: boolean;
  primaryButtonLabel: string;
  onPrimaryButton: () => void;
};

function DoneScreen({
  title,
  message,
  image,
  visible = true,
  primaryButtonLabel,
  onPrimaryButton,
}: DoneScreenProps) {
  const { fonts } = useTheme();

  useFocusEffect(
    useCallback(() => {
      const handler = () => {
        if (visible) {
          onPrimaryButton();
          return true;
        } else return false;
      };

      BackHandler.addEventListener('hardwareBackPress', handler);
      return () => BackHandler.removeEventListener('hardwareBackPress', handler);
    }, [visible, onPrimaryButton]),
  );

  if (!visible) return null;

  return (
    <SafeAreaViewFixed style={styles.screenBase}>
      <View style={{ ...styles.containerFlex, padding: 16 }}>
        <View style={styles.centered}>{image && image()}</View>
        <View style={{ flex: 1.1, alignItems: 'center' }}>
          <Text
            variant="headlineSmall"
            style={{ textAlign: 'center' }}
            testID={prepareTestID('done_screen_title_text')}
          >
            {title}
          </Text>
          {message && (
            <Text
              variant="bodyLarge"
              style={{
                textAlign: 'center',
                marginVertical: 16,
              }}
              testID={prepareTestID('done_screen_message_text')}
            >
              {message}
            </Text>
          )}
        </View>
        <View style={{ ...styles.formActionContainer, paddingHorizontal: 0 }}>
          <Button
            mode="contained"
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
            }}
            style={styles.formActionButton}
            contentStyle={{ width: '100%', height: '100%' }}
            labelStyle={fonts.labelSmall}
            onPress={onPrimaryButton}
            testID={prepareTestID('done_screen_primary_button')}
          >
            {primaryButtonLabel}
          </Button>
        </View>
      </View>
    </SafeAreaViewFixed>
  );
}

export default DoneScreen;
