import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { baseTheme } from '../assets';

// stacks
import MobileActivationStack from '../stacks/MobileActivationStack';

function MobileActivationNavigator() {
  return (
    <NavigationContainer theme={baseTheme}>
      <MobileActivationStack />
    </NavigationContainer>
  );
}

export default MobileActivationNavigator;
