import React, { useCallback, useMemo, useRef } from 'react';
import { Platform, StyleSheet, TextInput as RNTextInput, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../types';
import { useTheme } from '../../../../utilities/reactUtils';
import { usePaymentDomestic } from '../../context/PaymentDomesticContext';
import PaymentModalScreen from '../../components/shared/PaymentModalScreen';
import DomesticAccountInput from '../../components/domestic/DomesticAccountInput';
import { prepareTestID } from '../../../../utilities/utils';
import { colors, styles as stylesImported } from '../../../../assets';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentDomesticRecipient'>;

const PaymentDomesticRecipientScreen = () => {
  const { t } = useTranslation();
  const { fonts } = useTheme();
  const navigation = useNavigation<NavProp>();

  const { recipientValidation, resetPaymentData } = usePaymentDomestic();
  const { isPrefixValid, isAccountNumberValid, isBankCodeValid } = recipientValidation;

  const ref_input_account_prefix = useRef<RNTextInput>(null);
  const ref_input_account_number = useRef<RNTextInput>(null);
  const ref_input_bank_code = useRef<RNTextInput>(null);

  const canContinue = useMemo(() => {
    return isPrefixValid && isAccountNumberValid && isBankCodeValid;
  }, [isAccountNumberValid, isBankCodeValid, isPrefixValid]);

  const buttonText = useMemo(() => {
    if (!isAccountNumberValid) {
      return t('payment.domesticAccount.buttonTextAccountNumber');
    }
    if (!isBankCodeValid) {
      return t('payment.domesticAccount.buttonTextBank');
    }
    if (!isPrefixValid) {
      return t('payment.domesticAccount.buttonTextPrefix');
    }
    return t('payment.domesticAccount.buttonTextContinue');
  }, [isAccountNumberValid, isBankCodeValid, isPrefixValid, t]);

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onButtonPress = useCallback(() => {
    if (!isAccountNumberValid) {
      ref_input_account_number.current?.focus();
      return;
    }
    if (!isBankCodeValid) {
      ref_input_bank_code.current?.focus();
      return;
    }
    ref_input_account_prefix.current?.focus();
  }, [isAccountNumberValid, isBankCodeValid]);

  const onContinue = useCallback(() => {
    resetPaymentData();
    navigation.navigate('PaymentDomestic', { allowBack: true });
  }, [navigation, resetPaymentData]);

  return (
    <PaymentModalScreen
      headerProps={{ onClosePress: onClose, headerText: t('payment.domesticAccount.title') }}
      content={
        <>
          <Text variant={'titleSmall'} style={styles.title}>
            {t('payment.domesticAccount.accountSectionLabel')}
          </Text>
          <DomesticAccountInput
            ref_input_account_number={ref_input_account_number}
            ref_input_account_prefix={ref_input_account_prefix}
            ref_input_bank_code={ref_input_bank_code}
          />
          {(Platform.OS === 'ios' || Platform.OS === 'android') && (
            <Button
              icon="qrcode"
              labelStyle={[fonts.labelSmall, styles.buttonQrText]}
              mode="contained"
              onPress={() => navigation.navigate('PaymentDomesticQR')}
              theme={{
                roundness: 4,
                colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
              }}
              style={styles.buttonQr}
              testID={prepareTestID('payment_recipient_qr_button')}
            >
              {t('payment.domesticAccount.buttonQr')}
            </Button>
          )}
          <View style={styles.growContent}></View>
        </>
      }
      footerContent={
        <Button
          onPress={canContinue ? onContinue : onButtonPress}
          disabled={!isAccountNumberValid && !isBankCodeValid}
          loading={false}
          mode="contained"
          theme={{
            roundness: 8,
            colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
          }}
          style={stylesImported.formActionButton}
          labelStyle={fonts.labelSmall}
          contentStyle={{ width: '100%', height: '100%' }}
          testID={prepareTestID('payment_recipient_primary_button')}
        >
          {buttonText}
        </Button>
      }
    />
  );
};

const styles = StyleSheet.create({
  title: { marginLeft: 23 },
  growContent: { flexGrow: 1 },
  buttonQr: { alignSelf: 'center', marginTop: 12 },
  buttonQrText: { marginVertical: 8 },
});

export default PaymentDomesticRecipientScreen;
