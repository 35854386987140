import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { colors } from '../../../assets';
import { RootStackParamList } from '../../../types';
import { useTranslation } from 'react-i18next';

// screens
import ProfileScreen from '../../../screens/Main/Profile/ProfileScreen';
import AppInfoScreen from '../../../screens/Main/Profile/AppInfoScreen';
import DeviceManagementScreen from '../../../screens/Main/Profile/DeviceManagementScreen';
import DeviceDetailScreen from '../../../screens/Main/Profile/DeviceDetailScreen';
import UserDetailScreen from '../../../features/UserDetail/UserDetailScreen';
import { UserDetailProvider } from '../../../features/UserDetail/context/UserDetailContext';

const Stack = createNativeStackNavigator<RootStackParamList>();

function ProfileStack() {
  const { t } = useTranslation('resources');

  return (
    <UserDetailProvider>
      <Stack.Navigator
        initialRouteName="Profile"
        screenOptions={{
          headerStyle: { backgroundColor: colors.ui_white },
          headerShadowVisible: false,
        }}
      >
        <Stack.Screen
          name="Profile"
          component={ProfileScreen}
          options={{
            title: t('profile.title'),
            headerLargeTitle: true,
            headerTitleStyle: { color: colors.ui_black },
          }}
        />
        <Stack.Screen
          name="AppInfo"
          component={AppInfoScreen}
          options={{
            title: t('appInfo.title'),
            headerTitleStyle: { color: colors.ui_black },
          }}
        />
        <Stack.Screen
          name="DeviceManagement"
          component={DeviceManagementScreen}
          options={{
            title: t('deviceManagement.title'),
            headerTitleStyle: { color: colors.ui_black },
          }}
        />
        <Stack.Screen
          name="DeviceDetail"
          component={DeviceDetailScreen}
          options={{
            title: '',
          }}
        />
        <Stack.Screen
          name="UserDetail"
          component={UserDetailScreen}
          options={{
            title: '',
            headerTitleStyle: { color: colors.ui_black },
            headerShadowVisible: false,
          }}
        />
      </Stack.Navigator>
    </UserDetailProvider>
  );
}

export default ProfileStack;
