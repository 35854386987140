import { StyleSheet, View } from 'react-native';
import { colors } from '../../assets';
import { useTheme } from '../../utilities/reactUtils';
import { List, Text } from 'react-native-paper';
import React from 'react';

export type SectionItem = {
  label: string;
  value: string;
  subValue?: string;
  type?: SectionItemType;
};

export enum SectionItemType {
  Simple = 'Simple',
  Multiline = 'Multiline',
}
const CommonItemSection = ({ items, marginTop }: { items: SectionItem[]; marginTop?: number }) => {
  return (
    <View style={{ ...styles.container, marginTop: marginTop ?? 8 }}>
      {items.map((item, index) => {
        const type = item.type ?? SectionItemType.Simple;
        switch (type) {
          case SectionItemType.Multiline: {
            return <SectionItemMultiline key={index} label={item.label} value={item.value} />;
          }
          case SectionItemType.Simple:
          default: {
            return (
              <SectionItemSimple
                key={index}
                label={item.label}
                value={item.value}
                subValue={item.subValue}
              />
            );
          }
        }
      })}
    </View>
  );
};

const SectionItemSimple = ({ label, value, subValue }: SectionItem) => {
  const { fonts } = useTheme();

  return (
    <List.Item
      title={label}
      titleStyle={fonts.bodyMedium}
      right={() => (
        <View style={styles.simpleItem}>
          <Text variant="bodyMedium" style={styles.simpleItemValue}>
            {value}
          </Text>
          {Boolean(subValue) && (
            <Text variant="bodyMedium" style={styles.simpleItemSubValue}>
              {subValue}
            </Text>
          )}
        </View>
      )}
    />
  );
};

const SectionItemMultiline = ({ label, value }: SectionItem) => {
  const { fonts } = useTheme();

  return (
    <List.Item
      title={label}
      titleStyle={fonts.bodyMedium}
      description={value}
      descriptionStyle={{ ...fonts.bodyMedium, ...styles.multilineItemDescription }}
      descriptionNumberOfLines={5}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    backgroundColor: colors.ui_base_bg,
    borderRadius: 8,
  },
  simpleItem: {
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'flex-end',
  },
  simpleItemValue: { fontWeight: '600' },
  simpleItemSubValue: { opacity: 0.6 },
  multilineItemDescription: { fontWeight: '700', marginTop: 8 },
});

export default CommonItemSection;
