import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useGlobalContext } from './GlobalContext';
import { asyncStorageService } from '../services/asyncStorageService';
import { AvatarKey, AvatarType } from '../generated/graphql';
import { graphQLService } from '../services/graphQLService';

type AvatarContextType = {
  getAvatar: (avatar: AvatarKey | 'USER') => Promise<string | undefined>;
  putAvatar: (data: string) => void;
  clearAvatar: () => void;
  refreshAvatars: () => void;
};

const AvatarContext = createContext<AvatarContextType>({} as AvatarContextType);

export const AvatarContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    userData: { userId: globalUserId },
  } = useGlobalContext();

  const [userId, setUserId] = useState<string>();

  useEffect(() => void asyncStorageService.getUserId().then((id) => setUserId(id ?? undefined)));

  useEffect(() => {
    if (globalUserId) {
      void asyncStorageService.setUserId(globalUserId);
      setUserId(globalUserId);
    }
  }, [globalUserId]);

  const getAvatar = useCallback(
    (avatar: AvatarKey | 'USER') => {
      let avatarKey: AvatarKey | undefined;
      if (avatar === 'USER') {
        avatarKey = userId ? { type: AvatarType.Party, id: userId } : undefined;
      } else avatarKey = avatar;
      return avatarKey
        ? graphQLService
            .queryGetAvatar(avatarKey)
            .then((query) =>
              query.getAvatar?.data
                ? query.getAvatar?.data.replace(/(\r\n|\n|\r)/gm, '')
                : undefined,
            )
        : Promise.reject();
    },
    [userId],
  );

  const putAvatar = useCallback((data: string) => void graphQLService.mutationPutAvatar(data), []);
  const clearAvatar = useCallback(() => void graphQLService.mutationPutAvatar(undefined), []);

  const refreshAvatars = useCallback(() => void graphQLService.refreshAvatars(), []);

  return (
    <AvatarContext.Provider
      value={{
        getAvatar,
        putAvatar,
        clearAvatar,
        refreshAvatars,
      }}
    >
      {children}
    </AvatarContext.Provider>
  );
};

export const useAvatar = () => {
  const context = useContext(AvatarContext);

  if (!context) {
    throw new Error('useAvatar() must be used within an AvatarContextProvider');
  }

  return context;
};
