import React from 'react';
import { Text, View, ViewProps } from 'react-native';
import { styles } from '../../assets';
import Warning from '../../assets/svg/Warning';

function ErrorText(props: ViewProps & { error: string | undefined }) {
  return props.error ? (
    <View style={[props.style, styles.errorTextBox]}>
      <Warning width={24} height={24} style={styles.errorTextIcon} />
      <Text style={styles.errorTextText} numberOfLines={2}>
        {props.error}
      </Text>
    </View>
  ) : null;
}

export default ErrorText;
