import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

// screens
import SystemStateScreen from '../../screens/SystemState/SystemStateScreen';

const Stack = createNativeStackNavigator<RootStackParamList>();

function SystemStateStack() {
  const { t } = useTranslation('resources');

  function title(text: string) {
    return Platform.select({ web: `${t('systemState.titleWeb')}`, default: text });
  }

  return (
    <Stack.Navigator initialRouteName="SystemState" screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="SystemState"
        component={SystemStateScreen}
        options={{
          title: title(t('systemState.title')),
        }}
      />
    </Stack.Navigator>
  );
}

export default SystemStateStack;
