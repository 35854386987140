/**
 * Application languages
 */
export enum Language {
  CS = 'cs',
  EN = 'en',
}

const LANGUAGE_SK = 'sk';

/**
 * Select one of the application languages based an arbitrary supplied language
 */
export function parseLanguage(locale: string): Language {
  if (locale.startsWith(Language.CS) || locale.startsWith(LANGUAGE_SK)) return Language.CS;
  else return Language.EN;
}
