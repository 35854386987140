import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const Eye = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.333c2.99 0 5.63 2.028 7.926 5.953l.172.298.235.416-.407.714C15.63 14.64 12.99 16.667 10 16.667s-5.63-2.027-7.926-5.953l-.172-.298L1.667 10l.235-.416C4.24 5.464 6.935 3.334 10 3.334ZM10 5c-2.253 0-4.393 1.596-6.412 5 2.019 3.404 4.16 5 6.412 5 2.191 0 4.274-1.508 6.24-4.713l.172-.287-.173-.287C14.271 6.507 12.189 5 10 5Zm0 2.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z"
      fill="#000"
    />
  </Svg>
);

export default Eye;
