import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const Secure = (props: SvgProps) => (
  <Svg viewBox="0 0 85 60" width={85} height={60} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        d="M76.84 53.975c.737 1.305.111 5.711.111 5.711s-4.098-1.735-4.836-3.039a2.713 2.713 0 0 1 4.724-2.672Z"
        fill="#F1F1F1"
      />
      <Path
        d="m77.01 59.68-.086.019c-.839-3.984-3.767-6.686-3.797-6.713l.06-.066c.03.027 2.98 2.747 3.824 6.76Z"
        fill="#fff"
      />
      <Path
        d="M83.545 55.46c-1.001 1.818-6.648 4.279-6.648 4.279s-.93-6.089.07-7.905a3.756 3.756 0 1 1 6.578 3.627Z"
        fill="#F1F1F1"
      />
      <Path
        d="m76.947 59.805-.085-.09c4.086-3.88 5.144-9.292 5.154-9.346l.121.023c-.01.054-1.074 5.504-5.19 9.413Z"
        fill="#fff"
      />
      <Path
        d="M19.784 41.704a7.725 7.725 0 0 1-1.915-.248l-.123-.034-.114-.057c-4.135-2.073-7.623-4.811-10.368-8.138a30.807 30.807 0 0 1-5.234-9.295A35.776 35.776 0 0 1 .007 11.329l.003-.207c0-2.085 1.157-3.914 2.948-4.66C4.328 5.892 16.772.78 17.67.411c1.693-.848 3.5-.14 3.788-.016.649.265 12.152 4.97 14.638 6.153 2.562 1.22 3.245 3.412 3.245 4.514 0 4.992-.864 9.658-2.57 13.867a32.11 32.11 0 0 1-5.77 9.299c-4.71 5.3-9.421 7.18-9.467 7.196a5.146 5.146 0 0 1-1.75.28Zm-1.108-2.745c.409.092 1.35.23 1.962.006.779-.284 4.722-2.33 8.407-6.476 5.092-5.73 7.675-12.932 7.679-21.408-.01-.172-.131-1.397-1.753-2.169-2.438-1.16-14.394-6.05-14.515-6.099l-.033-.014c-.25-.105-1.048-.326-1.597-.038l-.11.051c-.134.055-13.343 5.48-14.751 6.067-.986.41-1.337 1.428-1.337 2.243 0 .06-.001.146-.004.258-.112 5.8 1.23 20.07 16.052 27.58Z"
        fill="#3F3D56"
      />
      <Path
        d="M18.218 1.601S4.89 7.076 3.461 7.671c-1.428.595-2.142 2.023-2.142 3.451s-1.071 20.063 16.9 29.073c0 0 1.63.45 2.867 0 1.238-.452 16.947-8.068 16.947-29.133 0 0 0-2.142-2.5-3.332-2.498-1.19-14.581-6.129-14.581-6.129s-1.484-.654-2.734 0Z"
        fill="#6C63FF"
      />
      <Path
        opacity={0.1}
        d="M19.646 5.886v29.203S6.198 28.616 6.317 11.598l13.33-5.712Z"
        fill="#000"
      />
      <Path
        d="m19.822 26.875-4.284-5.508 2.491-1.938 2.029 2.608 6.852-7.233 2.291 2.17-9.38 9.9Z"
        fill="#fff"
      />
      <Path
        d="M84.048 60H44.904a.103.103 0 0 1 0-.206h39.144a.103.103 0 1 1 0 .206Z"
        fill="#CACACA"
      />
      <Path d="M56.22 58.38h1.589l.756-6.128h-2.346v6.128Z" fill="#FFB8B8" />
      <Path
        d="M55.815 57.862h3.129a1.995 1.995 0 0 1 1.994 1.994v.065h-5.123v-2.06Z"
        fill="#2F2E41"
      />
      <Path d="M61.562 58.38h1.59l.755-6.128h-2.345v6.128Z" fill="#FFB8B8" />
      <Path
        d="M61.157 57.862h3.13a1.995 1.995 0 0 1 1.993 1.994v.065h-5.123v-2.06ZM58.717 36.858l.381 19.443-3.083-.19-2.139-12.385-.676-8.444 5.517 1.576Z"
        fill="#2F2E41"
      />
      <Path
        d="m64.009 33.593.432 23.017-3.596-.103-.777-13.682-1.35-4.954-5.518-2.59.901-6.191 8.106-.113 1.802 4.616Z"
        fill="#2F2E41"
      />
      <Path d="M57.81 12.99a2.757 2.757 0 1 0 0-5.515 2.757 2.757 0 0 0 0 5.515Z" fill="#FFB8B8" />
      <Path
        d="m60.096 14.151.422.64 1.126 3.152-.9 12.272-5.292.113-.675-14.073 1.415-1.77 3.904-.334Z"
        fill="#CCC"
      />
      <Path
        d="m52.638 16.592-.901-.112s-.225.112-.338.9c-.112.789-1.464 7.769-1.464 7.769l1.802 8.556 2.026-2.702-1.238-4.728 1.239-4.729-1.126-4.954ZM64.121 16.48h.676l1.689 9.12-1.577 7.092-1.576-2.59.45-2.814-.225-2.477-.788-1.463 1.351-6.868ZM59.439 10.232l-.502-.125s-.376-2.13-1.253-1.88c-.877.25-3.133.501-3.133-.501 0-1.003 2.13-1.88 3.384-1.755 1.253.125 2.854.541 3.258 2.381.649 2.95-1.338 3.695-1.338 3.695l.033-.107a1.673 1.673 0 0 0-.45-1.708Z"
        fill="#2F2E41"
      />
      <Path
        d="m51.85 16.48 3.602-1.464.845-.62L58.83 25.6l1.182-11.54 4.672 2.42-1.576 7.657-.225 2.927.675 2.364s2.365 1.689 1.577 3.49c-.789 1.802-1.69 1.914-1.69 1.914s-3.827-3.602-4.052-4.503c-.226-.9-.563-2.477-.563-2.477s-1.914 7.206-4.166 7.093c-2.252-.113-2.252-2.477-2.252-2.477l.563-2.477.9-2.59-.45-4.278-1.576-6.642Z"
        fill="#2F2E41"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h84.151v60H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Secure;
