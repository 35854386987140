import {
  PowerAuth,
  PowerAuthActivationStatus,
  PowerAuthActivation,
  PowerAuthAuthentication,
  PowerAuthCreateActivationResult,
  PowerAuthActivationState,
  PowerAuthBiometryInfo,
  PasswordType,
} from 'react-native-powerauth-mobile-sdk';

import { Platform } from 'react-native';
import {
  PA_APPLICATION_INSTANCEID,
  PA_APPLICATION_KEY,
  PA_APPLICATION_SECRET,
  PA_KEY_SERVER_MASTER_PUBLIC,
  PA_ENROLLMENT_BASE_ENDPOINT_URL,
  PA_ENABLE_UNSECURE_TRAFFIC,
} from '@env';
import { toBase64 } from '../utilities/utils';

const powerAuth = new PowerAuth(PA_APPLICATION_INSTANCEID);

function isPlatformSupported(): boolean {
  return Platform.OS == 'ios' || Platform.OS == 'android';
}

async function setupPowerAuth() {
  console.debug('powerAuthService: setupPowerAuth() invoked');
  // already configured instance will throw an
  // exception when you'll try to configure it again
  const isConfigured = powerAuth.isConfigured();

  if (await isConfigured) {
    console.log('powerAuthService: setupPowerAuth() PowerAuth was already configured');
  } else {
    try {
      await powerAuth.configure(
        PA_APPLICATION_KEY,
        PA_APPLICATION_SECRET,
        PA_KEY_SERVER_MASTER_PUBLIC,
        PA_ENROLLMENT_BASE_ENDPOINT_URL,
        PA_ENABLE_UNSECURE_TRAFFIC == 'true',
      );

      /*const biometryStatus = await powerAuth.getBiometryInfo();
      const hasBiometryFactor = await powerAuth.hasBiometryFactor();
      console.log(biometryStatus);
      console.log(hasBiometryFactor);*/
    } catch (e) {
      console.log(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        'powerAuthService: setupPowerAuth() PowerAuth failed to configure: '.concat(e.code),
      );
    }
  }
}

export type ActivationStatus = {
  hasValidActivation: boolean;
  status?: PowerAuthActivationStatus;
};

async function fetchActivationStatus(): Promise<ActivationStatus> {
  console.debug('powerAuthService: fetchActivationStatus() invoked');

  return new Promise((resolve, reject) => {
    powerAuth
      .hasValidActivation()
      .then((_hasValidActivation) => {
        if (_hasValidActivation) {
          powerAuth
            .fetchActivationStatus()
            .then((_status) => {
              resolve({ hasValidActivation: _hasValidActivation, status: _status });
            })
            .catch(reject);
        } else {
          resolve({ hasValidActivation: _hasValidActivation });
        }
      })
      .catch(reject);
  });
}

async function createActivation(
  deviceName: string,
  activationCode: string,
): Promise<PowerAuthCreateActivationResult> {
  console.debug('powerAuthService: createActivation() invoked');

  return new Promise((resolve, reject) => {
    // Create activation object with given activation code.
    const activation = PowerAuthActivation.createWithActivationCode(activationCode, deviceName);

    powerAuth.createActivation(activation).then(resolve).catch(reject);
  });
}

async function commitActivation(authentication: PowerAuthAuthentication): Promise<void> {
  console.debug('powerAuthService: commitActivation() invoked');

  await powerAuth.commitActivation(authentication);
}

async function removeActivationLocal(): Promise<void> {
  console.debug('powerAuthService: removeActivationLocal() invoked');

  return new Promise((resolve) => {
    powerAuth
      .removeActivationLocal()
      .then(() => {
        //
        resolve();
      })
      .catch(console.error);
  });
}

type PowerAuthAuthorizationData = {
  paHeader: string;
  payload: string;
};

async function authorizationHttpHeader(
  authentication: PowerAuthAuthentication,
  method: string,
  uriId: string,
  body: string,
): Promise<PowerAuthAuthorizationData> {
  console.debug(
    `powerAuthService: authorizationHttpHeader([authentication], ${method}, ${uriId}, ${body}) invoked`,
  );

  const header = await powerAuth.requestSignature(authentication, method, uriId, body);

  return { paHeader: header.value, payload: toBase64(body) };
}

async function hasBiometryFactor(): Promise<boolean> {
  return new Promise((resolve) => {
    powerAuth.hasBiometryFactor().then(resolve).catch(console.error);
  });
}

async function getBiometryInfo(): Promise<PowerAuthBiometryInfo> {
  return new Promise((resolve) => {
    powerAuth.getBiometryInfo().then(resolve).catch(console.error);
  });
}

async function addBiometryFactor(
  password: PasswordType,
  title: string,
  description: string,
): Promise<void> {
  return await powerAuth.addBiometryFactor(password, title, description);
}

async function removeBiometryFactor(): Promise<void> {
  return await powerAuth.removeBiometryFactor();
}

async function validatePassword(password: PasswordType): Promise<void> {
  return await powerAuth.validatePassword(password);
}

async function changePassword(oldPassword: PasswordType, newPassword: PasswordType): Promise<void> {
  const result = await powerAuth.unsafeChangePassword(oldPassword, newPassword);
  if (!result) throw Error('Error changing the password');
}

async function getActivationId(): Promise<string | undefined> {
  return await powerAuth.getActivationIdentifier();
}

export const powerAuthService = {
  isPlatformSupported,
  setupPowerAuth,
  fetchActivationStatus,
  createActivation,
  commitActivation,
  removeActivationLocal,
  authorizationHttpHeader,
  hasBiometryFactor,
  getBiometryInfo,
  addBiometryFactor,
  removeBiometryFactor,
  validatePassword,
  changePassword,
  getActivationId,
};

export { PowerAuthActivationState, PowerAuthCreateActivationResult, PowerAuthAuthorizationData };
