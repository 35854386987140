import { Platform } from 'react-native';
import * as Application from 'expo-application';
import { ENV_NAME, PA_ENROLLMENT_BASE_ENDPOINT_URL } from '@env';
import manifest from '../../app.json';
import expo_package from '../../node_modules/expo/package.json';
import react_native_package from '../../node_modules/react-native/package.json';
import react_native_web_package from '../../node_modules/react-native-web/package.json';
import react_package from '../../node_modules/react/package.json';
import { APOLLO_GRAPHQL_URL_DROID_LOCALHOST_FIX, getLongAppVersion } from './utils';

export interface AppInfo {
  key: string;
  data: string;
}

export const appInfoData: AppInfo[] = [
  {
    key: 'appName',
    data:
      Platform.select({
        native: Application.applicationName,
        web: manifest.expo.name,
        default: 'null',
      }) || 'null',
  },
  {
    key: 'appPlatform',
    data: Platform.OS || 'null',
  },
  {
    key: 'appId',
    data:
      Platform.select({
        native: Application.applicationId,
        web: manifest.expo.web.applicationId,
        default: 'null',
      }) || 'null',
  },
  {
    key: 'appVersion',
    data:
      Platform.select({
        native: Application.nativeApplicationVersion,
        web: manifest.expo.web.version,
        default: 'null',
      }) || 'null',
  },
  {
    key: 'appBuild',
    data:
      Platform.select({
        native: Application.nativeBuildVersion,
        web: manifest.expo.web.build,
        default: 'null',
      }) || 'null',
  },
  {
    key: 'appVersionLong',
    data: getLongAppVersion(),
  },
  {
    key: 'ENV_NAME',
    data: ENV_NAME,
  },
  {
    key: 'APOLLO_GRAPHQL_URL',
    data: APOLLO_GRAPHQL_URL_DROID_LOCALHOST_FIX(),
  },
  {
    key: 'PA_ENROLLMENT_BASE_ENDPOINT_URL',
    data: PA_ENROLLMENT_BASE_ENDPOINT_URL,
  },
  {
    key: 'Expo SDK version',
    data: expo_package.version,
  },
  {
    key: 'React Native version',
    data: react_native_package.version,
  },
  {
    key: 'React Native for Web version',
    data: react_native_web_package.version,
  },
  {
    key: 'React version',
    data: react_package.version,
  },
];
