import { globals } from '../utilities/globals';
import NetInfo from '@react-native-community/netinfo';
import { graphQLService } from './graphQLService';
import { SystemStateQuery, UpdateType } from '../generated/graphql';

export type SystemState = {
  systemStateStatus: SystemStateStatus;
  updateType: UpdateType;
  updateMessage?: string;
  updateAppUrl?: string;
  error?: Error;
};

export enum SystemStateStatus {
  Normal = 'normal',
  Error = 'error',
}

export enum ErrorType {
  noInternetConnection,
  appUpdateRequired,
  isOutage,
  general,
}

export type Error = {
  type: ErrorType;
  errorMessage?: string;
};

async function checkSystemState(): Promise<SystemState> {
  console.debug('systemStateService: checkSystemStateStatus() invoked');
  // dev delay
  await devDelay(2000);
  const isConnected = await checkInternetConnection();
  return await checkSystemStateResponse(isConnected);
}

async function checkInternetConnection(): Promise<boolean> {
  console.debug('systemStateService: checkInternetConnection() invoked');
  const state = await NetInfo.fetch();
  return state.isConnected === true;
}

async function checkSystemStateResponse(isConnected: boolean): Promise<SystemState> {
  console.debug('systemStateService: checkSystemStateResponse() invoked');
  try {
    const response = await getSystemStateResponse();
    const systemState = response.SystemState;
    if (!systemState) {
      return prepareErrorResult(ErrorType.general);
    }
    if (systemState.updateType === UpdateType.Force)
      return prepareErrorResult(
        ErrorType.appUpdateRequired,
        systemState.errorMessage ?? undefined,
        systemState.updateAppUrl ?? undefined,
        systemState.updateMessage ?? undefined,
      );
    if (!systemState.backendIsOnline)
      return prepareErrorResult(ErrorType.general, systemState.errorMessage ?? undefined);
    return prepareNormalResult(
      systemState.updateType,
      systemState.updateAppUrl ?? undefined,
      systemState.updateMessage ?? undefined,
    );
  } catch (e) {
    console.debug(e);
    if (!isConnected) return prepareErrorResult(ErrorType.noInternetConnection);
    return prepareErrorResult(ErrorType.general);
  }
}

function getSystemStateResponse(): Promise<SystemStateQuery> {
  const debugsSystemStateSettings = globals.debug.systemState;
  const responseError = debugsSystemStateSettings.resultError;
  const useDebug = debugsSystemStateSettings.useDebug;
  if (responseError || useDebug) {
    if (responseError) return Promise.reject('Could not get system state service result');
    return Promise.resolve({ SystemState: debugsSystemStateSettings.systemStateResponse });
  }
  return graphQLService.querySystemState();
}

function prepareErrorResult(
  errorType: ErrorType,
  errorMessage?: string,
  appUpdateUrl?: string,
  appUpdateMessage?: string,
): SystemState {
  const errorResult: SystemState = {
    systemStateStatus: SystemStateStatus.Error,
    updateType: UpdateType.None,
    updateAppUrl: appUpdateUrl,
    updateMessage: appUpdateMessage,
    error: { type: errorType, errorMessage: errorMessage },
  };
  console.debug(`SystemStateErrorResult: ${JSON.stringify(errorResult)}`);
  return errorResult;
}

function prepareNormalResult(
  updateType: UpdateType,
  updateAppUrl?: string,
  updateMessage?: string,
): SystemState {
  return {
    systemStateStatus: SystemStateStatus.Normal,
    updateType: updateType,
    updateAppUrl: updateAppUrl,
    updateMessage: updateMessage,
  };
}

function devDelay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const systemStateService = {
  checkSystemState: checkSystemState,
};
