import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../types';
import { useTranslation } from 'react-i18next';

// screens
import MobileLoginScreen from '../../screens/MobileLogin/MobileLoginScreen';

const Stack = createNativeStackNavigator<RootStackParamList>();

function MobileLoginStack() {
  const { t } = useTranslation('resources');

  return (
    <Stack.Navigator initialRouteName="MobileLogin" screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="MobileLogin"
        component={MobileLoginScreen}
        options={{
          title: t('login.title'),
        }}
      />
    </Stack.Navigator>
  );
}

export default MobileLoginStack;
