import React, { useCallback, useEffect } from 'react';
import { Button, Text } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../types';
import { useTheme } from '../../../../utilities/reactUtils';
import { useTranslation } from 'react-i18next';
import PaymentSummaryTop from '../../components/shared/PaymentSummaryTop';
import { usePaymentDomestic } from '../../context/PaymentDomesticContext';
import PaymentSummarySections from '../../components/domestic/PaymentDomesticSummarySections';
import { formatAccountNumber, formatAmount, formatDate } from '../../../../utilities/formatUtils';
import PaymentModalScreen from '../../components/shared/PaymentModalScreen';
import { colors, styles } from '../../../../assets';
import Toast, { ToastVariant } from '../../../../components/Toast';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentDomesticSummary'>;

const PaymentDomesticSummaryScreen = () => {
  const { t } = useTranslation();
  const { fonts } = useTheme();
  const navigation = useNavigation<NavProp>();

  const {
    paymentData,
    balanceInfoAmount,
    recipientName,
    recipientAccount,
    loading,
    navigateToVictory,
    showError,
    resetError,
    sendPayment,
  } = usePaymentDomestic();

  const { amount, dueDate } = paymentData;

  const onClose = useCallback(() => {
    resetError();
    navigation.navigate('Payment');
  }, [navigation, resetError]);

  const onBack = useCallback(() => {
    if (!loading) {
      resetError();
      navigation.goBack();
    }
  }, [loading, navigation, resetError]);

  const onContinue = useCallback(() => {
    resetError();
    if (!loading) {
      sendPayment();
    }
  }, [loading, resetError, sendPayment]);

  useEffect(() => {
    if (navigateToVictory) {
      navigation.navigate('PaymentDomesticAuthorization');
    }
  }, [navigateToVictory, navigation]);

  return (
    <PaymentModalScreen
      headerProps={{ onBackPress: onBack, onClosePress: onClose }}
      content={
        <>
          <PaymentSummaryTop
            avatarData={{ text: 'TV' }}
            headlineText={formatAmount(amount, 'Kč', true)}
            subTextLeft={recipientName ?? formatAccountNumber(recipientAccount)}
            subTextRight={dueDate ? formatDate(dueDate) : t('payment.domesticSummary.dueDateNow')}
          />
          <PaymentSummarySections />
          {balanceInfoAmount && (
            <Text variant={'bodyMedium'} style={{ marginTop: 8, padding: 16, alignSelf: 'center' }}>
              {t('payment.domesticSummary.remainingBalanceText', {
                balance: formatAmount(balanceInfoAmount, 'Kč', true),
              })}
            </Text>
          )}
        </>
      }
      footerContent={
        <Button
          onPress={onContinue}
          disabled={false}
          loading={false}
          mode="contained"
          theme={{
            roundness: 8,
            colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
          }}
          style={styles.formActionButton}
          labelStyle={fonts.labelSmall}
          contentStyle={{ width: '100%', height: '100%' }}
        >
          {t('payment.domesticSummary.buttonContinue', {
            amount: formatAmount(amount, 'Kč', true),
          })}
        </Button>
      }
      otherContent={
        showError && (
          <Toast
            text={t('error.unknown')}
            variant={ToastVariant.Error}
            style={{
              position: 'absolute',
              marginTop: 24,
              margin: 16,
              start: 0,
              end: 0,
              zIndex: 2,
            }}
          />
        )
      }
    />
  );
};

export default PaymentDomesticSummaryScreen;
