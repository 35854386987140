import React from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  StatusBar,
  useWindowDimensions,
  ViewProps,
} from 'react-native';
import { useHeaderHeight } from '@react-navigation/elements';

function KeyboardAware({
  children,
  style,
  viewHeight,
  adjustForModal = false,
}: ViewProps & { viewHeight?: number; adjustForModal?: boolean }) {
  const statusBarHeight = StatusBar.currentHeight ?? 0;
  const headerHeight = useHeaderHeight();
  const windowDimensions = useWindowDimensions();

  const isIpad = Platform.OS === 'ios' && Platform.isPad;
  // TODO: find less way for ipad
  const heightAdjust = isIpad && adjustForModal ? 40 : 0;

  const keyboardVerticalOffset =
    viewHeight && !isIpad
      ? windowDimensions.height - viewHeight
      : statusBarHeight + headerHeight + heightAdjust;

  return (
    <KeyboardAvoidingView
      style={style}
      behavior={Platform.select({ android: undefined, default: 'padding' })}
      keyboardVerticalOffset={keyboardVerticalOffset}
    >
      {children}
    </KeyboardAvoidingView>
  );
}

export default KeyboardAware;
