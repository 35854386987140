import React, { useMemo } from 'react';
import { List, Surface, Text } from 'react-native-paper';
import { baseTheme, colors } from '../../../../assets';
import { View } from 'react-native';
import { useTheme } from '../../../../utilities/reactUtils';
import { formatAmount, initials, splitAmount } from '../../../../utilities/formatUtils';
import { MovementFragment } from '../../../../generated/graphql';
import { amountSign } from '../../../../utilities/utils';
import CommonAvatar from '../../../../components/CommonAvatar';

interface MovementListItemProps {
  item: MovementFragment;
  accountCurrency: string;
  isFirst: boolean;
  isLast: boolean;
  onItemClick: (movement: MovementFragment) => void;
  testID: string;
}

export function MovementListItem({
  item,
  accountCurrency,
  isFirst,
  isLast,
  onItemClick,
  testID,
}: MovementListItemProps) {
  const { fonts } = useTheme();

  const topRadius = isFirst ? 8 : 0;
  const bottomRadius = isLast ? 8 : 0;

  return (
    <Surface
      style={{
        marginLeft: 0,
        backgroundColor: baseTheme.colors.background,
        borderTopStartRadius: topRadius,
        borderTopEndRadius: topRadius,
        borderBottomStartRadius: bottomRadius,
        borderBottomEndRadius: bottomRadius,
      }}
      elevation={0}
    >
      <List.Item
        title={item.title}
        titleStyle={fonts.bodyMedium}
        description={item.subtitle}
        descriptionStyle={{ ...fonts.bodySmall, opacity: 0.6 }}
        onPress={() => onItemClick(item)}
        testID={testID}
        left={() => (
          <CommonAvatar
            avatar={item.avatar}
            text={initials(item.title)}
            style={{ marginLeft: 16 }}
          />
        )}
        right={() =>
          item.amount && (
            <Amounts
              amount={amountSign(item.amount, item.direction)}
              currency={item.currencyCode}
              accountCurrency={accountCurrency}
              originalAmount={amountSign(item.originalAmount, item.direction)}
              originalCurrency={item.originalCurrencyCode}
              testID={testID}
            />
          )
        }
      />
    </Surface>
  );
}

function Amounts({
  amount = 0,
  currency,
  accountCurrency,
  originalAmount,
  originalCurrency,
  testID,
}: {
  amount?: number;
  currency?: string | null;
  accountCurrency: string;
  originalAmount?: number | null;
  originalCurrency?: string | null;
  testID: string;
}) {
  const { whole, fraction } = useMemo(
    () => splitAmount(amount, currency || accountCurrency),
    [amount, currency, accountCurrency],
  );

  const color = amount > 0 ? colors.ui_color_positive : baseTheme.colors.text;

  return (
    <View
      style={{
        flexDirection: 'column',
        alignSelf: 'center',
        alignItems: 'flex-end',
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        <Text
          variant="bodyMedium"
          style={{ lineHeight: 18, fontWeight: '600', color: color }}
          testID={`${testID}_whole_part_text`}
        >
          {whole}
        </Text>
        <Text
          variant="bodySmall"
          style={{ lineHeight: 19, fontWeight: '600', color: color }}
          testID={`${testID}_fraction_part_text`}
        >
          {fraction}
        </Text>
      </View>
      {originalAmount && originalCurrency != currency && (
        <Text
          variant="bodySmall"
          style={{ opacity: 0.6 }}
          testID={`${testID}_original_amount_text`}
        >
          {formatAmount(originalAmount, originalCurrency, true)}
        </Text>
      )}
    </View>
  );
}
