import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Keyboard, StyleSheet, TextInput as RNTextInput, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { HelperText, TextInput } from 'react-native-paper';
import { useTheme } from '../../../../../utilities/reactUtils';
import { usePaymentDomestic } from '../../../context/PaymentDomesticContext';
import { formatAmount, formatDate } from '../../../../../utilities/formatUtils';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../../types';
import { DateTime } from 'luxon';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentDomestic'>;
const PaymentDomesticInputs = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation<NavProp>();

  const { paymentData, setPaymentData, balanceInfoAmount } = usePaymentDomestic();

  const ref_input_amount = useRef<RNTextInput>(null);
  const ref_input_message = useRef<RNTextInput>(null);
  const ref_input_symbol_variable = useRef<RNTextInput>(null);
  const ref_input_due_date = useRef<RNTextInput>(null);
  const ref_input_note = useRef<RNTextInput>(null);
  const ref_input_symbol_constant = useRef<RNTextInput>(null);
  const ref_input_symbol_specific = useRef<RNTextInput>(null);

  const [amount, setAmount] = useState(
    paymentData.amount === 0 ? '' : paymentData.amount.toString(),
  );
  const [message, setMessage] = useState(paymentData.message ?? '');
  const [symbolVariable, setSymbolVariable] = useState(
    paymentData.symbolVariable?.toString() ?? '',
  );
  const [dueDate, setDueDate] = useState(paymentData.dueDate);
  const [dueDateInput, setDueDateInput] = useState('');
  const [note, setNote] = useState(paymentData.note ?? '');
  const [symbolConstant, setSymbolConstant] = useState(
    paymentData.symbolConstant?.toString() ?? '',
  );
  const [symbolSpecific, setSymbolSpecific] = useState(
    paymentData.symbolSpecific?.toString() ?? '',
  );

  useEffect(() => {
    setPaymentData({
      amount: amount.length === 0 ? 0 : parseFloat(amount.replace(',', '.')),
      message: message.length === 0 ? undefined : message,
      symbolVariable: symbolVariable.length === 0 ? undefined : symbolVariable,
      dueDate: dueDate,
      note: note.length === 0 ? undefined : note,
      symbolConstant: symbolConstant.length === 0 ? undefined : symbolConstant,
      symbolSpecific: symbolSpecific.length === 0 ? undefined : symbolSpecific,
    });
  }, [
    amount,
    message,
    symbolVariable,
    dueDate,
    symbolSpecific,
    symbolConstant,
    note,
    setPaymentData,
  ]);

  useEffect(() => {
    setDueDate(paymentData.dueDate);
  }, [paymentData]);

  useEffect(() => {
    if (dueDate) {
      setDueDateInput(formatDate(dueDate));
    } else {
      setDueDateInput('');
    }
  }, [dueDate]);

  const handleAmount = useCallback((value: string) => {
    if (value.length === 0) {
      setAmount('');
      return;
    }
    if (value.match(/^\d{1,8}([.,]\d{0,2})?$/)) {
      const resValue = value.replace('.', ',');
      setAmount(resValue);
    }
  }, []);

  const handleDueDate = useCallback((value: string) => {
    const filtered = value.replace(/\s/g, '');
    if (filtered.length === 0) {
      setDueDate(undefined);
    }
    if (filtered.match(/^[\d.]{0,10}$/)) {
      setDueDateInput(filtered);
      const date = DateTime.fromFormat(filtered, 'd.M.yyyy');
      if (date.isValid) {
        setDueDate(date);
      }
    }
  }, []);

  const handleSymbolVariable = useCallback((value: string) => {
    const filtered = value.replace(/\D/g, '');
    setSymbolVariable(filtered);
  }, []);

  const handleSymbolConstant = useCallback((value: string) => {
    const filtered = value.replace(/\D/g, '');
    setSymbolConstant(filtered);
  }, []);

  const handleSymbolSpecific = useCallback((value: string) => {
    const filtered = value.replace(/\D/g, '');
    setSymbolSpecific(filtered);
  }, []);

  const onCalendarPressed = () => {
    Keyboard.dismiss();
    navigation.navigate('PaymentDomesticDate');
  };

  return (
    <View style={styles.container}>
      <TextInput
        ref={ref_input_amount}
        label={t('payment.domesticForm.amountLabel')}
        placeholder={t('payment.domesticForm.amountPlaceholder')}
        activeUnderlineColor={colors.secondary}
        onChangeText={handleAmount}
        value={amount}
        textContentType="none"
        keyboardType="numeric"
        right={<TextInput.Affix text={'Kč'} />}
        maxLength={12}
      />

      {amount && (
        <HelperText type={'info'}>
          {t('payment.domesticForm.remainingBalanceText', {
            balance: formatAmount(balanceInfoAmount, 'Kč', true),
          })}
        </HelperText>
      )}

      <TextInput
        ref={ref_input_message}
        label={t('payment.domesticForm.messageLabel')}
        activeUnderlineColor={colors.secondary}
        onChangeText={setMessage}
        value={message}
        maxLength={140}
        autoFocus={false}
        autoCorrect={false}
        multiline={true}
        style={styles.inputs}
      />

      <TextInput
        ref={ref_input_symbol_variable}
        label={t('payment.domesticForm.symbolVariableLabel')}
        activeUnderlineColor={colors.secondary}
        onChangeText={handleSymbolVariable}
        value={symbolVariable}
        textContentType="none"
        keyboardType="numeric"
        maxLength={10}
        autoFocus={false}
        style={styles.inputs}
      />

      <TextInput
        ref={ref_input_due_date}
        label={t('payment.domesticForm.dueDateLabel')}
        activeUnderlineColor={colors.secondary}
        onChangeText={handleDueDate}
        value={dueDateInput}
        maxLength={12}
        autoFocus={false}
        autoCorrect={false}
        left={
          dueDateInput.length === 0 && (
            <TextInput.Affix text={t('payment.domesticForm.dueDateNow')} />
          )
        }
        right={<TextInput.Icon icon={'calendar'} onPress={onCalendarPressed} />}
        style={styles.inputs}
      />

      <TextInput
        ref={ref_input_note}
        label={t('payment.domesticForm.noteLabel')}
        activeUnderlineColor={colors.secondary}
        onChangeText={setNote}
        value={note}
        maxLength={70}
        autoFocus={false}
        autoCorrect={false}
        multiline={true}
        style={styles.inputs}
      />

      <TextInput
        ref={ref_input_symbol_constant}
        label={t('payment.domesticForm.symbolConstantLabel')}
        activeUnderlineColor={colors.secondary}
        onChangeText={handleSymbolConstant}
        value={symbolConstant}
        textContentType="none"
        keyboardType="numeric"
        maxLength={10}
        autoFocus={false}
        style={styles.inputs}
      />

      <TextInput
        ref={ref_input_symbol_specific}
        label={t('payment.domesticForm.symbolSpecificLabel')}
        activeUnderlineColor={colors.secondary}
        onChangeText={handleSymbolSpecific}
        value={symbolSpecific}
        textContentType="none"
        keyboardType="numeric"
        maxLength={10}
        autoFocus={false}
        style={styles.inputs}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginHorizontal: 16, marginTop: 16 },
  inputs: { marginTop: 16 },
});

export default PaymentDomesticInputs;
