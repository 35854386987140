import React, { useEffect, useState } from 'react';
import SafeAreaViewFixed from '../../components/SafeAreaViewFixed';
import { Image, Linking, Platform, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import FadeInView from 'react-native-fade-in-view';
import { colors as assetColors, styles } from '../../assets';
import { useTheme } from '../../utilities/reactUtils';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../types';
import { useNavigation } from '@react-navigation/native';
import LogoHorizontal from '../../assets/images/logoHorizontal/logo.png';
import { globals } from '../../utilities/globals';
import { notImplementedAlert, prepareTestID } from '../../utilities/utils';
import { languageService } from '../../services/languageService';
import { asyncStorageService } from '../../services/asyncStorageService';
import { Language } from '../../types/Language';
import { useGlobalContext } from '../../contexts/GlobalContext';
import Toast, { ToastVariant } from '../../components/Toast';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AppVersionInfo from '../../components/AppVersionInfo';

type ActivationProp = StackNavigationProp<RootStackParamList, 'MobileActivationIntro'>;

function MobileActivationIntroScreen() {
  const { t } = useTranslation('resources');
  const navigation = useNavigation<ActivationProp>();
  const { updateInfoData } = useGlobalContext();
  const globalContext = useGlobalContext();
  const { colors, fonts } = useTheme();

  const animationDuration = 700;

  const [language, setLanguage] = useState(languageService.getI18Language());
  const [showUpdate, setShowUpdate] = useState(false);

  useEffect(() => {
    void asyncStorageService.clearCaches();
  }, []);

  useEffect(() => {
    const show = updateInfoData.showUpdate;
    setShowUpdate(show);
  }, [updateInfoData.showUpdate]);

  const onContinue = () => {
    navigation.navigate('MobileActivationUser');
  };

  function OptionalUpdateView() {
    if (!showUpdate) return null;

    return (
      <Toast
        variant={ToastVariant.Info}
        style={{
          position: 'absolute',
          marginTop: 48, //TODO
          margin: 16,
          zIndex: 2,
        }}
        text={updateInfoData.updateMessage ?? t('common.optionalUpdateInfo')}
        buttonText={t(
          Platform.OS === 'ios'
            ? 'common.optionalUpdateButtonIOS'
            : 'common.optionalUpdateButtonAndroid',
        )}
        onButtonPress={() => {
          globalContext.hideUpdateInfo();
          if (updateInfoData?.updateAppUrl != null) {
            Linking.openURL(updateInfoData.updateAppUrl).catch(console.warn);
          }
        }}
        onAnimationFinished={() => {
          globalContext.hideUpdateInfo();
        }}
      />
    );
  }

  const languageTitle =
    language === Language.EN ? t('common.appLanguage.czech') : t('common.appLanguage.english');

  return (
    <SafeAreaViewFixed style={styles.screenBase}>
      <OptionalUpdateView />
      <AppVersionInfo />

      <View style={[styles.containerFlex, { justifyContent: 'space-between' }]}>
        <View
          style={{
            height: '30%',
            width: '100%',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
          }}
        >
          <FadeInView duration={animationDuration} style={styles.topRight}>
            <Button
              onPress={() => {
                const newLanguage = language === Language.EN ? Language.CS : Language.EN;
                languageService
                  .changeLanguage(newLanguage)
                  .then(() => setLanguage(newLanguage))
                  .catch(console.error);
              }}
              textColor={colors.secondary}
              labelStyle={fonts.labelMedium}
              mode="text"
              testID={prepareTestID('activation_intro_change_language_button')}
            >
              {languageTitle}
            </Button>
          </FadeInView>
        </View>

        <View
          style={{
            height: '35%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Image source={LogoHorizontal} style={styles.logoHorizontal} />
          <View style={styles.mainButtonContainer}>
            <Button
              onPress={onContinue}
              mode="contained"
              theme={{
                roundness: 8,
                colors: { primary: assetColors.ui_turq_prim, onPrimary: assetColors.ui_black },
              }}
              style={{ marginTop: 16, ...styles.formActionButton }}
              contentStyle={{ width: '100%', height: '100%' }}
              labelStyle={fonts.labelMedium}
              testID={prepareTestID('button_continue')}
            >
              {t('mobileActivation.signIn')}
            </Button>
          </View>
        </View>

        <FadeInView
          duration={animationDuration}
          style={{
            height: '35%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Text
            variant="bodyMedium"
            style={{ textAlign: 'center', lineHeight: 20 }}
            testID={prepareTestID('activation_intro_onboarding_text')}
          >
            {t('mobileActivation.onboardingMessage')}
          </Text>
          <View style={styles.mainButtonContainer}>
            <Button
              onPress={notImplementedAlert}
              mode="contained"
              theme={{
                roundness: 8,
                colors: {
                  primary: assetColors.ui_color_secondary,
                  onPrimary: assetColors.ui_white,
                },
              }}
              style={{ marginTop: 16, ...styles.formActionButton }}
              contentStyle={{ width: '100%', height: '100%' }}
              labelStyle={fonts.labelMedium}
              testID={prepareTestID('activation_intro_onboarding_button')}
            >
              {t('mobileActivation.onboarding')}
            </Button>
          </View>
          <Button
            onPress={notImplementedAlert}
            mode="text"
            textColor={colors.secondary}
            icon={() => <MaterialCommunityIcons name="phone-outline" size={20} />}
            labelStyle={fonts.labelMedium}
            testID={prepareTestID('activation_intro_call_cc_button')}
          >
            {globals.constants.customerCenterPhone}
          </Button>
        </FadeInView>
      </View>
    </SafeAreaViewFixed>
  );
}

export default MobileActivationIntroScreen;
