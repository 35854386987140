import React, { useCallback, useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import { globals } from '../utilities/globals';
import { LogoutReason, useGlobalContext } from './GlobalContext';
import { DateTime, Duration } from 'luxon';

interface Props {
  children?: React.ReactNode;
}

// REFACTOR: Better storage? useState doesn't work, AsyncStorage seems like overkill
let backgroundStartTime: DateTime | undefined = undefined;

const BackgroundLogoutProvider = ({ children }: Props) => {
  const globalContext = useGlobalContext();

  const handleAppState = useCallback(
    (appState: AppStateStatus) => {
      if (appState === 'inactive' || appState === 'background') {
        backgroundStartTime = DateTime.now();
      } else if (appState === 'active') {
        if (backgroundStartTime) {
          const backgroundTime = backgroundStartTime.diffNow().negate();
          if (backgroundTime > Duration.fromObject(globals.logout.automaticLogoutTime)) {
            console.log('Automatic logout');
            globalContext.logout(LogoutReason.AUTOMATIC);
          }
          backgroundStartTime = undefined;
        }
      }
    },
    [globalContext],
  );

  useEffect(() => {
    const subscription = AppState.addEventListener('change', handleAppState);

    return () => {
      subscription.remove();
      backgroundStartTime = undefined;
    };
  }, [handleAppState]);

  return <>{children}</>;
};

export default BackgroundLogoutProvider;
