import React, { useCallback, useMemo } from 'react';
import { LogoutReason, useGlobalContext } from '../../contexts/GlobalContext';
import PasswordScreen from '../Common/PasswordScreen';
import { useTranslation } from 'react-i18next';
import { PowerAuthAuthentication } from 'react-native-powerauth-mobile-sdk';
import AppVersionInfo from '../../components/AppVersionInfo';
import CommonAvatar from '../../components/CommonAvatar';

function MobileLoginScreen() {
  const { userData } = useGlobalContext();
  const globalContext = useGlobalContext();
  const { t } = useTranslation('resources');

  const onAuthorize = useCallback(
    (auth: PowerAuthAuthentication) => globalContext.powerAuthLogin(auth),
    [globalContext],
  );

  const message = useMemo(
    () =>
      userData.logoutReason === LogoutReason.AUTOMATIC ? t('login.automaticLogout') : undefined,
    [t, userData.logoutReason],
  );

  return (
    <>
      <AppVersionInfo />
      <PasswordScreen
        title={t('login.login')}
        message={message}
        image={() => <CommonAvatar avatar="USER" size={68} />}
        enabled={!userData.loginInProgress}
        allowBiometry={true}
        suppressAutoBiometry={userData.logoutReason === LogoutReason.MANUAL}
        onAuthorization={onAuthorize}
      />
    </>
  );
}

export default MobileLoginScreen;
