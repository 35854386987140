import { Account } from '../../../../generated/graphql';
import { BackHandler, Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  formatAccountNumber,
  formatAmount,
  formatDate,
  splitAmount,
} from '../../../../utilities/formatUtils';
import { Button, Chip, IconButton, Searchbar, Text } from 'react-native-paper';
import { NavigationProp, ParamListBase, useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Header, HeaderBackground } from '@react-navigation/elements';
import HeaderLeft from '../../../../components/HeaderLeft';
import { baseTheme, colors, styles as stylesImported } from '../../../../assets';
import { prepareTestID } from '../../../../utilities/utils';
import { FilterRange, MovementFilter } from '../../../../services/movementService';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../utilities/reactUtils';
import { FlatList, ScrollView } from 'react-native-gesture-handler';
import BottomSheet from '../../../../components/BottomSheet';
import { DateTime } from 'luxon';
import AmountInput from '../../../../components/AmountInput';
import DateInput from '../../../../components/DateInput';

function AccountDetailHeader({
  account,
  navigation,
  onReload,
  onShowAccountInfo,
  onSearch,
}: {
  account: Account;
  navigation: NavigationProp<ParamListBase>;
  onReload?: () => void;
  onShowAccountInfo?: () => void;
  onSearch?: () => void;
}) {
  const insets = useSafeAreaInsets();

  return (
    <View>
      <HeaderBackground style={StyleSheet.absoluteFill} />
      <Header
        headerTransparent={true}
        headerTitleAlign="left"
        headerLeft={() => (
          <HeaderLeft
            onPress={() => {
              navigation.goBack();
            }}
          />
        )}
        headerLeftContainerStyle={{ paddingLeft: Platform.OS === 'web' ? 0 : 16 }}
        headerRightContainerStyle={{ paddingRight: 16 }}
        title={account.name}
        headerTitle={() => (
          <View style={{ paddingVertical: 16 }}>
            <Text variant="titleMedium" style={{ fontWeight: '700' }}>
              {account.name}
            </Text>
            <Text variant="bodyMedium" style={{ opacity: 0.6 }}>
              {formatAccountNumber(account.number)}
            </Text>
          </View>
        )}
        headerRight={() => (
          <View style={{ flexDirection: 'row' }}>
            {Platform.OS === 'web' && (
              <IconButton
                icon="refresh"
                containerColor={baseTheme.colors.background}
                size={24}
                onPress={onReload}
                testID={prepareTestID('account_detail_refresh_button')}
              />
            )}
            <IconButton
              icon="dots-horizontal"
              containerColor={baseTheme.colors.background}
              size={24}
              onPress={onShowAccountInfo}
              testID={prepareTestID('account_detail_show_info_button')}
            />
            <IconButton
              icon="magnify"
              containerColor={baseTheme.colors.background}
              size={24}
              onPress={onSearch}
              testID={prepareTestID('account_detail_search_button')}
            />
          </View>
        )}
        headerStatusBarHeight={insets.top}
      />
      <AccountBalanceHeader account={account} style={{ marginLeft: 56 }} />
    </View>
  );
}

function AccountBalanceHeader({
  account,
  style,
}: {
  account: Account;
  style?: StyleProp<ViewStyle>;
}) {
  const { whole, fraction } = useMemo(
    () => splitAmount(account.availableBalance, account.currencyCode),
    [account],
  );
  return (
    <View
      style={[
        style,
        {
          marginBottom: 4,
          marginTop: Platform.select({ ios: 12, default: 0 }),
          flexDirection: 'row',
          alignItems: 'baseline',
        },
      ]}
    >
      <Text variant="headlineMedium" style={{ fontWeight: '700' }}>
        {whole}
      </Text>
      <Text variant="titleMedium" style={{ fontWeight: '700', lineHeight: 22 }}>
        {fraction}
      </Text>
    </View>
  );
}

export const MovementSearchHeader = ({
  fullText,
  onFullText,
  filter,
  currency,
  onFilter,
  onClose,
}: {
  fullText: string;
  onFullText: (fullText: string) => void;
  filter: MovementFilter;
  currency: string;
  onFilter: (filter: MovementFilter) => void;
  onClose: () => void;
}) => {
  const insets = useSafeAreaInsets();
  const { t } = useTranslation();
  const { fonts, colors } = useTheme();

  const chips = useMemo(() => mapChips(filter, currency), [filter, currency]);
  const [showMore, setShowMore] = useState(false);

  const ref_chip_list = useRef<FlatList<ChipInfo>>(null);

  useFocusEffect(
    useCallback(() => {
      const handler = () => {
        onClose();
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', handler);
      return () => BackHandler.removeEventListener('hardwareBackPress', handler);
    }, [onClose]),
  );

  return (
    <View>
      <HeaderBackground style={StyleSheet.absoluteFill} />
      <View
        style={{
          paddingVertical: 16,
          marginTop: insets.top,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 16,
            marginBottom: 16,
          }}
        >
          <View style={{ flex: 1, marginRight: 4 }}>
            <Searchbar
              placeholder={t('accountDetail.search')}
              value={fullText}
              onChangeText={onFullText}
              elevation={0}
              theme={{ roundness: 8 }}
              style={{ backgroundColor: colors.secondaryContainer }}
              inputStyle={{ paddingLeft: 0 }}
              testID={prepareTestID('account_detail_full_text_search')}
            />
          </View>
          <Button
            onPress={onClose}
            mode="text"
            labelStyle={fonts.labelMedium}
            textColor={colors.secondary}
            testID={prepareTestID('account_detail_search_cancel')}
          >
            {t('common.cancel')}
          </Button>
        </View>
        <FlatList
          ref={ref_chip_list}
          data={chips}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingHorizontal: 16 }}
          testID={prepareTestID('account_detail_filter_chips')}
          renderItem={({ item }) => (
            <FilterChip
              item={item}
              onPress={() => {
                onFilter(mapChipClick(filter, item));
                ref_chip_list.current && ref_chip_list.current.scrollToIndex({ index: 0 });
              }}
            />
          )}
          ListFooterComponent={() => (
            <Button
              mode="text"
              compact
              labelStyle={fonts.labelSmall}
              textColor={colors.secondary}
              onPress={() => setShowMore(true)}
              testID={prepareTestID('account_detail_filter_more_button')}
            >
              {t('accountDetail.moreParameters')}
            </Button>
          )}
        />
      </View>
      <FilterModal
        visible={showMore}
        filter={filter}
        currency={currency}
        onHide={() => setShowMore(false)}
        onFilter={(filter) => {
          onFilter(filter);
          ref_chip_list.current && ref_chip_list.current.scrollToOffset({ offset: 0 });
        }}
      />
    </View>
  );
};

function FilterChip({ item, onPress }: { item: ChipInfo; onPress: () => void }) {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <Chip
      selected={item.selected}
      showSelectedOverlay
      selectedColor={item.selected ? colors.inverseOnSurface : undefined}
      onPress={onPress}
      style={[
        {
          marginRight: 8,
        },
        item.selected ? { backgroundColor: colors.inverseSurface } : undefined,
      ]}
      testID={prepareTestID(`account_detail_filter_chip_${item.type.toLowerCase()}`)}
    >
      {t('accountDetail.filterChip', {
        context: item.valueFrom === undefined ? item.type : `${item.type}_RANGE`,
        from: item.valueFrom,
        to: item.valueTo,
      })}
    </Chip>
  );
}

const FilterModal = ({
  visible,
  filter: startingFilter,
  currency,
  onHide,
  onFilter: onSave,
}: {
  visible: boolean;
  filter: MovementFilter;
  currency: string;
  onHide: () => void;
  onFilter: (filter: MovementFilter) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [filter, setFilter] = useState<FilterPartial>(startingFilter);
  const chips = useMemo(() => mapMoreChips(filter), [filter]);
  const toSave = useMemo(() => savePartialFilter(filter), [filter]);

  useEffect(() => setFilter(startingFilter), [startingFilter]);

  return (
    <BottomSheet visible={visible} onDismiss={onHide}>
      <ScrollView
        contentContainerStyle={{ paddingVertical: 16 }}
        scrollEnabled={typeof filter.date === 'object' || typeof filter.amount === 'object'}
        testID={prepareTestID('account_detail_filter_more')}
      >
        <Text variant="headlineSmall" style={styles.sectionTitle}>
          {t('accountDetail.moreParameters')}
        </Text>
        <Text variant="titleMedium" style={styles.sectionTitle}>
          {t('accountDetail.typeFilter')}
        </Text>
        <FlatList
          data={chips.type}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingHorizontal: 16 }}
          style={{ marginBottom: 16 }}
          testID={prepareTestID('account_detail_filter_more_type_chips')}
          renderItem={({ item }) => (
            <FilterChip item={item} onPress={() => onTypeChipClick(item, filter, setFilter)} />
          )}
        />
        <Text variant="titleMedium" style={styles.sectionTitle}>
          {t('accountDetail.dateFilter')}
        </Text>
        <FlatList
          data={chips.date}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingHorizontal: 16 }}
          style={{ marginBottom: 16 }}
          testID={prepareTestID('account_detail_filter_more_date_chips')}
          renderItem={({ item }) => (
            <FilterChip item={item} onPress={() => onDateChipClick(item, filter, setFilter)} />
          )}
        />
        <DateFromTo filter={filter} setFilter={setFilter} />
        <Text variant="titleMedium" style={styles.sectionTitle}>
          {t('accountDetail.amountFilter')}
        </Text>
        <FlatList
          data={chips.amount}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingHorizontal: 16 }}
          style={{ marginBottom: 16 }}
          testID={prepareTestID('account_detail_filter_more_amount_chips')}
          renderItem={({ item }) => (
            <FilterChip item={item} onPress={() => onAmountChipClick(item, filter, setFilter)} />
          )}
        />
        <AmountFromTo filter={filter} currency={currency} setFilter={setFilter} />
        <View style={{ marginTop: 24, marginHorizontal: 16 }}>
          <Button
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
            }}
            mode="contained"
            style={{ ...stylesImported.formActionButton, marginBottom: 16 }}
            labelStyle={theme.fonts.labelLarge}
            contentStyle={{ width: '100%', height: '100%' }}
            disabled={!toSave}
            onPress={() => {
              if (toSave) {
                onSave(toSave);
                onHide();
              }
            }}
            testID={prepareTestID('account_detail_filter_more_submit_button')}
          >
            {t('accountDetail.applyFilter')}
          </Button>
          <Button
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
            }}
            mode="contained"
            style={stylesImported.formActionButton}
            labelStyle={theme.fonts.labelLarge}
            contentStyle={{ width: '100%', height: '100%' }}
            onPress={() => {
              onSave(MovementFilter.EMPTY);
              onHide();
            }}
            testID={prepareTestID('account_detail_filter_more_clear_button')}
          >
            {t('accountDetail.cancelFilter')}
          </Button>
        </View>
      </ScrollView>
    </BottomSheet>
  );
};

const DateFromTo = ({
  filter,
  setFilter,
}: {
  filter: FilterPartial;
  setFilter: (value: ((prevState: FilterPartial) => FilterPartial) | FilterPartial) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {typeof filter.date === 'object' && (
        <View style={{ paddingHorizontal: 16 }}>
          <DateInput
            date={filter.date.from}
            label={t('accountDetail.dateFrom')}
            onDate={(date) => {
              if (typeof filter.date === 'object')
                setFilter({ ...filter, date: { ...filter.date, from: date } });
            }}
            autoFocus={true}
            style={styles.input}
            testID={prepareTestID('account_detail_filter_more_date_from')}
          />
          <DateInput
            date={filter.date.to}
            label={t('accountDetail.dateTo')}
            onDate={(date) => {
              if (typeof filter.date === 'object')
                setFilter({ ...filter, date: { ...filter.date, to: date } });
            }}
            style={styles.input}
            testID={prepareTestID('account_detail_filter_more_date_to')}
          />
        </View>
      )}
    </>
  );
};

const AmountFromTo = ({
  filter,
  currency,
  setFilter,
}: {
  filter: FilterPartial;
  currency: string;
  setFilter: (value: ((prevState: FilterPartial) => FilterPartial) | FilterPartial) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {typeof filter.amount === 'object' && (
        <View style={{ paddingHorizontal: 16 }}>
          <AmountInput
            amount={filter.amount.from}
            label={t('accountDetail.amountFrom')}
            currency={currency}
            onAmount={(amount) => {
              if (typeof filter.amount === 'object')
                setFilter({ ...filter, amount: { ...filter.amount, from: amount } });
            }}
            autoFocus={true}
            style={styles.input}
            testID={prepareTestID('account_detail_filter_more_amount_from')}
          />
          <AmountInput
            amount={filter.amount.to}
            label={t('accountDetail.amountTo')}
            currency={currency}
            onAmount={(amount) => {
              if (typeof filter.amount === 'object')
                setFilter({ ...filter, amount: { ...filter.amount, to: amount } });
            }}
            style={styles.input}
            testID={prepareTestID('account_detail_filter_more_amount_to')}
          />
        </View>
      )}
    </>
  );
};

enum ChipType {
  ALL = 'ALL',
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  CARD = 'CARD',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  DATE_FROM_TO = 'DATE_FROM_TO',
  AMOUNT_FROM_TO = 'AMOUNT_FROM_TO',
}

type ChipInfo = {
  type: ChipType;
  selected: boolean;
  valueFrom?: string;
  valueTo?: string;
};

const mapChips = (filter: MovementFilter, currency: string) => {
  const result: ChipInfo[] = [];
  switch (filter.direction) {
    case 'ALL':
      result.push(
        { type: ChipType.INCOMING, selected: false },
        { type: ChipType.OUTGOING, selected: false },
      );
      break;
    case 'INCOMING':
      result.push(
        { type: ChipType.INCOMING, selected: true },
        { type: ChipType.OUTGOING, selected: false },
      );
      break;
    case 'OUTGOING':
      result.push(
        { type: ChipType.INCOMING, selected: false },
        { type: ChipType.OUTGOING, selected: true },
      );
      break;
  }

  result.push({ type: ChipType.CARD, selected: filter.type === 'CARD' });
  result.push({ type: ChipType.THIS_MONTH, selected: filter.date === 'THIS_MONTH' });

  // more parameters
  if (filter.date === 'LAST_MONTH') result.push({ type: ChipType.LAST_MONTH, selected: true });

  if (typeof filter.date === 'object')
    result.push({
      type: ChipType.DATE_FROM_TO,
      selected: true,
      valueFrom: formatDate(filter.date.from),
      valueTo: formatDate(filter.date.to),
    });

  if (typeof filter.amount === 'object')
    result.push({
      type: ChipType.AMOUNT_FROM_TO,
      selected: true,
      valueFrom: formatAmount(filter.amount.from, currency),
      valueTo: formatAmount(filter.amount.to, currency),
    });

  return result.sort((a, b) => Number(b.selected) - Number(a.selected));
};

const mapChipClick = (filter: MovementFilter, chip: ChipInfo) => {
  switch (chip.type) {
    case ChipType.INCOMING:
      return chip.selected ? filter.withDirection('ALL') : filter.withDirection('INCOMING');
    case ChipType.OUTGOING:
      return chip.selected ? filter.withDirection('ALL') : filter.withDirection('OUTGOING');
    case ChipType.CARD:
      return chip.selected ? filter.withType('ALL') : filter.withType('CARD');
    case ChipType.THIS_MONTH:
      return chip.selected ? filter.withDate('ALL') : filter.withDate('THIS_MONTH');
    case ChipType.LAST_MONTH:
      return filter.withDate('ALL');
    case ChipType.DATE_FROM_TO:
      return filter.withDate('ALL');
    case ChipType.AMOUNT_FROM_TO:
      return filter.withAmount('ALL');
    default:
      return filter;
  }
};

type FilterPartial = {
  direction: 'ALL' | 'INCOMING' | 'OUTGOING';
  type: 'ALL' | 'CARD';
  date: 'ALL' | 'THIS_MONTH' | 'LAST_MONTH' | FilterRange<DateTime | undefined>;
  amount: 'ALL' | FilterRange<number | undefined>;
};

const savePartialFilter: (filter: FilterPartial) => null | MovementFilter = (filter) => {
  let date;
  if (typeof filter.date === 'string') date = filter.date;
  else if (filter.date.from && filter.date.to) date = filter.date as FilterRange<DateTime>;
  else return null;

  let amount;
  if (typeof filter.amount === 'string') amount = filter.amount;
  else if (filter.amount.from !== undefined && filter.amount.to !== undefined)
    amount = filter.amount as FilterRange<number>;
  else return null;

  return new MovementFilter(filter.direction, filter.type, date, amount);
};

const mapMoreChips: (filter: FilterPartial) => {
  date: ChipInfo[];
  amount: ChipInfo[];
  type: ChipInfo[];
} = (filter: FilterPartial) => {
  return {
    type: [
      { type: ChipType.ALL, selected: filter.direction === 'ALL' && filter.type === 'ALL' },
      { type: ChipType.INCOMING, selected: filter.direction === 'INCOMING' },
      { type: ChipType.OUTGOING, selected: filter.direction === 'OUTGOING' },
      { type: ChipType.CARD, selected: filter.type === 'CARD' },
    ],
    date: [
      { type: ChipType.ALL, selected: filter.date === 'ALL' },
      { type: ChipType.DATE_FROM_TO, selected: typeof filter.date === 'object' },
      { type: ChipType.THIS_MONTH, selected: filter.date === 'THIS_MONTH' },
      { type: ChipType.LAST_MONTH, selected: filter.date === 'LAST_MONTH' },
    ],
    amount: [
      { type: ChipType.ALL, selected: filter.amount === 'ALL' },
      { type: ChipType.AMOUNT_FROM_TO, selected: typeof filter.amount === 'object' },
    ],
  };
};

function onTypeChipClick(
  item: ChipInfo,
  filter: FilterPartial,
  setFilter: (filter: FilterPartial) => void,
) {
  switch (item.type) {
    case ChipType.ALL:
      setFilter({ ...filter, type: 'ALL', direction: 'ALL' });
      break;
    case ChipType.INCOMING:
      setFilter({ ...filter, direction: 'INCOMING' });
      break;
    case ChipType.OUTGOING:
      setFilter({ ...filter, direction: 'OUTGOING' });
      break;
    case ChipType.CARD:
      setFilter({ ...filter, type: 'CARD' });
      break;
  }
}

function onDateChipClick(
  item: ChipInfo,
  filter: FilterPartial,
  setFilter: (filter: FilterPartial) => void,
) {
  switch (item.type) {
    case ChipType.ALL:
      setFilter({ ...filter, date: 'ALL' });
      break;
    case ChipType.DATE_FROM_TO:
      setFilter({ ...filter, date: { from: undefined, to: undefined } });
      break;
    case ChipType.THIS_MONTH:
      setFilter({ ...filter, date: 'THIS_MONTH' });
      break;
    case ChipType.LAST_MONTH:
      setFilter({ ...filter, date: 'LAST_MONTH' });
      break;
  }
}

function onAmountChipClick(
  item: ChipInfo,
  filter: FilterPartial,
  setFilter: (filter: FilterPartial) => void,
) {
  switch (item.type) {
    case ChipType.ALL:
      setFilter({ ...filter, amount: 'ALL' });
      break;
    case ChipType.AMOUNT_FROM_TO:
      setFilter({ ...filter, amount: { from: undefined, to: undefined } });
      break;
  }
}

const styles = StyleSheet.create({
  input: { marginBottom: 16, backgroundColor: '#F5F5F5' },
  sectionTitle: { fontWeight: '700', marginBottom: 16, marginHorizontal: 16 },
});

export default AccountDetailHeader;
