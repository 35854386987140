import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Text, View } from 'react-native';
import { Button } from 'react-native-paper';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { colors, styles } from '../../assets';
import AppVersionInfo from '../../components/AppVersionInfo';
import { prepareTestID } from '../../utilities/utils';
import { MOCK_USER_ID_1, MOCK_USER_ID_2 } from '@env';

function WebLoginScreen() {
  const { userData } = useGlobalContext();
  const globalContext = useGlobalContext();

  function _onPressButtonIbLoginUser1() {
    void globalContext.ibTempLogin(MOCK_USER_ID_1);
  }

  function _onPressButtonIbLoginUser2() {
    void globalContext.ibTempLogin(MOCK_USER_ID_2);
  }

  function Login() {
    if (userData.userLoggedIn && userData.userId !== undefined) {
      return (
        <>
          <Text style={styles.itemBoldText}>User:{userData.userId} logged in</Text>
          <Text style={{ paddingTop: 16 }}>Opening authenticated zone...</Text>
        </>
      );
    } else {
      return (
        <>
          <Button
            onPress={_onPressButtonIbLoginUser1}
            disabled={userData.loginInProgress}
            mode="contained"
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
            }}
            style={{ ...styles.formActionButton, maxWidth: 350, marginBottom: 8 }}
            contentStyle={{ height: '100%' }}
            testID={prepareTestID('web_login_screen_login1_button')}
          >
            IB Login user: Radek
          </Button>
          <Text>{MOCK_USER_ID_1}</Text>

          <Button
            onPress={_onPressButtonIbLoginUser2}
            disabled={userData.loginInProgress}
            mode="contained"
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
            }}
            style={{ ...styles.formActionButton, maxWidth: 350, marginTop: 40, marginBottom: 8 }}
            contentStyle={{ height: '100%' }}
            testID={prepareTestID('web_login_screen_login2_button')}
          >
            IB Login user: Tomáš
          </Button>
          <Text>{MOCK_USER_ID_2}</Text>
        </>
      );
    }
  }

  return (
    <SafeAreaView style={styles.screenBase}>
      <AppVersionInfo />
      <View style={styles.centered}>
        <Login />
      </View>
    </SafeAreaView>
  );
}

export default WebLoginScreen;
