import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import CommonAvatar, { CommonAvatarProps } from '../../../../../components/CommonAvatar';

export interface PaymentSummaryTopProps {
  avatarData: CommonAvatarProps;
  headlineText: string;
  subTextLeft: string;
  subTextRight: string;
}

const PaymentSummaryTop = ({
  avatarData,
  headlineText,
  subTextLeft,
  subTextRight,
}: PaymentSummaryTopProps) => {
  return (
    <View style={styles.container}>
      <CommonAvatar {...avatarData} size={48} />
      <Text variant={'headlineMedium'} style={styles.headlineText}>
        {headlineText}
      </Text>
      <View style={styles.subTextContainer}>
        <Text variant={'bodySmall'}>{subTextLeft}</Text>
        <Text style={styles.subTextDivider}>•</Text>
        <Text variant={'bodySmall'} style={styles.subTextRight}>
          {subTextRight}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    alignSelf: 'center',
    alignItems: 'center',
  },
  headlineText: { marginTop: 24 },
  subTextContainer: { flexDirection: 'row', marginTop: 8 },
  subTextDivider: { marginHorizontal: 8, opacity: 0.6 },
  subTextRight: { opacity: 0.6 },
});

export default PaymentSummaryTop;
