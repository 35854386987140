/*
 RFR: code below is complex, grab a coffee and read through articles below first

 * https://github.com/LucasGarcez/react-native-auth-flow
 * https://levelup.gitconnected.com/react-native-authentication-flow-the-simplest-and-most-efficient-way-3aa13e80af61
 * https://blog.devgenius.io/react-native-state-management-with-context-api-61f63f5b099
*/

import React, { createContext, useContext, useState } from 'react';

// services
import { SystemState, systemStateService, SystemStateStatus } from '../services/systemStateService';
import {
  ActivationStatus,
  PowerAuthActivationState,
  powerAuthService,
} from '../services/powerAuthService';
import { loginService } from '../services/loginService';
import { graphQLService } from '../services/graphQLService';

// graphql generated types
import { AccountsQuery, UpdateType } from '../generated/graphql';
import {
  PowerAuthAuthentication,
  PowerAuthError,
  PowerAuthErrorCode,
  PasswordType as PAPassword,
} from 'react-native-powerauth-mobile-sdk';
import { tokenStorage } from '../services/tokenStorage';
import PasswordType from '../types/PasswordType';
import { asyncStorageService } from '../services/asyncStorageService';
import { activationService, Device } from '../services/activationsService';

// global data types
type SystemStateData = {
  loading: boolean;
  result?: SystemState;
};

type PowerAuthData = {
  loading: boolean;
  isPlatformSupported: boolean;
  activationStatus?: ActivationStatus;
};

type UserData = {
  loginInProgress: boolean;
  userLoggedIn: boolean;
  userId?: string;
  userAccounts?: AccountsQuery;
  userAccountsLoading?: boolean;
  logoutReason?: LogoutReason;
};

enum LogoutReason {
  MANUAL,
  AUTOMATIC,
}

type UpdateInfoData = {
  showUpdate: boolean;
  updateMessage?: string;
  updateAppUrl?: string;
};

// master global context type
type GlobalContextType = {
  systemStateData: SystemStateData;
  powerAuthData: PowerAuthData;
  userData: UserData;
  userActivations?: Array<Device>;
  updateInfoData: UpdateInfoData;
  // system state functions
  initializePowerAuth(): Promise<void>;
  checkSystemState(): Promise<void>;
  // powerauth functions
  fetchActivationStatus(): Promise<void>;
  createActivation(userId: string, deviceName: string): Promise<void>;
  commitActivation(authentication: PowerAuthAuthentication): Promise<void>;
  removeActivationLocal(): Promise<void>;
  validatePassword(password: PAPassword): Promise<void>;
  changePassword(
    oldPassword: PAPassword,
    newPassword: PAPassword,
    newPasswordType?: PasswordType,
  ): Promise<void>;
  // login functions
  powerAuthLogin(authentication: PowerAuthAuthentication): Promise<void>;
  ibTempLogin(userId: string): Promise<void>;
  logout(reason?: LogoutReason): void;
  // graphql functions
  queryUserAccounts(): Promise<void>;
  queryUserActivations(): Promise<void>;
  removeUserActivation(
    activationId: string,
    authentication: PowerAuthAuthentication,
  ): Promise<void>;
  hideUpdateInfo(): void;
};

const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);

type Props = {
  children?: React.ReactNode;
};

const GlobalContextProvider: React.FC<Props> = ({ children }) => {
  // init systemStateData with defaults
  const [systemStateData, setSystemStateData] = useState<SystemStateData>({ loading: true });

  // init powerAuthData with defaults
  const [powerAuthData, setPowerAuthData] = useState<PowerAuthData>({
    loading: false,
    isPlatformSupported: false,
  });

  // init userData with defaults
  const [userData, setUserData] = useState<UserData>({
    loginInProgress: false,
    userLoggedIn: false,
  });

  const [userActivations, setUserActivations] = useState<Array<Device> | undefined>();

  const [updateData, setUpdateData] = useState<UpdateInfoData>({
    showUpdate: false,
  });

  // functions
  async function checkSystemState() {
    console.debug('GlobalContext: checkSystemState() invoked');

    if (!systemStateData.loading) {
      setSystemStateData({ loading: true });
    }

    const _systemStateResult = await systemStateService.checkSystemState();

    setSystemStateData({ loading: false, result: _systemStateResult });

    if (_systemStateResult.updateType === UpdateType.Optional && _systemStateResult.updateAppUrl) {
      setUpdateData({
        showUpdate: true,
        updateAppUrl: _systemStateResult.updateAppUrl,
        updateMessage: _systemStateResult.updateMessage,
      });
    }
  }

  async function initializePowerAuth(): Promise<void> {
    console.debug('GlobalContext: initializePowerAuth() invoked');

    return new Promise((resolve) => {
      //
      if (powerAuthService.isPlatformSupported()) {
        //
        if (!powerAuthData.loading) {
          setPowerAuthData({ loading: true, isPlatformSupported: true });
        }

        powerAuthService
          .setupPowerAuth()
          .then(() => {
            //
            powerAuthService
              .fetchActivationStatus()
              .then((_activationStatus) => {
                //
                setPowerAuthData({
                  loading: false,
                  isPlatformSupported: true,
                  activationStatus: _activationStatus,
                });
                //
                resolve();
              })
              .catch((reason) => {
                if (reason instanceof PowerAuthError) {
                  switch (reason.code) {
                    case PowerAuthErrorCode.INVALID_ACTIVATION_STATE:
                    case PowerAuthErrorCode.INVALID_ACTIVATION_DATA:
                      removeActivationLocal().then(resolve).catch(console.error);
                      break;
                    default:
                      console.error(reason);
                  }
                } else console.error(reason);
              });
          })
          .catch(console.error);
      }
    });
  }

  async function powerAuthLogin(authentication: PowerAuthAuthentication): Promise<void> {
    console.debug('GlobalContext: powerAuthLogin([authentication]) invoked');

    return new Promise((resolve, reject) => {
      setUserData({
        loginInProgress: true,
        userLoggedIn: userData.userLoggedIn,
        logoutReason: userData.logoutReason,
      });

      const payload = '{}';
      powerAuthService
        .authorizationHttpHeader(authentication, 'POST', '/login', payload)
        .then((authorizationData) => {
          //
          loginService
            .powerAuthLogin(authorizationData.paHeader, authorizationData.payload)
            .then((_loginResult) => {
              //
              if (
                _loginResult.success &&
                _loginResult.userId &&
                _loginResult.accessToken &&
                _loginResult.refreshToken
              ) {
                setUserData({
                  loginInProgress: true,
                  userLoggedIn: _loginResult.success,
                  userId: _loginResult.userId,
                  logoutReason: userData.logoutReason,
                });
                tokenStorage.setTokens(_loginResult.accessToken, _loginResult.refreshToken);

                // fetch my accounts using graphQLService immediately after successfull login
                graphQLService
                  .queryUserAccounts(_loginResult.userId)
                  .then((_userAccounts) => {
                    //
                    setUserData({
                      loginInProgress: false,
                      userLoggedIn: _loginResult.success,
                      userId: _loginResult.userId,
                      userAccounts: _userAccounts,
                      logoutReason: undefined,
                    });

                    resolve();
                  })
                  .catch(console.error);
              } else {
                // unsuccessful login attempt
                setUserData({
                  loginInProgress: false,
                  userLoggedIn: _loginResult.success,
                  userId: _loginResult.userId,
                  logoutReason: userData.logoutReason,
                });
              }
            })
            .catch((reason) => {
              //console.error(reason);
              setUserData({
                loginInProgress: false,
                userLoggedIn: false,
                logoutReason: userData.logoutReason,
              });
              reject(reason);
            });
        })
        .catch((reason) => {
          setUserData({
            loginInProgress: false,
            userLoggedIn: false,
            logoutReason: userData.logoutReason,
          });
          reject(reason);
        });
    });
  }

  async function ibTempLogin(userId: string): Promise<void> {
    console.debug(`GlobalContext: ibTempLogin(${userId}) invoked`);

    return new Promise((resolve) => {
      setUserData({
        loginInProgress: true,
        userLoggedIn: userData.userLoggedIn,
      });

      loginService
        .ibTempLogin(userId)
        .then((_loginResult) => {
          //
          if (
            _loginResult.success &&
            _loginResult.userId &&
            _loginResult.accessToken &&
            _loginResult.refreshToken
          ) {
            setUserData({
              loginInProgress: true,
              userLoggedIn: _loginResult.success,
              userId: _loginResult.userId,
            });
            tokenStorage.setTokens(_loginResult.accessToken, _loginResult.refreshToken);

            // fetch my accounts using graphQLService immediately after successfull login
            graphQLService
              .queryUserAccounts(_loginResult.userId)
              .then((_userAccounts) => {
                //
                setUserData({
                  loginInProgress: false,
                  userLoggedIn: _loginResult.success,
                  userId: _loginResult.userId,
                  userAccounts: _userAccounts,
                });

                resolve();
              })
              .catch(console.error);
          } else {
            // unsuccessful login attempt
            setUserData({
              loginInProgress: false,
              userLoggedIn: _loginResult.success,
              userId: _loginResult.userId,
            });
          }
        })
        .catch(console.error);
    });
  }

  function logout(reason?: LogoutReason) {
    console.debug('GlobalContext: logout() invoked');

    // for logout - do not wait for loginService to process, log out in UI right away, process loginService call on background
    void loginService.logout();

    setUserData({
      loginInProgress: false,
      userLoggedIn: false,
      userId: undefined,
      logoutReason: reason,
    });
    setUserActivations(undefined);
    /*setPowerAuthData({
        loading: false,
        isPlatformSupported: powerAuthData.isPlatformSupported,
      });*/
    tokenStorage.clearTokens();
  }

  async function fetchActivationStatus(): Promise<void> {
    console.debug('GlobalContext: fetchActivationStatus() invoked');

    return new Promise((resolve) => {
      powerAuthService
        .fetchActivationStatus()
        .then((activationStatus) => {
          setPowerAuthData({ ...powerAuthData, activationStatus: activationStatus });
          resolve();
        })
        .catch(console.error);
    });
  }

  async function createActivation(userId: string, deviceName: string): Promise<void> {
    console.debug(`GlobalContext: createActivation(${userId}, ${deviceName}) invoked`);

    const { activationCode, activationId } = await activationService.createActivation(userId);
    await powerAuthService.createActivation(deviceName, activationCode);

    // We should commit on backend right away, otherwise it might time out
    await activationService.commitActivation(userId, activationId);
  }

  async function commitActivation(authentication: PowerAuthAuthentication): Promise<void> {
    console.debug('GlobalContext: commitActivation([authentication]) invoked');

    await powerAuthService.commitActivation(authentication);

    // HACK! We must get login info from the server somehow
    if (authentication.password) {
      await powerAuthLogin(PowerAuthAuthentication.password(authentication.password));
    }
  }

  async function removeActivationLocal(): Promise<void> {
    console.debug('GlobalContext: removeActivationLocal() invoked');

    return new Promise((resolve) => {
      powerAuthService
        .removeActivationLocal()
        .then(() => {
          // fetch right away
          fetchActivationStatus()
            .then(() => {
              resolve();
            })
            .catch(console.error);
        })
        .catch(console.error);
    });
  }

  async function validatePassword(password: PAPassword): Promise<void> {
    console.debug('GlobalContext: validatePassword() invoked');

    return await powerAuthService.validatePassword(password);
  }

  async function changePassword(
    oldPassword: PAPassword,
    newPassword: PAPassword,
    newPasswordType?: PasswordType,
  ): Promise<void> {
    console.debug(
      'GlobalContext: changePassword([oldPassword], [newPassword], [newPasswordType]) invoked',
    );

    if (newPasswordType !== undefined) {
      await asyncStorageService.setPasswordType(newPasswordType);
    }
    return await powerAuthService.changePassword(oldPassword, newPassword);
  }

  async function queryUserAccounts(): Promise<void> {
    console.debug('GlobalContext: queryUserAccounts() invoked');

    return new Promise((resolve, reject) => {
      if (userData.userId === undefined) {
        reject('userData.userId is undefined');
      } else {
        //
        setUserData({
          loginInProgress: userData.loginInProgress,
          userLoggedIn: userData.userLoggedIn,
          userId: userData.userId,
          userAccounts: userData.userAccounts,
          userAccountsLoading: true,
        });

        graphQLService
          .queryUserAccounts(userData.userId)
          .then((_userAccounts) => {
            //
            setUserData({
              loginInProgress: userData.loginInProgress,
              userLoggedIn: userData.userLoggedIn,
              userId: userData.userId,
              userAccounts: _userAccounts,
              userAccountsLoading: false,
            });

            resolve();
          })
          .catch(console.error);
      }
    });
  }

  async function queryUserActivations(): Promise<void> {
    console.debug('GlobalContext: queryUserActivations() invoked');

    if (userData.userId === undefined) {
      throw Error('userData.userId is undefined');
    }

    const activations = await activationService.queryUserActivations(
      userData.userId,
      powerAuthData.isPlatformSupported,
    );

    setUserActivations(activations);
  }

  async function removeUserActivation(
    activationId: string,
    authentication: PowerAuthAuthentication,
  ): Promise<void> {
    console.debug(`GlobalContext: removeUserActivation(${activationId}, [authentication]) invoked`);

    if (userData.userId === undefined) {
      throw Error('userData.userId is undefined');
    }

    await activationService.removeUserActivation(userData.userId, activationId, authentication);
  }

  function hideUpdateInfo(): void {
    setUpdateData({ showUpdate: false });
  }

  return (
    <GlobalContext.Provider
      value={{
        systemStateData,
        powerAuthData,
        userData,
        userActivations,
        updateInfoData: updateData,
        initializePowerAuth,
        checkSystemState,
        powerAuthLogin,
        ibTempLogin,
        logout,
        fetchActivationStatus,
        createActivation,
        commitActivation,
        removeActivationLocal,
        validatePassword,
        changePassword,
        queryUserAccounts,
        queryUserActivations,
        removeUserActivation,
        hideUpdateInfo,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

function useGlobalContext(): GlobalContextType {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error('useGlobalContext() must be used within an GlobalContextProvider');
  }

  return context;
}

export {
  GlobalContext,
  GlobalContextProvider,
  useGlobalContext,
  SystemStateData,
  SystemState,
  SystemStateStatus,
  PowerAuthData,
  UserData,
  LogoutReason,
  PowerAuthActivationState,
  UpdateInfoData,
};
