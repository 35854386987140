import React from 'react';
import {
  createNativeStackNavigator,
  NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import HeaderLeft from '../../components/HeaderLeft';

// screens
import MobileActivationIntroScreen from '../../screens/MobileActivation/MobileActivationIntroScreen';
import MobileActivationUserScreen from '../../screens/MobileActivation/MobileActivationUserScreen';
import MobileActivationVerificationScreen from '../../screens/MobileActivation/MobileActivationVerificationScreen';
import MobileActivationPasswordScreen from '../../screens/MobileActivation/MobileActivationPasswordScreen';
import MobileActivationBiometryScreen from '../../screens/MobileActivation/MobileActivationBiometryScreen';

const Stack = createNativeStackNavigator<RootStackParamList>();
type navProp = NativeStackNavigationProp<ParamListBase>;

function MobileActivationStack() {
  const { t } = useTranslation('resources');
  const navigation = useNavigation<navProp>();

  return (
    <Stack.Navigator
      initialRouteName="MobileActivationIntro"
      screenOptions={{
        headerBackTitleVisible: false,
        headerBackButtonMenuEnabled: false,
        headerLeft: () => <HeaderLeft onPress={() => navigation.goBack()} />,
        headerTitleAlign: 'center',
        headerShadowVisible: false,
        headerTitleStyle: {
          fontSize: 22,
          fontWeight: '700',
        },
      }}
    >
      <Stack.Screen
        name="MobileActivationIntro"
        component={MobileActivationIntroScreen}
        options={{
          title: '',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="MobileActivationUser"
        component={MobileActivationUserScreen}
        options={{
          title: t('mobileActivation.user'),
        }}
      />
      <Stack.Screen
        name="MobileActivationVerification"
        component={MobileActivationVerificationScreen}
        options={{
          title: t('mobileActivation.verification'),
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="MobileActivationPassword"
        component={MobileActivationPasswordScreen}
        options={{
          title: '',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="MobileActivationBiometry"
        component={MobileActivationBiometryScreen}
        options={{
          title: '',
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

export default MobileActivationStack;
