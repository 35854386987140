import { useEffect, useState } from 'react';
import { HomeItemType, HomeSettingsListItem } from '../../../types/HomeItem';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { Account } from '../../../generated/graphql';

/**
 * Provides all home screen items.
 */
export default function useHomeItems() {
  const { t } = useTranslation('resources');
  const [homeItemInDefaultOrder, setHomeItemInDefaultOrder] = useState({
    accounts: new Array<Account>(),
    cards: [
      {
        saveId: 'mockCards',
        type: HomeItemType.CARD,
        sortId: -1,
        displayed: true,
        title: t('home.internalTiles.mockCards'),
      },
    ],
    offers: [
      {
        saveId: 'customerOffers',
        type: HomeItemType.OFFER,
        sortId: -1,
        displayed: true,
        title: t('home.internalTiles.customerOffers'),
      },
    ],
    others: [
      // {
      //   saveId: 'customerService',
      //   type: HomeItemType.OTHER,
      //   sortId: -1,
      //   displayed: true,
      //   title: t('home.internalTiles.customerService'),
      // },
      // {
      //   saveId: 'convenientNegotiation',
      //   type: HomeItemType.OTHER,
      //   sortId: -1,
      //   displayed: true,
      //   title: t('home.internalTiles.convenientNegotiation'),
      // },
    ],
  });

  // Get available data
  const { userData } = useGlobalContext();

  useEffect(() => {
    setHomeItemInDefaultOrder((prevState) => ({
      ...prevState,
      accounts: userData.userAccounts?.accounts ?? new Array<Account>(),
    }));
  }, [userData.userAccounts?.accounts]);

  return [homeItemInDefaultOrder];
}
