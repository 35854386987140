import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import CommonItemSection, { SectionItem } from '../../../../components/CommonItemSection';
import { useUserDetail } from '../../context/UserDetailContext';
import { User } from '../../../../generated/graphql';

const UserDetailSections = () => {
  const { t } = useTranslation();
  const { userDetailData } = useUserDetail();

  const baseDataSection = useMemo(
    () => prepareBaseDataSection(userDetailData, t),
    [userDetailData, t],
  );

  return (
    <>
      <CommonItemSection items={baseDataSection} marginTop={24} />
    </>
  );
};

const prepareBaseDataSection = (userDetailData: User | undefined, t: TFunction) => {
  const section: Array<SectionItem> = [];
  if (userDetailData === undefined) return section;
  const firstName = {
    label: t('profile.userDetail.labelFirstName'),
    value: userDetailData.firstName,
  };
  section.push(firstName);
  if (userDetailData.middleName) {
    section.push({
      label: t('profile.userDetail.labelMiddleName'),
      value: userDetailData.middleName,
    });
  }
  const lastName = {
    label: t('profile.userDetail.labelLastName'),
    value: userDetailData.lastName,
  };
  section.push(lastName);
  const birthDate = {
    label: t('profile.userDetail.labelBirthDate'),
    value: userDetailData.birthDate,
  };
  section.push(birthDate);
  if (userDetailData.email) {
    section.push({
      label: t('profile.userDetail.labelMail'),
      value: userDetailData.email,
    });
  }
  if (userDetailData.phone) {
    section.push({
      label: t('profile.userDetail.labelPhone'),
      value: userDetailData.phone,
    });
  }
  return section;
};

export default UserDetailSections;
