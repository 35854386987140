import { SortableListItem } from './SortableListItem';

/**
 * TODO
 */
export type SavableHomeSettingsItem = SortableListItem<HomeItemType>;

/**
 * Dashboard screen list item. Can be sorted based on the [sortId].
 */
export type HomeSettingsListItem = {
  title: string;
} & SavableHomeSettingsItem;

/**
 * Home list item data wrapper.
 */
export type HomeListItem = {
  value: unknown;
} & SortableListItem<HomeItemType>;

/**
 * Represents sortable dashboard item types.
 */
export enum HomeItemType {
  ACCOUNT,
  CARD,
  OFFER,
  OTHER,
}

/**
 * Parsing dashboard items encoded to JSON into array with objects.
 */
export function parseHomeItems(items: string): Array<SavableHomeSettingsItem> {
  return JSON.parse(items) as Array<SavableHomeSettingsItem>;
}
