import React, { useCallback, useMemo, useState } from 'react';
import { Account } from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { Button, List, Portal, Snackbar, Text } from 'react-native-paper';
import { FlatList, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import { baseTheme, colors } from '../../../../assets';
import BottomSheet from '../../../../components/BottomSheet';
import { useTheme } from '../../../../utilities/reactUtils';
import { globals } from '../../../../utilities/globals';
import { prepareTestID } from '../../../../utilities/utils';
import { formatAmount } from '../../../../utilities/formatUtils';

interface AccountDetailItem {
  key: string;
  data: string;
  testID: string;
  canCopy?: boolean;
}

interface AccountDetailProps {
  account: Account;
  visible: boolean;
  onDismiss: () => void;
}

export function AccountInfoModal({ account, visible, onDismiss }: AccountDetailProps) {
  const { t } = useTranslation('resources');
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const dismiss = useCallback(() => {
    onDismiss();
    setSnackbarVisible(false);
  }, [onDismiss]);

  const accountDetailData: AccountDetailItem[] = useMemo(
    () => [
      {
        key: t('accountDetail.availableBalance'),
        data: formatAmount(account.availableBalance, account.currencyCode, true),
        testID: 'account_info_available_balance',
      },
      {
        key: t('accountDetail.balance'),
        data: formatAmount(account.balance, account.currencyCode, true),
        testID: 'account_info_balance',
      },
      {
        key: t('accountDetail.number'),
        data: account.number.number + '/' + account.number.bankCode,
        canCopy: true,
        testID: 'account_info_number',
      },
      {
        key: t('accountDetail.iban'),
        data: account.iban,
        canCopy: true,
        testID: 'account_info_iban',
      },
      {
        key: t('accountDetail.bic'),
        data: globals.constants.bic,
        canCopy: true,
        testID: 'account_info_bic',
      },
      {
        key: t('accountDetail.type'),
        data: t('accountDetail.type', { context: account.type }),
        testID: 'account_info_account_type',
      },
    ],
    [account, t],
  );

  return (
    <>
      <BottomSheet visible={visible} onDismiss={dismiss}>
        <View
          style={{ flexDirection: 'row', alignSelf: 'center', alignItems: 'center', margin: 16 }}
        >
          <Text
            variant="headlineSmall"
            style={{ fontWeight: '700' }}
            testID={prepareTestID('account_info_name_text')}
          >
            {account.name}
          </Text>
          <MaterialCommunityIcons
            name="pencil"
            size={24}
            color={baseTheme.colors.secondary}
            style={{ marginLeft: 8 }}
            testID={prepareTestID('account_info_name_edit_button')}
          />
        </View>
        <FlatList
          data={accountDetailData}
          renderItem={({ item }) => (
            <Item item={item} showSnackbar={() => setSnackbarVisible(true)} />
          )}
          initialNumToRender={15}
          scrollEnabled={false}
        />
        <Button
          mode="contained"
          onPress={dismiss}
          style={{
            height: 48,
            justifyContent: 'center',
            margin: 16,
            marginTop: 40,
            marginBottom: 32,
          }}
          contentStyle={{ width: '100%', height: '100%' }}
          theme={{
            roundness: 8,
            colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
          }}
          testID={prepareTestID('account_info_close_button')}
        >
          {t('common.close')}
        </Button>
      </BottomSheet>
      <Portal>
        <Snackbar
          visible={snackbarVisible}
          onDismiss={() => setSnackbarVisible(false)}
          duration={Snackbar.DURATION_SHORT}
          action={{
            label: t('common.ok'),
            onPress: () => setSnackbarVisible(false),
          }}
        >
          {t('common.copiedToClipboard')}
        </Snackbar>
      </Portal>
    </>
  );
}

function Item({ item, showSnackbar }: { item: AccountDetailItem; showSnackbar: () => void }) {
  const { fonts } = useTheme();
  const baseTestID = prepareTestID(item.testID);

  return (
    <List.Item
      title={item.key}
      titleStyle={{ ...fonts.bodyMedium, lineHeight: 18 }}
      testID={baseTestID}
      right={() => (
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'center',
            alignItems: 'baseline',
          }}
        >
          <Text
            variant="bodyMedium"
            style={{ fontWeight: '600', lineHeight: 18 }}
            testID={`${baseTestID}_data`}
          >
            {item.data}
          </Text>
          {item.canCopy && (
            <MaterialCommunityIcons
              name="content-copy"
              size={16}
              color={baseTheme.colors.secondary}
              style={{ marginLeft: 8 }}
              testID={`${baseTestID}_copy_button`}
            />
          )}
        </View>
      )}
      onPress={
        item.canCopy
          ? () => void Clipboard.setStringAsync(item.data).then(showSnackbar).catch(console.debug)
          : undefined
      }
    />
  );
}
