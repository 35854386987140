import { Account, AccountNumber, AccountType } from '../generated/graphql';
import { DateTime } from 'luxon';
import { PaymentData } from '../features/Payment/context/PaymentDomesticContext';

const WEIGHTS = [1, 2, 4, 8, 5, 10, 9, 7, 3, 6];
const ACCOUNT_PREFIX_MAX_LENGTH = 6;
const ACCOUNT_NUMBER_MAX_LENGTH = 10;
const ACCOUNT_NUMBER_MIN_LENGTH = 2;
const BANK_CODE_LENGTH = 4;

const QR_PAYMENT_HEADER = 'SPD';
const QR_PAYMENT_ACCOUNT_FIELD = 'ACC';
const QR_PAYMENT_AMOUNT_FIELD = 'AM';
const QR_PAYMENT_DUE_DATE_FIELD = 'DT';
const QR_PAYMENT_MESSAGE_FIELD = 'MSG';
const QR_PAYMENT_VS_FIELD = 'X-VS';
const QR_PAYMENT_SS_FIELD = 'X-SS';
const QR_PAYMENT_CS_FIELD = 'X-KS';

const QR_INVOICE_HEADER = 'SID';
const QR_INVOICE_VS_FIELD = 'VS';
const QR_INVOICE_SS_FIELD = 'SS';
const QR_INVOICE_CS_FIELD = 'KS';

const QR_ALLOWED_HEADERS = [QR_PAYMENT_HEADER, QR_INVOICE_HEADER];

export const getDefaultDebitAccount = (accounts: Account[], onlyDomestic?: boolean) => {
  let account: Account | undefined;
  account = accounts.find(
    (account) => account.type === AccountType.Current && account.currencyCode === 'CZK',
  );
  if (!account && !onlyDomestic) {
    account = accounts.find((account) => account.type === AccountType.Current);
  }
  if (!account) {
    account = accounts.find(
      (account) => account.type === AccountType.Saving && account.currencyCode === 'CZK',
    );
  }
  if (!account && !onlyDomestic) {
    account = accounts.find((account) => account.type === AccountType.Saving);
  }
  return account;
};

export const getDefaultCreditAccount = (accounts: Account[]) => {
  let account: Account | undefined;
  account = accounts.find(
    (account) => account.type === AccountType.Saving && account.currencyCode === 'CZK',
  );
  if (!account) {
    account = accounts.find((account) => account.type === AccountType.Saving);
  }
  if (!account) {
    account = accounts.find(
      (account) => account.type === AccountType.Current && account.currencyCode === 'CZK',
    );
  }
  if (!account) {
    account = accounts.find((account) => account.type === AccountType.Current);
  }
  return account;
};

export const getToaPaymentAccounts = (accounts: Account[]) => {
  return accounts.filter(
    (account) => account.type === AccountType.Current || account.type === AccountType.Saving,
  );
};

export const getDomesticPaymentAccounts = (accounts: Account[]) => {
  return accounts.filter(
    (account) =>
      account.currencyCode === 'CZK' &&
      (account.type === AccountType.Current || account.type === AccountType.Saving),
  );
};

export const isAccountPrefixValid = (prefix: string) => {
  if (prefix.length > ACCOUNT_PREFIX_MAX_LENGTH) return false;
  return isAccountPartModulo11(prefix);
};

export const isAccountNumberValid = (accountNumber: string) => {
  const withoutLeadingZero = accountNumber.replace(/^0+/, '');
  if (
    withoutLeadingZero.length < ACCOUNT_NUMBER_MIN_LENGTH ||
    accountNumber.length > ACCOUNT_NUMBER_MAX_LENGTH
  ) {
    return false;
  }
  return isAccountPartModulo11(accountNumber);
};

export const isBankCodeValid = (bankCode: string) => {
  if (bankCode.length != BANK_CODE_LENGTH) return false;
  // TODO check againts list of valid bankCodes
  return true;
};

const isAccountPartModulo11 = (part: string) => {
  let sum = 0;
  for (let i = part.length - 1; i >= 0; i--) {
    sum += parseInt(part[i]) * WEIGHTS[part.length - i - 1];
  }
  return sum % 11 === 0;
};

export const getCurrencyLabel = (currencyCode?: string) => {
  if (!currencyCode) return '';
  switch (currencyCode) {
    case 'CZK':
      return 'Kč';
    case 'EUR':
      return 'EUR';
    case 'USD':
      return 'USD';
    default:
      return currencyCode;
  }
};

export const getAccountNumberFromIBAN = (iban: string) => {
  let accNum: AccountNumber | undefined = undefined;
  const [withoutSwift] = iban.split('+');
  if (withoutSwift.match('^CZ[0-9]{2}([0-9]{4})([0-9]{6})([0-9]{10})$')) {
    const bank = withoutSwift.slice(4, 8);
    const prefix = withoutSwift.slice(8, 14).replace(/^0+/, '');
    const number = withoutSwift.slice(14).replace(/^0+/, '');
    accNum = { bankCode: bank, prefix: prefix, number: number };
  }
  return accNum;
};

export const isQrPayment = (data: string) => {
  return QR_ALLOWED_HEADERS.includes(data.split('*')[0]);
};

export const getDataFromQrPayment = (data: string) => {
  const dataMap: { [id: string]: string } = {};
  data
    .split('*')
    .map((it) => it.split(':'))
    .filter((it) => it.length === 2)
    .map((it) => (dataMap[it[0]] = it[1]));
  return dataMap;
};

export const getAccountFromQr = (data: string) => {
  return getAccountNumberFromIBAN(getDataFromQrPayment(data)[QR_PAYMENT_ACCOUNT_FIELD]);
};

export const getDomesticPaymentDataFromQr = (data: string) => {
  const paymentDataMap = getDataFromQrPayment(data);

  const qrInvoice = data.split('*')[0] === QR_INVOICE_HEADER;
  const vsKey = qrInvoice ? QR_INVOICE_VS_FIELD : QR_PAYMENT_VS_FIELD;
  const csKey = qrInvoice ? QR_INVOICE_CS_FIELD : QR_PAYMENT_CS_FIELD;
  const ssKey = qrInvoice ? QR_INVOICE_SS_FIELD : QR_PAYMENT_SS_FIELD;

  const paymentData: PaymentData = { amount: 0 };

  for (const key in paymentDataMap) {
    switch (key) {
      case QR_PAYMENT_AMOUNT_FIELD:
        paymentData.amount = Number(paymentDataMap[key]);
        break;
      case QR_PAYMENT_MESSAGE_FIELD:
        paymentData.message = decodeURIComponent(paymentDataMap[key]);
        break;
      case QR_PAYMENT_DUE_DATE_FIELD: {
        const parsedDate = DateTime.fromFormat(paymentDataMap[key], 'yyyyMMdd');
        const canUseDate = parsedDate >= DateTime.now().startOf('day');
        paymentData.dueDate = canUseDate ? parsedDate : undefined;
        break;
      }
      case vsKey:
        paymentData.symbolVariable = paymentDataMap[key];
        break;
      case csKey:
        paymentData.symbolConstant = paymentDataMap[key];
        break;
      case ssKey:
        paymentData.symbolSpecific = paymentDataMap[key];
        break;
      default:
        break;
    }
  }
  return paymentData;
};
