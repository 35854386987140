import React, { useEffect, useState } from 'react';
import { Avatar } from 'react-native-paper';
import { ImageSourcePropType, StyleProp, ViewStyle } from 'react-native';
import { colors } from '../../assets';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import { AvatarKey } from '../../generated/graphql';
import { useAvatar } from '../../contexts/AvatarContext';

export interface CommonAvatarProps {
  avatar?: AvatarKey | 'USER';
  image?: ImageSourcePropType;
  icon?: IconSource;
  text?: string;
  size?: number;
  backgroundColor?: string;
  color?: string;
  style?: StyleProp<ViewStyle>;
}

const CommonAvatar = ({
  avatar,
  image,
  icon,
  text,
  size = 40,
  color,
  backgroundColor,
  style,
}: CommonAvatarProps) => {
  const avatarContext = useAvatar();
  const [avatarData, setAvatarData] = useState<string>();
  const defaultColor = avatarData || icon ? colors.ui_transparent : colors.ui_grey_32;
  const defaultStyle = { backgroundColor: backgroundColor ?? defaultColor };

  useEffect(() => {
    if (avatar) {
      avatarContext.getAvatar(avatar).then(setAvatarData);
      //.catch((cause) => console.log(`Avatar loading error: ${JSON.stringify(cause)}`));
    }
  }, [avatarContext, avatar]);

  if (avatarData) {
    return (
      <Avatar.Image
        source={{ uri: `data:image/png;base64,${avatarData}` }}
        size={size}
        style={[defaultStyle, style]}
      />
    );
  }

  if (image) {
    return <Avatar.Image source={image} size={size} style={[defaultStyle, style]} />;
  }

  if (icon) {
    return (
      <Avatar.Icon
        icon={icon}
        size={size}
        style={[defaultStyle, style]}
        color={color ?? colors.ui_white}
      />
    );
  }

  return (
    <Avatar.Text
      label={text ?? '?'}
      labelStyle={{
        fontSize: 1.1 * size * 0.35,
        lineHeight: 1.1 * size * 0.45,
        fontWeight: '600',
        color: color ?? colors.ui_white,
      }}
      size={size}
      style={[defaultStyle, style]}
    />
  );
};

export default CommonAvatar;
