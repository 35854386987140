enum PaymentActionType {
  DOMESTIC_PAYMENT = 'domestic_payment',
  TOA_PAYMENT = 'toa_payment',
  QR_PAYMENT = 'qr_payment',
  PAY_ME = 'pay_me',
  QUICK_TRANSFER = 'quick_transfer',
  RECURRENT_PAYMENT = 'recurrent_payment',
  DIRECT_DEBIT = 'direct_debit',
  SIPO = 'sipo',
  FOREIGN_PAYMENT = 'foreign_payment',
}

export default PaymentActionType;
