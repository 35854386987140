import React, { useEffect, useMemo, useState } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { notImplementedAlert, prepareTestID } from '../../../../../utilities/utils';
import { usePaymentDashboard } from '../../../context/PaymentDashboardContext';
import PaymentAction, { PaymentActionProps } from '../PaymentAction';
import { PaymentNavProp } from '../../../../../screens/Main/Payment/PaymentScreen';
import { usePaymentDomestic } from '../../../context/PaymentDomesticContext';
import { usePaymentToa } from '../../../context/PaymentToaContext';
import PaymentCategoryTabType from './PaymentCategoryTabType';
import PaymentActionType from './PaymentActionType';
import Sipo from '../../../../../assets/svg/Sipo';
import { colors } from '../../../../../assets';
import CurveArrowRight from '../../../../../assets/svg/CurveArrowRight';

interface PaymentActionSegmentItemProps extends PaymentActionProps {
  actionType: PaymentActionType;
  paymentTab: PaymentCategoryTabType;
}

const PaymentActionSegment = ({ selectedTab }: { selectedTab: PaymentCategoryTabType }) => {
  const { t } = useTranslation();
  const navigation = useNavigation<PaymentNavProp>();
  const { allowedPaymentTypes } = usePaymentDashboard();
  const { resetPayment } = usePaymentDomestic();
  const { resetToaPayment } = usePaymentToa();

  const [filteredActions, setFilteredActions] = useState<PaymentActionSegmentItemProps[]>([]);

  const paymentActions: PaymentActionSegmentItemProps[] = useMemo(
    () => [
      {
        actionType: PaymentActionType.DOMESTIC_PAYMENT,
        paymentTab: PaymentCategoryTabType.SINGLE,
        icon: ({ size }) => <CurveArrowRight width={size} height={size} color={colors.ui_black} />,
        color: colors.ui_turq_prim,
        onPress: () => {
          resetPayment();
          navigation.navigate('PaymentDomesticModal');
        },
      },
      {
        actionType: PaymentActionType.QR_PAYMENT,
        paymentTab: PaymentCategoryTabType.SINGLE,
        icon: 'qrcode',
        color: colors.ui_turq_prim,
        onPress: () => {
          resetPayment();
          navigation.navigate('PaymentDomesticModal', { screen: 'PaymentDomesticQR' });
        },
      },
      {
        actionType: PaymentActionType.TOA_PAYMENT,
        paymentTab: PaymentCategoryTabType.SINGLE,
        icon: 'swap-horizontal',
        color: colors.ui_turq_prim,
        onPress: () => {
          resetToaPayment();
          navigation.navigate('PaymentToaModal');
        },
      },
      {
        actionType: PaymentActionType.RECURRENT_PAYMENT,
        paymentTab: PaymentCategoryTabType.SINGLE,
        icon: 'calendar-outline',
        color: colors.ui_turq_prim,
        onPress: () => notImplementedAlert(),
      },
      {
        actionType: PaymentActionType.DIRECT_DEBIT,
        paymentTab: PaymentCategoryTabType.SINGLE,
        icon: 'bank-check',
        color: colors.ui_turq_prim,
        onPress: () => notImplementedAlert(),
      },
      {
        actionType: PaymentActionType.SIPO,
        paymentTab: PaymentCategoryTabType.SINGLE,
        icon: ({ size }) => <Sipo width={size} height={size} color={colors.ui_black} />,
        color: colors.ui_turq_prim,
        onPress: () => notImplementedAlert(),
      },
    ],
    [navigation, resetPayment, resetToaPayment],
  );

  useEffect(() => {
    setFilteredActions(
      paymentActions.filter(
        (action) =>
          action.paymentTab === selectedTab && allowedPaymentTypes.includes(action.actionType),
      ),
    );
  }, [allowedPaymentTypes, paymentActions, selectedTab]);

  return (
    <FlatList
      horizontal={true}
      data={filteredActions}
      renderItem={({ item }) =>
        PaymentAction({
          ...item,
          text: t(`payment.actions.${item.actionType}`),
          textColor: colors.ui_black,
          iconColor: colors.ui_black,
          testID: prepareTestID(`payment_action_${item.actionType}`),
        })
      }
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles.contentContainer}
      style={styles.list}
    />
  );
};

const styles = StyleSheet.create({
  contentContainer: { paddingStart: 16, justifyContent: 'center', flexGrow: 1 },
  list: { marginTop: 16, width: '100%', flexShrink: 1 },
});

export default PaymentActionSegment;
