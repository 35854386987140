import React from 'react';
import { baseTheme, styles } from '../../../assets';
import { View } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../../../types';
import { StackNavigationProp } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { Button, Card, Text } from 'react-native-paper';
import SafeAreaViewFixed from '../../../components/SafeAreaViewFixed';

type DeviceDetailRouteProp = RouteProp<RootStackParamList, 'DeviceDetail'>;
type DeviceDetailProp = StackNavigationProp<RootStackParamList, 'DeviceDetail'>;

function DeviceDetailScreen() {
  const { t } = useTranslation('resources');
  const navigation = useNavigation<DeviceDetailProp>();
  const route = useRoute<DeviceDetailRouteProp>();
  const device = route.params.device;

  return (
    <SafeAreaViewFixed style={styles.screenBase} edges={['top', 'left', 'right']}>
      <View style={{ ...styles.container, padding: 16 }}>
        <Text variant="headlineSmall" style={{ fontWeight: '700' }}>
          {device.name}
        </Text>
        {device.current && (
          <Text variant="bodySmall" style={{ opacity: 0.6, marginTop: 8 }}>
            {t('deviceManagement.thisDevice')}
          </Text>
        )}
        <Card mode="elevated" style={{ marginTop: 32 }}>
          <Card.Content>
            {/*{device.authorization || (
            <Button theme={{ roundness: 0 }} mode="outlined" style={{ marginBottom: 8 }}>
              {t('deviceManagement.setAsAuthorization')}
            </Button>
          )}*/}
            <Button
              theme={{ roundness: 0 }}
              mode="outlined"
              onPress={() => {
                navigation.navigate('RemoveActivation', { device: device });
              }}
              textColor={baseTheme.colors.error}
              style={{ borderColor: baseTheme.colors.error }}
            >
              {t('deviceManagement.removeDevice', { device: device.name })}
            </Button>
          </Card.Content>
        </Card>
        {/*{device.authorization || (
        <Text variant="bodySmall" style={{ opacity: 0.6, margin: 16 }}>
          {t('deviceManagement.authorizationDeviceMessage')}
        </Text>
      )}*/}
      </View>
    </SafeAreaViewFixed>
  );
}

export default DeviceDetailScreen;
