import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const Sipo = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <G clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill={props.color}>
      <Path d="M.46 7a.455.455 0 0 0-.46.448c.232 6.437 5.44 11.638 11.99 12.063.286.019.575.006.866.006.931 0 1.899-.16 2.773-.347 2.829-.605 4.924-3.053 4.924-6.012a5.959 5.959 0 0 0-.238-1.678C19.565 8.895 17.127 7 14.235 7c-2.891 0-5.33 1.895-6.08 4.48.518 1.783 1.84 3.237 3.559 3.968a3.3 3.3 0 0 1-.925-2.29c0-1.855 1.542-3.359 3.446-3.359 1.904 0 3.447 1.504 3.447 3.36a3.3 3.3 0 0 1-.925 2.289 3.477 3.477 0 0 1-2.522 1.07c-2.8 0-5.209-1.626-6.287-3.963a6.59 6.59 0 0 1-.19-5.054l.038-.098A.34.34 0 0 0 7.458 7H.461" />
      <Path d="M23.67 7.339c.215.785.33 1.61.33 2.46 0 2.879-1.31 5.458-3.383 7.203-.518.382-.96.76-1.823 1.207 1.567-1.441 2.335-3.132 2.335-5.051 0-.58-.076-1.143-.217-1.68a6.588 6.588 0 0 0-.218-4.03A.34.34 0 0 1 21.02 7h2.205c.211-.001.392.15.445.338Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#000" transform="translate(0 7)" d="M0 0h24v12.522H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Sipo;
