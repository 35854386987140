import React, { useCallback, useMemo, useState } from 'react';
import { Keyboard } from 'react-native';
import { Button, HelperText } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { colors, styles } from '../../../../assets';
import { RootStackParamList } from '../../../../types';
import { useTheme } from '../../../../utilities/reactUtils';
import PaymentRecipient from '../../components/shared/PaymentRecipient';
import { formatAmount } from '../../../../utilities/formatUtils';
import { useTranslation } from 'react-i18next';
import { usePaymentToa } from '../../context/PaymentToaContext';
import PaymentToaInputs from '../../components/toa/PaymentToaInputs';
import ProfileAvatar from '../../../../assets/images/profileAvatar/avatar.png';
import AccountPickerOverlay, {
  AccountPickerVariant,
} from '../../components/shared/AccountPickerOverlay';
import { Account } from '../../../../generated/graphql';
import { getCurrencyLabel } from '../../../../utilities/paymentUtils';
import PaymentModalScreen from '../../components/shared/PaymentModalScreen';

enum AccountPickTarget {
  DEBIT_ACCOUNT,
  CREDIT_ACCOUNT,
}

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentToa'>;

const PaymentToaScreen = () => {
  const { t } = useTranslation();
  const { fonts } = useTheme();
  const navigation = useNavigation<NavProp>();

  const {
    paymentData,
    paymentDataValid,
    creditAccount,
    setCreditAccount,
    debitAccount,
    setDebitAccount,
  } = usePaymentToa();

  const [showAccountPicker, setShowAccountPicker] = useState(false);
  const [accountPickTarget, setAccountPickTarget] = useState<AccountPickTarget | undefined>();

  const accountsValid = useMemo(() => {
    return creditAccount !== debitAccount;
  }, [creditAccount, debitAccount]);

  const canContinue = useMemo(() => {
    if (paymentData.amount < 0.1) return false;
    if (!accountsValid) return false;
    return paymentDataValid;
  }, [paymentData, paymentDataValid, accountsValid]);

  const onClose = useCallback(() => {
    navigation.navigate('Payment');
  }, [navigation]);

  const onContinue = useCallback(() => {
    Keyboard.dismiss();
    setTimeout(() => {
      navigation.navigate('PaymentToaSummary');
    }, 50);
  }, [navigation]);

  const onAccountPress = useCallback((target: AccountPickTarget) => {
    Keyboard.dismiss();
    setAccountPickTarget(target);
    setTimeout(() => {
      setShowAccountPicker(true);
    }, 50);
  }, []);

  const onDebitAccountPress = useCallback(
    () => onAccountPress(AccountPickTarget.DEBIT_ACCOUNT),
    [onAccountPress],
  );

  const onCreditAccountPress = useCallback(
    () => onAccountPress(AccountPickTarget.CREDIT_ACCOUNT),
    [onAccountPress],
  );

  const onPickAccount = useCallback(
    (account: Account) => {
      accountPickTarget === AccountPickTarget.DEBIT_ACCOUNT
        ? setDebitAccount(account)
        : setCreditAccount(account);
      setAccountPickTarget(undefined);
      setShowAccountPicker(false);
    },
    [accountPickTarget, setCreditAccount, setDebitAccount],
  );

  const onAccountPickerDismiss = useCallback(() => {
    setAccountPickTarget(undefined);
    setShowAccountPicker(false);
  }, []);

  return (
    <PaymentModalScreen
      headerProps={{
        accountData: debitAccount,
        onAccountPress: onDebitAccountPress,
        onClosePress: onClose,
      }}
      content={
        <>
          <PaymentRecipient
            avatar={ProfileAvatar}
            recipientName={creditAccount?.name}
            recipientAccount={formatAmount(
              creditAccount?.availableBalance,
              getCurrencyLabel(creditAccount?.currencyCode),
              true,
            )}
            disabled={false}
            onRecipientPressed={onCreditAccountPress}
          />
          {!accountsValid && (
            <HelperText type={'error'} style={{ marginStart: 8 }}>
              {t('payment.toaForm.accountsError')}
            </HelperText>
          )}
          <PaymentToaInputs />
        </>
      }
      footerContent={
        <Button
          onPress={onContinue}
          disabled={!canContinue}
          loading={false}
          mode="contained"
          theme={{
            roundness: 8,
            colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
          }}
          style={styles.formActionButton}
          labelStyle={fonts.labelSmall}
          contentStyle={{ width: '100%', height: '100%' }}
        >
          {t(canContinue ? 'payment.toaForm.buttonContinue' : 'payment.toaForm.buttonAmount')}
        </Button>
      }
      otherContent={
        <AccountPickerOverlay
          visible={showAccountPicker}
          variant={AccountPickerVariant.TOA_PAYMENT}
          onDismiss={onAccountPickerDismiss}
          onSubmit={onPickAccount}
        />
      }
    />
  );
};

export default PaymentToaScreen;
