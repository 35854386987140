import React, { useMemo } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { IconButton, Text } from 'react-native-paper';
import { colors } from '../../../../../assets';
import CommonAvatar from '../../../../../components/CommonAvatar';
import { Account } from '../../../../../generated/graphql';
import { formatAmount } from '../../../../../utilities/formatUtils';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export interface PaymentFormHeaderProps {
  accountData?: Account;
  headerText?: string;
  onBackPress?: () => void;
  onAccountPress?: () => void;
  onClosePress?: () => void;
}

const PaymentFormHeader = ({
  onBackPress,
  onClosePress,
  onAccountPress,
  headerText,
  accountData,
}: PaymentFormHeaderProps) => {
  const showHeaderText = headerText && !accountData;

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        {onBackPress && (
          <IconButton onPress={onBackPress} icon="arrow-left" style={styles.iconButton} />
        )}
      </View>

      <View style={{ ...styles.headerContent, paddingTop: accountData ? 8 : 16 }}>
        {accountData && <HeaderAccountView {...accountData} onAccountPress={onAccountPress} />}
        {showHeaderText && <Text variant={'titleMedium'}>{headerText}</Text>}
      </View>

      <View style={styles.iconContainer}>
        {onClosePress && <IconButton onPress={onClosePress} icon="window-close" />}
      </View>
    </View>
  );
};

const HeaderAccountView = ({
  name,
  availableBalance,
  currencyCode,
  onAccountPress,
}: Account & { onAccountPress?: () => void }) => {
  const avatar = useMemo(() => {
    return <CommonAvatar size={32} avatar="USER" style={styles.avatar} />;
  }, []);

  return (
    <Pressable onPress={onAccountPress} style={styles.accountHeaderContainer}>
      {avatar}
      <View style={styles.accountDataColumn}>
        <View style={styles.accountDataRow}>
          <Text
            variant={'bodyLarge'}
            ellipsizeMode={'tail'}
            numberOfLines={1}
            style={styles.accountDataTitle}
          >
            {name}
          </Text>
          <MaterialCommunityIcons name={'menu-down'} size={24} />
        </View>

        <Text style={styles.accountDataSubtitle} ellipsizeMode={'tail'} numberOfLines={1}>
          {formatAmount(availableBalance, currencyCode, true)}
        </Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 56,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconContainer: { width: 52 },
  iconButton: { marginTop: 8 },
  headerContent: { paddingHorizontal: 8, flexShrink: 1 },
  avatar: { alignSelf: 'center', backgroundColor: colors.ui_transparent, marginStart: 8 },
  accountHeaderContainer: {
    backgroundColor: colors.ui_base_bg,
    height: 48,
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    flexDirection: 'row',
  },
  accountDataColumn: {
    flexDirection: 'column',
    paddingVertical: 4,
    paddingHorizontal: 8,
    flexShrink: 1,
  },
  accountDataRow: { flexDirection: 'row', flexShrink: 1 },
  accountDataTitle: { flexShrink: 1 },
  accountDataSubtitle: { fontSize: 12, fontWeight: '500', lineHeight: 14, opacity: 0.6 },
});

export default PaymentFormHeader;
