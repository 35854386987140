import React, { useCallback, useEffect, useState, useMemo } from 'react';
import SafeAreaViewFixed from '../../components/SafeAreaViewFixed';
import { AppState, AppStateStatus, Image, Linking, Platform, View } from 'react-native';
import { Button, Text, IconButton, ActivityIndicator } from 'react-native-paper';
import FadeInView from 'react-native-fade-in-view';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { colors as assetColors, styles, uiConstants } from '../../assets';
import { useTheme } from '../../utilities/reactUtils';
import LogoHorizontal from '../../assets/images/logoHorizontal/logo.png';
import { languageService } from '../../services/languageService';
import { ErrorType } from '../../services/systemStateService';
import { useTranslation } from 'react-i18next';
import NoConnection from '../../assets/svg/NoConnection';
import Gears from '../../assets/svg/Gears';
import Update from '../../assets/svg/Update';
import { notImplementedAlert } from '../../utilities/utils';
import AppVersionInfo from '../../components/AppVersionInfo';
import Refresh from '../../assets/images/refresh/icon.png';

enum SystemStateScreenType {
  LOADING,
  GENERAL_ERROR,
  NO_CONNECTION,
  FORCE_UPDATE,
}

function SystemStateScreen() {
  const { t } = useTranslation('resources');
  const { colors, fonts } = useTheme();

  const { systemStateData, powerAuthData } = useGlobalContext();
  const globalContext = useGlobalContext();

  const [phoneIconDisplayed, setPhoneIconDisplayed] = useState(false);
  const [loaderAnimating, setLoaderAnimating] = useState(false);
  const [screenType, setScreenType] = useState(SystemStateScreenType.LOADING);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    languageService.initLanguage().catch(console.error);

    // delay phoneIconDisplayed
    setTimeout(() => setPhoneIconDisplayed(true), 5000);
  }, []);

  useEffect(() => {
    // delay loader animating by 1s - when waiting for loading finish
    if (!loaderAnimating) setTimeout(() => setLoaderAnimating(true), 1000);
  }, [loaderAnimating]);

  useEffect(() => {
    if (!shouldRefresh) return;
    setScreenType(SystemStateScreenType.LOADING);
    globalContext.initializePowerAuth().catch(console.error);
    globalContext.checkSystemState().catch(console.error);
    setShouldRefresh(false);
  }, [shouldRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAppState = useCallback((appState: AppStateStatus) => {
    if (appState === 'active') {
      setShouldRefresh(true);
    }
  }, []);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', handleAppState);

    return () => {
      subscription.remove();
    };
  }, [handleAppState]);

  useEffect(() => {
    if (systemStateData.loading || powerAuthData.loading) {
      setScreenType(SystemStateScreenType.LOADING);
      return;
    }
    switch (systemStateData.result?.error?.type) {
      case ErrorType.appUpdateRequired:
        setScreenType(SystemStateScreenType.FORCE_UPDATE);
        break;
      case ErrorType.noInternetConnection:
        setScreenType(SystemStateScreenType.NO_CONNECTION);
        break;
      case ErrorType.general:
      default:
        setScreenType(SystemStateScreenType.GENERAL_ERROR);
        break;
    }
  }, [systemStateData, powerAuthData]);

  const centerViewJustifyContent = useMemo(() => {
    return screenType === SystemStateScreenType.LOADING ? 'flex-start' : 'space-evenly';
  }, [screenType]);

  //useMemo used here to prevent flashing of <Image> on page re-renders
  const CenterImage = useMemo(() => {
    const centerIcon = () => {
      switch (screenType) {
        case SystemStateScreenType.FORCE_UPDATE:
          return Update;
        case SystemStateScreenType.NO_CONNECTION:
          return NoConnection;
        case SystemStateScreenType.GENERAL_ERROR:
        default:
          return Gears;
      }
    };

    if (screenType === SystemStateScreenType.LOADING) {
      return <Image source={LogoHorizontal} style={styles.logoHorizontal} />;
    }

    return <View style={{ alignItems: 'center' }}>{centerIcon()({})}</View>;
  }, [screenType]);

  //useMemo used here to prevent flashing of running <ActivityIndicator> on page re-renders
  const CenterContent = useMemo(() => {
    const title = () => {
      switch (screenType) {
        case SystemStateScreenType.LOADING:
          return null;
        case SystemStateScreenType.GENERAL_ERROR:
          return t('systemState.generalErrorTitle');
        case SystemStateScreenType.NO_CONNECTION:
          return t('systemState.noConnectionTitle');
        case SystemStateScreenType.FORCE_UPDATE:
          return t('systemState.forceUpdateTitle');
      }
    };

    const message = () => {
      switch (screenType) {
        case SystemStateScreenType.LOADING:
          return null;
        case SystemStateScreenType.GENERAL_ERROR:
          return (
            systemStateData.result?.error?.errorMessage ?? t('systemState.generalErrorMessage')
          );
        case SystemStateScreenType.NO_CONNECTION:
          return t('systemState.noConnectionMessage');
        case SystemStateScreenType.FORCE_UPDATE: {
          if (Platform.OS === 'web') {
            return t('systemState.forceUpdateMessageWeb');
          }
          const updateMessage = systemStateData.result?.updateMessage;
          const store =
            Platform.OS === 'ios' ? t('systemState.storeApple') : t('systemState.storeAndroid');
          return updateMessage
            ? updateMessage
            : t('systemState.forceUpdateMessage', { store: store });
        }
      }
    };

    if (screenType === SystemStateScreenType.LOADING) {
      return <ActivityIndicator size="small" color={colors.primary} animating={loaderAnimating} />;
    }
    return (
      <View
        style={{
          width: '80%',
          maxWidth: uiConstants.maxWidth,
        }}
      >
        <Text variant="headlineSmall" style={{ textAlign: 'center' }}>
          {title()}
        </Text>
        <Text variant="bodyMedium" style={{ textAlign: 'center', paddingTop: 8 }}>
          {message()}
        </Text>
      </View>
    );
  }, [
    screenType,
    loaderAnimating,
    colors.primary,
    t,
    systemStateData.result?.error?.errorMessage,
    systemStateData.result?.updateMessage,
  ]);

  const mainButtonText = () => {
    if (screenType === SystemStateScreenType.FORCE_UPDATE) {
      return t('systemState.updateButton');
    }
    return t('systemState.retryButton');
  };

  const shouldShowMainButton = useMemo(() => {
    if (screenType === SystemStateScreenType.LOADING) {
      return false;
    }
    if (screenType === SystemStateScreenType.FORCE_UPDATE && Platform.OS === 'web') {
      return false;
    }
    return true;
  }, [screenType]);

  const onMainButtonSubmit = () => {
    if (
      screenType === SystemStateScreenType.FORCE_UPDATE &&
      Platform.OS !== 'web' &&
      systemStateData.result?.updateAppUrl !== undefined
    ) {
      Linking.openURL(systemStateData.result?.updateAppUrl).catch(console.warn);
      return;
    }
    setShouldRefresh(true);
  };

  const MainButton = () => {
    return shouldShowMainButton ? (
      <Button
        onPress={onMainButtonSubmit}
        icon={Refresh}
        mode="outlined"
        theme={{
          roundness: 8,
          colors: { primary: assetColors.ui_turq_prim, onPrimary: assetColors.ui_black },
        }}
        labelStyle={fonts.labelMedium}
        style={{
          marginBottom: 32,
          ...styles.formActionButton,
        }}
      >
        {mainButtonText()}
      </Button>
    ) : (
      <></>
    );
  };

  return (
    <SafeAreaViewFixed style={styles.screenBase}>
      <AppVersionInfo />

      <View style={[styles.containerFlex, { justifyContent: 'space-between' }]}>
        <View
          style={{
            height: '30%',
            width: '100%',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
          }}
        >
          {phoneIconDisplayed && (
            <FadeInView duration={1500} style={styles.topRight}>
              <IconButton onPress={notImplementedAlert} icon="phone-outline" />
            </FadeInView>
          )}
        </View>

        <View
          style={{
            height: '35%',
            width: '100%',
            alignItems: 'center',
            justifyContent: centerViewJustifyContent,
          }}
        >
          {CenterImage}
          {CenterContent}
        </View>

        <View
          style={{
            height: '35%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <View style={styles.mainButtonContainer}>
            <MainButton />
          </View>
        </View>
      </View>
    </SafeAreaViewFixed>
  );
}

export default SystemStateScreen;
