import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import SafeAreaViewFixed from '../../components/SafeAreaViewFixed';
import { TextInput as RNTextInput, View, ScrollView, Keyboard } from 'react-native';
import { Button, IconButton, Text, TextInput } from 'react-native-paper';
import { colors as assetColors, styles } from '../../assets';
import { useTheme } from '../../utilities/reactUtils';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../types';
import { useNavigation } from '@react-navigation/native';
import { DateTime } from 'luxon';
import DropDownPicker from '../../components/DropDownPicker';
import { globals } from '../../utilities/globals';
import { notImplementedAlert, prepareTestID } from '../../utilities/utils';
import KeyboardAware from '../../components/KeyboardAware';

type ActivationProp = StackNavigationProp<RootStackParamList, 'MobileActivationUser'>;

function MobileActivationUserScreen() {
  const { t } = useTranslation('resources');
  const navigation = useNavigation<ActivationProp>();
  const { fonts, colors } = useTheme();

  const [formActionInProgress, setFormActionInProgress] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState<number | undefined>();

  const ref_input_phoneNumber = useRef<RNTextInput>(null);
  const ref_input_birthDateDay = useRef<RNTextInput>(null);

  const birthDateValid = useMemo(() => {
    const date = DateTime.fromObject({
      year: 2000,
      month: birthMonth || 0,
      day: Number(birthDay) || 0,
    });
    return date.isValid;
  }, [birthDay, birthMonth]);

  const phoneNumberValid = useMemo(() => {
    return phoneNumber.replace(/\D/g, '').length === 9;
  }, [phoneNumber]);

  const actionButtonTitle = useMemo(() => {
    if (phoneNumberValid && birthDateValid) {
      return t('common.continue');
    } else if (phoneNumberValid && !birthDateValid) {
      return t('mobileActivation.birthDatePrompt');
    } else {
      return t('mobileActivation.phoneNumberPrompt');
    }
  }, [phoneNumberValid, birthDateValid, t]);

  const submitForm = () => {
    setFormActionInProgress(true);
    Keyboard.dismiss();
    setTimeout(() => {
      navigation.navigate('MobileActivationVerification', { phoneNumber: phoneNumber });
      setFormActionInProgress(false);
    }, 2000);
  };

  const onActionButtonSubmit = () => {
    if (phoneNumberValid && birthDateValid) {
      submitForm();
    } else if (phoneNumberValid && !birthDateValid) {
      ref_input_birthDateDay.current?.focus();
    } else {
      ref_input_phoneNumber.current?.focus();
    }
  };

  const months = t('common.months', { returnObjects: true });
  const menuItems = months.map((month, index) => {
    return { label: month, value: index + 1 };
  });

  const filterPhoneNumber = useCallback((phone: string) => {
    const filtered = phone.replace(/[^\d +]/g, '').replace(/^\+420\s*/, '');
    setPhoneNumber(filtered);
  }, []);

  useEffect(() => {
    setTimeout(() => ref_input_phoneNumber.current?.focus(), 700);
  }, []);

  const ActionButton = () => {
    return (
      <Button
        onPress={onActionButtonSubmit}
        disabled={formActionInProgress}
        loading={formActionInProgress}
        mode="contained"
        theme={{
          roundness: 8,
          colors: { primary: assetColors.ui_turq_prim, onPrimary: assetColors.ui_black },
        }}
        style={styles.formActionButton}
        labelStyle={fonts.labelMedium}
        contentStyle={{ width: '100%', height: '100%' }}
        testID={prepareTestID('button_continue')}
      >
        {actionButtonTitle}
      </Button>
    );
  };

  return (
    <SafeAreaViewFixed style={styles.screenBase} edges={['left', 'right', 'bottom']}>
      <KeyboardAware style={{ width: '100%', flex: 1 }}>
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          keyboardShouldPersistTaps="always"
          keyboardDismissMode="interactive"
          contentContainerStyle={styles.formContainer}
        >
          <TextInput
            ref={ref_input_phoneNumber}
            activeUnderlineColor={colors.secondary}
            label={t('mobileActivation.phoneNumber')}
            onChangeText={filterPhoneNumber}
            value={phoneNumber}
            textContentType="telephoneNumber"
            autoComplete="tel-national"
            keyboardType="phone-pad"
            left={<TextInput.Affix text={globals.constants.phonePrefixCz} />}
            maxLength={16}
            editable={!formActionInProgress}
            testID={prepareTestID('activation_phone_number_input')}
          />

          <View style={{ height: 56, flexDirection: 'row', alignItems: 'center' }}>
            <Text variant="bodyMedium" testID={prepareTestID('activation_birthdate_info_text')}>
              {t('mobileActivation.birthDatePrompt')}
            </Text>
            <IconButton
              icon="information-outline"
              size={24}
              style={{ marginLeft: -4 }}
              onPress={notImplementedAlert}
              testID={prepareTestID('activation_birthdate_info_button')}
            />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <TextInput
              ref={ref_input_birthDateDay}
              style={{ flex: 3, marginRight: 16 }}
              activeUnderlineColor={colors.secondary}
              label={t('mobileActivation.birthDateDay')}
              onChangeText={setBirthDay}
              value={birthDay}
              textContentType="none"
              keyboardType="numeric"
              maxLength={2}
              editable={!formActionInProgress}
              testID={prepareTestID('activation_birthdate_day_input')}
            />
            <DropDownPicker
              style={{ flex: 7 }}
              label={t('mobileActivation.birthDateMonth')}
              value={birthMonth ? months[birthMonth - 1] : ''}
              menu={menuItems}
              onPicked={(month) => setBirthMonth(month)}
              testID={prepareTestID('activation_birthdate_month_dropdown')}
            />
          </View>
        </ScrollView>
        <View style={styles.formActionContainer}>
          <ActionButton />
        </View>
      </KeyboardAware>
    </SafeAreaViewFixed>
  );
}

export default MobileActivationUserScreen;
