import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Update = (props: SvgProps) => (
  <Svg width={64} height={64} viewBox="0 0 64 64" fill="none" {...props}>
    <Path d="M54 51.172L50 47.172V42H46V48.828L51.172 54L54 51.172Z" fill="black" />
    <Path
      d="M48 62.0002C45.2311 62.0002 42.5243 61.1791 40.222 59.6408C37.9197 58.1024 36.1253 55.9159 35.0657 53.3578C34.0061 50.7996 33.7288 47.9847 34.269 45.2689C34.8092 42.5532 36.1426 40.0586 38.1005 38.1007C40.0584 36.1428 42.553 34.8094 45.2687 34.2692C47.9845 33.729 50.7994 34.0063 53.3576 35.0659C55.9157 36.1255 58.1022 37.9199 59.6406 40.2222C61.1789 42.5245 62 45.2313 62 48.0002C61.9958 51.7119 60.5194 55.2704 57.8948 57.895C55.2702 60.5196 51.7117 61.996 48 62.0002V62.0002ZM48 38.0002C46.0222 38.0002 44.0888 38.5867 42.4443 39.6855C40.7998 40.7843 39.5181 42.3461 38.7612 44.1734C38.0043 46.0006 37.8063 48.0113 38.1921 49.9511C38.578 51.8909 39.5304 53.6727 40.9289 55.0713C42.3275 56.4698 44.1093 57.4222 46.0491 57.8081C47.9889 58.1939 49.9996 57.9959 51.8268 57.239C53.6541 56.4821 55.2159 55.2004 56.3147 53.5559C57.4135 51.9114 58 49.978 58 48.0002C57.9974 45.3489 56.9429 42.8069 55.0681 40.9321C53.1934 39.0573 50.6514 38.0029 48 38.0002V38.0002ZM32 56.0002C25.6369 55.9933 19.5364 53.4626 15.037 48.9632C10.5377 44.4638 8.00688 38.3633 8 32.0002H4C4.00847 39.4237 6.96118 46.5407 12.2104 51.7898C17.4595 57.039 24.5765 59.9917 32 60.0002V56.0002ZM24 16.0002H14.156C17.3924 12.3766 21.6536 9.82215 26.3748 8.6754C31.096 7.52865 36.0542 7.84377 40.5923 9.57899C45.1303 11.3142 49.0339 14.3876 51.7855 18.3917C54.5371 22.3959 56.0069 27.1417 56 32.0002H60C60.0088 26.4338 58.3557 20.9914 55.2523 16.3703C52.149 11.7492 47.7368 8.15977 42.5808 6.06174C37.4249 3.9637 31.7601 3.45263 26.3119 4.59399C20.8637 5.73534 15.8803 8.47715 12 12.4682V4.0002H8V20.0002H24V16.0002Z"
      fill="black"
    />
  </Svg>
);

export default Update;
