import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Warning = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M12 1.5C6.225 1.5 1.5 6.225 1.5 12S6.225 22.5 12 22.5 22.5 17.775 22.5 12 17.775 1.5 12 1.5ZM11.175 6h1.65v8.25h-1.65V6ZM12 18.75c-.6 0-1.125-.525-1.125-1.125S11.4 16.5 12 16.5s1.125.525 1.125 1.125S12.6 18.75 12 18.75Z"
      fill={props.color ?? '#fff'}
    />
  </Svg>
);

export default Warning;
