/**
 * TODO
 *
 * @param saveId unique identifier
 * @param type item type, useful to differentiate items into smaller groups
 * @param sortId list position/index
 * @param displayed item visibility
 */
export type SortableListItem<ItemType> = {
  readonly saveId: string | number;
  readonly type: ItemType;
  sortId: number;
  displayed: boolean;
};

/**
 * TODO
 *
 * @param itemsOrder
 * @param itemsInDefaultOrder
 */
export function sortItems<
  ItemsOrderType extends SortableListItem<CommonType>,
  DefaultItemsOrderType extends SortableListItem<CommonType>,
  CommonType,
>(
  itemsOrder: Array<ItemsOrderType> | null,
  itemsInDefaultOrder: Array<DefaultItemsOrderType>,
): Array<DefaultItemsOrderType> {
  if (itemsOrder != null && itemsOrder.length > 0) {
    // home items copy
    const itemsInDefaultOrderCopy = [...itemsInDefaultOrder];
    // list of sorted items
    const sortedItems: Array<DefaultItemsOrderType> = [];

    itemsOrder.forEach((savableItem) => {
      // try to find savable item in home list
      const foundItem = itemsInDefaultOrderCopy.find(
        (homeItem) => homeItem.saveId === savableItem.saveId && homeItem.type === savableItem.type,
      );

      // if there is a match, ...
      if (foundItem) {
        // ...it will create a copy and set the right sort ID
        sortedItems.push({
          ...foundItem,
          sortId: savableItem.sortId,
          displayed: savableItem.displayed,
        });
        // ...and removes found item from the home item list (creating list of newly added)
        itemsInDefaultOrderCopy.splice(itemsInDefaultOrderCopy.indexOf(foundItem), 1);
      }
    });

    // The rest of items in "itemsInDefaultOrderCopy" are newly added items
    // because don't have an equivalent in the list of ordered items.

    // Inserts new items on the right places.
    itemsInDefaultOrderCopy.forEach((newItem) => {
      const similarItem = sortedItems.find((item) => item.type === newItem.type);

      // if there is a similar item, ...
      if (similarItem) {
        // ...it will add the new one behind it
        sortedItems.splice(sortedItems.indexOf(similarItem), 0, newItem);
      } else {
        // otherwise it will place it on the end of the list
        sortedItems.push(newItem);
      }
    });

    //console.log('returns sorted home items');
    return sortedItems;
  } else {
    // Default items order
    //console.log('returns default items order');
    return itemsInDefaultOrder;
  }
}
