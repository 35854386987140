import { powerAuthService } from './powerAuthService';
import { graphQLService } from './graphQLService';
import { MobileDeviceType, RemoveActivationsData } from '../generated/graphql';
import { PowerAuthAuthentication } from 'react-native-powerauth-mobile-sdk';

export type Device = {
  activationId: string;
  name: string;
  type: MobileDeviceType;
  lastLogin: number;
  current: boolean;
  authorization: boolean;
};

export type CreateActivationResult = { activationCode: string; activationId: string };

async function queryUserActivations(
  userId: string,
  powerAuthSupported: boolean,
): Promise<Array<Device>> {
  console.debug('activationService: queryUserActivations() invoked');

  const result = await graphQLService.queryUserActivations(userId);
  const currentActivationId = powerAuthSupported
    ? await powerAuthService.getActivationId()
    : undefined;

  const comparison = (d1: Device, d2: Device) => {
    if (d1.current) return -1;
    if (d2.current) return 1;

    return d2.lastLogin - d1.lastLogin;
  };

  return (
    result.activations
      ?.map((activation) => {
        return {
          name: activation.name,
          activationId: activation.registrationId,
          type: activation.deviceType,
          current: activation.registrationId === currentActivationId,
          authorization: false,
          lastLogin: activation.timestampCreated, // FIXME: We need timestampLastUsed
        };
      })
      .sort(comparison) ?? []
  );
}

async function removeUserActivation(
  userId: string,
  activationId: string,
  authentication: PowerAuthAuthentication,
): Promise<void> {
  console.debug(
    `activationService: removeUserActivation(${userId}, ${activationId}, [authentication]) invoked`,
  );

  const payload: RemoveActivationsData = {
    userId: userId,
    activationIds: [activationId],
  };
  const paData = await powerAuthService.authorizationHttpHeader(
    authentication,
    'DELETE',
    '/removeActivation',
    JSON.stringify(payload),
  );

  await graphQLService.mutationRemoveActivations(paData.paHeader, paData.payload);
}

async function createActivation(userId: string): Promise<CreateActivationResult> {
  console.debug(`activationService: createActivation(${userId}) invoked`);

  const response = await graphQLService.mutationCreateActivation(userId, 'cz_timmo_mtimmo_rn');

  return {
    activationCode: response.createActivation.activationCode,
    activationId: response.createActivation.activationId,
  };
}

async function commitActivation(userId: string, activationId: string): Promise<void> {
  console.debug(`activationService: commitActivation(${userId}, ${activationId}) invoked`);

  await graphQLService.mutationCommitActivation(userId, activationId);
}

export const activationService = {
  queryUserActivations,
  removeUserActivation,
  createActivation,
  commitActivation,
};
