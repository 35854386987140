import React, { useMemo } from 'react';
import { ActivityIndicator, FlatList, Image, View } from 'react-native';
import { colors, styles } from '../../../assets';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { DateTime, DurationUnit } from 'luxon';
import AndroidPhone from '../../../assets/images/device/AndroidPhone.png';
import AndroidTablet from '../../../assets/images/device/AndroidTablet.png';
import AppleIPad from '../../../assets/images/device/AppleIPad.png';
import AppleIPhone from '../../../assets/images/device/AppleIPhone.png';
import GenericPhone from '../../../assets/images/device/GenericPhone.png';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../types';
import { useNavigation } from '@react-navigation/native';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { Card, Text } from 'react-native-paper';
import { Device } from '../../../services/activationsService';
import { MobileDeviceType } from '../../../generated/graphql';
import FadeInView from 'react-native-fade-in-view';

type DeviceManagementProp = StackNavigationProp<RootStackParamList, 'DeviceManagement'>;

function DeviceManagementScreen() {
  const navigation = useNavigation<DeviceManagementProp>();
  const globalContext = useGlobalContext();
  const { userActivations } = useGlobalContext();

  React.useEffect(() => {
    const unSubscribe = navigation.addListener(
      'focus',
      () => void globalContext.queryUserActivations().catch(console.error),
    );

    return () => unSubscribe();
  }, [globalContext, navigation]);

  return userActivations ? (
    <FlatList
      data={userActivations}
      renderItem={({ item }) => (
        <DeviceCard item={item} navigation={navigation} length={userActivations?.length ?? 0} />
      )}
      contentInsetAdjustmentBehavior="automatic"
      contentContainerStyle={{ ...styles.container, padding: 16 }}
    />
  ) : (
    <FadeInView duration={1000} style={styles.centered}>
      <ActivityIndicator size="large" color={colors.ui_black} />
    </FadeInView>
  );
}

function DeviceCard({
  item,
  navigation,
  length,
}: {
  item: Device;
  navigation: DeviceManagementProp;
  length: number;
}) {
  const { t } = useTranslation('resources');

  const image = useMemo(() => {
    switch (item.type) {
      case MobileDeviceType.Ipad:
        return AppleIPad;
      case MobileDeviceType.Iphone:
        return AppleIPhone;
      case MobileDeviceType.Phone:
        return AndroidPhone;
      case MobileDeviceType.Tablet:
        return AndroidTablet;
      case MobileDeviceType.Unknown:
        return GenericPhone;
    }
  }, [item.type]);

  const description = useMemo(
    () =>
      item.current
        ? t('deviceManagement.thisDevice')
        : t('deviceManagement.lastLogin', { time: getRoughDuration(item.lastLogin, t) }),
    [t, item],
  );

  return (
    <>
      <Card
        onPress={() => navigation.navigate('DeviceDetail', { device: item })}
        mode={item.current ? 'elevated' : 'outlined'}
        style={{ marginBottom: 8 }}
      >
        <Card.Content style={{ flexDirection: 'row' }}>
          <Image source={image} style={styles.deviceCardImage} resizeMode="contain" />
          <View style={styles.deviceCardColumn}>
            <Text variant="titleMedium" style={styles.deviceCardName}>
              {item.name}
            </Text>
            <Text variant="bodySmall" style={{ opacity: 0.6 }}>
              {description}
            </Text>
            {item.authorization && (
              <Text variant="bodySmall" style={styles.deviceCardAuthorization}>
                {t('deviceManagement.authorizationDevice')}
              </Text>
            )}
          </View>
          <MaterialCommunityIcons name="chevron-right" size={24} />
        </Card.Content>
      </Card>
      {item.authorization && (
        <Text variant="bodySmall" style={{ opacity: 0.6, marginBottom: 8 }}>
          {t('deviceManagement.authorizationDeviceMessage')}
        </Text>
      )}
      {item.current && length > 1 && (
        <Text variant="titleLarge" style={{ fontWeight: '700', marginBottom: 8 }}>
          {t('deviceManagement.otherDevices')}
        </Text>
      )}
    </>
  );
}

function getRoughDuration(time: number, t: TFunction<'resources'>): string {
  const startTime = DateTime.fromMillis(time);
  const diff = startTime.diffNow();
  const units: DurationUnit[] = ['years', 'months', 'weeks', 'days', 'hours'];

  for (const unit of units) {
    const value = Math.trunc(Math.abs(diff.as(unit)));
    if (value >= 1) {
      switch (unit) {
        case 'years':
          return t('deviceManagement.roughDuration.years', { count: value });
        case 'months':
          return t('deviceManagement.roughDuration.months', { count: value });
        case 'weeks':
          return t('deviceManagement.roughDuration.weeks', { count: value });
        case 'days':
          return t('deviceManagement.roughDuration.days', { count: value });
        case 'hours':
          return t('deviceManagement.roughDuration.hours', { count: value });
      }
    }
  }
  return t('deviceManagement.roughDuration.recently');
}

export default DeviceManagementScreen;
