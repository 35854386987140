import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PaymentActionType from '../components/dashboard/PaymentActionSegment/PaymentActionType';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { getDomesticPaymentAccounts, getToaPaymentAccounts } from '../../../utilities/paymentUtils';
import { Platform } from 'react-native';

type PaymentDashboardContextType = {
  allowedPaymentTypes: PaymentActionType[];
};

const defaultState: PaymentDashboardContextType = {
  allowedPaymentTypes: [],
};

const PaymentDashboardContext = createContext<PaymentDashboardContextType>(defaultState);

export const PaymentDashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userData } = useGlobalContext();

  const [allowedPaymentTypes, setAllowedPaymentTypes] = useState<PaymentActionType[]>(
    defaultState.allowedPaymentTypes,
  );

  const getAllowedPaymentTypes = useCallback(() => {
    // TODO: improve when account moved from global context
    const allowed: PaymentActionType[] = [];
    const { userAccounts } = userData;
    if (userAccounts?.accounts) {
      const domesticAccountsCount = getDomesticPaymentAccounts(userAccounts.accounts).length;
      const toaAccountsCount = getToaPaymentAccounts(userAccounts.accounts).length;
      if (domesticAccountsCount != 0) {
        allowed.push(PaymentActionType.DOMESTIC_PAYMENT);
        allowed.push(PaymentActionType.PAY_ME);
        allowed.push(PaymentActionType.RECURRENT_PAYMENT);
        allowed.push(PaymentActionType.DIRECT_DEBIT);
        allowed.push(PaymentActionType.SIPO);
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          allowed.push(PaymentActionType.QR_PAYMENT);
        }
      }
      if (toaAccountsCount >= 2) {
        allowed.push(PaymentActionType.TOA_PAYMENT);
        allowed.push(PaymentActionType.QUICK_TRANSFER);
      }
      if (toaAccountsCount != 0) {
        // TODO: foreign payments can be done from all CA and SA?
        allowed.push(PaymentActionType.FOREIGN_PAYMENT);
      }
    }
    setAllowedPaymentTypes(allowed);
  }, [userData]);

  useEffect(() => {
    getAllowedPaymentTypes();
  }, [getAllowedPaymentTypes, userData]);

  return (
    <PaymentDashboardContext.Provider value={{ allowedPaymentTypes }}>
      {children}
    </PaymentDashboardContext.Provider>
  );
};

export const usePaymentDashboard = () => {
  const context = useContext(PaymentDashboardContext);

  if (!context) {
    throw new Error('usePaymentDashboard() must be used within an PaymentDashboardProvider');
  }

  return context;
};
