import React, { useMemo } from 'react';
import PasswordType, { isDigits } from '../../types/PasswordType';
import { StyleProp, View, ViewStyle } from 'react-native';
import { colors } from '../../assets';
import Ellipse from '../../assets/svg/Ellipse';
import { TextInput } from 'react-native-paper';
import { useTheme } from '../../utilities/reactUtils';
import { prepareTestID } from '../../utilities/utils';

interface PasswordInputFieldProps {
  enabled: boolean;
  passwordType: PasswordType;
  password: string;
  error: boolean;
  label: string;
  style?: StyleProp<ViewStyle>;
  onChangeText: (text: string) => void;
  onSubmitEditing?: () => void;
}

interface PasswordDotsProps {
  length: number;
  password: string;
  enabled: boolean;
  error: boolean;
  style?: StyleProp<ViewStyle>;
}

function PasswordDots({ length, password, enabled, error, style }: PasswordDotsProps) {
  const filled = password.length;
  const margin = useMemo(() => 54 / length, [length]);
  const dots: React.ReactElement[] = [];

  let filledColor: string;
  if (error) filledColor = colors.ui_error_red;
  else filledColor = enabled ? colors.ui_black : colors.ui_grey_50;

  for (let i = 0; i < length; i++) {
    const color = filled > i ? filledColor : colors.ui_grey_20;
    dots[i] = (
      <Ellipse key={i} width={16} height={16} color={color} style={{ marginHorizontal: margin }} />
    );
  }

  return <View style={[style, { flexDirection: 'row' }]}>{dots}</View>;
}

function PasswordInputField({
  passwordType,
  password,
  enabled,
  error,
  style,
  label,
  onChangeText,
  onSubmitEditing,
}: PasswordInputFieldProps) {
  const { colors } = useTheme();

  if (isDigits(passwordType)) {
    return (
      <PasswordDots
        length={passwordType.length}
        password={password}
        enabled={enabled}
        error={error}
        style={style}
      />
    );
  } else {
    return (
      <View style={{ width: '100%' }}>
        <TextInput
          key="pinInput"
          value={password}
          style={style}
          label={label}
          onChangeText={onChangeText}
          activeUnderlineColor={colors.secondary}
          secureTextEntry={true}
          textContentType="password"
          autoComplete="password"
          returnKeyType="send"
          onSubmitEditing={onSubmitEditing}
          editable={enabled}
          autoFocus={true}
          testID={prepareTestID('input_password')}
        />
      </View>
    );
  }
}

export default PasswordInputField;
