import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const DragHandle = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path stroke="#000" d="M2 5.5h16M2 13.5h16" />
  </Svg>
);

export default DragHandle;
