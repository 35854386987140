import React, { ReactNode } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { styles } from '../../assets';
import { Divider } from 'react-native-paper';

type CommonCardProps = {
  style?: StyleProp<ViewStyle> | undefined;
  header?: ReactNode | undefined;
  content?: ReactNode | undefined;
  visibleDivider?: boolean;
};

/**
 * Common UI wrapper for content that should be on a "card" with evelevation.
 *
 * @param children custom UI content
 * @param style normal design style
 */
export const CommonCard = ({ style, header, content, visibleDivider = false }: CommonCardProps) => {
  return (
    <View style={[{ ...styles.commonCard }, style]}>
      {header}
      {visibleDivider && <Divider />}
      {content}
    </View>
  );
};
