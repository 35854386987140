import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useUserDetail } from '../../context/UserDetailContext';
import { initials } from '../../../../utilities/formatUtils';
import CommonAvatar from '../../../../components/CommonAvatar';

const UserDetailTop = () => {
  const { userDetailData } = useUserDetail();
  const firstName = userDetailData?.firstName ?? '';
  const lastName = userDetailData?.lastName ?? '';

  return (
    <View style={styles.container}>
      <CommonAvatar avatar={'USER'} text={initials(`${firstName} ${lastName}`)} size={48} />
      <Text variant={'headlineMedium'} style={styles.headlineText}>
        {`${firstName} ${lastName}`}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    alignSelf: 'center',
    alignItems: 'center',
  },
  headlineText: { marginTop: 24 },
  subTextContainer: { flexDirection: 'row', marginTop: 8 },
  subTextDivider: { marginHorizontal: 8, opacity: 0.6 },
  subTextRight: { opacity: 0.6 },
});

export default UserDetailTop;
