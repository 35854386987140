import { FeatureFlag, UpdateType } from '../generated/graphql';

export const globals = {
  constants: {
    customerCenterPhone: '728 404 911',
    phonePrefixCz: '+420 ',
    bic: 'CNBACZPP',
  },
  logout: {
    automaticLogoutTime: { minute: 2 },
  },
  // debug settings
  debug: {
    systemState: {
      useDebug: true,
      resultError: false,
      systemStateResponse: {
        updateType: UpdateType.None,
        //updateMessage: 'Prosím aktualizujte si aplikaci.\nToto je zpráva SystemState služby banky.',
        updateAppUrl: 'https://play.google.com/store/apps/details?id=co.uk.getmondo',
        backendIsOnline: true,
        //errorMessage: 'This is errorMessage from debug data.',
        featureFlag: FeatureFlag.None,
      },
    },
  },
};
