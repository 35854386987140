import { MD3LightTheme, Text } from 'react-native-paper';
import React from 'react';
import { useTheme } from '../../../../utilities/reactUtils';
import { StyleProp, View, ViewStyle } from 'react-native';

export function SectionHeader({
  title,
  description,
  style,
}: {
  title: string;
  description: string;
  style?: StyleProp<ViewStyle> | undefined;
}) {
  const { fonts } = useTheme();

  return (
    <View style={style}>
      <Text
        style={{
          // FONT - not defined yet
          ...MD3LightTheme.fonts,
          fontSize: 20,
          fontWeight: '800',
          lineHeight: 30,
          paddingBottom: 8,
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          ...fonts.bodyMedium,
          color: '#5A5A5A', // COLOR - not defined yet
        }}
      >
        {description}
      </Text>
    </View>
  );
}
