import React, { useMemo } from 'react';

import { Pressable, StyleSheet, Text, View } from 'react-native';
import { baseTheme, colors } from '../../assets';
import { PowerAuthBiometryType } from 'react-native-powerauth-mobile-sdk';
import { SvgProps } from 'react-native-svg';
import TouchId from '../../assets/svg/TouchId';
import FaceId from '../../assets/svg/FaceId';
import IrisScan from '../../assets/svg/IrisScan';
import Erase from '../../assets/svg/Erase';
import { prepareTestID } from '../../utilities/utils';

interface PasswordKeyboardProps {
  enabled: boolean;
  biometryVisible: boolean;
  biometryType: PowerAuthBiometryType;
  keySize: KeySize;
  onKey: (key: string) => void;
  onErase: () => void;
  onBiometry: () => void;
}

interface PasswordKeyProps {
  enabled: boolean;
  hidden?: boolean;
  styles: ReturnType<typeof getStyles>;
  onPress: () => void;
  children: React.ReactNode;
  testID: string;
}

interface PasswordNumberKeyProps {
  text: string;
  enabled: boolean;
  styles: ReturnType<typeof getStyles>;
  onPress: (key: string) => void;
}

type BiometryIcon = (props: SvgProps) => JSX.Element;

export function getBiometryIcon(biometryType: PowerAuthBiometryType): BiometryIcon {
  switch (biometryType) {
    case PowerAuthBiometryType.NONE:
    case PowerAuthBiometryType.GENERIC:
    case PowerAuthBiometryType.FINGERPRINT:
      return TouchId;
    case PowerAuthBiometryType.FACE:
      return FaceId;
    case PowerAuthBiometryType.IRIS:
      return IrisScan;
  }
}

function PasswordKey({ enabled, hidden, styles, onPress, children, testID }: PasswordKeyProps) {
  if (hidden) {
    return <View style={styles.passwordKeyboardKeyHidden}></View>;
  } else
    return (
      <Pressable
        style={({ pressed }) => [
          styles.passwordKeyboardKey,
          pressed ? { backgroundColor: baseTheme.colors.ripple, borderRadius: 32 } : null,
        ]}
        onPress={onPress}
        disabled={!enabled}
        testID={testID}
      >
        {children}
      </Pressable>
    );
}

function PasswordNumberKey({ text, enabled, styles, onPress }: PasswordNumberKeyProps) {
  return (
    <PasswordKey
      enabled={enabled}
      onPress={() => onPress(text)}
      styles={styles}
      testID={prepareTestID(`password_keyboard_password_key_${text}_button`)}
    >
      <Text style={[styles.passwordKeyboardText, { color: getColor(enabled) }]}>{text}</Text>
    </PasswordKey>
  );
}

function getColor(enabled: boolean) {
  return enabled ? baseTheme.colors.primary : colors.ui_grey_50;
}

export enum KeySize {
  Default = 64,
  Small = 48,
}

function PasswordKeyboard({
  biometryVisible,
  biometryType,
  enabled,
  keySize,
  onKey,
  onErase,
  onBiometry,
}: PasswordKeyboardProps) {
  const biometryIcon: BiometryIcon = getBiometryIcon(biometryType);
  const styles = useMemo(() => getStyles(keySize), [keySize]);
  const iconSize = keySize === KeySize.Default ? 28 : 24;

  return (
    <View style={styles.passwordKeyboard}>
      <View style={styles.passwordKeyboardRow}>
        <PasswordNumberKey enabled={enabled} text="1" styles={styles} onPress={onKey} />
        <PasswordNumberKey enabled={enabled} text="2" styles={styles} onPress={onKey} />
        <PasswordNumberKey enabled={enabled} text="3" styles={styles} onPress={onKey} />
      </View>
      <View style={styles.passwordKeyboardRow}>
        <PasswordNumberKey enabled={enabled} text="4" styles={styles} onPress={onKey} />
        <PasswordNumberKey enabled={enabled} text="5" styles={styles} onPress={onKey} />
        <PasswordNumberKey enabled={enabled} text="6" styles={styles} onPress={onKey} />
      </View>
      <View style={styles.passwordKeyboardRow}>
        <PasswordNumberKey enabled={enabled} text="7" styles={styles} onPress={onKey} />
        <PasswordNumberKey enabled={enabled} text="8" styles={styles} onPress={onKey} />
        <PasswordNumberKey enabled={enabled} text="9" styles={styles} onPress={onKey} />
      </View>
      <View style={styles.passwordKeyboardRow}>
        <PasswordKey
          enabled={true}
          hidden={!biometryVisible}
          styles={styles}
          onPress={onBiometry}
          testID={prepareTestID('password_keyboard_biometry_button')}
        >
          {biometryIcon({
            width: iconSize,
            height: iconSize,
            color: getColor(enabled),
            style: { alignSelf: 'center' },
          })}
        </PasswordKey>
        <PasswordNumberKey enabled={enabled} text="0" styles={styles} onPress={onKey} />
        <PasswordKey
          enabled={enabled}
          styles={styles}
          onPress={onErase}
          testID={prepareTestID('password_keyboard_erase_button')}
        >
          <Erase
            width={iconSize}
            height={iconSize}
            color={getColor(enabled)}
            style={{ alignSelf: 'center' }}
          />
        </PasswordKey>
      </View>
    </View>
  );
}

const getStyles = (keySize: number) => {
  const rowGap = keySize === KeySize.Default ? 12 : 8;
  const columnGap = keySize === KeySize.Default ? 24 : 16;
  const fontSize = keySize === KeySize.Default ? 26 : 22;

  return StyleSheet.create({
    passwordKeyboard: {
      flex: 0,
      flexDirection: 'column',
      paddingVertical: 12,
      alignSelf: 'center',
    },
    passwordKeyboardRow: {
      flexDirection: 'row',
      flex: 0,
      flexBasis: keySize,
      marginVertical: rowGap / 2,
      alignContent: 'center',
    },
    passwordKeyboardKey: {
      marginHorizontal: columnGap / 2,
      flex: 0,
      flexBasis: keySize,
      height: keySize,
      borderRadius: keySize / 2,
      borderWidth: 1,
      borderColor: '#9191913D',
      backgroundColor: baseTheme.colors.card,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    passwordKeyboardKeyHidden: {
      marginHorizontal: columnGap / 2,
      flex: 0,
      flexBasis: keySize,
      height: keySize,
      flexDirection: 'column',
    },
    passwordKeyboardText: {
      fontSize: fontSize,
      fontWeight: '400',
      textAlign: 'center',
    },
  });
};

export default PasswordKeyboard;
