import React, { useCallback, useState } from 'react';
import { colors, styles } from '../../assets';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useTranslation } from 'react-i18next';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../../types';
import { useGlobalContext } from '../../contexts/GlobalContext';
import AcceptTerms from '../../assets/svg/AcceptTerms';
import DoneScreen from '../Common/DoneScreen';
import {
  PowerAuthAuthentication,
  PowerAuthError,
  PowerAuthErrorCode,
} from 'react-native-powerauth-mobile-sdk';
import ErrorText from '../../components/ErrorText';
import { capitalize } from '../../utilities/formatUtils';
import { Button, Text } from 'react-native-paper';
import { useTheme } from '../../utilities/reactUtils';
import { prepareTestID } from '../../utilities/utils';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type MobileActivationBiometryRouteProp = RouteProp<RootStackParamList, 'MobileActivationBiometry'>;

function MobileActivationBiometryScreen() {
  const { t } = useTranslation('resources');
  const route = useRoute<MobileActivationBiometryRouteProp>();
  const globalContext = useGlobalContext();
  const { fonts } = useTheme();

  const password = route.params.password;
  const biometryType = route.params.biometryType;

  const [isDone, setIsDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function handleError(reason: unknown) {
    if (reason instanceof PowerAuthError) {
      switch (reason.code) {
        case PowerAuthErrorCode.BIOMETRY_CANCEL:
        case PowerAuthErrorCode.BIOMETRY_NOT_RECOGNIZED:
        case PowerAuthErrorCode.BIOMETRY_LOCKOUT:
        case PowerAuthErrorCode.BIOMETRY_FAILED:
        case PowerAuthErrorCode.BIOMETRY_NOT_AVAILABLE:
          return;
        default:
          break;
      }
    }
    setErrorMessage(t('error.unknown'));
    console.debug(reason);
  }

  const onSubmit = (useBiometry: boolean) => {
    setErrorMessage('');
    const authentication = useBiometry
      ? PowerAuthAuthentication.commitWithPasswordAndBiometry(password, {
          promptTitle: t('mobileActivation.allowBiometry'),
          promptMessage: t('mobileActivation.allowBiometry', { context: biometryType }),
        })
      : PowerAuthAuthentication.commitWithPassword(password);

    void globalContext
      .commitActivation(authentication)
      .then(() => setIsDone(true))
      .catch(handleError);
  };

  const onExit = useCallback(
    () => void globalContext.fetchActivationStatus().catch(console.debug),
    [globalContext],
  );

  return isDone ? (
    <DoneScreen
      title={t('mobileActivation.success')}
      image={() => (
        <MaterialCommunityIcons
          name="checkbox-marked-circle-outline"
          size={140}
          color={colors.ui_turq_prim}
        />
      )}
      primaryButtonLabel={t('common.continue')}
      onPrimaryButton={onExit}
    />
  ) : (
    <SafeAreaView style={{ ...styles.screenBase, paddingHorizontal: 16 }}>
      <View style={styles.containerFlex}>
        <View style={styles.centered}>
          <AcceptTerms />
        </View>
        <View style={{ ...styles.centered, justifyContent: 'flex-start' }}>
          <Text
            variant="headlineSmall"
            style={{ fontWeight: '700' }}
            testID={prepareTestID('activation_biometry_title_text')}
          >
            {t('mobileActivation.allowBiometry', { context: biometryType }) + '?'}
          </Text>
          <Text
            variant="bodyLarge"
            style={{
              textAlign: 'center',
              paddingHorizontal: 16,
              marginVertical: 16,
              fontWeight: '400',
            }}
            testID={prepareTestID('activation_biometry_message_text')}
          >
            {capitalize(t('mobileActivation.allowBiometryMessage', { context: biometryType }))}
          </Text>
        </View>
        <View style={styles.formActionContainer}>
          <Button
            onPress={() => onSubmit(true)}
            mode="contained"
            style={{ ...styles.mainButtonContainer, ...styles.formActionButton }}
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
            }}
            labelStyle={fonts.labelSmall}
            contentStyle={{ width: '100%', height: '100%' }}
            testID={prepareTestID('activation_biometry_allow_button')}
          >
            {t('mobileActivation.allowBiometry', { context: biometryType })}
          </Button>
          <Button
            onPress={() => onSubmit(false)}
            mode="text"
            style={styles.mainButtonContainer}
            labelStyle={fonts.labelMedium}
            testID={prepareTestID('activation_biometry_decline_button')}
          >
            {t('mobileActivation.biometryNotNow')}
          </Button>
        </View>
        <ErrorText error={errorMessage} />
      </View>
    </SafeAreaView>
  );
}

export default MobileActivationBiometryScreen;
