import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { baseTheme } from '../assets';

// stacks
import MobileLoginStack from '../stacks/MobileLoginStack';

function MobileLoginNavigator() {
  return (
    <NavigationContainer theme={baseTheme}>
      <MobileLoginStack />
    </NavigationContainer>
  );
}

export default MobileLoginNavigator;
