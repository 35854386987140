import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PageSettings = (props: SvgProps) => (
  <Svg viewBox="0 0 21 18" fill="none" {...props}>
    <Path
      d="M3.833.667c.46 0 .834.373.834.833v5.833a.833.833 0 0 1-1.667 0V1.5c0-.46.373-.833.833-.833ZM4.667 11.5h1.666a.833.833 0 1 0 0-1.667h-5a.833.833 0 0 0 0 1.667H3v5a.833.833 0 1 0 1.667 0v-5ZM11.333 9a.833.833 0 1 0-1.666 0v7.5a.833.833 0 1 0 1.666 0V9ZM10.5.667c.46 0 .833.373.833.833v3.333H13A.833.833 0 0 1 13 6.5H8a.833.833 0 0 1 0-1.667h1.667V1.5c0-.46.373-.833.833-.833ZM19.667 11.5a.833.833 0 0 1 0 1.666H18V16.5a.833.833 0 1 1-1.667 0v-3.334h-1.666a.833.833 0 1 1 0-1.666h5ZM17.167.667c.46 0 .833.373.833.833V9a.833.833 0 1 1-1.667 0V1.5c0-.46.373-.833.834-.833Z"
      fill="#121228"
    />
  </Svg>
);

export default PageSettings;
