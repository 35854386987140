import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../types';
import DoneScreen from '../../../../screens/Common/DoneScreen';
import { useTranslation } from 'react-i18next';
import { usePaymentDomestic } from '../../context/PaymentDomesticContext';
import { formatAmount } from '../../../../utilities/formatUtils';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { colors } from '../../../../assets';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentDomesticAuthorization'>;

const PaymentDomesticAuthorizationScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavProp>();

  const { balanceInfoAmount } = usePaymentDomestic();

  const onClose = useCallback(() => {
    navigation.navigate('Payment');
  }, [navigation]);

  return (
    <DoneScreen
      title={t('payment.domesticDone.title')}
      message={t('payment.domesticDone.message', {
        balance: formatAmount(balanceInfoAmount, 'Kč', true),
      })}
      image={() => (
        <MaterialCommunityIcons
          name="checkbox-marked-circle-outline"
          size={140}
          color={colors.ui_turq_prim}
        />
      )}
      primaryButtonLabel={t('payment.domesticDone.button')}
      onPrimaryButton={onClose}
    />
  );
};

export default PaymentDomesticAuthorizationScreen;
