import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const NoConnection = (props: SvgProps) => (
  <Svg width={64} height={64} viewBox="0 0 64 64" fill="none" {...props}>
    <Path
      d="M8.72009 3.83984L5.33343 7.25318L10.8001 12.7198C7.33343 14.3198 4.00009 16.2932 1.01343 18.6665C11.2001 31.4665 21.7068 44.4532 32.0001 57.3332L42.4268 44.3465L51.2801 53.1998L54.6668 49.8132C39.6534 34.7732 8.72009 3.83984 8.72009 3.83984ZM32.0001 7.99984C28.2668 7.99984 24.5601 8.45318 20.9601 9.33318L25.4934 13.8398C27.6534 13.5198 29.8134 13.3332 32.0001 13.3332C40.1868 13.3332 48.2401 15.6265 55.2268 19.8665L44.6934 33.0132L48.4801 36.7998C53.5468 30.4798 58.6668 23.9998 63.0668 18.6665C54.1868 11.7598 43.2534 7.99984 32.0001 7.99984ZM14.8534 16.7732L38.6668 40.5598L32.0001 48.7998L8.72009 19.8398C10.6668 18.6665 12.7468 17.6265 14.8534 16.7732Z"
      fill="black"
    />
  </Svg>
);

export default NoConnection;
