import React from 'react';
import { colors, styles as stylesImported } from '../../../assets';
import { ScrollView, StyleSheet, View } from 'react-native';
import PaymentActionSegment from '../../../features/Payment/components/dashboard/PaymentActionSegment';
import { PaymentDashboardProvider } from '../../../features/Payment/context/PaymentDashboardContext';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../types';
import PaymentCategoryTabType from '../../../features/Payment/components/dashboard/PaymentActionSegment/PaymentCategoryTabType';
import PaymentFavoritesSegment from '../../../features/Payment/components/dashboard/PaymentFavoritesSegment';

export type PaymentNavProp = StackNavigationProp<RootStackParamList, 'Payment'>;

const PaymentScreen = () => {
  return (
    <PaymentDashboardProvider>
      <View style={styles.screen}>
        <PaymentSingle />
      </View>
    </PaymentDashboardProvider>
  );
};

function PaymentSingle() {
  return (
    <ScrollView style={styles.content} contentInsetAdjustmentBehavior="automatic">
      <View style={styles.container}>
        <PaymentActionSegment selectedTab={PaymentCategoryTabType.SINGLE} />
        <PaymentFavoritesSegment />
      </View>
    </ScrollView>
  );
}

function PaymentRecurrent() {
  return (
    <ScrollView style={styles.content}>
      <PaymentActionSegment selectedTab={PaymentCategoryTabType.RECURRENT} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  screen: { ...stylesImported.screenBase, backgroundColor: colors.ui_white },
  container: { ...stylesImported.container, backgroundColor: colors.ui_white, height: '100%' },
  header: { marginStart: 16, marginTop: 16 },
  tabBarStyle: {
    marginHorizontal: 16,
    borderBottomColor: colors.ui_white,
    borderBottomWidth: 1,
    elevation: 0,
    shadowColor: 'transparent',
  },
  tabBarItemStyle: { paddingHorizontal: 16, backgroundColor: colors.ui_white, marginBottom: 2 },
  tabBarIndicatorStyle: {
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    backgroundColor: colors.ui_black,
  },
  tabBarLabelStyle: {
    textTransform: 'none',
    fontStyle: 'normal',
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 20,
  },
  content: { backgroundColor: colors.ui_white },
});

export default PaymentScreen;
