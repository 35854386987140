import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const IrisScan = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color ?? '#000'}
      d="M12 9a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0 9.821 9.821 0 0 0-17.64 0Z"
    />
  </Svg>
);

export default IrisScan;
