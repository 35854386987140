import 'react-native-gesture-handler';
import './i18n/i18n';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './services/graphQLService';
import { GlobalContextProvider } from './contexts/GlobalContext';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Router from './navigation/Router';
import { StatusBar } from 'expo-status-bar';
import { materialTheme } from './assets';
import { MD3Theme, Provider as PaperProvider } from 'react-native-paper';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { MenuProvider } from 'react-native-popup-menu';
import { AvatarContextProvider } from './contexts/AvatarContext';

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <GlobalContextProvider>
        <AvatarContextProvider>
          <PaperProvider theme={materialTheme as MD3Theme}>
            <SafeAreaProvider>
              <GestureHandlerRootView style={{ flex: 1 }}>
                <BottomSheetModalProvider>
                  <MenuProvider backHandler={true}>
                    <Router />
                    <StatusBar style="auto" />
                  </MenuProvider>
                </BottomSheetModalProvider>
              </GestureHandlerRootView>
            </SafeAreaProvider>
          </PaperProvider>
        </AvatarContextProvider>
      </GlobalContextProvider>
    </ApolloProvider>
  );
}

export default App;
