import { Animated, Pressable, View, ViewProps } from 'react-native';
import { colors, styles } from '../../assets';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Text } from 'react-native-paper';
import React, { useEffect, useRef } from 'react';

export enum ToastVariant {
  Info,
  Warning,
  Error,
}

function Toast({
  text,
  icon,
  variant,
  buttonText,
  onButtonPress,
  onAnimationFinished,
  ...props
}: ViewProps & {
  text: string;
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  variant: ToastVariant;
  buttonText?: string;
  onButtonPress?: () => void;
  onAnimationFinished?: () => void;
}) {
  const opacity = useRef(new Animated.Value(0)).current;
  const iconName = icon ?? getIconVariant(variant);
  const bgColor = getColorVariant(variant);

  useEffect(() => {
    Animated.sequence([
      Animated.delay(200),
      Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.delay(10000),
      Animated.timing(opacity, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start(() => {
      onAnimationFinished && onAnimationFinished();
    });
  }, [onAnimationFinished, opacity]);

  return (
    <Animated.View
      style={[
        props.style,
        styles.toastContainer,
        {
          opacity,
          transform: [
            {
              translateY: opacity.interpolate({
                inputRange: [0, 1],
                outputRange: [-20, 0],
              }),
            },
          ],
          backgroundColor: bgColor,
        },
      ]}
    >
      <View style={{ justifyContent: 'center', marginLeft: 13, marginRight: 16 }}>
        <MaterialCommunityIcons size={24} name={iconName} color={colors.ui_white} />
      </View>

      <View style={{ flex: 1, marginVertical: 20, marginRight: 14 }}>
        <Text variant="bodyMedium" style={{ color: colors.ui_white }}>
          {text}
        </Text>
        {buttonText && onButtonPress && (
          <Pressable onPress={onButtonPress}>
            <View style={{ flexDirection: 'row', marginTop: 11, justifyContent: 'space-between' }}>
              <Text variant="titleMedium" style={{ color: colors.ui_white }}>
                {buttonText}
              </Text>
              <MaterialCommunityIcons size={24} name="chevron-right" color={colors.ui_white} />
            </View>
          </Pressable>
        )}
      </View>
    </Animated.View>
  );
}

function getIconVariant(variant: ToastVariant): keyof typeof MaterialCommunityIcons.glyphMap {
  switch (variant) {
    case ToastVariant.Warning:
    case ToastVariant.Error:
      return 'alert-circle';
    case ToastVariant.Info:
      return 'information';
  }
}

function getColorVariant(variant: ToastVariant): string {
  switch (variant) {
    case ToastVariant.Warning:
      return colors.ui_color_warning;
    case ToastVariant.Error:
      return colors.ui_error_red;
    case ToastVariant.Info:
      return colors.ui_color_focus;
  }
}

export default Toast;
