import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const FaceId = (props: SvgProps) => (
  <Svg viewBox="0 0 30 30" fill="none" {...props}>
    <Path
      d="M8.75 3.75h-2.5a2.5 2.5 0 0 0-2.5 2.5v2.5m17.5-5h2.5a2.5 2.5 0 0 1 2.5 2.5v2.5M20 10v2.5M10 10v2.5m1.25 7.5s1.25 1.25 3.75 1.25S18.75 20 18.75 20M15 10v6.25h-1.25m-5 10h-2.5a2.5 2.5 0 0 1-2.5-2.5v-2.5m17.5 5h2.5a2.5 2.5 0 0 0 2.5-2.5v-2.5"
      stroke={props.color ?? '#000'}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default FaceId;
