import React, { ReactNode, useMemo, useState } from 'react';
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import { Button, Card, IconButton, List, Text } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack/src/types';
import { RootStackParamList } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../utilities/reactUtils';
import {
  formatAccountNumber,
  formatAmount,
  formatDate,
  initials,
} from '../../../utilities/formatUtils';
import { DateTime } from 'luxon';
import { baseTheme, colors, styles } from '../../../assets';
import { amountSign, prepareTestID } from '../../../utilities/utils';
import { Account, Direction, MovementFragment, MovementState } from '../../../generated/graphql';
import CommonAvatar from '../../../components/CommonAvatar';
import BottomSheet from '../../../components/BottomSheet';

function MovementDetailScreen({ route }: StackScreenProps<RootStackParamList, 'MovementDetail'>) {
  const movement = route.params.movement;
  const account = route.params.account;

  const [buttonsVisible, setButtonsVisible] = useState(false);
  const buttons = useMemo(() => getButtonOptions(movement), [movement]);

  return (
    <>
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        contentContainerStyle={{ ...styles.container, paddingVertical: 16 }}
      >
        <DetailHeader movement={movement} />
        <AccountsCard movement={movement} account={account} />
        <SymbolsCard movement={movement} />
        <DatesCard movement={movement} />
        <MessagesCard movement={movement} />
        <ButtonsLine buttons={buttons} onShowMore={() => setButtonsVisible(true)} />
      </ScrollView>
      <ButtonsModal
        buttons={buttons}
        visible={buttonsVisible}
        onHide={() => setButtonsVisible(false)}
      />
    </>
  );
}

function DetailHeader({ movement }: { movement: MovementFragment }) {
  const date = useMemo(() => DateTime.fromISO(movement.date), [movement.date]);
  const amount = amountSign(movement.amount, movement.direction) || 0;
  const amountColor = amount > 0 ? colors.ui_color_positive : baseTheme.colors.text;

  return (
    <View style={{ alignItems: 'center' }}>
      <CommonAvatar
        avatar={movement.avatar}
        text={initials(movement.title)}
        size={48}
        style={{ marginBottom: 24 }}
      />
      <Text
        variant="headlineMedium"
        style={{ fontWeight: '700', marginBottom: 8, color: amountColor }}
        testID={prepareTestID('movement_detail_header_amount_text')}
      >
        {formatAmount(amount, movement.currencyCode, amount % 1 != 0)}
      </Text>
      <View style={{ flexDirection: 'row' }}>
        <Text variant={'bodyMedium'} testID={prepareTestID('movement_detail_header_title_text')}>
          {movement.title}
        </Text>
        <Text variant="bodyMedium" style={{ opacity: 0.6, marginHorizontal: 8 }}>
          •
        </Text>
        <Text
          variant="bodyMedium"
          style={{ opacity: 0.6 }}
          testID={prepareTestID('movement_detail_header_date_text')}
        >
          {formatDate(date)}
        </Text>
      </View>
    </View>
  );
}

function AccountsCard({ movement, account }: { movement: MovementFragment; account: Account }) {
  const { t } = useTranslation();

  let counterAccountNumber;
  if (
    movement.source.__typename === 'Domestic' ||
    movement.source.__typename === 'StandingOrder' ||
    movement.source.__typename === 'Toa'
  ) {
    counterAccountNumber = movement.source.counterPartyAccountNumber;
  } else counterAccountNumber = undefined;

  return (
    <DetailCard style={{ marginTop: 24, marginBottom: 8 }}>
      {counterAccountNumber && (
        <Line
          label={
            movement.direction === Direction.Credit
              ? t('movementDetail.fromAccount')
              : t('movementDetail.toAccount')
          }
          right={movement.title}
          rightBottom={formatAccountNumber(counterAccountNumber)}
          testID={prepareTestID('movement_detail_from_account_line')}
        />
      )}
      <Line
        label={
          movement.direction === Direction.Credit
            ? t('movementDetail.toAccount')
            : t('movementDetail.fromAccount')
        }
        right={account.name}
        rightBottom={formatAccountNumber(account.number)}
        testID={prepareTestID('movement_detail_to_account_line')}
      />
      <Line
        label={t('movementDetail.state')}
        right={t('movementDetail.state', { context: movement.state })}
        color={getStateColor(movement.state)}
        testID={prepareTestID('movement_detail_state_line')}
      />
    </DetailCard>
  );
}

function SymbolsCard({ movement }: { movement: MovementFragment }) {
  const { t } = useTranslation();

  let variableSymbol: string | null | undefined;
  let constantSymbol: string | null | undefined;
  let specificSymbol: string | null | undefined;
  if (movement.source.__typename === 'Domestic' || movement.source.__typename === 'StandingOrder') {
    variableSymbol = movement.source.variableSymbol;
    constantSymbol = movement.source.constantSymbol;
    specificSymbol = movement.source.specificSymbol;
  }

  return (
    <>
      {(variableSymbol || constantSymbol || specificSymbol) && (
        <DetailCard style={{ marginBottom: 8 }}>
          {variableSymbol && (
            <Line
              label={t('movementDetail.variableSymbol')}
              right={variableSymbol}
              testID={prepareTestID('movement_detail_variable_symbol_line')}
            />
          )}
          {constantSymbol && (
            <Line
              label={t('movementDetail.constantSymbol')}
              right={constantSymbol}
              testID={prepareTestID('movement_detail_constant_symbol_line')}
            />
          )}
          {specificSymbol && (
            <Line
              label={t('movementDetail.specificSymbol')}
              right={specificSymbol}
              testID={prepareTestID('movement_detail_specific_symbol_line')}
            />
          )}
        </DetailCard>
      )}
    </>
  );
}

function DatesCard({ movement }: { movement: MovementFragment }) {
  const { t } = useTranslation();

  const date = useMemo(() => DateTime.fromISO(movement.date), [movement.date]);
  let originDate: DateTime | undefined;
  let dueDate: DateTime | undefined;
  if (movement.direction === Direction.Debit)
    switch (movement.source.__typename) {
      case 'Card':
        originDate = DateTime.fromISO(movement.source.paymentDate);
        dueDate = undefined;
        break;
      case 'Domestic':
        originDate = date; // TODO: missing creation date
        dueDate = DateTime.fromISO(movement.source.dueDate);
        break;
      case 'Toa':
        originDate = date; // TODO: missing creation date
        dueDate = date; // TODO: missing creation date
        break;
    }

  return (
    <DetailCard style={{ marginBottom: 8 }}>
      {originDate && (
        <Line
          label={t('movementDetail.originDate')}
          right={formatDate(originDate)}
          testID={prepareTestID('movement_detail_origin_date_line')}
        />
      )}
      <Line
        label={t('movementDetail.paymentDate')}
        right={formatDate(date)}
        testID={prepareTestID('movement_detail_payment_date_line')}
      />
      {dueDate && (
        <Line
          label={t('movementDetail.dueDate')}
          right={formatDate(dueDate)}
          testID={prepareTestID('movement_detail_due_date_line')}
        />
      )}
    </DetailCard>
  );
}

function MessagesCard({ movement }: { movement: MovementFragment }) {
  const { t } = useTranslation();

  let myDescription: string | undefined | null;
  let messageForRecipient: string | undefined;
  switch (movement.source.__typename) {
    case 'Domestic':
      messageForRecipient = movement.source.messageForRecipient;
      myDescription = movement.source.description;
      break;
    case 'Toa':
      myDescription = movement.source.description;
      break;
  }

  return (
    <DetailCard style={{ marginBottom: 24, paddingHorizontal: 16, paddingVertical: 8 }}>
      {myDescription && (
        <TwoLine
          label={t('movementDetail.myDescription')}
          text={myDescription}
          style={{ marginBottom: 16 }}
          testID={prepareTestID('movement_detail_my_description')}
        />
      )}
      {messageForRecipient && (
        <TwoLine
          label={t('movementDetail.messageForRecipient')}
          text={messageForRecipient}
          style={{ marginBottom: 16 }}
          testID={prepareTestID('movement_detail_message_for_recipient')}
        />
      )}
      <TwoLine
        label={t('movementDetail.reference')}
        text={movement.reference}
        testID={prepareTestID('movement_detail_reference')}
      />
    </DetailCard>
  );
}

function ButtonsLine({
  buttons: { canMore, primaryButton },
  onShowMore,
}: {
  buttons: ButtonOptions;
  onShowMore: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (primaryButton)
    return (
      <>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 16 }}>
          <View style={{ flex: 1 }}>
            <Button
              theme={{
                roundness: 8,
                colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
              }}
              mode="contained"
              style={styles.formActionButton}
              labelStyle={theme.fonts.labelLarge}
              contentStyle={{ width: '100%', height: '100%' }}
            >
              {t(`movementDetail.${primaryButton}`)}
            </Button>
          </View>
          {canMore && (
            <IconButton
              icon="dots-horizontal"
              containerColor={theme.colors.secondaryContainer}
              onPress={onShowMore}
              style={{ marginLeft: 16, marginRight: 0 }}
            />
          )}
        </View>
      </>
    );
  else return <></>;
}

function ButtonsModal({
  buttons: { canTemplate, canRepeat, canStandingOrder, canConfirmation },
  visible,
  onHide,
}: {
  buttons: ButtonOptions;
  visible: boolean;
  onHide: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <BottomSheet visible={visible} onDismiss={onHide}>
      <View style={{ padding: 16 }}>
        {canRepeat && (
          <Button
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
            }}
            mode="contained"
            style={{ ...styles.formActionButton, marginBottom: 16 }}
            labelStyle={theme.fonts.labelLarge}
            contentStyle={{ width: '100%', height: '100%' }}
          >
            {t('movementDetail.repeatPayment')}
          </Button>
        )}
        {canTemplate && (
          <Button
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
            }}
            mode="contained"
            style={{ ...styles.formActionButton, marginBottom: 16 }}
            labelStyle={theme.fonts.labelLarge}
            contentStyle={{ width: '100%', height: '100%' }}
          >
            {t('movementDetail.createTemplate')}
          </Button>
        )}
        <View style={{ flexDirection: 'row' }}>
          {canStandingOrder && (
            <View style={{ flex: 1, marginRight: canConfirmation ? 16 : 0 }}>
              <Button
                theme={{
                  roundness: 8,
                  colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
                }}
                mode="contained"
                style={styles.formActionButton}
                labelStyle={theme.fonts.labelLarge}
                contentStyle={{ width: '100%', height: '100%' }}
              >
                {t('movementDetail.createStandingOrder')}
              </Button>
            </View>
          )}
          {canConfirmation && (
            <View style={{ flex: 1 }}>
              <Button
                theme={{
                  roundness: 8,
                  colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
                }}
                mode="contained"
                style={styles.formActionButton}
                labelStyle={theme.fonts.labelLarge}
                contentStyle={{ width: '100%', height: '100%' }}
              >
                {t('movementDetail.confirmation')}
              </Button>
            </View>
          )}
        </View>
      </View>
    </BottomSheet>
  );
}

function DetailCard({ children, style }: { children: ReactNode; style?: StyleProp<ViewStyle> }) {
  return (
    <Card
      style={[{ backgroundColor: baseTheme.colors.card, marginHorizontal: 16 }, style]}
      elevation={0}
    >
      <Card.Content style={{ paddingHorizontal: 0, paddingVertical: 8 }}>{children}</Card.Content>
    </Card>
  );
}

function Line({
  label,
  right,
  rightBottom,
  color,
  testID,
}: {
  label: string;
  right: string;
  rightBottom?: string;
  color?: string;
  testID: string;
}) {
  const { fonts } = useTheme();

  return (
    <List.Item
      title={label}
      titleStyle={fonts.bodyMedium}
      testID={testID}
      right={() => (
        <View
          style={{
            flexDirection: 'column',
            alignSelf: 'center',
            alignItems: 'flex-end',
          }}
        >
          <Text
            variant="bodyMedium"
            style={{ fontWeight: '600', color: color }}
            testID={`${testID}_right_text`}
          >
            {right}
          </Text>
          {Boolean(rightBottom) && (
            <Text
              variant="bodyMedium"
              style={{ opacity: 0.6 }}
              testID={`${testID}_right_bottom_text`}
            >
              {rightBottom}
            </Text>
          )}
        </View>
      )}
    />
  );
}

function TwoLine({
  label,
  text,
  testID,
  style,
}: {
  label: string;
  text: string;
  testID: string;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View style={style} testID={testID}>
      <Text variant="bodyMedium" style={{ marginBottom: 8, fontWeight: '400' }}>
        {label}
      </Text>
      <Text variant="bodyMedium" style={{ fontWeight: '600' }} testID={`${testID}_text`}>
        {text}
      </Text>
    </View>
  );
}

function getStateColor(status: MovementState): string {
  switch (status) {
    case MovementState.Processed:
      return colors.ui_color_processed;
    case MovementState.Planned:
    case MovementState.WaitingForAuthorisation:
      return colors.ui_color_warning;
    case MovementState.Cancelled:
    case MovementState.NotProcessed:
      return colors.ui_error_red;
  }
}

type ButtonOptions = {
  canRepeat: boolean;
  canConfirmation: boolean;
  canTemplate: boolean;
  canStandingOrder: boolean;
  canMore: boolean;
  primaryButton:
    | 'repeatPayment'
    | 'createTemplate'
    | 'createStandingOrder'
    | 'confirmation'
    | false;
};

function getButtonOptions(movement: MovementFragment): ButtonOptions {
  switch (movement.source.__typename) {
    case 'Toa':
      return {
        canRepeat: movement.direction === Direction.Debit,
        canConfirmation: movement.direction === Direction.Debit,
        canTemplate: movement.direction === Direction.Debit,
        canStandingOrder: false,
        canMore: movement.direction === Direction.Debit,
        primaryButton: 'repeatPayment',
      };
    case 'Domestic':
      return {
        canRepeat: movement.direction === Direction.Debit,
        canTemplate: movement.direction === Direction.Debit,
        canStandingOrder: movement.direction === Direction.Debit,
        canConfirmation: true,
        canMore: movement.direction === Direction.Debit,
        primaryButton: movement.direction === Direction.Debit ? 'repeatPayment' : 'confirmation',
      };
    case 'StandingOrder':
      return {
        canRepeat: true,
        canTemplate: true,
        canStandingOrder: true,
        canConfirmation: true,
        canMore: true,
        primaryButton: 'repeatPayment',
      };
    case 'Sipo':
    case 'Card':
      return {
        canRepeat: false,
        canTemplate: false,
        canStandingOrder: false,
        canConfirmation: true,
        canMore: false,
        primaryButton: 'confirmation',
      };
    default:
      return {
        canRepeat: false,
        canTemplate: false,
        canStandingOrder: false,
        canConfirmation: false,
        canMore: false,
        primaryButton: false,
      };
  }
}

export default MovementDetailScreen;
