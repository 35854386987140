import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  gql,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { APOLLO_GRAPHQL_URL_DROID_LOCALHOST_FIX, notNull } from '../utilities/utils';
import {
  AccessTokenRefreshMutation,
  AccountsQuery,
  AccountsQueryVariables,
  ActivationsQuery,
  ActivationsQueryVariables,
  AppParams,
  AvatarKey,
  AvatarType,
  CommitActivationMutation,
  CommitActivationMutationVariables,
  CreateActivationMutation,
  CreateActivationMutationVariables,
  GetAvatarQuery,
  GetAvatarQueryVariables,
  GetAvatarUpdatedQuery,
  GetAvatarUpdatedQueryVariables,
  Language as GqlLanguage,
  LoginMutation,
  LoginMutationVariables,
  LogoutMutation,
  LogoutMutationVariables,
  MovementsByAccountQuery,
  MovementsByAccountQueryVariables,
  ToaPaymentOrder,
  Platform as GqlPlatform,
  RemoveActivationsMutation,
  RemoveActivationsMutationVariables,
  SystemStateQuery,
  SystemStateQueryVariables,
  SystemStateRequest,
  ToaPaymentOrderMutation,
  ToaPaymentOrderMutationVariables,
  TokenData,
  DomesticPaymentOrder,
  DomesticPaymentOrderMutation,
  DomesticPaymentOrderMutationVariables,
  UserQuery,
  UserQueryVariables,
} from '../generated/graphql';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import decodeJWT, { JwtPayload } from 'jwt-decode';

import { Platform } from 'react-native';
import { tokenStorage } from './tokenStorage';
import * as Application from 'expo-application';
import { DateTime } from 'luxon';
import 'react-native-get-random-values';
import { v4 as uuid } from 'uuid';
import { languageService } from './languageService';
import DeviceInfo from 'react-native-device-info';
import { Language } from '../types/Language';

const cache = new InMemoryCache({
  typePolicies: {
    AvatarData: {
      keyFields: ['key', ['type', 'id']],
    },
    AvatarKey: {
      keyFields: ['id', 'type'],
    },
  },
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

function getHeaders(): HeadersInit {
  const jwtToken = tokenStorage.getAccessToken();
  const headers: HeadersInit = {};

  headers['correlationId'] = uuid();
  headers['callerId'] = 'TODO'; // TODO IDs
  headers['callerProcessId'] = 'TODO'; // TODO IDs

  if (jwtToken) headers['Authorization'] = `Bearer ${jwtToken}`;
  return headers;
}

function createTokenRefreshLink(): TokenRefreshLink {
  return new TokenRefreshLink<TokenData>({
    isTokenValidOrUndefined: () => {
      const jwtToken = tokenStorage.getAccessToken();
      if (!jwtToken) return true;

      let jwtExp: number | undefined;
      try {
        jwtExp = decodeJWT<JwtPayload>(jwtToken).exp;
        if (!jwtExp) return true;
      } catch (e: unknown) {
        console.warn(e);
        return true;
      }

      const expiration = DateTime.fromSeconds(jwtExp);
      const now = DateTime.now();

      return expiration.isValid && expiration > now;
    },
    fetchAccessToken: async () => {
      console.debug('Refreshing access token');
      const refreshToken = tokenStorage.getRefreshToken();

      const REFRESH_MUTATION = /* GraphQL */ `
        mutation AccessTokenRefresh($refreshTokenInput: RefreshTokenInput!) {
          accessTokenRefresh(refreshTokenInput: $refreshTokenInput) {
            accessToken
            refreshToken
          }
        }
      `;

      const request = await fetch(APOLLO_GRAPHQL_URL_DROID_LOCALHOST_FIX(), {
        method: 'POST',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: REFRESH_MUTATION,
          variables: {
            refreshTokenInput: {
              refreshToken: refreshToken,
            },
          },
        }),
      });

      return request.json();
    },
    handleResponse: () => (response: AccessTokenRefreshMutation) => response,
    accessTokenField: 'AccessTokenRefresh',
    handleFetch: ({ accessToken, refreshToken }) => {
      if (accessToken && refreshToken) {
        tokenStorage.setTokens(accessToken, refreshToken);
      } else throw Error('Tokens not in refresh response');
    },
    handleError: (error) => {
      tokenStorage.clearTokens();
      console.debug(error);
    },
  });
}

function createLink(): ApolloLink {
  const httpLink = new HttpLink({
    uri: APOLLO_GRAPHQL_URL_DROID_LOCALHOST_FIX,
    credentials: 'include',
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...getHeaders(),
      },
    }));
    return forward(operation);
  });

  return ApolloLink.from([createTokenRefreshLink(), authLink, httpLink]);
}

export const apolloClient = new ApolloClient({
  link: createLink(),
  cache: cache,
  defaultOptions: defaultOptions,
});

function mutationLogin(powerAuthHeader: string, powerAuthPayload: string): Promise<LoginMutation> {
  console.debug('graphQLService: mutationLogin([powerAuthHeader], [powerAuthPayload]) invoked');

  const LOGIN_MUTATION = gql`
    mutation Login($loginData: LoginData!) {
      login(loginData: $loginData) {
        userId
        expireTime
        accessToken
        refreshToken
      }
    }
  `;

  const appParams: AppParams = {
    platform: Platform.OS,
    version:
      Platform.select({
        native: Application.nativeApplicationVersion,
        default: 'error',
      }) || 'error',
    deviceType: 'iPhone', //TODO: fill correct deviceType
  };

  return new Promise((resolve, reject) => {
    apolloClient
      .mutate<LoginMutation, LoginMutationVariables>({
        mutation: LOGIN_MUTATION,
        variables: {
          loginData: {
            appParams: appParams,
            paParams: {
              paAuthHeader: powerAuthHeader,
              paPayload: powerAuthPayload,
            },
          },
        },
      })
      .then((response) => {
        if (response.errors) {
          reject(response.errors);
        }

        if (response.data === undefined || response.data === null) {
          reject('response.data is undefined or null');
        } else {
          resolve(response.data);
        }
      })
      .catch(reject);
  });
}

function mutationLogout(): Promise<void> {
  console.debug('graphQLService: mutationLogout() invoked');

  const LOGOUT_MUTATION = gql`
    mutation Logout($refreshTokenInput: RefreshTokenInput!) {
      logout(refreshTokenInput: $refreshTokenInput)
    }
  `;

  return apolloClient
    .mutate<LogoutMutation, LogoutMutationVariables>({
      mutation: LOGOUT_MUTATION,
      variables: {
        refreshTokenInput: {
          refreshToken: tokenStorage.getRefreshToken() || '',
        },
      },
    })
    .then((response) => {
      if (response.errors) {
        throw response.errors;
      }
    })
    .catch(console.log);
}

function queryUserAccounts(userId: string): Promise<AccountsQuery> {
  console.debug(`graphQLService: queryUserAccounts(${userId}) invoked`);

  const USER_ACCOUNTS_QUERY = gql`
    query Accounts($userId: ID!, $accountTypes: [AccountType!]) {
      accounts(userId: $userId, accountTypes: $accountTypes) {
        id
        name
        number {
          prefix
          number
          bankCode
        }
        avatarData {
          key {
            type
            id
          }
          updatedTs
          data
        }
        type
        iban
        currencyCode
        balance
        availableBalance
        openingDate
      }
    }
  `;

  return new Promise((resolve, reject) => {
    apolloClient
      .query<AccountsQuery, AccountsQueryVariables>({
        query: USER_ACCOUNTS_QUERY,
        variables: {
          userId: userId,
        },
      })
      .then((response) => {
        //
        if (response.error) {
          reject(response.error);
        }

        resolve(response.data);
      })
      .catch(console.error);
  });
}

async function queryUserActivations(userId: string): Promise<ActivationsQuery> {
  console.debug(`graphQLService: queryUserActivations(${userId}) invoked`);

  const USER_ACTIVATIONS_QUERY = gql`
    query Activations($userId: String!) {
      activations(userId: $userId) {
        registrationId
        name
        deviceType
        registrationStatus
        timestampCreated
      }
    }
  `;

  const response = await apolloClient.query<ActivationsQuery, ActivationsQueryVariables>({
    query: USER_ACTIVATIONS_QUERY,
    variables: { userId: userId },
  });

  if (response.error) throw response.error;

  return response.data;
}

async function mutationRemoveActivations(powerAuthHeader: string, powerAuthPayload: string) {
  console.debug(
    'graphQLService: mutationRemoveActivations([powerAuthHeader], [powerAuthPayload]) invoked',
  );

  const REMOVE_ACTIVATIONS_MUTATION = gql`
    mutation RemoveActivations($paParams: PaParams!) {
      removeActivations(paParams: $paParams)
    }
  `;

  const response = await apolloClient.mutate<
    RemoveActivationsMutation,
    RemoveActivationsMutationVariables
  >({
    mutation: REMOVE_ACTIVATIONS_MUTATION,
    variables: { paParams: { paAuthHeader: powerAuthHeader, paPayload: powerAuthPayload } },
  });

  if (response.errors) throw response.errors;
}

async function mutationCreateActivation(
  userId: string,
  appId: string,
): Promise<CreateActivationMutation> {
  console.debug(`graphQLService: mutationCreateActivation(${userId}, ${appId}) invoked`);

  const CREATE_ACTIVATION_MUTATION = gql`
    mutation CreateActivation($userId: String!, $appId: String) {
      createActivation(userId: $userId, appId: $appId) {
        activationCode
        activationId
      }
    }
  `;

  const response = await apolloClient.mutate<
    CreateActivationMutation,
    CreateActivationMutationVariables
  >({
    mutation: CREATE_ACTIVATION_MUTATION,
    variables: { userId: userId, appId: appId },
  });

  if (response.errors) throw response.errors;
  if (!response.data) throw Error('Activation data not received');

  return response.data;
}

async function mutationCommitActivation(userId: string, activationId: string): Promise<void> {
  console.debug(`graphQLService: mutationCommitActivation(${userId}, ${activationId}) invoked`);

  const COMMIT_ACTIVATION_MUTATION = gql`
    mutation CommitActivation($userId: String!, $activationId: String!) {
      commitActivation(userId: $userId, activationId: $activationId) {
        status
      }
    }
  `;

  const response = await apolloClient.mutate<
    CommitActivationMutation,
    CommitActivationMutationVariables
  >({
    mutation: COMMIT_ACTIVATION_MUTATION,
    variables: { userId: userId, activationId: activationId },
  });

  if (response.errors) throw response.errors;
  if (!response.data || response.data.commitActivation.status !== 'OK')
    throw Error(
      `Commit unsuccessful, status: ${response.data?.commitActivation?.status || 'undefined'}`,
    );
}

async function querySystemState(): Promise<SystemStateQuery> {
  const request: SystemStateRequest = {
    language: languageService.getI18Language() === Language.CS ? GqlLanguage.Cs : GqlLanguage.En,
    platform: Platform.select({
      ios: GqlPlatform.Ios,
      android: GqlPlatform.Android,
      web: GqlPlatform.Web,
      default: GqlPlatform.Web,
    }),
    version: DeviceInfo.getVersion(),
  };
  console.debug(`graphQLService: querySystemState('${JSON.stringify(request)}') invoked`);

  const SYSTEM_STATE_QUERY = gql`
    query SystemState($platform: Platform!, $language: Language!, $version: String!) {
      systemState(platform: $platform, language: $language, version: $version) {
        backendIsOnline
        updateType
        updateAppUrl
        updateMessage
        featureFlags
        errorMessage
      }
    }
  `;

  const response = await apolloClient.query<SystemStateQuery, SystemStateQueryVariables>({
    query: SYSTEM_STATE_QUERY,
    variables: {
      platform: request.platform,
      language: request.language,
      version: request.version,
    },
  });

  if (response.error) throw response.error;

  console.debug(`graphQLService: systemStateResponse('${JSON.stringify(response.data)}')`);

  return response.data;
}

async function queryMovements(
  accountId: string,
  count: number,
  offset: number,
): Promise<MovementsByAccountQuery> {
  console.debug(`graphQLService: queryMovements(${accountId}, ${count}, ${offset}) invoked`);

  const FRAGMENT_MOVEMENT = gql`
    fragment Movement on Movement {
      id
      date
      title
      subtitle
      amount
      currencyCode
      originalAmount
      originalCurrencyCode
      direction
      accountNumber {
        prefix
        number
        bankCode
      }
      state
      country
      accounted
      reference
      source {
        ... on Card {
          number
          paymentAddress
          walletType
          online
          paymentDate
          bookingDate
        }
        ... on Sipo {
          sipoNumber
          sipoName
        }
        ... on Domestic {
          counterPartyAccountNumber {
            prefix
            number
            bankCode
          }
          messageForRecipient
          variableSymbol
          constantSymbol
          specificSymbol
          dueDate
          description
        }
        ... on StandingOrder {
          frequency
          counterPartyAccountNumber {
            prefix
            number
            bankCode
          }
          counterPartyName
          variableSymbol
          constantSymbol
          specificSymbol
          dueDate
          description
        }
        ... on Toa {
          counterPartyAccountNumber {
            prefix
            number
            bankCode
          }
          description
        }
      }
      avatar {
        type
        id
      }
      subAvatar {
        type
        id
      }
    }
  `;
  const QUERY_MOVEMENTS_QUERY = gql`
    query MovementsByAccount($movementsByAccountId: ID!, $count: Int, $offset: Int) {
      movementsByAccount(id: $movementsByAccountId, count: $count, offset: $offset) {
        ...Movement
      }
    }
    ${FRAGMENT_MOVEMENT}
  `;

  const response = await apolloClient.query<
    MovementsByAccountQuery,
    MovementsByAccountQueryVariables
  >({
    query: QUERY_MOVEMENTS_QUERY,
    variables: { movementsByAccountId: accountId, count: count, offset: offset },
  });

  if (response.error) throw response.error;

  return response.data;
}

async function mutationToaPaymentOrder(
  paymentOrder: ToaPaymentOrder,
): Promise<ToaPaymentOrderMutation> {
  console.debug(`graphQLService: mutationToaPaymentOrder(${JSON.stringify(paymentOrder)}) invoked`);

  const TOA_PAYMENT_ORDER_MUTATION = gql`
    mutation ToaPaymentOrder($paymentOrder: ToaPaymentOrder!) {
      toaPaymentOrder(paymentOrder: $paymentOrder)
    }
  `;

  const response = await apolloClient.mutate<
    ToaPaymentOrderMutation,
    ToaPaymentOrderMutationVariables
  >({
    mutation: TOA_PAYMENT_ORDER_MUTATION,
    variables: { paymentOrder: paymentOrder },
  });

  if (response.errors) throw response.errors;
  if (!response.data) throw Error('Payment data not received');
  return response.data;
}

async function mutationDomesticPaymentOrder(
  domesticPaymentOrder: DomesticPaymentOrder,
): Promise<DomesticPaymentOrderMutation> {
  console.debug(
    `graphQLService: mutationDomesticPaymentOrder(${JSON.stringify(domesticPaymentOrder)}) invoked`,
  );

  const DOMESTIC_PAYMENT_ORDER_MUTATION = gql`
    mutation DomesticPaymentOrder($domesticPaymentOrder: DomesticPaymentOrder!) {
      domesticPaymentOrder(domesticPaymentOrder: $domesticPaymentOrder)
    }
  `;

  const response = await apolloClient.mutate<
    DomesticPaymentOrderMutation,
    DomesticPaymentOrderMutationVariables
  >({
    mutation: DOMESTIC_PAYMENT_ORDER_MUTATION,
    variables: { domesticPaymentOrder: domesticPaymentOrder },
  });

  if (response.errors) throw response.errors;
  if (!response.data) throw Error('Payment data not received');

  return response.data;
}

const GET_AVATAR_QUERY = gql`
  query GetAvatar($type: AvatarType, $id: String) {
    getAvatar(type: $type, id: $id) {
      data
      key {
        id
        type
      }
      updatedTs
    }
  }
`;

export async function queryGetAvatar(key: AvatarKey, force = false): Promise<GetAvatarQuery> {
  console.debug(`graphQLService: queryGetAvatar(${JSON.stringify(key)}) invoked`);

  const response = await apolloClient.query<GetAvatarQuery, GetAvatarQueryVariables>({
    query: GET_AVATAR_QUERY,
    variables: { type: key.type, id: key.id },
    fetchPolicy: force ? 'network-only' : 'cache-first',
  });

  if (response.error) throw response.error;

  /*console.debug(
    `queryGetAvatar: ${key.type}:${key.id} ${response.data?.getAvatar?.updatedTs ?? 0}`,
  );*/
  if (response.data?.getAvatar) {
    const identification = cache.identify(response.data.getAvatar);
    //console.log(`ID: ${identification ?? '-'}`);
  }
  return response.data;
}

export async function mutationPutAvatar(data: string | undefined) {
  console.debug(`graphQLService: mutationPutAvatar(${data ? '<image>' : 'undefined'}) invoked`);
  const PUT_AVATAR_MUTATION = gql`
    mutation PutAvatar($data: String) {
      putAvatar(data: $data)
    }
  `;

  const response = await apolloClient.mutate({
    mutation: PUT_AVATAR_MUTATION,
    variables: { data: data },
  });

  if (response.errors) throw response.errors;
  console.debug(`mutationPutAvatar result: ${JSON.stringify(response.data)}`);
}

export async function refreshAvatars() {
  const GET_AVATAR_UPDATED = gql`
    query GetAvatarUpdated($type: AvatarType, $id: String) {
      getAvatar(type: $type, id: $id) {
        updatedTs
      }
    }
  `;

  const { ROOT_QUERY } = cache.extract();
  const list =
    (ROOT_QUERY &&
      Object.keys(ROOT_QUERY)
        .filter((s) => s.startsWith('getAvatar'))
        .map((s) => s.match(/id":"(.+?)","type":"(.+)"/)?.slice(1))
        .filter(notNull)) ??
    [];

  for (const [id, type] of list) {
    const variables = { id: id, type: type as AvatarType };
    const { data: serverT } = await apolloClient.query<
      GetAvatarUpdatedQuery,
      GetAvatarUpdatedQueryVariables
    >({
      query: GET_AVATAR_UPDATED,
      variables: variables,
      fetchPolicy: 'no-cache',
    });
    const cacheT = apolloClient.readQuery<GetAvatarQuery, GetAvatarQueryVariables>({
      query: GET_AVATAR_QUERY,
      variables: variables,
    });

    console.log(
      `cache:${cacheT?.getAvatar?.updatedTs ?? ''} server:${serverT.getAvatar?.updatedTs ?? ''}`,
    );

    if (
      cacheT?.getAvatar?.updatedTs &&
      cacheT.getAvatar.updatedTs !== serverT.getAvatar?.updatedTs
    ) {
      console.log('Updating');
      await queryGetAvatar(variables, true);
    }
  }
}

const GET_USER_DETAIL_QUERY = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      birthDate
      email
      firstName
      gender
      lastName
      middleName
      phone
      id
    }
  }
`;

export async function queryUserDetail(userId: string): Promise<UserQuery> {
  console.debug(`graphQLService: queryUserDetail(${JSON.stringify(userId)}) invoked`);

  const response = await apolloClient.query<UserQuery, UserQueryVariables>({
    query: GET_USER_DETAIL_QUERY,
    variables: { userId: userId },
  });

  if (response.error) throw response.error;

  if (!response.data) throw Error('UserDetail data not received');

  return response.data;
}

export const graphQLService = {
  mutationLogin,
  mutationLogout,
  queryUserAccounts,
  queryUserActivations,
  mutationRemoveActivations,
  mutationCreateActivation,
  mutationCommitActivation,
  querySystemState,
  queryMovements,
  mutationToaPaymentOrder,
  mutationDomesticPaymentOrder,
  queryGetAvatar,
  mutationPutAvatar,
  refreshAvatars,
  queryUserDetail,
};
