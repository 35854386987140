import React, { useMemo } from 'react';
import { PaymentData, usePaymentDomestic } from '../../../context/PaymentDomesticContext';
import { Account, AccountNumber } from '../../../../../generated/graphql';
import {
  formatAccountNumber,
  formatAmount,
  formatDate,
} from '../../../../../utilities/formatUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import CommonItemSection, {
  SectionItem,
  SectionItemType,
} from '../../../../../components/CommonItemSection';

const PaymentDomesticSummarySections = () => {
  const { t } = useTranslation();
  const { paymentData, recipientAccount, debitAccount, recipientName } = usePaymentDomestic();

  const accountSection = useMemo(
    () => prepareAccountsSection(paymentData, recipientAccount, recipientName, t, debitAccount),
    [paymentData, recipientAccount, recipientName, t, debitAccount],
  );
  const baseDataSection = useMemo(() => prepareBaseDataSection(paymentData, t), [paymentData, t]);
  const symbolsSection = useMemo(() => prepareSymbolsSection(paymentData, t), [paymentData, t]);

  return (
    <>
      <CommonItemSection items={accountSection} marginTop={24} />
      <CommonItemSection items={baseDataSection} />
      {symbolsSection.length > 0 && <CommonItemSection items={symbolsSection} />}
    </>
  );
};

const prepareAccountsSection = (
  paymentData: PaymentData,
  recipientAccount: AccountNumber,
  recipientName: string | undefined,
  t: TFunction,
  debitAccount?: Account,
) => {
  const section: Array<SectionItem> = [];
  if (debitAccount) {
    section.push({
      label: t('payment.domesticSummary.sectionItemLabelOriginAcc'),
      value: debitAccount.name,
      subValue: formatAccountNumber(debitAccount.number),
    });
  }
  section.push({
    label: t('payment.domesticSummary.sectionItemLabelRecipientAcc'),
    value: recipientName ?? formatAccountNumber(recipientAccount),
    subValue: recipientName ? formatAccountNumber(recipientAccount) : undefined,
  });
  return section;
};

const prepareBaseDataSection = ({ amount, message, note, dueDate }: PaymentData, t: TFunction) => {
  const section: Array<SectionItem> = [];
  const amountData = {
    label: t('payment.domesticSummary.sectionItemLabelAmount'),
    value: formatAmount(amount, 'Kč', true),
  };
  section.push(amountData);
  section.push({
    label: t('payment.domesticSummary.sectionItemLabelDueDate'),
    value: dueDate ? formatDate(dueDate) : t('payment.domesticSummary.dueDateNow'),
  });
  if (message) {
    section.push({
      label: t('payment.domesticSummary.sectionItemLabelMessage'),
      value: message,
      type: SectionItemType.Multiline,
    });
  }
  if (note) {
    section.push({
      label: t('payment.domesticSummary.sectionItemLabelNote'),
      value: note,
      type: SectionItemType.Multiline,
    });
  }
  return section;
};

const prepareSymbolsSection = (
  { symbolVariable, symbolConstant, symbolSpecific }: PaymentData,
  t: TFunction,
) => {
  const section: Array<SectionItem> = [];
  if (symbolVariable) {
    section.push({
      label: t('payment.domesticSummary.sectionItemLabelSymbolVariable'),
      value: symbolVariable.toString(),
    });
  }
  if (symbolConstant) {
    section.push({
      label: t('payment.domesticSummary.sectionItemLabelSymbolConstant'),
      value: symbolConstant.toString(),
    });
  }
  if (symbolSpecific) {
    section.push({
      label: t('payment.domesticSummary.sectionItemLabelSymbolSpecific'),
      value: symbolSpecific.toString(),
    });
  }
  return section;
};

export default PaymentDomesticSummarySections;
