import React from 'react';
import PasswordType, { isDigits, PasswordTypes } from '../../types/PasswordType';
import { useTranslation } from 'react-i18next';
import PickerModal from './PickerModal';
import { prepareTestID } from '../../utilities/utils';

interface PasswordTypeModalProps {
  visible: boolean;
  onDismiss: () => void;
  onSubmit: (type: PasswordType) => void;
}

function PasswordTypeModal({ visible, onDismiss, onSubmit }: PasswordTypeModalProps) {
  const { t } = useTranslation('resources');

  const items = PasswordTypes.map((type) => {
    return {
      item: type,
      label: t('password.type', {
        context: type.type,
        count: isDigits(type) ? type.length : undefined,
        postProcess: 'interval',
      }),
      testID: `password_type_${
        type.type === 'Alphanumeric' ? 'alphanumeric' : `${type.type.length}_digit`
      }`,
    };
  });

  return (
    <PickerModal
      title={t('password.type')}
      items={items}
      visible={visible}
      onDismiss={onDismiss}
      onSubmit={onSubmit}
      testID={prepareTestID('password_type_modal')}
    />
  );
}

export default PasswordTypeModal;
