import React from 'react';
import {
  ColorValue,
  ImageSourcePropType,
  Pressable,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { Text } from 'react-native-paper';
import { colors } from '../../../../../assets';
import CommonAvatar from '../../../../../components/CommonAvatar';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import { initials } from '../../../../../utilities/formatUtils';

export interface PaymentActionProps {
  text?: string;
  avatar?: ImageSourcePropType;
  icon?: IconSource;
  color?: string;
  iconColor?: string;
  containerStyle?: StyleProp<ViewStyle>;
  textColor?: ColorValue | undefined;
  textVariant?: 'bodySmall' | 'labelMedium' | undefined;
  textStyle?: StyleProp<TextStyle>;
  onPress: () => void;
  testID?: string;
}

const PaymentAction = ({
  text,
  avatar,
  icon,
  color,
  iconColor,
  containerStyle,
  textColor,
  textVariant,
  textStyle,
  onPress,
  testID,
}: PaymentActionProps) => {
  return (
    <Pressable onPress={onPress} style={[styles.container, containerStyle]} testID={testID}>
      <CommonAvatar
        size={48}
        backgroundColor={color}
        color={iconColor}
        icon={icon}
        avatar={avatar}
        text={text ? initials(text) : undefined}
      />
      <Text
        variant={textVariant ?? 'labelMedium'}
        style={[styles.text, textStyle, { color: textColor }]}
        numberOfLines={2}
        ellipsizeMode={'tail'}
        testID={testID ? `${testID}_text` : undefined}
      >
        {text ? text.replace(' ', '\n') : ''}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    marginEnd: 16,
    backgroundColor: colors.ui_base_bg,
    borderRadius: 8,
    flexGrow: 1,
    alignItems: 'center',
  },
  text: {
    marginTop: 14,
    textAlign: 'center',
    maxWidth: 100,
  },
});

export default PaymentAction;
