import { TFunction } from 'i18next';
import { DateTime } from 'luxon';

const LOCALE_CS = 'cs-CZ';

export function formatPhoneNumber(phoneNumber: string): string {
  return phoneNumber.trim().replaceAll(/(\d{3})(?!$)/g, '$1 ');
}

export function capitalize(str: string): string {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

export function initials(str: string): string {
  return str
    .replaceAll(/(\p{L})\p{L}+/gu, '$1')
    .replaceAll(/(\P{L})/gu, '')
    .slice(undefined, 2)
    .toUpperCase();
}

export function formatAccountNumber({
  prefix,
  number,
  bankCode,
}: {
  prefix?: string | null;
  number: string;
  bankCode: string;
}) {
  return prefix ? `${prefix}-${number}/${bankCode}` : `${number}/${bankCode}`;
}

export function renameCurrency(currency: string) {
  return currency === 'CZK' ? 'Kč' : currency;
}

export function formatAmount(
  amount: number | null | undefined,
  currency?: string | null | undefined,
  forceFraction?: boolean,
): string {
  if (amount === undefined || amount === null) return '';

  const amountString = amount.toLocaleString(LOCALE_CS, {
    minimumFractionDigits: forceFraction ? 2 : undefined,
    maximumFractionDigits: 2,
  });

  return currency ? `${amountString} ${renameCurrency(currency)}` : amountString;
}

export function splitAmount(amount: number, currency: string): { whole: string; fraction: string } {
  const whole = Math.trunc(amount);
  const fraction = Math.abs(amount - whole)
    .toLocaleString(LOCALE_CS, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .slice(1);

  return {
    whole: whole.toLocaleString(LOCALE_CS),
    fraction: `${fraction} ${renameCurrency(currency)}`,
  };
}

export const formatDate = (date: DateTime, withYear = true, withTime = false) => {
  return date.toLocaleString(
    {
      day: 'numeric',
      month: 'numeric',
      year: withYear ? 'numeric' : undefined,
      hour: withTime ? '2-digit' : undefined,
      minute: withTime ? '2-digit' : undefined,
    },
    { locale: LOCALE_CS },
  );
};

export function formatTransactionDate(
  date: DateTime,
  t: TFunction<'resources'>,
  language: string,
): string {
  const today = DateTime.now().startOf('day');
  const withYear = date.year !== today.year;

  const weekDay = date.toLocaleString({ weekday: 'long' }, { locale: language });
  const dateString = formatDate(date, withYear);

  const diff = date.startOf('day').diff(today, 'days');
  switch (diff.days) {
    case -1:
      return `${t('accountDetail.yesterday')} ${dateString}`;
    case 0:
      return `${t('accountDetail.today')} ${dateString}`;
    case 1:
      return `${t('accountDetail.tomorrow')} ${dateString}`;
    default:
      return `${capitalize(weekDay)} ${dateString}`;
  }
}

export function formatTransactionMonth(date: DateTime, language: string): string {
  const withYear = date.year !== DateTime.now().year;

  return capitalize(
    date.toLocaleString(
      { month: 'long', year: withYear ? 'numeric' : undefined },
      { locale: language },
    ),
  );
}
