import React, { useCallback, useState } from 'react';
import { TextInput } from 'react-native-paper';
import { useTheme } from '../../utilities/reactUtils';
import { renameCurrency } from '../../utilities/formatUtils';
import { StyleProp, ViewStyle } from 'react-native';

const AmountInput = ({
  amount,
  label,
  placeholder,
  currency,
  autoFocus,
  onAmount,
  testID,
  style,
}: {
  amount: number | null | undefined;
  label: string;
  placeholder?: string;
  currency?: string;
  autoFocus?: boolean | undefined;
  onAmount: (amount: number | undefined) => void;
  testID?: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const { colors } = useTheme();
  const [value, setValue] = useState(amount?.toLocaleString('cs', { useGrouping: false }) ?? '');

  const handleAmount = useCallback(
    (value: string) => {
      const filtered = value.replace(/[^0-9,.]/g, '').replace('.', ',');
      setValue(filtered);

      if (filtered.length > 0 && filtered.match(/^\d{1,8}([.,]\d{0,2})?$/)) {
        const numberValue = Number.parseFloat(filtered.replace(',', '.'));
        onAmount(isNaN(numberValue) ? undefined : numberValue);
      } else {
        onAmount(undefined);
      }
    },
    [onAmount],
  );

  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      activeUnderlineColor={colors.secondary}
      onChangeText={handleAmount}
      value={value}
      textContentType="none"
      keyboardType="numeric"
      right={currency && <TextInput.Affix text={renameCurrency(currency)} />}
      maxLength={12}
      autoFocus={autoFocus}
      testID={testID}
      style={style}
    />
  );
};

export default AmountInput;
