import React, { useMemo, useState } from 'react';
import { Keyboard, ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import { TextInput } from 'react-native-paper';
import { onLayoutMeasure } from '../../utilities/reactUtils';
import { colors } from '../../assets';
import { Menu, MenuOption, MenuOptions, MenuTrigger, renderers } from 'react-native-popup-menu';

interface DropDownPickerProps<T> {
  label: string;
  value?: string;
  menu: MenuItem<T>[];
  style?: StyleProp<ViewStyle>;
  onPicked: (value: T) => void;
  testID: string;
}

export type MenuItem<T> = {
  label: string;
  value: T;
};

function DropDownPicker<T>({
  label,
  value,
  menu,
  style,
  onPicked,
  testID,
}: DropDownPickerProps<T>) {
  const [inputSize, setInputSize] = useState({ width: 0, height: 0 });

  const menuItems = useMemo(
    () =>
      menu.map((item, index) => (
        <MenuOption
          key={index}
          value={item.value}
          text={item.label}
          customStyles={{ optionTouchable: { testID: `${testID}_menu_item_${index}` } }}
        />
      )),
    [menu, testID],
  );

  const triggerStyles = {
    triggerTouchable: {
      underlayColor: colors.ui_base_bg,
    },
  };

  const optionsStyles = {
    optionsContainer: {
      width: inputSize.width,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 9,
    },

    optionWrapper: {
      marginTop: 8,
      marginBottom: 8,
      marginLeft: 12,
      marginRight: 12,
    },
    optionTouchable: {
      activeOpacity: 100,
    },
    optionText: {},
  };

  return (
    <View style={style} onLayout={onLayoutMeasure(setInputSize)}>
      <Menu
        renderer={renderers.NotAnimatedContextMenu}
        onSelect={(value: T) => onPicked(value)}
        onOpen={() => Keyboard.dismiss()}
      >
        <MenuTrigger customStyles={triggerStyles} testID={`${testID}_trigger`}>
          <View pointerEvents="none">
            <TextInput
              label={label}
              editable={false}
              value={value}
              right={<TextInput.Icon icon="menu-down" />}
              testID={`${testID}_input_value`}
            />
          </View>
        </MenuTrigger>
        <MenuOptions customStyles={optionsStyles}>
          <ScrollView style={{ maxHeight: 285 }} testID={`${testID}_options`}>
            {menuItems}
          </ScrollView>
        </MenuOptions>
      </Menu>
    </View>
  );
}

export default DropDownPicker;
