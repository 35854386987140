const types = ['Digits', 'Alphanumeric'] as const;
type Type = typeof types[number];

interface PasswordType {
  readonly type: Type;
}

interface Digits extends PasswordType {
  readonly length: number;
}

const FourDigits: Digits = { type: 'Digits', length: 4 };
const SixDigits: Digits = { type: 'Digits', length: 6 };
const EightDigits: Digits = { type: 'Digits', length: 8 };
const Alphanumeric: PasswordType = { type: 'Alphanumeric' };

export const PasswordTypes: PasswordType[] = [FourDigits, SixDigits, EightDigits];
export const DefaultPasswordType = SixDigits;

function isPasswordType(object: unknown): object is PasswordType {
  return (
    object != null &&
    typeof object === 'object' &&
    !Array.isArray(object) &&
    Object.hasOwn(object, 'type') &&
    typeof (object as { type: unknown }).type === 'string' &&
    types.find((type) => type == (object as { type: string }).type) !== undefined &&
    ((object as PasswordType).type == 'Alphanumeric' ||
      (Object.hasOwn(object, 'length') &&
        typeof (object as { length: unknown }).length === 'number'))
  );
}

export function isDigits(passwordType: PasswordType): passwordType is Digits {
  return (passwordType as Digits).length !== undefined;
}

export function parsePasswordType(json: string | null): PasswordType {
  if (json != null) {
    const o: unknown = JSON.parse(json);
    if (isPasswordType(o)) return o;
  }
  return DefaultPasswordType;
}

export default PasswordType;
