import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Button, Text } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../../types';
import { useTheme } from '../../../../utilities/reactUtils';
import { usePaymentDomestic } from '../../context/PaymentDomesticContext';
import { useTranslation } from 'react-i18next';
import PaymentModalScreen from '../../components/shared/PaymentModalScreen';
import { colors, styles } from '../../../../assets';
import { StyleSheet, View, Vibration, Linking } from 'react-native';
import {
  BarCodeEvent,
  BarCodeScanner,
  Constants,
  requestPermissionsAsync,
} from 'expo-barcode-scanner';
import {
  getAccountFromQr,
  isQrPayment,
  getDomesticPaymentDataFromQr,
} from '../../../../utilities/paymentUtils';
import * as ImagePicker from 'expo-image-picker';

type NavProp = StackNavigationProp<RootStackParamList, 'PaymentDomesticQR'>;

enum QrPaymentError {
  MISSING_PERMISSIONS,
  NOT_QR_PAYMENT,
  INVALID_DATA,
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
const QR_TYPE: string = Constants.BarCodeType.qr;

const PaymentDomesticQrScreen = () => {
  const { t } = useTranslation();
  const { fonts } = useTheme();
  const navigation = useNavigation<NavProp>();

  const { setRecipientAccount, setPaymentData } = usePaymentDomestic();
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  const [error, setError] = useState<QrPaymentError | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    void requestPermissionsAsync()
      .then((r) => {
        const isGranted = r.status == 'granted';
        setHasCameraPermission(isGranted);
        if (!isGranted) {
          setError(QrPaymentError.MISSING_PERMISSIONS);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const processScannedData = useCallback(
    (data: string) => {
      if (!isQrPayment(data)) {
        setError(QrPaymentError.NOT_QR_PAYMENT);
        setLoading(false);
        return;
      }
      const acc = getAccountFromQr(data);
      if (acc) {
        setRecipientAccount(acc);
        setPaymentData(getDomesticPaymentDataFromQr(data));
        navigation.navigate('PaymentDomestic', { allowBack: false });
        return;
      }
      setError(QrPaymentError.INVALID_DATA);
      setLoading(false);
    },
    [navigation, setPaymentData, setRecipientAccount],
  );

  const secondaryButtonText = useMemo(() => {
    if (!hasCameraPermission) {
      return t('payment.domesticQr.buttonSettings');
    }
    return t('payment.domesticQr.buttonScanAgain');
  }, [hasCameraPermission, t]);

  const errorText = useMemo(() => {
    switch (error) {
      case QrPaymentError.INVALID_DATA:
        return t('payment.domesticQr.errorInvalidData');
      case QrPaymentError.NOT_QR_PAYMENT:
        return t('payment.domesticQr.errorNotQrPayment');
      case QrPaymentError.MISSING_PERMISSIONS:
        return t('payment.domesticQr.missingPermission');
      default:
        return undefined;
    }
  }, [error, t]);

  const onClose = useCallback(() => {
    navigation.navigate('Payment');
  }, [navigation]);

  const handleBarCodeScanned = useCallback(
    ({ data }: BarCodeEvent) => {
      Vibration.vibrate();
      setLoading(true);
      processScannedData(data);
    },
    [processScannedData],
  );

  const pickImage = useCallback(async () => {
    setLoading(true);
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      allowsMultipleSelection: false,
      exif: false,
      quality: 1,
    });

    if (!result.canceled) {
      if (result.assets.length != 0) {
        void BarCodeScanner.scanFromURLAsync(result.assets[0].uri, [QR_TYPE])
          .then(
            (result) => {
              if (result.length != 0) {
                processScannedData(result[0].data);
              } else {
                setError(QrPaymentError.NOT_QR_PAYMENT);
              }
            },
            () => {
              setError(QrPaymentError.INVALID_DATA);
            },
          )
          .finally(() => setLoading(false));
      } else setLoading(false);
    } else {
      setLoading(false);
    }
  }, [processScannedData]);

  const onButtonPress = useCallback(() => {
    if (loading) return;
    setLoading(true);
    setError(undefined);
    void pickImage();
  }, [loading, pickImage]);

  const onSecondaryButtonPress = useCallback(() => {
    if (loading) return;
    setError(undefined);
    if (!hasCameraPermission) {
      void Linking.openSettings();
      onClose();
    }
  }, [hasCameraPermission, loading, onClose]);

  return (
    <PaymentModalScreen
      contentContainerStyle={{ flex: 1 }}
      headerProps={{ onClosePress: onClose, headerText: t('payment.domesticQr.title') }}
      content={
        <View style={style.container}>
          {loading ? (
            <ActivityIndicator size="small" color={colors.ui_black} animating={true} />
          ) : (
            <>
              {error || !hasCameraPermission ? (
                <Text variant={'titleSmall'} style={style.text}>
                  {errorText ?? ''}
                </Text>
              ) : (
                <BarCodeScanner
                  barCodeTypes={[QR_TYPE]}
                  onBarCodeScanned={handleBarCodeScanned}
                  style={{ flexGrow: 1 }}
                />
              )}
            </>
          )}
        </View>
      }
      footerContent={
        <>
          {error != undefined && (
            <Button
              onPress={onSecondaryButtonPress}
              disabled={loading}
              loading={false}
              mode="contained"
              theme={{
                roundness: 8,
                colors: { primary: colors.ui_color_secondary, onPrimary: colors.ui_white },
              }}
              style={{ ...styles.formActionButton, marginBottom: 8 }}
              labelStyle={fonts.labelSmall}
            >
              {secondaryButtonText}
            </Button>
          )}
          <Button
            onPress={onButtonPress}
            disabled={loading}
            loading={false}
            mode="contained"
            theme={{
              roundness: 8,
              colors: { primary: colors.ui_turq_prim, onPrimary: colors.ui_black },
            }}
            style={styles.formActionButton}
            labelStyle={fonts.labelSmall}
            contentStyle={{ width: '100%', height: '100%' }}
          >
            {t('payment.domesticQr.buttonGallery')}
          </Button>
        </>
      }
    />
  );
};

const style = StyleSheet.create({
  text: { alignSelf: 'center', padding: 16, textAlign: 'center' },
  container: { flexGrow: 1, justifyContent: 'center' },
});

export default PaymentDomesticQrScreen;
