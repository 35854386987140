/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

//RFR - https://github.com/expo/expo/issues/18485

import 'expo/build/Expo.fx';
import { Platform } from 'react-native';
import { registerRootComponent } from 'expo';
import { activateKeepAwake } from 'expo-keep-awake';
import { createRoot } from 'react-dom/client';
import App from './src/App';

if (__DEV__) {
  activateKeepAwake();
}

if (Platform.OS === 'web') {
  const root = createRoot(document.getElementById('root') ?? document.getElementById('main'));
  root.render(<App />);
} else {
  registerRootComponent(App);
}
