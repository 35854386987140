import React from 'react';
import { FlatList } from 'react-native';
import { appInfoData, AppInfo } from '../../../utilities/appInfoData';
import { List } from 'react-native-paper';

function Item({ appInfo }: { appInfo: AppInfo }) {
  return <List.Item title={appInfo.key} description={appInfo.data} />;
}

function AppInfoScreen() {
  return (
    <FlatList
      data={appInfoData}
      renderItem={({ item }) => <Item appInfo={item} />}
      keyExtractor={(item) => item.key}
      contentInsetAdjustmentBehavior="automatic"
    />
  );
}

export default AppInfoScreen;
