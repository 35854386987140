import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const AcceptTerms = (props: SvgProps) => (
  <Svg width={204} height={146} viewBox="0 0 204 146" fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        d="M13.521 145.399H190.47c.09 0 .176-.035.239-.099a.338.338 0 0 0-.239-.577H13.521a.34.34 0 0 0-.338.338.337.337 0 0 0 .338.338Z"
        fill="#CCC"
      />
      <Path
        d="M47.518 63.483C37.169 53.295 32.798 42.667 35.21 33.557c3.096-11.692 16.846-19.776 37.726-22.179l.077.67c-20.59 2.37-34.13 10.273-37.15 21.681-2.347 8.867 1.96 19.263 12.128 29.273l-.473.48ZM147.364 99.645c-12.916 0-26.951-1.92-40.892-5.61-17.24-4.564-33.046-11.453-45.712-19.922l.375-.561c12.605 8.428 28.342 15.285 45.51 19.83 15.515 4.108 31.146 6.011 45.198 5.509l.025.674c-1.484.053-2.986.08-4.504.08ZM176.994 95.4l-.216-.639c11.551-3.905 18.776-10.197 20.893-18.194 2.692-10.167-3.156-21.966-16.465-33.222-13.377-11.313-32.667-20.7-54.317-26.431l.173-.653c21.745 5.757 41.129 15.192 54.58 26.57 13.519 11.433 19.444 23.475 16.682 33.908-2.178 8.227-9.554 14.68-21.33 18.661ZM70.926 99.927C45.548 88.671 25.992 74.068 15.861 58.808l.562-.373C26.483 73.589 45.937 88.105 71.2 99.31l-.274.617ZM160.155 119.426c-2.618 0-5.28-.058-7.987-.175l.029-.674c20.202.874 38.027-1.543 51.551-6.993l.252.626c-11.797 4.754-26.834 7.216-43.845 7.216ZM145.43 11.983a240 240 0 0 0-12.823-3.78C107.778 1.632 83.371-.702 62.024 1.457l-.068-.671c21.427-2.167 45.917.173 70.823 6.766a240.518 240.518 0 0 1 12.86 3.79l-.209.643Z"
        fill="#F0F0F0"
      />
      <Path
        d="M31.657 64.495C21.308 54.308 16.937 43.68 19.349 34.569c3.096-11.692 16.846-19.775 37.726-22.178l.077.67c-20.59 2.37-34.13 10.272-37.15 21.68-2.347 8.867 1.96 19.263 12.128 29.273l-.473.481ZM131.503 100.657c-12.915 0-26.951-1.92-40.891-5.61-17.24-4.564-33.047-11.453-45.713-19.922l.376-.56c12.604 8.427 28.34 15.285 45.51 19.83 15.514 4.107 31.145 6.01 45.197 5.508l.025.674c-1.484.054-2.985.08-4.504.08ZM161.133 96.413l-.216-.64c11.551-3.905 18.776-10.196 20.894-18.194 2.691-10.167-3.156-21.965-16.465-33.221-13.378-11.313-32.667-20.7-54.318-26.432l.173-.652c21.745 5.756 41.129 15.192 54.58 26.569 13.52 11.433 19.444 23.476 16.682 33.909-2.178 8.227-9.554 14.68-21.33 18.661ZM55.065 100.939C29.687 89.684 10.131 75.081 0 59.821l.562-.374c10.061 15.155 29.514 29.671 54.777 40.875l-.274.617ZM144.294 120.439c-2.617 0-5.28-.059-7.987-.175l.029-.675c20.202.875 38.027-1.543 51.551-6.993l.252.627c-11.797 4.753-26.833 7.216-43.845 7.216ZM129.569 12.996a239.735 239.735 0 0 0-12.824-3.78C91.917 2.643 67.51.31 46.163 2.47l-.068-.672c21.427-2.166 45.917.173 70.823 6.767a240.454 240.454 0 0 1 12.86 3.79l-.209.642Z"
        fill="#F0F0F0"
      />
      <Path
        d="M11.504 46.417c4.85-5.438 12.587-8.752 19.598-6.768a33.573 33.573 0 0 0-13.1 18.965c-.758 2.96-1.292 6.332-3.755 8.142-1.532 1.127-3.562 1.396-5.446 1.14-1.884-.257-3.665-.99-5.423-1.716l-.473.04c1.36-7.158 3.749-14.365 8.599-19.803Z"
        fill="#F2F2F2"
      />
      <Path
        d="M31.08 39.8a28.696 28.696 0 0 0-15.049 9.232 12.358 12.358 0 0 0-2.083 3.264 7.087 7.087 0 0 0-.419 3.706c.158 1.17.454 2.348.325 3.536a4.34 4.34 0 0 1-1.84 3.035c-1.23.9-2.712 1.302-4.174 1.646-1.623.382-3.315.753-4.638 1.831-.16.131-.353-.125-.193-.256 2.302-1.877 5.5-1.635 8.048-2.999 1.188-.636 2.192-1.634 2.436-3.003.213-1.198-.091-2.413-.26-3.596a7.562 7.562 0 0 1 .273-3.64 11.422 11.422 0 0 1 1.95-3.331 27.839 27.839 0 0 1 6.483-5.805 29.205 29.205 0 0 1 9.104-3.938c.2-.049.237.27.038.319Z"
        fill="#fff"
      />
      <Path
        d="M17.208 47.483a4.306 4.306 0 0 1-.133-5.574c.13-.16.386.035.255.196a3.987 3.987 0 0 0 .134 5.184c.138.154-.119.346-.256.194ZM13.372 55.42a8.298 8.298 0 0 0 5.765-1.826c.16-.13.354.126.194.256a8.631 8.631 0 0 1-6.003 1.888c-.207-.015-.162-.333.044-.318ZM24.799 42.017a2.437 2.437 0 0 0 1.966 1.217c.207.011.162.33-.044.318a2.73 2.73 0 0 1-2.178-1.34.166.166 0 0 1 .03-.226.162.162 0 0 1 .226.032Z"
        fill="#fff"
      />
      <Path
        d="M41.055 62.224c-.13-.004-.259-.008-.39-.009a32.093 32.093 0 0 0-5.197.303 33.837 33.837 0 0 0-12.164 4.154 32.855 32.855 0 0 0-4.127 2.809c-1.8 1.435-3.636 3.135-5.742 3.87-.219.08-.442.145-.67.197l-9.773-6.753-.045-.078-.403-.251c.063-.059.13-.118.194-.176l.112-.1.072-.066a.46.46 0 0 1 .023-.02c.022-.022.044-.04.064-.058.374-.332.75-.661 1.13-.989.002-.002.002-.002.006-.002a48.312 48.312 0 0 1 9.412-6.471c.102-.052.205-.106.31-.154a31.357 31.357 0 0 1 4.746-1.914 27.736 27.736 0 0 1 2.719-.688 23.05 23.05 0 0 1 7.201-.276c4.784.571 9.333 2.666 12.298 6.385.076.096.15.19.224.287Z"
        fill="#F2F2F2"
      />
      <Path
        d="M40.947 62.333a28.695 28.695 0 0 0-17.574-1.69 12.356 12.356 0 0 0-3.629 1.351 7.086 7.086 0 0 0-2.565 2.708c-.579 1.029-1.051 2.148-1.87 3.018a4.339 4.339 0 0 1-3.296 1.316c-1.524-.023-2.95-.593-4.324-1.199-1.525-.672-3.1-1.395-4.805-1.33-.207.008-.207-.313 0-.321 2.967-.113 5.376 2.006 8.23 2.45 1.332.209 2.735.016 3.754-.93.892-.828 1.38-1.982 1.957-3.029a7.562 7.562 0 0 1 2.41-2.74 11.425 11.425 0 0 1 3.563-1.486 27.839 27.839 0 0 1 8.67-.732 29.203 29.203 0 0 1 9.64 2.336c.19.082.027.359-.161.278Z"
        fill="#fff"
      />
      <Path
        d="M25.245 60.114a4.305 4.305 0 0 1 3.25-4.53c.2-.05.286.26.085.31a3.986 3.986 0 0 0-3.014 4.22c.018.205-.303.204-.321 0ZM17.403 64.141a8.298 8.298 0 0 0 5.703 2.014c.206-.007.206.314 0 .321a8.631 8.631 0 0 1-5.93-2.107c-.156-.136.072-.363.227-.228ZM34.597 60.32a2.437 2.437 0 0 0 .838 2.156c.158.133-.07.36-.227.227a2.732 2.732 0 0 1-.932-2.382.166.166 0 0 1 .16-.16.161.161 0 0 1 .16.16Z"
        fill="#fff"
      />
      <Path
        d="M201.55 90.995c-2.74-6.751-8.913-12.473-16.183-12.961a33.572 33.572 0 0 1 5.964 22.264c-.281 3.043-.911 6.399.799 8.931 1.065 1.576 2.886 2.512 4.747 2.903 1.861.392 3.785.3 5.684.207l.432.198c1.125-7.199 1.297-14.79-1.443-21.542Z"
        fill="#F2F2F2"
      />
      <Path
        d="M185.336 78.184a28.693 28.693 0 0 1 11.071 13.753c.485 1.204.777 2.478.866 3.773a7.093 7.093 0 0 1-.852 3.632c-.541 1.049-1.217 2.059-1.494 3.221a4.337 4.337 0 0 0 .712 3.477c.858 1.26 2.117 2.138 3.378 2.953 1.401.905 2.87 1.823 3.753 3.283.107.177.376.001.269-.176-1.538-2.541-4.632-3.389-6.572-5.529-.906-.999-1.516-2.276-1.285-3.648.201-1.2.897-2.242 1.454-3.299a7.56 7.56 0 0 0 .965-3.52 11.432 11.432 0 0 0-.717-3.793 27.853 27.853 0 0 0-4.154-7.646 29.207 29.207 0 0 0-7.251-6.769c-.172-.113-.314.175-.143.288Z"
        fill="#fff"
      />
      <Path
        d="M195.82 90.082a4.303 4.303 0 0 0 1.998-5.205c-.069-.194-.374-.096-.305.099a3.989 3.989 0 0 1-1.869 4.837c-.182.098-.004.366.176.269ZM196.766 98.846a8.296 8.296 0 0 1-4.817-3.657c-.107-.176-.376 0-.268.176a8.63 8.63 0 0 0 5.019 3.796c.2.055.264-.26.066-.315ZM190.508 82.383a2.442 2.442 0 0 1-2.262.485c-.198-.059-.262.256-.065.314a2.73 2.73 0 0 0 2.503-.53.17.17 0 0 0 .067-.103.169.169 0 0 0-.021-.12.162.162 0 0 0-.222-.046Z"
        fill="#fff"
      />
      <Path
        d="M168.406 95.95c.123.04.246.08.369.123a32.086 32.086 0 0 1 4.793 2.032c.12.06.241.124.359.187a33.829 33.829 0 0 1 9.701 7.814 32.754 32.754 0 0 1 2.944 4.032c1.212 1.957 2.37 4.175 4.107 5.575.179.148.368.285.564.41l11.475-3.075c.023-.02.046-.037.069-.058l.464-.101-.124-.231-.072-.132-.045-.086-.016-.027c-.013-.027-.028-.051-.04-.076-.241-.438-.485-.875-.733-1.311l-.004-.004a48.322 48.322 0 0 0-6.69-9.258c-.078-.083-.157-.168-.241-.25a31.347 31.347 0 0 0-3.826-3.397 27.666 27.666 0 0 0-2.329-1.561 23.055 23.055 0 0 0-6.69-2.68c-4.698-1.07-9.686-.626-13.728 1.88-.104.064-.205.127-.307.194Z"
        fill="#F2F2F2"
      />
      <Path
        d="M168.471 96.089a28.696 28.696 0 0 1 17.119 4.315 12.368 12.368 0 0 1 2.964 2.492 7.093 7.093 0 0 1 1.506 3.413c.199 1.163.268 2.376.747 3.471a4.325 4.325 0 0 0 1.08 1.458c.458.405.997.708 1.582.889 1.443.491 2.977.433 4.474.324 1.663-.12 3.389-.272 4.974.363.192.076.3-.226.108-.303-2.757-1.103-5.738.082-8.576-.458-1.324-.252-2.581-.905-3.223-2.139-.561-1.079-.633-2.33-.825-3.509a7.55 7.55 0 0 0-1.348-3.392 11.441 11.441 0 0 0-2.857-2.597 27.842 27.842 0 0 0-7.919-3.603 29.206 29.206 0 0 0-9.865-1.04c-.206.014-.146.33.059.316Z"
        fill="#fff"
      />
      <Path
        d="M184.005 99.276a4.308 4.308 0 0 0-1.538-5.358c-.172-.114-.357.149-.185.263a3.983 3.983 0 0 1 1.421 4.987c-.086.188.217.295.302.108ZM190.037 105.705a8.296 8.296 0 0 1-6.048-.02c-.192-.076-.3.227-.108.303a8.636 8.636 0 0 0 6.293.008c.193-.076.055-.365-.137-.291ZM175.128 96.328a2.44 2.44 0 0 1-1.514 1.748c-.193.073-.055.363.138.29a2.733 2.733 0 0 0 1.678-1.93.166.166 0 0 0-.097-.205.16.16 0 0 0-.205.097Z"
        fill="#fff"
      />
      <Path d="M57.276 39.1a2.7 2.7 0 1 0 0-5.399 2.7 2.7 0 0 0 0 5.4Z" fill="#FD6584" />
      <Path
        d="M160.614 65.736a2.7 2.7 0 1 0-5.328-.866 2.7 2.7 0 0 0 5.328.866ZM57.166 8.72a2.7 2.7 0 1 0 0-5.4 2.7 2.7 0 0 0 0 5.4Z"
        fill="#E6E6E6"
      />
      <Path
        d="M137.721 37.789h-.771v-21.11A12.219 12.219 0 0 0 124.732 4.46H80.008A12.218 12.218 0 0 0 67.79 16.679V132.49a12.217 12.217 0 0 0 12.218 12.218h44.724a12.217 12.217 0 0 0 12.218-12.218V52.815h.771V37.79Z"
        fill="#3F3D56"
      />
      <Path
        d="M134.349 16.763v115.642a9.131 9.131 0 0 1-2.671 6.451 9.129 9.129 0 0 1-6.45 2.672H80.285a9.122 9.122 0 0 1-9.123-9.122V16.763a9.125 9.125 0 0 1 9.122-9.123h5.455a4.335 4.335 0 0 0 4.013 5.972h25.623a4.333 4.333 0 0 0 3.596-1.918 4.333 4.333 0 0 0 .417-4.054h5.838a9.122 9.122 0 0 1 9.123 9.122v.001Z"
        fill="#F2F2F2"
      />
      <Path
        d="M88.508 18.679H74.567v.624h13.942v-.624ZM109.726 18.679H95.784v.624h13.942v-.624Z"
        fill="#fff"
      />
      <Path
        d="M124.611 93.4H79.165a1.166 1.166 0 1 1 0-2.332h45.446a1.169 1.169 0 0 1 1.167 1.166 1.177 1.177 0 0 1-.342.824 1.167 1.167 0 0 1-.825.342ZM124.611 101.512H79.165a1.164 1.164 0 0 1-.824-1.99c.218-.219.515-.342.824-.342h45.446a1.167 1.167 0 1 1 0 2.332ZM124.611 109.625H79.165a1.165 1.165 0 0 1 0-2.332h45.446a1.169 1.169 0 0 1 1.167 1.166 1.166 1.166 0 0 1-1.167 1.166Z"
        fill="#CCC"
      />
      <Path
        d="M130.944 18.679h-13.942v.624h13.942v-.624ZM88.508 130.695H74.567v.624h13.942v-.624ZM109.726 130.695H95.784v.624h13.942v-.624ZM130.944 130.695h-13.942v.624h13.942v-.624Z"
        fill="#fff"
      />
      <Path
        d="M102.755 77.426c11.671 0 21.133-9.461 21.133-21.133 0-11.671-9.462-21.133-21.133-21.133s-21.133 9.462-21.133 21.133c0 11.672 9.462 21.133 21.133 21.133Z"
        fill="#D1D3D4"
      />
      <Path
        d="M102.755 76.51c10.648 0 19.28-8.632 19.28-19.28 0-10.648-8.632-19.28-19.28-19.28-10.648 0-19.28 8.632-19.28 19.28 0 10.648 8.632 19.28 19.28 19.28Z"
        fill="#6C63FF"
      />
      <Path
        d="M101.072 64.786c-.042 0-.084 0-.126-.003a2.567 2.567 0 0 1-1.903-.989l-3.039-3.906a2.573 2.573 0 0 1 .45-3.607l.11-.084a2.574 2.574 0 0 1 3.607.45 1.539 1.539 0 0 0 1.143.593 1.512 1.512 0 0 0 1.195-.48l6.17-6.512a2.573 2.573 0 0 1 3.633-.098l.1.095a2.576 2.576 0 0 1 .099 3.633l-9.574 10.106a2.565 2.565 0 0 1-1.865.802Z"
        fill="#fff"
      />
      <Path
        d="M68.99 26.718h-2.497v13.417h2.496V26.718ZM68.99 41.695h-2.497v7.8h2.496v-7.8ZM68.99 51.055h-2.497v7.8h2.496v-7.8Z"
        fill="#3F3D56"
      />
      <Path d="M90.52 10.804a1.248 1.248 0 1 0 0-2.496 1.248 1.248 0 0 0 0 2.496Z" fill="#fff" />
      <Path
        opacity={0.16}
        d="M102.755 73.839a19.277 19.277 0 0 1-19.227-17.923c-.03.449-.052.9-.052 1.357a19.28 19.28 0 1 0 38.559 0c0-.457-.022-.908-.053-1.357a19.278 19.278 0 0 1-19.227 17.923Z"
        fill="#231F20"
      />
      <Path
        d="m129.936 72.787 15.289.624 1.872-10.297-13.105-4.056-4.056 13.73ZM135.715 50.485l.147-4.864-7.409-1.858-13.341-3.892a2.79 2.79 0 0 0-2.516-1.91 2.785 2.785 0 1 0 1.029 5.315l12.97 5.33 9.12 1.88Z"
        fill="#FFB8B8"
      />
      <Path
        d="m130.94 50.451 4.168-5.945.104.014c.066.008 6.603.883 10.888 2.16a3.422 3.422 0 0 1 2.219 1.843 3.593 3.593 0 0 1-.5 3.908 3.847 3.847 0 0 1-3.95 1.312l-12.929-3.292Z"
        fill="#6C63FF"
      />
      <Path
        d="m133.58 140.587-4.24-.001-2.017-16.354h6.258l-.001 16.355ZM166.595 138.751l-4.192.634-4.44-15.87 5.497-.935 3.135 16.171Z"
        fill="#FFB8B8"
      />
      <Path
        d="m145.849 70.29-14.665-2.495-.521 1.075a49.792 49.792 0 0 0-4.88 18.496 267.86 267.86 0 0 0 .645 42.937l8.049-.05a190.09 190.09 0 0 1 3.41-41.52 113.79 113.79 0 0 0 7.74 21.728c2.812 5.948 8.598 12.823 11.411 18.771l8.646-1.037c-2.271-6.007-6.323-14.778-8.595-20.785-3.247-8.588-4.714-14.411-7.962-23 .185-1.614.222-3.242.108-4.863-.151-2.702-.713-5.944-2.356-8.106l-1.03-1.15Z"
        fill="#2F2E41"
      />
      <Path
        d="M148.115 65.814c.732 1.369-1.007 4.205-2.266 4.477l-14.502-4.688 7.302-21.16 3.674.668 5.876.99 2.084 1.895a3.66 3.66 0 0 1 1.011 3.867l-3.179 13.951Z"
        fill="#6C63FF"
      />
      <Path
        d="m138.132 57.637-4.103-2.618-5.39 5.413-10.175 9.466a2.784 2.784 0 1 0 2.163 3.02l11.221-8.41 6.284-6.871Z"
        fill="#FFB8B8"
      />
      <Path
        d="m135.657 61.72-2.972-6.624.065-.083c.041-.051 4.14-5.218 7.43-8.246a3.438 3.438 0 0 1 2.721-.96 3.595 3.595 0 0 1 3.1 2.43 3.84 3.84 0 0 1-.895 4.064l-9.449 9.42Z"
        fill="#6C63FF"
      />
      <Path
        d="M144.901 43.03a6.45 6.45 0 1 0 0-12.9 6.45 6.45 0 0 0-6.449 6.45 6.45 6.45 0 0 0 6.449 6.45Z"
        fill="#FFB8B8"
      />
      <Path
        d="m150.111 45.926-.025-.007-9.373-2.344a2.145 2.145 0 0 1-1.617-2.237 2.15 2.15 0 0 1 .3-.944l.31-.516a3.517 3.517 0 0 0 .143-3.356 3.696 3.696 0 0 0-2.127-1.817l-.031-.015-.197-.123a1.537 1.537 0 0 1-.813-.94 1.587 1.587 0 0 1 .156-1.278 7.75 7.75 0 0 1 3.542-3.122 7.997 7.997 0 0 1 5.878-.177 1.044 1.044 0 0 1 .96-.244c4.854 1.176 5.254 4.434 4.295 9.664l1.135 4.578a2.298 2.298 0 0 1-.366 1.894 2.29 2.29 0 0 1-1.68.945l-.49.039Zm-3.906-16.824Zm.117-.029h.001-.001ZM134.614 144.826h-2.42l-.432-2.284-1.107 2.284h-6.418a1.446 1.446 0 0 1-1.377-1.013 1.442 1.442 0 0 1 .557-1.617l5.126-3.54v-2.309l5.391.321.68 8.158ZM167.27 144.826h-2.42l-.432-2.284-1.106 2.284h-6.418c-.308 0-.608-.098-.856-.28a1.452 1.452 0 0 1-.522-.733 1.442 1.442 0 0 1 .558-1.617l5.125-3.54v-2.309l5.391.321.68 8.158Z"
        fill="#2F2E41"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h204v145.4H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default AcceptTerms;
