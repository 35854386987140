import React, { useMemo } from 'react';
import { ImageSourcePropType, Pressable, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native-paper';
import { initials } from '../../../../../utilities/formatUtils';
import CommonAvatar from '../../../../../components/CommonAvatar';
import { colors } from '../../../../../assets';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface PaymentRecipientProps {
  recipientName?: string;
  recipientAccount: string;
  disabled: boolean;
  avatar?: ImageSourcePropType;
  onRecipientPressed?: () => void;
}

const PaymentRecipient = ({
  recipientName,
  recipientAccount,
  disabled,
  avatar,
  onRecipientPressed,
}: PaymentRecipientProps) => {
  const { t } = useTranslation();

  const title = recipientName ? recipientName : recipientAccount;
  const description = recipientName ? recipientAccount : null;

  const itemAvatar = useMemo(() => {
    return (
      <CommonAvatar
        size={40}
        image={avatar}
        text={recipientName ? initials(recipientName) : recipientAccount.slice(0, 3)}
      />
    );
  }, [recipientName, recipientAccount, avatar]);

  return (
    <View style={styles.container}>
      <Text variant={'titleSmall'}>{t('payment.paymentRecipient.titleLabel')}</Text>
      <Pressable
        disabled={disabled}
        onPress={onRecipientPressed}
        style={{
          ...styles.pressable,
          backgroundColor: disabled ? colors.ui_whisper : colors.ui_base_bg,
        }}
      >
        <View style={styles.outerRow}>
          {itemAvatar}
          <View style={styles.column}>
            <View style={styles.innerRow}>
              <Text
                variant={'bodyLarge'}
                numberOfLines={disabled ? 2 : 1}
                ellipsizeMode={'tail'}
                style={styles.title}
              >
                {title}
              </Text>
              {!disabled && <MaterialCommunityIcons name={'menu-down'} size={24} />}
            </View>
            {description && (
              <Text
                variant={'bodySmall'}
                style={styles.description}
                numberOfLines={2}
                ellipsizeMode={'tail'}
              >
                {description ?? ''}
              </Text>
            )}
          </View>
        </View>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginTop: 8,
  },
  pressable: {
    marginTop: 8,
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    padding: 12,
  },
  outerRow: { flexDirection: 'row', alignItems: 'center' },
  column: { flexDirection: 'column', marginStart: 12, flexShrink: 1 },
  innerRow: { flexDirection: 'row' },
  title: { flexShrink: 1 },
  description: { opacity: 0.6 },
});

export default PaymentRecipient;
