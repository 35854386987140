export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: number;
  DateTime: string;
};

export type Account = {
  __typename?: 'Account';
  availableBalance: Scalars['Float'];
  avatarData?: Maybe<AvatarData>;
  balance: Scalars['Float'];
  currencyCode: Scalars['String'];
  iban: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  number: AccountNumber;
  openingDate: Scalars['String'];
  partyId: Scalars['String'];
  type: AccountType;
};

export type AccountNumber = {
  __typename?: 'AccountNumber';
  bankCode: Scalars['String'];
  number: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
};

export enum AccountType {
  Current = 'CURRENT',
  Loan = 'LOAN',
  Mortgage = 'MORTGAGE',
  Saving = 'SAVING',
  TermDeposit = 'TERM_DEPOSIT',
  Unknown = 'UNKNOWN',
}

export type Activation = {
  __typename?: 'Activation';
  applicationId: Scalars['String'];
  deviceType: MobileDeviceType;
  flags?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  registrationId: Scalars['String'];
  registrationStatus: ActivationStatus;
  timestampCreated: Scalars['Float'];
  timestampLastUsed: Scalars['Float'];
  userId: Scalars['String'];
};

export type ActivationCommitResponse = {
  __typename?: 'ActivationCommitResponse';
  status?: Maybe<Scalars['String']>;
};

export type ActivationCreateResponse = {
  __typename?: 'ActivationCreateResponse';
  activationCode: Scalars['String'];
  activationId: Scalars['String'];
};

export enum ActivationStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Created = 'CREATED',
  PendingCommit = 'PENDING_COMMIT',
  Removed = 'REMOVED',
}

export type AppParams = {
  deviceType: Scalars['String'];
  platform: Scalars['String'];
  version: Scalars['String'];
};

export type AvatarData = {
  __typename?: 'AvatarData';
  data?: Maybe<Scalars['String']>;
  key: AvatarKey;
  updatedTs?: Maybe<Scalars['Float']>;
};

export type AvatarKey = {
  __typename?: 'AvatarKey';
  id: Scalars['String'];
  type: AvatarType;
};

export enum AvatarType {
  Category = 'CATEGORY',
  Global = 'GLOBAL',
  Merchant = 'MERCHANT',
  Party = 'PARTY',
}

export type Card = {
  __typename?: 'Card';
  bookingDate: Scalars['DateTime'];
  number: Scalars['String'];
  online: Scalars['Boolean'];
  paymentAddress: Scalars['String'];
  paymentDate: Scalars['DateTime'];
  walletType: WalletType;
};

export enum Direction {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

export type DocumentBinaryData = {
  __typename?: 'DocumentBinaryData';
  data: Scalars['String'];
  name: Scalars['String'];
};

export type DocumentMetaData = {
  __typename?: 'DocumentMetaData';
  createdAt: Scalars['String'];
  documentId: Scalars['String'];
  fileName: Scalars['String'];
  modifiedAt: Scalars['String'];
  properties: DocumentProperties;
};

export type DocumentProperties = {
  __typename?: 'DocumentProperties';
  documentCategory?: Maybe<Scalars['String']>;
  documentSubcategory?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  partyId?: Maybe<Scalars['String']>;
  processId?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export type Domestic = {
  __typename?: 'Domestic';
  constantSymbol?: Maybe<Scalars['String']>;
  counterPartyAccountNumber: AccountNumber;
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  messageForRecipient: Scalars['String'];
  specificSymbol?: Maybe<Scalars['String']>;
  variableSymbol?: Maybe<Scalars['String']>;
};

export type DomesticPaymentOrder = {
  amount: Scalars['Float'];
  constantSymbol?: InputMaybe<Scalars['String']>;
  creditAccountNumber: TargetAccountNumber;
  debitAccountId: Scalars['String'];
  executionDate?: InputMaybe<Scalars['String']>;
  messageForMe?: InputMaybe<Scalars['String']>;
  messageForRecipient?: InputMaybe<Scalars['String']>;
  specificSymbol?: InputMaybe<Scalars['String']>;
  variableSymbol?: InputMaybe<Scalars['String']>;
};

export enum ErrorCode {
  ActivationLimitExceeded = 'ACTIVATION_LIMIT_EXCEEDED',
  GetToken = 'GET_TOKEN',
  InvalidSignature = 'INVALID_SIGNATURE',
  InvalidSms = 'INVALID_SMS',
  NoDocForId = 'NO_DOC_FOR_ID',
  RefreshTokenDenied = 'REFRESH_TOKEN_DENIED',
  Undefined = 'UNDEFINED',
  Unknown = 'UNKNOWN',
  VerifyApp = 'VERIFY_APP',
}

export enum FeatureFlag {
  None = 'NONE',
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
}

export enum Language {
  Cs = 'CS',
  En = 'EN',
  Other = 'OTHER',
}

export type LoginData = {
  appParams: AppParams;
  paParams: PaParams;
};

export enum MobileDeviceType {
  Ipad = 'IPAD',
  Iphone = 'IPHONE',
  Phone = 'PHONE',
  Tablet = 'TABLET',
  Unknown = 'UNKNOWN',
}

export type Movement = {
  __typename?: 'Movement';
  accountNumber?: Maybe<AccountNumber>;
  accounted: Scalars['Boolean'];
  amount: Scalars['BigDecimal'];
  avatar: AvatarKey;
  country: Scalars['String'];
  currencyCode?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  direction: Direction;
  id: Scalars['String'];
  originalAmount?: Maybe<Scalars['BigDecimal']>;
  originalCurrencyCode?: Maybe<Scalars['String']>;
  reference: Scalars['String'];
  source: Source;
  state: MovementState;
  subAvatar?: Maybe<AvatarKey>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum MovementState {
  Cancelled = 'CANCELLED',
  NotProcessed = 'NOT_PROCESSED',
  Planned = 'PLANNED',
  Processed = 'PROCESSED',
  WaitingForAuthorisation = 'WAITING_FOR_AUTHORISATION',
}

export type Mutation = {
  __typename?: 'Mutation';
  accessTokenRefresh: TokenData;
  commitActivation: ActivationCommitResponse;
  /**  appId is optional for old app which is dependent on PA with app cz_timmo_mtimmo, any other app need fill this param   */
  createActivation: ActivationCreateResponse;
  createApplication: Scalars['String'];
  domesticPaymentOrder: Scalars['Boolean'];
  /** Login with Powerauth. Payload is empty JSON object '{}' in base64 encoding, Sign it with method:'POST' and uriId:'/login'  */
  login: TokenData;
  logout: Scalars['Boolean'];
  putAvatar?: Maybe<Scalars['String']>;
  /**  Payload is signed object RemoveActivationsData in base64 encoding, Sign it with method:'DELETE' and uriId:'/removeActivation'  */
  removeActivations: Scalars['Boolean'];
  toaPaymentOrder: Scalars['Boolean'];
  /** Login with user/pass  */
  userLogin: TokenData;
};

export type MutationAccessTokenRefreshArgs = {
  refreshTokenInput: RefreshTokenInput;
};

export type MutationCommitActivationArgs = {
  activationId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationCreateActivationArgs = {
  appId?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<Array<Scalars['String']>>;
  userId: Scalars['String'];
};

export type MutationCreateApplicationArgs = {
  userId: Scalars['ID'];
};

export type MutationDomesticPaymentOrderArgs = {
  domesticPaymentOrder: DomesticPaymentOrder;
};

export type MutationLoginArgs = {
  loginData: LoginData;
};

export type MutationLogoutArgs = {
  refreshTokenInput: RefreshTokenInput;
};

export type MutationPutAvatarArgs = {
  data?: InputMaybe<Scalars['String']>;
};

export type MutationRemoveActivationsArgs = {
  paParams: PaParams;
};

export type MutationToaPaymentOrderArgs = {
  paymentOrder: ToaPaymentOrder;
};

export type MutationUserLoginArgs = {
  loginData: UserLoginData;
};

export type PaParams = {
  paAuthHeader: Scalars['String'];
  paPayload: Scalars['String'];
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

export type Process = {
  __typename?: 'Process';
  createdAt: Scalars['String'];
  customerId: Scalars['String'];
  id: Scalars['String'];
  modifiedAt?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taskResult?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  accounts?: Maybe<Array<Account>>;
  activations?: Maybe<Array<Activation>>;
  application: Process;
  applicationState?: Maybe<Scalars['String']>;
  /**  Every Subgraph have to has at least one query, and this is it ;-)  */
  authenticationSubgraphMandatoryQuery?: Maybe<Scalars['Boolean']>;
  documentBinaryData: DocumentBinaryData;
  documentMetaData: DocumentMetaData;
  documentsByType?: Maybe<Array<DocumentMetaData>>;
  getAvatar?: Maybe<AvatarData>;
  movementsByAccount?: Maybe<Array<Movement>>;
  /**  Every Subgraph have to has at least one query, and this is it ;-)  */
  paymentSubgraphMandatoryQuery?: Maybe<Scalars['Boolean']>;
  systemState: SystemStateResponse;
  testEvent?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type QueryAccountsArgs = {
  accountTypes?: InputMaybe<Array<AccountType>>;
  userId: Scalars['ID'];
};

export type QueryActivationsArgs = {
  userId: Scalars['String'];
};

export type QueryApplicationArgs = {
  applicationId: Scalars['String'];
};

export type QueryApplicationStateArgs = {
  applicationId: Scalars['String'];
};

export type QueryDocumentBinaryDataArgs = {
  documentId: Scalars['ID'];
};

export type QueryDocumentMetaDataArgs = {
  documentId: Scalars['ID'];
};

export type QueryDocumentsByTypeArgs = {
  documentCategory: Scalars['String'];
  documentSubcategory?: InputMaybe<Scalars['String']>;
};

export type QueryGetAvatarArgs = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AvatarType>;
};

export type QueryMovementsByAccountArgs = {
  count?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySystemStateArgs = {
  language: Language;
  platform: Platform;
  version: Scalars['String'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type RemoveActivationsData = {
  __typename?: 'RemoveActivationsData';
  activationIds?: Maybe<Array<Scalars['String']>>;
  userId: Scalars['String'];
};

export type SendSmsData = {
  authCode: Scalars['String'];
};

export type SendSmsResponse = {
  __typename?: 'SendSmsResponse';
  maxAttempts: Scalars['Boolean'];
  ok: Scalars['Boolean'];
};

export type Sipo = {
  __typename?: 'Sipo';
  sipoName: Scalars['String'];
  sipoNumber: Scalars['String'];
};

export type Source = Card | Domestic | Sipo | StandingOrder | Toa;

export type StandingOrder = {
  __typename?: 'StandingOrder';
  constantSymbol?: Maybe<Scalars['String']>;
  counterPartyAccountNumber: AccountNumber;
  counterPartyName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  frequency: Scalars['String'];
  specificSymbol?: Maybe<Scalars['String']>;
  variableSymbol?: Maybe<Scalars['String']>;
};

export type SystemStateRequest = {
  language: Language;
  platform: Platform;
  version: Scalars['String'];
};

export type SystemStateResponse = {
  __typename?: 'SystemStateResponse';
  backendIsOnline: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  featureFlags?: Maybe<Array<FeatureFlag>>;
  updateAppUrl?: Maybe<Scalars['String']>;
  updateMessage?: Maybe<Scalars['String']>;
  updateType: UpdateType;
};

export type TargetAccountNumber = {
  bankCode: Scalars['String'];
  number: Scalars['String'];
  prefix?: InputMaybe<Scalars['String']>;
};

export type Toa = {
  __typename?: 'Toa';
  counterPartyAccountNumber: AccountNumber;
  description?: Maybe<Scalars['String']>;
};

export type ToaPaymentOrder = {
  amount: Scalars['Float'];
  creditAccountId: Scalars['String'];
  debitAccountId: Scalars['String'];
  executionDate?: InputMaybe<Scalars['String']>;
  messageForMe?: InputMaybe<Scalars['String']>;
};

export type TokenData = {
  __typename?: 'TokenData';
  accessToken?: Maybe<Scalars['String']>;
  expireTime?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum UpdateType {
  Force = 'FORCE',
  None = 'NONE',
  Optional = 'OPTIONAL',
}

export type User = {
  __typename?: 'User';
  avatarData?: Maybe<AvatarData>;
  birthDate: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Gender;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userState: UserState;
};

export type UserLoginData = {
  appParams: AppParams;
  password: Scalars['String'];
  userName: Scalars['String'];
};

export type UserLoginResponse = {
  __typename?: 'UserLoginResponse';
  authCode: Scalars['String'];
  sessionId: Scalars['String'];
};

export type UserPassData = {
  appParams: AppParams;
  authCode: Scalars['String'];
  password: Scalars['String'];
  smsCode: Scalars['String'];
};

export enum UserState {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
}

export enum WalletType {
  Any = 'ANY',
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY',
  None = 'NONE',
}

export type AccessTokenRefreshMutationVariables = Exact<{
  refreshTokenInput: RefreshTokenInput;
}>;

export type AccessTokenRefreshMutation = {
  __typename?: 'Mutation';
  accessTokenRefresh: {
    __typename?: 'TokenData';
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type LoginMutationVariables = Exact<{
  loginData: LoginData;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'TokenData';
    userId?: string | null;
    expireTime?: number | null;
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type LogoutMutationVariables = Exact<{
  refreshTokenInput: RefreshTokenInput;
}>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type AccountsQueryVariables = Exact<{
  userId: Scalars['ID'];
  accountTypes?: InputMaybe<Array<AccountType> | AccountType>;
}>;

export type AccountsQuery = {
  __typename?: 'Query';
  accounts?: Array<{
    __typename?: 'Account';
    id: string;
    name: string;
    type: AccountType;
    iban: string;
    currencyCode: string;
    balance: number;
    availableBalance: number;
    openingDate: string;
    number: {
      __typename?: 'AccountNumber';
      prefix?: string | null;
      number: string;
      bankCode: string;
    };
    avatarData?: {
      __typename?: 'AvatarData';
      updatedTs?: number | null;
      data?: string | null;
      key: { __typename?: 'AvatarKey'; type: AvatarType; id: string };
    } | null;
  }> | null;
};

export type ActivationsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type ActivationsQuery = {
  __typename?: 'Query';
  activations?: Array<{
    __typename?: 'Activation';
    registrationId: string;
    name: string;
    deviceType: MobileDeviceType;
    registrationStatus: ActivationStatus;
    timestampCreated: number;
  }> | null;
};

export type RemoveActivationsMutationVariables = Exact<{
  paParams: PaParams;
}>;

export type RemoveActivationsMutation = { __typename?: 'Mutation'; removeActivations: boolean };

export type CreateActivationMutationVariables = Exact<{
  userId: Scalars['String'];
  appId?: InputMaybe<Scalars['String']>;
}>;

export type CreateActivationMutation = {
  __typename?: 'Mutation';
  createActivation: {
    __typename?: 'ActivationCreateResponse';
    activationCode: string;
    activationId: string;
  };
};

export type CommitActivationMutationVariables = Exact<{
  userId: Scalars['String'];
  activationId: Scalars['String'];
}>;

export type CommitActivationMutation = {
  __typename?: 'Mutation';
  commitActivation: { __typename?: 'ActivationCommitResponse'; status?: string | null };
};

export type SystemStateQueryVariables = Exact<{
  platform: Platform;
  language: Language;
  version: Scalars['String'];
}>;

export type SystemStateQuery = {
  __typename?: 'Query';
  systemState: {
    __typename?: 'SystemStateResponse';
    backendIsOnline: boolean;
    updateType: UpdateType;
    updateAppUrl?: string | null;
    updateMessage?: string | null;
    featureFlags?: Array<FeatureFlag> | null;
    errorMessage?: string | null;
  };
};

export type MovementFragment = {
  __typename?: 'Movement';
  id: string;
  date: string;
  title: string;
  subtitle?: string | null;
  amount: number;
  currencyCode?: string | null;
  originalAmount?: number | null;
  originalCurrencyCode?: string | null;
  direction: Direction;
  state: MovementState;
  country: string;
  accounted: boolean;
  reference: string;
  accountNumber?: {
    __typename?: 'AccountNumber';
    prefix?: string | null;
    number: string;
    bankCode: string;
  } | null;
  source:
    | {
        __typename?: 'Card';
        number: string;
        paymentAddress: string;
        walletType: WalletType;
        online: boolean;
        paymentDate: string;
        bookingDate: string;
      }
    | {
        __typename?: 'Domestic';
        messageForRecipient: string;
        variableSymbol?: string | null;
        constantSymbol?: string | null;
        specificSymbol?: string | null;
        dueDate: string;
        description?: string | null;
        counterPartyAccountNumber: {
          __typename?: 'AccountNumber';
          prefix?: string | null;
          number: string;
          bankCode: string;
        };
      }
    | { __typename?: 'Sipo'; sipoNumber: string; sipoName: string }
    | {
        __typename?: 'StandingOrder';
        frequency: string;
        counterPartyName: string;
        variableSymbol?: string | null;
        constantSymbol?: string | null;
        specificSymbol?: string | null;
        dueDate: string;
        description?: string | null;
        counterPartyAccountNumber: {
          __typename?: 'AccountNumber';
          prefix?: string | null;
          number: string;
          bankCode: string;
        };
      }
    | {
        __typename?: 'Toa';
        description?: string | null;
        counterPartyAccountNumber: {
          __typename?: 'AccountNumber';
          prefix?: string | null;
          number: string;
          bankCode: string;
        };
      };
  avatar: { __typename?: 'AvatarKey'; type: AvatarType; id: string };
  subAvatar?: { __typename?: 'AvatarKey'; type: AvatarType; id: string } | null;
};

export type MovementsByAccountQueryVariables = Exact<{
  movementsByAccountId: Scalars['ID'];
  count?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type MovementsByAccountQuery = {
  __typename?: 'Query';
  movementsByAccount?: Array<{
    __typename?: 'Movement';
    id: string;
    date: string;
    title: string;
    subtitle?: string | null;
    amount: number;
    currencyCode?: string | null;
    originalAmount?: number | null;
    originalCurrencyCode?: string | null;
    direction: Direction;
    state: MovementState;
    country: string;
    accounted: boolean;
    reference: string;
    accountNumber?: {
      __typename?: 'AccountNumber';
      prefix?: string | null;
      number: string;
      bankCode: string;
    } | null;
    source:
      | {
          __typename?: 'Card';
          number: string;
          paymentAddress: string;
          walletType: WalletType;
          online: boolean;
          paymentDate: string;
          bookingDate: string;
        }
      | {
          __typename?: 'Domestic';
          messageForRecipient: string;
          variableSymbol?: string | null;
          constantSymbol?: string | null;
          specificSymbol?: string | null;
          dueDate: string;
          description?: string | null;
          counterPartyAccountNumber: {
            __typename?: 'AccountNumber';
            prefix?: string | null;
            number: string;
            bankCode: string;
          };
        }
      | { __typename?: 'Sipo'; sipoNumber: string; sipoName: string }
      | {
          __typename?: 'StandingOrder';
          frequency: string;
          counterPartyName: string;
          variableSymbol?: string | null;
          constantSymbol?: string | null;
          specificSymbol?: string | null;
          dueDate: string;
          description?: string | null;
          counterPartyAccountNumber: {
            __typename?: 'AccountNumber';
            prefix?: string | null;
            number: string;
            bankCode: string;
          };
        }
      | {
          __typename?: 'Toa';
          description?: string | null;
          counterPartyAccountNumber: {
            __typename?: 'AccountNumber';
            prefix?: string | null;
            number: string;
            bankCode: string;
          };
        };
    avatar: { __typename?: 'AvatarKey'; type: AvatarType; id: string };
    subAvatar?: { __typename?: 'AvatarKey'; type: AvatarType; id: string } | null;
  }> | null;
};

export type ToaPaymentOrderMutationVariables = Exact<{
  paymentOrder: ToaPaymentOrder;
}>;

export type ToaPaymentOrderMutation = { __typename?: 'Mutation'; toaPaymentOrder: boolean };

export type DomesticPaymentOrderMutationVariables = Exact<{
  domesticPaymentOrder: DomesticPaymentOrder;
}>;

export type DomesticPaymentOrderMutation = {
  __typename?: 'Mutation';
  domesticPaymentOrder: boolean;
};

export type GetAvatarQueryVariables = Exact<{
  type?: InputMaybe<AvatarType>;
  id?: InputMaybe<Scalars['String']>;
}>;

export type GetAvatarQuery = {
  __typename?: 'Query';
  getAvatar?: {
    __typename?: 'AvatarData';
    data?: string | null;
    updatedTs?: number | null;
    key: { __typename?: 'AvatarKey'; id: string; type: AvatarType };
  } | null;
};

export type PutAvatarMutationVariables = Exact<{
  data?: InputMaybe<Scalars['String']>;
}>;

export type PutAvatarMutation = { __typename?: 'Mutation'; putAvatar?: string | null };

export type GetAvatarUpdatedQueryVariables = Exact<{
  type?: InputMaybe<AvatarType>;
  id?: InputMaybe<Scalars['String']>;
}>;

export type GetAvatarUpdatedQuery = {
  __typename?: 'Query';
  getAvatar?: { __typename?: 'AvatarData'; updatedTs?: number | null } | null;
};

export type UserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    birthDate: string;
    email?: string | null;
    firstName: string;
    gender: Gender;
    lastName: string;
    middleName?: string | null;
    phone?: string | null;
    id: string;
  } | null;
};
