import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useGlobalContext } from '../../../contexts/GlobalContext';

import { User, UserState } from '../../../generated/graphql';
import { graphQLService } from '../../../services/graphQLService';

type UserDetailContextType = {
  userDetailData?: User;
  loading: boolean;
  onRefresh: () => void;
};

const defaultState: UserDetailContextType = {
  userDetailData: undefined,
  loading: true,
  onRefresh: () => {},
};

const UserDetailContext = createContext<UserDetailContextType>(defaultState);

export const UserDetailProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userData } = useGlobalContext();

  const [userDetailData, setUserDetailData] = useState<User | undefined>(
    defaultState.userDetailData,
  );
  const [loading, setLoading] = useState(defaultState.loading);

  const refresh = () => {
    console.debug('onRefresh');
    setUserDetailData(undefined);
    getUserDetailData();
  };

  const getUserDetailData = useCallback(() => {
    setLoading(true);
    if (!userData.userId) {
      setLoading(false);
      return;
    }
    graphQLService
      .queryUserDetail(userData.userId)
      .then((response) => {
        console.debug(response.user);
        if (response.user)
          setUserDetailData({
            avatarData: undefined,
            id: response.user.id,
            firstName: response.user.firstName,
            middleName: response.user.middleName,
            lastName: response.user.lastName,
            gender: response.user.gender,
            birthDate: response.user.birthDate,
            email: response.user.email,
            phone: response.user.phone,
            userState: UserState.Active,
          });
        else {
          setUserDetailData(undefined);
        }
      })
      .catch((reason) => {
        console.error(reason);
      })
      .finally(() => setLoading(false));
  }, [userData.userId]);

  useEffect(() => {
    getUserDetailData();
  }, [getUserDetailData, userData]);

  return (
    <UserDetailContext.Provider
      value={{
        userDetailData: userDetailData,
        loading: loading,
        onRefresh: refresh,
      }}
    >
      {children}
    </UserDetailContext.Provider>
  );
};

export const useUserDetail = () => {
  const context = useContext(UserDetailContext);

  if (!context) {
    throw new Error('useUserDetail() must be used within an UserDetailProvider');
  }

  return context;
};
