import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Erase = (props: SvgProps) => (
  <Svg viewBox="0 0 28 28" fill="none" {...props}>
    <Path
      d="m13.658 18.325 2.675-2.675 2.675 2.675 1.65-1.65L17.983 14l2.675-2.675-1.65-1.65-2.675 2.675-2.675-2.675-1.65 1.65L14.683 14l-2.675 2.675 1.65 1.65Z"
      fill={props.color ?? '#000'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.917 4.667c-.358 0-.697.164-.918.446l-6.416 8.166a1.167 1.167 0 0 0 0 1.442l6.416 8.166c.221.282.56.446.918.446h12.25a3.5 3.5 0 0 0 3.5-3.5V8.167a3.5 3.5 0 0 0-3.5-3.5H9.917ZM4.984 14l5.5-7h11.683c.644 0 1.166.522 1.166 1.167v11.666c0 .645-.522 1.167-1.166 1.167H10.484l-5.5-7Z"
      fill={props.color ?? '#000'}
    />
  </Svg>
);

export default Erase;
