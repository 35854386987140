import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Button } from 'react-native-paper';
import { colors } from '../../../assets';
import { RootStackParamList } from '../../../types';
import { useTranslation } from 'react-i18next';
import { LogoutReason, useGlobalContext } from '../../../contexts/GlobalContext';
import { useTheme } from '../../../utilities/reactUtils';

// screens
import HomeScreen from '../../../screens/Main/Home/HomeScreen';
import AccountDetailScreen from '../../../screens/Main/Home/AccountDetailScreen';
import MovementDetailScreen from '../../../screens/Main/Home/MovementDetailScreen';
import HomeSettingsScreen from '../../../screens/Main/HomeSettings/HomeSettingsScreen';
import AccountDetailHeader from '../../../screens/Main/Home/AccountDetailHeader';
import { prepareTestID } from '../../../utilities/utils';

const Stack = createNativeStackNavigator<RootStackParamList>();

function HomeStack() {
  const { t } = useTranslation('resources');
  const theme = useTheme();
  const globalContext = useGlobalContext();

  function _onPressButtonLogout() {
    void globalContext.logout(LogoutReason.MANUAL);
  }

  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerStyle: { backgroundColor: colors.ui_white },
        headerShadowVisible: false,
      }}
    >
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: t('home.title'),
          headerLargeTitle: true,
          headerTitleStyle: { color: colors.ui_black },
          headerRight: () => (
            <>
              <Button
                style={{ paddingRight: 16 }}
                mode="text"
                textColor={theme.colors.secondary}
                onPress={_onPressButtonLogout}
                testID={prepareTestID('profile_screen_log_out_button')}
              >
                {t('home.logOut')}
              </Button>
            </>
          ),
        }}
      />
      <Stack.Screen
        name="AccountDetail"
        component={AccountDetailScreen}
        options={({
          route: {
            params: { account },
          },
        }) => ({
          header: ({ navigation }) => (
            <AccountDetailHeader account={account} navigation={navigation} />
          ),
        })}
      />
      <Stack.Screen
        name="MovementDetail"
        component={MovementDetailScreen}
        options={{
          title: '',
          headerTitleStyle: { color: colors.ui_black },
        }}
      />
      <Stack.Screen
        name="HomeSettings"
        component={HomeSettingsScreen}
        options={{
          title: '',
          headerTitleStyle: { color: colors.ui_black },
        }}
      />
    </Stack.Navigator>
  );
}

export default HomeStack;
